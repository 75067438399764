import { getThenaDB } from "@/db";
import { useDuckDBStore } from "@/store/duckDBStore";
import {
  TABLE_CREATION_QUERIES,
  TABLE_INSERT_QUERIES,
  TABLE_TRUNCATION_QUERIES,
  transformObjectToList,
} from "@/utils/duckDB";
import * as duckdb from "@duckdb/duckdb-wasm";
import eh_worker from "@duckdb/duckdb-wasm/dist/duckdb-browser-eh.worker.js?url";
import mvp_worker from "@duckdb/duckdb-wasm/dist/duckdb-browser-mvp.worker.js?url";
import duckdb_wasm_eh from "@duckdb/duckdb-wasm/dist/duckdb-eh.wasm?url";
import duckdb_wasm from "@duckdb/duckdb-wasm/dist/duckdb-mvp.wasm?url";
import { crmRelationDB } from "./useCRMFilterInit";
import { useGlobalStore } from "@/store/globalStore";
import * as Sentry from "@sentry/browser";

const MANUAL_BUNDLES: duckdb.DuckDBBundles = {
  mvp: {
    mainModule: duckdb_wasm,
    mainWorker: mvp_worker,
  },
  eh: {
    mainModule: duckdb_wasm_eh,
    mainWorker: eh_worker,
  },
};

export const truncateTables = async (connection: any) => {
  try {
    const truncatePromises: Promise<any>[] = [];
    for (const key in TABLE_TRUNCATION_QUERIES) {
      const query = TABLE_TRUNCATION_QUERIES[key]();
      truncatePromises.push(connection.query(query));
    }
    await Promise.all(truncatePromises);
    console.log("All tables truncated successfully");
  } catch (error) {
    console.log("Error truncating tables", error);
  }
};

const loadDataToDuckDB = async (
  connection: any,
  tableName:
    | "requests"
    | "relationships"
    | "users"
    | "subStatuses"
    | "crmFields"
) => {
  try {
    const insertPromises: any[] = [];

    switch (tableName) {
      case "crmFields": {
        const allData = await crmRelationDB.table("crmRelations").toArray();
        for (const data of allData) {
          const resultList = transformObjectToList(data);
          resultList.forEach((data) => {
            insertPromises.push(
              connection.query(TABLE_INSERT_QUERIES[tableName](data))
            );
          });
        }
        break;
      }

      default: {
        const allData = await getThenaDB().table(tableName).toArray();

        for (const data of allData) {
          insertPromises.push(
            connection.query(TABLE_INSERT_QUERIES[tableName](data))
          );

          if (tableName === "requests") {
            const customFields = Object.keys(data)
              .filter((key) => key.includes("custom_"))
              .map((key) => {
                const value = data[key];
                return {
                  _id: data._id,
                  field_hash: key,
                  field_values: value,
                };
              });
            customFields.forEach((customField) => {
              insertPromises.push(
                connection.query(
                  TABLE_INSERT_QUERIES["customFields"](customField)
                )
              );
            });
            if (!customFields.length) {
              insertPromises.push(
                connection.query(
                  TABLE_INSERT_QUERIES["customFields"]({
                    _id: data._id,
                    field_hash: "",
                    field_values: [],
                  })
                )
              );
            }
          }
        }
        break;
      }
    }

    await Promise.all(insertPromises);
  } catch (error) {
    console.log("Error loading data to DuckDB", error);
  }
};

export const initDuckDB = async () => {
  try {
    let dbConn = useDuckDBStore.getState().dbConnection;
    if (!dbConn) {
      const bundle = await duckdb.selectBundle(MANUAL_BUNDLES);
      const worker = new Worker(bundle.mainWorker!);
      const logger = new duckdb.ConsoleLogger(4);
      const db = new duckdb.AsyncDuckDB(logger, worker);
      await db.instantiate(bundle.mainModule, bundle.pthreadWorker);
      const conn = await db.connect();
      //
      useDuckDBStore.getState().setDbInstance(db);
      useDuckDBStore.getState().setDbConnection(conn);
      dbConn = useDuckDBStore.getState().dbConnection;
      //
      const promises = Object.keys(TABLE_CREATION_QUERIES).map((key) => {
        const query = TABLE_CREATION_QUERIES[key];
        return dbConn.query(query);
      });
      await Promise.all(promises);
    }
    dbConn = useDuckDBStore.getState().dbConnection;
    //
    const loadDataPromises = [
      loadDataToDuckDB(dbConn, "requests"),
      loadDataToDuckDB(dbConn, "relationships"),
      loadDataToDuckDB(dbConn, "users"),
      loadDataToDuckDB(dbConn, "subStatuses"),
      loadDataToDuckDB(dbConn, "crmFields"),
    ];
    await Promise.all(loadDataPromises);
    useGlobalStore.setState({ analyticsInitLoading: false });
    const duckDBInitCount = useGlobalStore.getState().duckDBInitCount;
    useGlobalStore.getState().setDuckDBInitCount(duckDBInitCount + 1);

    const allRequests = await window.runThenaDBQuery(
      `SELECT COUNT(*) AS count FROM requests`
    );

    if (Number(allRequests?.[0]?.count) <= 0) {
      console.log("DuckDB initialized but no requests found.");
      Sentry.withScope((scope) => {
        scope.setTag("duckdb-error", "true");
        scope.setLevel("error");
        Sentry.captureMessage("DuckDB initialized but no requests found.");
      });
      useGlobalStore.getState().setDuckDBInitCount(duckDBInitCount - 1);
      await truncateTables(dbConn);
      await Promise.all(loadDataPromises);
      useGlobalStore.getState().setDuckDBInitCount(duckDBInitCount + 1);
    }
  } catch (error) {
    console.log("Error initializing DuckDB", error);
  }
};
