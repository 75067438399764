import { create } from "zustand";

interface StateType {
  chartData: any[];
  setChartData: (data: any[]) => void;
  dateType: string;
  setDateType: (data: string) => void;
  tableList: any[];
  setTableList: (data: any[]) => void;
  topRequest: any;
  setTopRequest: (data: any) => void;
  openRequest: any[];
  setOpenRequest: (data: any[]) => void;
  firstResponse: any[];
  setFirstResponse: (data: any[]) => void;
  requestClosureTime: any[];
  setRequestClosureTime: (data: any[]) => void;
  firstResponseAlertCount: any[];
  setFirstResponseAlertCount: (data: any[]) => void;
  requestClosureAlertCount: any[];
  setRequestClosureAlertCount: (data: any[]) => void;
  requestsByHour: any[];
  setRequestsByHour: (data: any[]) => void;
  requestsByDay: any[];
  setRequestsByDay: (data: any[]) => void;
  customField: any;
  setCustomField: (data: any) => void;
  topValues: any[];
  setTopValues: (data: any[]) => void;
  accountRequests: any;
  setAccountRequests: (data: any) => void;
  requestOverTime: any[];
  setRequestOverTime: (data: any[]) => void;
  customFieldChartData: any[];
  setCustomFieldChartData: (data: any[]) => void;
  triggerRender: number;
  setTriggerRender: (n: number) => void;
  assignee: any;
  setAssignee: (data: any) => void;
  frtAssignee: any;
  setFrtAssignee: (data: any) => void;
  resolutionAssignee: any;
  setResolutionAssignee: (data: any) => void;
  assigneeMatrix: any;
  setAssigneeMatrix: (data: any) => void;
  firstResponseP50: any[];
  setFirstResponseP50: (data: any[]) => void;
  requestClosureTimeP50: any[];
  setRequestClosureTimeP50: (data: any[]) => void;
  customFieldMatrix: any[];
  setCustomFieldMatrix: (data: any[]) => void;
  selectedFilter: string;
  setSelectedFilter: (data: string) => void;
}

export const useAnalyticsStore = create<StateType>((set) => ({
  chartData: [],
  setChartData: (data: any[]) => set({ chartData: data }),
  dateType: "day",
  setDateType: (data: string) => set({ dateType: data }),
  tableList: [],
  setTableList: (data: any[]) => set({ tableList: data }),
  topRequest: {},
  setTopRequest: (data: any) => set({ topRequest: data }),
  openRequest: [],
  setOpenRequest: (data: any[]) => set({ openRequest: data }),
  firstResponse: [],
  setFirstResponse: (data: any[]) => set({ firstResponse: data }),
  requestClosureTime: [],
  setRequestClosureTime: (data: any[]) => set({ requestClosureTime: data }),
  firstResponseAlertCount: [],
  setFirstResponseAlertCount: (data: any[]) =>
    set({ firstResponseAlertCount: data }),
  requestClosureAlertCount: [],
  setRequestClosureAlertCount: (data: any[]) =>
    set({ requestClosureAlertCount: data }),
  requestsByHour: [],
  setRequestsByHour: (data: any[]) => set({ requestsByHour: data }),
  requestsByDay: [],
  setRequestsByDay: (data: any[]) => set({ requestsByDay: data }),
  customField: null,
  setCustomField: (data: any) => set({ customField: data }),
  topValues: [],
  setTopValues: (data: any[]) => set({ topValues: data }),
  accountRequests: null,
  setAccountRequests: (data: any) => set({ accountRequests: data }),
  requestOverTime: [],
  setRequestOverTime: (data: any[]) => set({ requestOverTime: data }),
  customFieldChartData: [],
  setCustomFieldChartData: (data: any[]) => set({ customFieldChartData: data }),
  triggerRender: 0,
  setTriggerRender: (n: number) => set({ triggerRender: n }),
  assignee: [],
  setAssignee: (data: any) => set({ assignee: data }),
  frtAssignee: [],
  setFrtAssignee: (data: any) => set({ frtAssignee: data }),
  resolutionAssignee: [],
  setResolutionAssignee: (data: any) => set({ resolutionAssignee: data }),
  assigneeMatrix: [],
  setAssigneeMatrix: (data: any) => set({ assigneeMatrix: data }),
  firstResponseP50: [],
  setFirstResponseP50: (data: any[]) => set({ firstResponseP50: data }),
  requestClosureTimeP50: [],
  setRequestClosureTimeP50: (data: any[]) =>
    set({ requestClosureTimeP50: data }),
  customFieldMatrix: [],
  setCustomFieldMatrix: (data: any[]) => set({ customFieldMatrix: data }),
  selectedFilter: "",
  setSelectedFilter: (data: string) => set({ selectedFilter: data }),
}));
