/* eslint-disable @typescript-eslint/no-explicit-any */
import { RequestFilterType } from "@/types/requests";
import { subDays } from "date-fns";
import { cloneDeep } from "lodash";
import { create } from "zustand";
import { devtools, redux, persist } from "zustand/middleware";

export type StoreType = {
  filters: RequestFilterType;
  view: "BOARD" | "LIST";
  queryValuesMap: Record<
    string,
    {
      values: string[];
      indexed_key: string;
    }
  >;
  searchQuery: string;
  sortby: {
    field: string;
    order: "ASC" | "DESC";
  };
  showAiTitle: boolean;
  selectedFiltersOrder: string[];
  displayOptions: {
    card: {
      firstResponseSla: boolean;
      resolutionSla: boolean;
      aiTags: boolean;
      crmFields: boolean;
      visibleCrmFields: { label: string; value: string }[];
      closedRequestsShowPeriod: string;
    };
    drawer: {
      firstResponseSla: boolean;
      resolutionSla: boolean;
      aiTags: boolean;
      crmFields: boolean;
      visibleCrmFields: { label: string; value: string }[];
    };
  };
  externalLinksForm: any[];
};

type ActionType = {
  type:
    | "RESET"
    | "RESET_FILTERS"
    | "SET_FILTERS"
    | "SEARCH"
    // | "SET_FILTER_URGENCY"
    | "SET_FILTER_DATE"
    | "SET_FILTER_PRESELECTED_DATE_RANGE"
    | "SET_VIEW"
    | "SET_QUERY_VALUES_MAP"
    | "SET_SORT_BY"
    | "REMOVE_FILTER"
    | "SET_SHOW_AI_TITLE"
    | "SET_DISPLAY_OPTIONS"
    | "SET_EXTERNAL_LINKS_FORM"
    | "SET_FILTERS_FROM_DB";
  payload?: NonNullable<any>;
};

const initialState: StoreType = {
  filters: {
    urgency: [],
    assignee: [],
    sentiment: [],
    dateRange: {
      from: subDays(new Date(), 30),
      to: new Date(),
    },
    preselectedRange: "30 days",
  },
  searchQuery: "",
  view: "BOARD",
  queryValuesMap: {},
  sortby: {
    field: "created_at_date",
    order: "DESC",
  },
  showAiTitle: false,
  selectedFiltersOrder: [],
  displayOptions: {
    card: {
      firstResponseSla: true,
      resolutionSla: true,
      aiTags: false,
      crmFields: true,
      visibleCrmFields: [],
      closedRequestsShowPeriod: "past-week",
    },
    drawer: {
      firstResponseSla: true,
      resolutionSla: true,
      aiTags: false,
      crmFields: true,
      visibleCrmFields: [],
    },
  },
  externalLinksForm: [],
};

const reducer = (state: StoreType, action: ActionType): StoreType => {
  const { type, payload } = action;

  switch (type) {
    case "RESET": {
      return cloneDeep(initialState);
    }

    case "RESET_FILTERS": {
      const initialStateCopy = cloneDeep(initialState);
      initialStateCopy.displayOptions = state.displayOptions;
      initialStateCopy.externalLinksForm = state.externalLinksForm;
      initialStateCopy.searchQuery = state.searchQuery;
      return initialStateCopy;
    }

    case "SEARCH":
      if (!payload) return state;
      return {
        ...state,
        searchQuery: payload?.query,
      };

    // case "SET_FILTER_URGENCY": {
    //   if (!payload) return state;
    //   const { urgency } = payload;
    //   return {
    //     ...state,
    //     filters: {
    //       ...state.filters,
    //       urgency,
    //     },
    //   };
    // }

    case "SET_FILTERS": {
      if (!payload) return state;
      const { filters } = payload;
      return {
        ...state,
        filters,
      };
    }

    case "SET_FILTER_DATE": {
      if (!payload) return state;
      const { from, to } = payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          dateRange: {
            from,
            to,
          },
        },
      };
    }

    case "SET_FILTER_PRESELECTED_DATE_RANGE": {
      if (!payload) return state;
      const { preselectedRange } = payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          preselectedRange,
        },
      };
    }

    case "SET_VIEW":
      return {
        ...state,
        view: payload.view,
      };

    case "SET_QUERY_VALUES_MAP": {
      if (!payload) return state;
      const { query, values, indexed_key, isAtTheRateCalled } = payload;
      if (isAtTheRateCalled) {
        if (values?.length > 0) {
          return {
            ...state,
            queryValuesMap: {
              ...state.queryValuesMap,
              [query]: {
                values,
                indexed_key,
              },
            },
            selectedFiltersOrder: state.selectedFiltersOrder.includes(query)
              ? state.selectedFiltersOrder
              : [...state.selectedFiltersOrder, query],
          };
        }
        return {
          ...state,
          queryValuesMap: Object.fromEntries(
            Object.entries(state.queryValuesMap).filter(
              ([key]) => key !== query
            )
          ),
          selectedFiltersOrder: state.selectedFiltersOrder.filter(
            (key) => key !== query
          ),
        };
      }
      return {
        ...state,
        queryValuesMap: {
          ...state.queryValuesMap,
          [query]: {
            values,
            indexed_key,
          },
        },
        selectedFiltersOrder: state.selectedFiltersOrder.includes(query)
          ? state.selectedFiltersOrder
          : [...state.selectedFiltersOrder, query],
      };
    }

    case "SET_SORT_BY": {
      if (!payload) return state;
      const { field, order } = payload;
      return {
        ...state,
        sortby: {
          field,
          order,
        },
      };
    }

    case "REMOVE_FILTER": {
      if (!payload) {
        return state;
      }

      const { query } = payload;
      const queryValuesMap = cloneDeep(state.queryValuesMap);
      const selectedFiltersOrder = state.selectedFiltersOrder.filter(
        (filter) => filter !== query
      );
      delete queryValuesMap[query];

      return { ...state, queryValuesMap, selectedFiltersOrder };
    }

    case "SET_SHOW_AI_TITLE": {
      return {
        ...state,
        showAiTitle: payload.showAiTitle,
      };
    }

    case "SET_DISPLAY_OPTIONS": {
      if (!payload) return state;
      const { displayOptions } = payload;
      return {
        ...state,
        displayOptions,
      };
    }

    case "SET_EXTERNAL_LINKS_FORM": {
      if (!payload) return state;
      const { externalLinksForm } = payload;
      return {
        ...state,
        externalLinksForm,
      };
    }

    case "SET_FILTERS_FROM_DB": {
      const {
        dateRange,
        queryValuesMap,
        selectedFiltersOrder,
        preselectedRange,
        sortby,
      } = payload;

      return {
        ...state,
        queryValuesMap,
        selectedFiltersOrder,
        filters: { ...state.filters, dateRange, preselectedRange },
        sortby,
      };
    }

    default:
      return state;
  }
};

const useKanbanStorePersist = create(
  persist(
    devtools(redux(reducer, initialState), {
      name: "useKanbanStorePersist",
    }),
    {
      name: "useKanbanStorePersist",
      version: 0.9,
    }
  )
);

export { useKanbanStorePersist };
