import { getThenaDB } from "@/db";
import Dexie from "dexie";
import { useLiveQuery } from "dexie-react-hooks";
import { cloneDeep, debounce, uniqBy } from "lodash";
import { useEffect, useMemo, useRef } from "react";

const dbName = "thena-crm-relationships";
export const crmRelationDB = new Dexie(dbName);

crmRelationDB.version(1).stores({
  crmRelations: "_id",
});

export const getFormattedKey = (key: string) => {
  return `crm_${key}`;
};

export const getRedactedKey = (key: string) => {
  return key.replace("crm_", "");
};

const getProcessedValue = (value: any, isLabel = false) => {
  if (typeof value === "string") {
    return value;
  }
  if (typeof value === "number") {
    return value.toString();
  }
  if (typeof value === "object" && value?.reference) {
    return isLabel ? value.name : value.id;
  }
  return "-";
};

const filterValue = (val: any) => {
  if (typeof val === "number" || val === "") {
    return true;
  }
  return Boolean(val);
};

const getProcessedValues = (listOrValue: any, isLabel = false) => {
  if (Array.isArray(listOrValue)) {
    return listOrValue
      .filter(filterValue)
      .map((val) => getProcessedValue(val, isLabel));
  }
  if (filterValue(listOrValue)) {
    return getProcessedValue(listOrValue, isLabel);
  }
  return "-";
};

const updateSchema = async (relationshipsMap: any = [], keys: any[] = []) => {
  try {
    if (relationshipsMap.length && keys.length) {
      const multiEntryIndex = keys.map((key) => `*${key}`);
      if (crmRelationDB.isOpen()) {
        crmRelationDB.close();
      }
      crmRelationDB.version(crmRelationDB.verno + 1).stores({
        crmRelations: "_id, " + multiEntryIndex.join(","),
      });
      await crmRelationDB.open();
      const cleanedUpRelationMap = relationshipsMap.map((relation) => {
        const cloneDeepRelation = cloneDeep(relation);
        for (const key in cloneDeepRelation) {
          const value = cloneDeepRelation[key];
          if (Array.isArray(value)) {
            cloneDeepRelation[key] = value.map((val) => {
              if (typeof val === "object" && val?.reference) {
                return val.id;
              }
              return val;
            });
          }
        }
        return cloneDeepRelation;
      });
      crmRelationDB.table("crmRelations").bulkPut(cleanedUpRelationMap);
    }
  } catch (err) {
    console.error("Failed to update CRM DB schema/data: ", err);
  }
};

const debouncedUpdateSchema = debounce(updateSchema, 1000);

const useCRMFilterInit = () => {
  const filters = useLiveQuery(
    () => {
      return getThenaDB()
        .crmFields.toArray()
        .then((list) => {
          let fields: any[] = [];
          try {
            fields = list[0].fields;
            fields = fields.map((item) => {
              return {
                name: item.label,
                indexed_key: getFormattedKey(item.name),
              };
            });
          } catch (error) {
            //
          }
          return fields;
        });
    },
    [],
    []
  );

  const keyNames = useMemo(() => {
    return filters.map((filter) => filter.indexed_key);
  }, [filters]);

  const keyRef = useRef(keyNames);
  const filterListRef = useRef<any[]>([]);
  const isFiltersDirtyRef = useRef(false);

  const { relationshipsMap } = useLiveQuery(
    () => {
      return getThenaDB()
        .relationships.toArray()
        .then((list) => {
          const relationsMap: any[] = [];

          list.forEach((rel, index) => {
            relationsMap.push({
              _id: rel._id,
            });
            keyNames.forEach((key) => {
              relationsMap[index][key] = [];
            });
            const crmObject = rel.crm_values?.sinked_crm_object || {};
            const keys = Object.keys(crmObject).filter((key) => {
              return keyNames.includes(getFormattedKey(key));
            });
            keys.forEach((key) => {
              const value = crmObject[getRedactedKey(key)];
              if (Array.isArray(value)) {
                relationsMap[index][getFormattedKey(key)].push(
                  ...getProcessedValues(value)
                );
              } else {
                if (typeof value === "object" && value?.reference) {
                  relationsMap[index][getFormattedKey(key)].push(value);
                } else {
                  relationsMap[index][getFormattedKey(key)].push(
                    getProcessedValues(value)
                  );
                }
              }
            });
          });

          const newRelationsMap = relationsMap.map((obj) => {
            for (const key in obj) {
              if (Array.isArray(obj[key]) && obj[key].length === 0) {
                obj[key] = ["-"];
              }
            }
            return obj;
          });

          const uniqueValuesMap = Object.keys(newRelationsMap || {}).reduce(
            (acc, key) => {
              const obj = newRelationsMap?.[key] || {};
              keyNames.forEach((keyName) => {
                obj[keyName].forEach((value) => {
                  if (value) {
                    if (!acc[keyName]) {
                      acc[keyName] = [];
                    }
                    acc[keyName].push(value);
                    const isAccountObjectPresent = acc[keyName].some(
                      (item) => typeof item === "object" && item?.reference
                    );
                    if (isAccountObjectPresent) {
                      let list = [...new Set(acc[keyName])];
                      list = list.map((item) => {
                        if (typeof item !== "object") {
                          return {
                            id: item,
                            name: item,
                            reference: true,
                          };
                        }
                        return item;
                      });
                      acc[keyName] = uniqBy(list, "id");
                    } else {
                      acc[keyName] = [...new Set(acc[keyName])];
                    }
                  }
                });
              });
              return acc;
            },
            {}
          );

          isFiltersDirtyRef.current = true;

          filterListRef.current = filters.map((filter) => {
            let valuesList = uniqueValuesMap[filter.indexed_key] || [];
            const isArrayValues = Array.isArray(valuesList[0]);
            if (isArrayValues) {
              const uniqueValueList = valuesList.flat();
              valuesList = [...new Set(uniqueValueList)];
            }
            return {
              ...filter,
              values: valuesList.map((value) => {
                return {
                  label: getProcessedValues(value, true),
                  value: getProcessedValues(value),
                };
              }),
            };
          });
          keyRef.current = keyNames;
          return { relationshipsMap: newRelationsMap };
        });
    },
    [keyNames, filters],
    { relationshipsMap: [], filterList: [] }
  );

  useEffect(() => {
    const init = async () => {
      if (!isFiltersDirtyRef.current) {
        return;
      }
      isFiltersDirtyRef.current = false;

      getThenaDB()
        .filters.filter((filter) => {
          if (filter.indexed_key.includes("crm_")) {
            return true;
          }
          return false;
        })
        .delete()
        .then(() => {
          const newFilterList = filterListRef.current.map((obj) => {
            const clonedObj = cloneDeep(obj);
            const index = clonedObj.values.findIndex(
              (item) => item.label === "-"
            );
            if (index !== -1) {
              const emptyLabel = clonedObj.values.splice(index, 1)[0];
              clonedObj.values.unshift(emptyLabel);
            } else {
              clonedObj.values.unshift({ label: "-", value: "-" });
            }
            return clonedObj;
          });
          getThenaDB().filters.bulkPut(newFilterList);
        });
    };

    window.addEventListener("FILTERS_LANE_CLICKED", init);

    return () => {
      window.removeEventListener("FILTERS_LANE_CLICKED", init);
    };
  }, []);

  useEffect(() => {
    debouncedUpdateSchema(relationshipsMap, keyRef.current);
  }, [relationshipsMap]);
};

export default useCRMFilterInit;
