import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { toZonedTime, format } from "date-fns-tz";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const convertUTCtoIST = (utcDateString: string) => {
  const date = toZonedTime(new Date(utcDateString), "Asia/Kolkata");
  const formatedDate = format(date, "EEE, MMM do, hh:mm a");
  return { formatedDate, timeZone: "Asia/Kolkata" };
};

export const getPosition = (
  json: any,
  keyword: string
): { start: number; end: number } => {
  const jsonString = JSON.stringify(json, null, 2);
  const start = jsonString.indexOf(keyword);
  return { start, end: start + keyword.length };
};

export const measureTableElement =
  // measure dynamic row height,
  // except in firefox because it measures table border height incorrectly
  typeof window !== "undefined" && navigator.userAgent.indexOf("Firefox") === -1
    ? (element) => element?.getBoundingClientRect().height
    : undefined;

export const validateLink = (url: string) => {
  return url.match(/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/);
};

export function bytesToKilobytes(bytes) {
  const kilobytes = Math.floor(bytes / 1024);
  return kilobytes;
}
