import { fetchPatchKanban } from "@/api/kanban";
import { MODAL_TYPES } from "@/constants/kanban";
import { getThenaDB } from "@/db";
import { useKanbanStore } from "@/store/kanbanStore";
import { RequestType } from "@/types/requests";
import { cloneDeep, get } from "lodash";
import {
  File,
  FileCheck2,
  FileImage,
  FileSpreadsheet,
  FileText,
  FileVideo,
  Mail,
  MessagesSquare,
} from "lucide-react";
import pMap from "p-map";
import { toast } from "sonner";
import ClosedBoardIcon from "../../images/icons/closed-board.svg";
import HighPriorityIcon from "../../images/icons/high-priority.svg";
import InProgressBoardIcon from "../../images/icons/inprogress-board.svg";
import LowPriorityIcon from "../../images/icons/low-priority.svg";
import MedPriorityIcon from "../../images/icons/med-priority.svg";
import OnholdBoardIcon from "../../images/icons/onhold-board.svg";
import OpenBoardIcon from "../../images/icons/open-board.svg";
import SlackIcon from "../../images/icons/slack.svg?react";
import { processRequestData } from "../requests";
import MsTeamsLogo from "@/components/common/MSTeamsLogo/MsTeamsLogo";
import { smartUpdateDBWithTx } from "../dbUtils";

export const getRequestSourceInfo = (source?: string) => {
  if (source === "EMAIL") {
    return { source: "Email", icon: () => <Mail width={16} height={16} /> };
  }
  if (source === "WEB") {
    return {
      source: "Web",
      icon: () => <MessagesSquare width={16} height={16} />,
    };
  }
  if (source === "SLACK") {
    return {
      source: "Slack",
      icon: () => <SlackIcon width={14} height={14} />,
    };
  }
  if (source === "MS_TEAMS") {
    return {
      source: "MS Teams",
      icon: () => <MsTeamsLogo />,
    };
  }
  return null;
};

export const getUrgencyIcon = (
  urgency: RequestType["ai_metadata"]["urgency"] | string
) => {
  if (urgency?.toLowerCase() === "low") {
    return LowPriorityIcon;
  }
  if (urgency?.toLowerCase() === "medium") {
    return MedPriorityIcon;
  }
  if (urgency?.toLowerCase() === "high") {
    return HighPriorityIcon;
  }
  return "";
};

export const renderFileType = (fileType: string) => {
  if (fileType === "image/png") {
    return <FileImage size={18} color="#E05D86" />;
  }
  if (fileType === "text/csv") {
    return <FileSpreadsheet size={18} color="#E05D86" />;
  }
  if (fileType === "image/jpeg") {
    return <FileImage size={18} color="#E05D86" />;
  }
  if (fileType === "video/mp4") {
    return <FileVideo size={18} color="#E05D86" />;
  }
  if (fileType === "application/pdf") {
    return <FileCheck2 size={18} color="#E05D86" />;
  }
  if (fileType === "text/plain") {
    return <FileText size={18} color="#E05D86" />;
  } else {
    return <File size={18} color="#E05D86" />;
  }
};

export const getLaneIcon = (
  status: string,
  size: string | undefined = "20px"
) => {
  if (status === "INPROGRESS") {
    return (
      <img
        src={InProgressBoardIcon}
        alt="In progress"
        style={{ height: size, width: size }}
      />
    );
  }
  if (status === "ONHOLD")
    return (
      <img
        src={OnholdBoardIcon}
        alt="On hold"
        style={{ height: size, width: size }}
      />
    );
  if (status === "OPEN") {
    return (
      <img
        src={OpenBoardIcon}
        alt="Open"
        style={{ height: size, width: size }}
      />
    );
  }
  if (status === "CLOSED") {
    return (
      <img
        src={ClosedBoardIcon}
        alt="Closed"
        style={{ height: size, width: size }}
      />
    );
  }
};

export const getLaneTitle = (status: string) => {
  switch (status) {
    case "INPROGRESS":
      return "In progress";
    case "ONHOLD":
      return "On hold";
    case "OPEN":
      return "Open";
    case "CLOSED":
      return "Closed";
    default: {
      return status;
    }
  }
};

export const handleOptimisticUpdate = async (payload: {
  _id: string;
  payload: Record<string, any>;
}) => {
  useKanbanStore.dispatch({
    type: "UPDATE_REQUEST_STORE",
    payload,
  });
  try {
    useKanbanStore.dispatch({
      type: "INCREMENT_MUTATE_API_COUNT",
    });
    const res = await fetchPatchKanban(payload);
    if (res._id === payload._id) {
      const processedData = processRequestData(res);
      await smartUpdateDBWithTx([processedData], "requests");
    }
  } catch (error) {
    console.error(
      "Error updating request, reverting optimistic changes",
      error
    );
    const requestObj = await getThenaDB().requests.get({ _id: payload._id });
    if (!get(requestObj, "sub_status")) {
      // @ts-expect-error ignore
      requestObj.sub_status = null;
    }
    useKanbanStore.dispatch({
      type: "UPDATE_REQUEST_STORE",
      payload: {
        _id: payload._id,
        payload: {
          ...requestObj,
        },
      },
    });
    const errMsg = (error as any)?.response?.data?.message || "";
    if (errMsg.includes("Request cannot be closed without mandatory tags")) {
      useKanbanStore.dispatch({
        type: "SET_SHOW_MANDATORY_FIELD_ERROR",
        payload: { showMandatoryFieldError: true },
      });

      useKanbanStore.dispatch({
        type: "SET_CURRENT_REQUEST_ID",
        payload: { currentRequestId: payload._id },
      });

      useKanbanStore.dispatch({
        type: "SET_MODAL",
        payload: {
          modal: {
            type: MODAL_TYPES.KANBAN_DRAWER,
          },
        },
      });
      toast.error("Please fill all the mandatory fields!");
      const event = new CustomEvent("MANDATORY_TAG_ALERT", {
        detail: {
          requestId: (requestObj?.request_id || "").toString(),
          teamId: requestObj?.installation_team_id || "",
        },
      });
      window.dispatchEvent(event);
      return;
    }
    if (errMsg) {
      toast.error(errMsg);
    }
  } finally {
    useKanbanStore.dispatch({
      type: "DECREMENT_MUTATE_API_COUNT",
    });
  }
};

export const getTime = (timestamp: any) => {
  if (timestamp.includes("second ago")) {
    return "just now";
  }

  return timestamp;
};

export const parallelUpdate = async (
  payloadList: Array<Record<string, any>>,
  apiFunction: any
) => {
  const mapper = async (data: any) => {
    try {
      return await apiFunction(data);
    } catch (error: any) {
      return { error: true, _id: data._id, errorMessage: error.message };
    }
  };

  return pMap(payloadList, mapper, { concurrency: 3 });
};

export function sortByKey(array, key) {
  return array?.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
}

export const processConversationData = (data: any[] = []) => {
  const newData = cloneDeep(data);

  newData.forEach((item) => {
    if (item.thread_ts === item.ts || !item.thread_ts) {
      item.isTopLevel = true;
    }
  });

  const topLevelConversations = newData.filter((list) => list.isTopLevel);

  const combinedConversations = topLevelConversations.map((topLevel) => {
    const replies =
      newData.filter((list) => {
        return (
          !list.isTopLevel &&
          (list.thread_ts === topLevel.thread_ts ||
            list.thread_ts === "unknown") &&
          list.ts !== topLevel.ts
        );
      }) || [];

    return {
      ...topLevel,
      childReplies: replies,
    };
  });

  return combinedConversations as any[];
};
