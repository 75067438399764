import { VendorPortalMember } from "@/types/customerPortal";
import { api } from ".";

export const fetchRequests = async () => {
  return api.get("v2/customers/requests").then((data) => data.data);
};

export const inviteMembers = async (emails: string[], is_admin: boolean) => {
  return api
    .post("v2/customers/members", { emails, is_admin })
    .then((data) => data.data);
};

export const fetchMembers = async () => {
  return api
    .get<
      {
        id: string;
        name: string;
        email: string;
        is_admin: boolean;
      }[]
    >("v2/customers/members")
    .then((data) => data.data);
};

export const updateRole = async (
  id: string,
  payload: { is_admin: boolean }
) => {
  return api
    .patch(`v2/customers/members/${id}`, payload)
    .then((data) => data.data);
};

export const updateRoleVendor = async (
  id: string,
  payload: { is_admin: boolean }
) => {
  return api
    .patch(`v2/settings/customers/${id}`, payload)
    .then((data) => data.data);
};

export const removeUser = async (id: string) => {
  return api.delete(`v2/customers/members/${id}`).then((data) => data.data);
};

export const removeUserVendor = async (id: string) => {
  return api.delete(`v2/settings/customers/${id}`).then((data) => data.data);
};

export const fetchVenderPortalMembers = async () =>
  api
    .get<VendorPortalMember[]>("v2/settings/customers")
    .then((data) => data.data);

export const vendorPortalInviteMembers = (payload: {
  email: string[];
  team_id: string;
  is_admin: boolean;
}) => api.post("v2/settings/customers", payload).then((data) => data.data);

export const fetchCustomerPortalConversation = async (data) => {
  return api
    .post("v2/customers/conversations", data, {
      headers: { "x-enforce-no-cache": "true" },
    })
    .then((data) => data.data);
};

export const postCustomerPortalConversation = async (
  id: string,
  message: string
) =>
  api
    .post(`v2/customers/requests/${id}/conversations`, {
      message,
    })
    .then((data) => data.data);

export const escalateRequest = async (id: string) =>
  api.post(`v2/customers/requests/${id}/escalate`).then((data) => data.data);

export const fetchTeamID = async ({
  email,
  controller,
}: {
  email: string;
  controller: AbortController;
}) =>
  api
    .post<{
      team_ids: string[];
    }>("v2/settings/customers/email", { email }, { signal: controller.signal })
    .then((data) => data.data);
