import DOMPurify from "dompurify";

const template = document.createElement("template");
template.innerHTML = `
  <div>
    <slot>
    </slot>
  </div>`;
//Web component that leverages shadow dom and does not allow bleeding of styles from the HTML set inside it.
class ShadowDom extends HTMLElement {
  constructor() {
    super();
    const shadow = this.attachShadow({ mode: "open" });
    shadow.append(template.content.cloneNode(true));
  }

  static get observedAttributes() {
    return ["html"];
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (this.shadowRoot) {
      const cleanContent = DOMPurify.sanitize(newValue);
      this.shadowRoot.innerHTML = cleanContent;
    }
  }
}
if (!customElements.get("shadow-dom")) {
  customElements.define("shadow-dom", ShadowDom);
}
