import { useEffect, useState } from "react";
import {
  paragon,
  AuthenticatedConnectUser,
  SDK_EVENT,
} from "@useparagon/connect";
import useAuth from "./useAuth";

const PARAGON_PROJECT_ID = process.env.REACT_APP_PARAGON_PROJECT_ID || "";

export default function useParagonAuth(): {
  user?: AuthenticatedConnectUser;
  error?: Error;
} {
  const [paragonUser, setParagonUser] = useState<
    AuthenticatedConnectUser | undefined
  >();
  const [error, setError] = useState<Error | undefined>();
  const user = useAuth();

  // Listen for account state changes
  useEffect(() => {
    const listener = () => {
      const authedUser = paragon.getUser();
      if (authedUser.authenticated) {
        setParagonUser({ ...authedUser });
      }
    };
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, listener);
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, listener);
    return () => {
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, listener);
      paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, listener);
    };
  }, []);

  useEffect(() => {
    if (user && user?.paragon_token && !error) {
      paragon
        .authenticate(PARAGON_PROJECT_ID, user?.paragon_token)
        .then(() => {
          const authedUser = paragon.getUser();
          if (authedUser.authenticated) {
            setParagonUser(authedUser);
          }
        })
        .catch(setError);
    }
  }, [error, user]);

  return { user: paragonUser, error };
}
