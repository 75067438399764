// import { helphubUtil } from '../../../utils/helphub'

import { cn } from "@/utils";

interface Props {
  imgSrc: string;
  title: string;
  description: string;
  imageClassName: string;
}

const IntegrationModalHeader = ({
  imgSrc,
  title,
  description,
  imageClassName = "",
}: Props) => {
  return (
    <div>
      <div>
        <div>
          <img
            src={imgSrc}
            className={cn('h-[40px] w-[40px]',imageClassName)}
            alt={title + " logo icon"}
          />
        </div>
        <div className="mt-3">
          <h1 className="mb-0 text-xl font-bold">{title}</h1>
          <div className="flex items-start justify-between">
            <p className="font-medium mb-0">{description}</p>
            {title.includes("HubSpot") ? (
              <p
                className="text-primary m-0 font-medium cursor-pointer"
                // onClick={() => helphubUtil(133831)}
                role="button"
                data-intercom-target="Settings Integrations Salesforce Setup"
              >
                Where do I find this?
              </p>
            ) : (
              title.includes("Intercom") && (
                <p
                  className="text-primary m-0 font-medium cursor-pointer"
                  //   onClick={() => helphubUtil(133828)}
                  role="button"
                  data-intercom-target="Settings Integrations Salesforce Setup"
                >
                  Where do I find this?
                </p>
              )
            )}
            {title.includes("Shortcut") && (
              <p
                className="text-primary m-0 font-medium cursor-pointer"
                onClick={
                  () =>
                    window.open(
                      "https://help.thena.ai/docs/shortcut/",
                      "_blank"
                    ) //Todo: Add articleId for commandbar
                }
                role="button"
              >
                Where do I find this?
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { IntegrationModalHeader };
