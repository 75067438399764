import { create } from "zustand";
import { devtools } from "zustand/middleware";

import { CustomerRequests } from "@/types/customerPortal";

type StateType = {
  requests: CustomerRequests[];
  conversations: any[];
  drawerOpen: boolean;
  setDrawerOpen: (open: boolean) => void;
  setRequests: (requests: CustomerRequests[]) => void;
};

const initialState: { requests: StateType["requests"] } = {
  requests: [],
};

export const useCustomerStore = create<StateType>()(
  devtools(
    (set) => ({
      requests: initialState.requests,
      conversations: [],
      drawerOpen: false,
      setDrawerOpen: (open) => set({ drawerOpen: open }),
      setRequests: (requests) => set({ requests }),
    }),
    {
      name: "useCustomerStore",
    }
  )
);
