import { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import useAuth from "@/hooks/useAuth";
import { RequestResult, getSearchedRequests } from "@/utils/commandbar";
import { clearBrowserStorage } from "@/utils/auth";

const ReloadIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-refresh-cw"><path d="M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8"/><path d="M21 3v5h-5"/><path d="M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16"/><path d="M8 16H3v5"/></svg>`;

const LazyCommandbar = () => {
  const user = useAuth();
  const navigate = useNavigate();

  const aborControllerRef = useRef(new AbortController());

  useEffect(() => {
    if (user?._id) {
      window?.CommandBar?.boot(user?._id);

      window?.CommandBar?.addRecords("requests", [], {
        onInputChange: async (query: any) => {
          aborControllerRef.current.abort();
          aborControllerRef.current = new AbortController();

          const res = await getSearchedRequests(
            query?.toLowerCase() || "",
            aborControllerRef.current
          );
          const resultList = res.map((item) => {
            const processedItem = {
              ...item,
              id: item.request_id,
              team_id: item.installation_team_id,
              label:
                item.original_message_text || item.ai_metadata?.title || "",
            };
            const html = new RequestResult(processedItem).getHTML();
            return {
              id: item.request_id,
              teamId: item.installation_team_id,
              label: "",
              __extraHTML: html,
              icon: item.customer_team?.icon?.image_68 || "",
              isInternalHelpdesk: item.is_internal_helpdesk ?? false,
            };
          });
          return Promise.resolve(resultList);
        },
        labelKey: "label",
      });

      window?.CommandBar?.addRecordAction("requests", {
        text: "Open Request",
        name: "open_request",
        template: {
          type: "callback",
          value: "openRequest",
        },
      });

      window?.CommandBar?.addCallback("openRequest", (args) => {
        const record = args.record;
        if (record.isInternalHelpdesk) {
          navigate(
            `/request?tab=internal-helpdesk&requestId=${record.id}&teamId=${record.teamId}`
          );
          return;
        }
        navigate(`/requests?requestId=${record.id}&teamId=${record.teamId}`);
      });

      window.CommandBar.addRecords("settings", [
        {
          id: 1,
          icon: ReloadIcon,
          label: "Reload workspace",
        },
      ]);

      window?.CommandBar?.addRecordAction("settings", {
        text: "Reload workspace",
        name: "reload_workspace",
        template: {
          type: "callback",
          value: "reloadWorkspace",
        },
      });

      window?.CommandBar?.addCallback("reloadWorkspace", async () => {
        await clearBrowserStorage();
        window.location.reload();
      });
    }

    return () => {
      window?.CommandBar?.shutdown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  return null;
};

export default LazyCommandbar;
