import { CUSTOMER_LOGOUT_URL, LOGOUT_URL } from "@/constants";
import { useAuthStorePersist } from "@/store/authStorePersist";
import Dexie from "dexie";
import { emitBroadcastMsg, getIsCustomerPortal } from "./domUtils";
import * as Sentry from "@sentry/react";

export const tabId = Math.random().toString(36).substring(2, 15);

export const logoutHelper = async (redirect = false) => {
  await clearBrowserStorage();
  emitBroadcastMsg("auth_channel", {
    type: "LOGOUT",
    tabId,
  });
  Sentry.setContext("user", {});
  if (redirect) {
    window.location.href = getIsCustomerPortal()
      ? CUSTOMER_LOGOUT_URL
      : LOGOUT_URL;
  }
};

export const clearBrowserStorage = async () => {
  try {
    useAuthStorePersist.dispatch({ type: "RESET" });
    const currentTheme = localStorage.getItem("vite-ui-theme") ?? "system";
    window.localStorage.clear();
    localStorage.setItem("vite-ui-theme", currentTheme);
    const dbNames = await Dexie.getDatabaseNames();
    const thenaDBList = dbNames.filter((dbName) => dbName.includes("ThenaDB"));
    const promiseList = thenaDBList.map((dbName) => Dexie.delete(dbName));
    await Promise.all([
      ...promiseList,
      Dexie.delete("thena-crm-relationships"),
    ]);
    await unregisterServiceWorker();
    clearCaches();
  } catch (error) {
    console.error(error);
  }
};

function clearCaches() {
  if ("caches" in window) {
    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        caches.delete(cacheName);
      });
    });
  }
}

export const findPrimaryUserImage = (data: any): string => {
  try {
    if (Array.isArray(data?.slackUsers)) {
      if (data.slackUsers.length === 1) {
        return (
          data.slackUsers[0]?.slack?.profile?.image_72 || data?.user_image || ""
        );
      }
      const primaryInstallation = data.installations?.find(
        (installation: any) => installation?.is_primary
      );
      const teamId = primaryInstallation?.team?.id;
      const slackUser = data.slackUsers.find(
        (user: any) => user.slack?.team_id === teamId
      );
      const imageUrl =
        slackUser?.slack?.profile?.image_72 || data?.user_image || "";
      return imageUrl as string;
    }
    return (data?.user_image as string) || "";
  } catch (error) {
    console.log(error);
    return (data?.user_image as string) || "";
  }
};

async function unregisterServiceWorker() {
  if ("serviceWorker" in navigator) {
    return navigator.serviceWorker
      .getRegistrations()
      .then((registrations) => {
        for (const registration of registrations) {
          registration.unregister();
        }
      })
      .catch((error) => {
        console.error("Failed to unregister service worker: ", error);
      });
  }
  return Promise.resolve();
}

export const getIsOnboardingModalShown = () => {
  const onboardingShown = localStorage.getItem("onboardingModalShown");
  return onboardingShown === "true";
};

export const setOnboardingModalShownTrue = () => {
  localStorage.setItem("onboardingModalShown", "true");
};
