import { useState, useEffect } from "react";

const useProgressBar = (shouldComplete) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;
    if (progress < 75) {
      interval = setInterval(() => {
        setProgress((prev) => Math.min(prev + 1, 75));
      }, 50); // Adjust the interval for smoothness
    } else if (shouldComplete) {
      setProgress(100);
    }

    return () => clearInterval(interval);
  }, [progress, shouldComplete]);

  return progress;
};

export default useProgressBar;
