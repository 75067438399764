import { JSONContent } from "@tiptap/react";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

type StateType = {
  assistants: JSONContent[];
  setAssistants: (value: JSONContent[]) => void;
  sources: JSONContent[];
  setSources: (sources: JSONContent[]) => void;
};

const useAIAssistStore = create<StateType>()(
  devtools(
    (set) => ({
      assistants: [],
      setAssistants: (value) => set({ assistants: value }),
      sources: [],
      setSources: (sources) => set({ sources: sources }),
    }),
    {
      name: "useAIAssistStore",
    }
  )
);

export { useAIAssistStore };
