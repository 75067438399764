/* eslint-disable max-lines */
import { DEFAULT_COLUMN_ORDER, STATUS_ORDER } from "@/constants/kanban";
import { getThenaDB } from "@/db";
import { useKanbanStore } from "@/store/kanbanStore";
import { useKanbanStorePersist } from "@/store/kanbanStorePersist";
import { RequestType, ViewsType } from "@/types/requests";
import { getDateObjectsFromRange } from "@/utils/dateUtils";
import { endOfDay, startOfDay } from "date-fns";
import Dexie from "dexie";
import { intersection, isEmpty, isEqual, union } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  crmRelationDB,
  getFormattedKey,
  getRedactedKey,
} from "./useCRMFilterInit";
import { useKanbanCacheStore } from "@/store/kanbanCacheStore";
import { getFormattedOverflowValue } from "./useCRMInfo";
import BBPromise from "bluebird";
import { useGlobalStore } from "@/store/globalStore";
import useFocusedLiveQuery from "./useFocusedLiveQuery";
import { insertOrUpdateDataToDuckDB } from "@/utils/duckDB";
import { views } from "@/constants/views";
import {
  getDateRangeByPeriod,
  getRelationshipFilterKey,
  includesIgnoreCase,
} from "@/utils/requests";

BBPromise.config({ cancellation: true });

const useRequestsInit = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentView = searchParams.get("view");
  const savedViews = useFocusedLiveQuery(
    () => getThenaDB().views.toArray(),
    []
  );
  const isValidSavedView = useMemo(
    () => savedViews?.filter((item) => item._id === currentView).length,
    [savedViews, currentView]
  );
  const isInternal = searchParams.get("tab") === "internal-helpdesk";

  const dateRange = useKanbanStorePersist((state) => state.filters.dateRange);
  const preselectedRange = useKanbanStorePersist(
    (state) => state.filters.preselectedRange
  );

  useEffect(() => {
    if (currentView && isValidSavedView) {
      useKanbanStore.dispatch({
        type: "SET_CURRENT_VIEW",
        payload: {
          currentView,
        },
      });
    } else if (savedViews !== undefined) {
      setSearchParams((prev) => {
        const newParams = new URLSearchParams(prev);
        newParams.delete("view");
        return newParams;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentView, savedViews, isValidSavedView]);

  const searchQuery = useKanbanStorePersist((state) => state.searchQuery);
  const queryValuesMap = useKanbanStorePersist((state) => state.queryValuesMap);

  const [filterIntersectionList, setFilterIntersectionList] = useState<
    string[]
  >([]);

  const requestMap = useKanbanStore((state) => state.requestMap);

  const dateFilterPipe = useFocusedLiveQuery(() => {
    let updatedRange = dateRange;
    if (preselectedRange) {
      updatedRange = getDateObjectsFromRange(preselectedRange);
    }
    return getThenaDB()
      .requests.where("created_at_date")
      .between(
        updatedRange?.from
          ? startOfDay(new Date(updatedRange.from))
          : Dexie.minKey,
        updatedRange?.to ? endOfDay(new Date(updatedRange.to)) : Dexie.maxKey,
        true,
        true
      )
      .reverse()
      .primaryKeys();
  }, [dateRange, preselectedRange]);

  const [isAnyFilterApplied, setIsAnyFilterApplied] = useState(false);

  const filterPromiseRef = useRef<BBPromise<any[]> | null>(null);

  useEffect(() => {
    const queryList: any[] = [];

    if (filterPromiseRef.current) {
      filterPromiseRef.current.cancel();
    }

    Object.keys(queryValuesMap).forEach((key) => {
      const item = queryValuesMap[key];

      if (item.values.length > 0) {
        if (item.indexed_key.includes("crm_")) {
          const pipe = crmRelationDB
            .table("crmRelations")
            .where(item.indexed_key)
            .anyOf(item.values)
            .primaryKeys()
            .then(async (relationshipIds) => {
              const requestIds = await getThenaDB()
                .requests.where("relationship_id")
                .anyOf(relationshipIds)
                .primaryKeys();
              return requestIds;
            });

          queryList.push(pipe);

          return;
        } else if (item.indexed_key === "relationship_id") {
          const relationshipsPipe = getThenaDB()
            .relationships.where(getRelationshipFilterKey(key))
            .anyOf(item.values)
            .primaryKeys();

          queryList.push(
            relationshipsPipe.then(async (relationshipsList) => {
              return getThenaDB()
                .requests.where("relationship_id")
                .anyOf(relationshipsList || [])
                .primaryKeys();
            })
          );
          return;
        } else if (item.indexed_key === "status") {
          queryList.push(
            getThenaDB()
              .requests.where("sub_status")
              .anyOf(item.values)
              .primaryKeys()
              .then(async (subStatusesRequestList) => {
                return await getThenaDB()
                  .requests.where("status")
                  .anyOf(item.values)
                  .primaryKeys()
                  .then((statusRequestList) => [
                    ...subStatusesRequestList,
                    ...statusRequestList,
                  ]);
              })
          );
          return;
        }

        queryList.push(
          getThenaDB()
            .requests.where(item.indexed_key)
            .anyOf(item.values)
            .primaryKeys()
        );
      }
    });

    filterPromiseRef.current = BBPromise.all(queryList).then((res) => {
      setIsAnyFilterApplied(res.length > 0);
      const intersectionRes = intersection(...res) as string[];
      setFilterIntersectionList(intersectionRes);
    });
  }, [queryValuesMap, dateFilterPipe]);

  const allNonClosedPKeys = useFocusedLiveQuery(
    () => {
      return getThenaDB()
        .requests.where("status")
        .anyOf(["OPEN", "INPROGRESS", "ONHOLD"])
        .primaryKeys();
    },
    [],
    []
  );

  const allClosedPKeys = useFocusedLiveQuery(
    () => {
      return getThenaDB()
        .requests.where("status")
        .equals("CLOSED")
        .primaryKeys();
    },
    [],
    []
  );

  const closedRequestsShowPeriod = useKanbanStorePersist(
    (state) => state.displayOptions.card.closedRequestsShowPeriod
  );

  const allClosedPKeysByPeriod = useFocusedLiveQuery(
    () => {
      const dateRange = getDateObjectsFromRange(
        getDateRangeByPeriod(closedRequestsShowPeriod)
      );
      return getThenaDB()
        .requests.where("created_at_date")
        .between(
          dateRange?.from ? startOfDay(new Date(dateRange.from)) : Dexie.minKey,
          dateRange?.to ? endOfDay(new Date(dateRange.to)) : Dexie.maxKey,
          true,
          true
        )
        .primaryKeys();
    },
    [closedRequestsShowPeriod],
    []
  );

  const closedRequestsFilterPipe = useMemo(() => {
    return intersection(allClosedPKeys, allClosedPKeysByPeriod);
  }, [allClosedPKeys, allClosedPKeysByPeriod]);

  const statusCleanupPipe = useMemo(() => {
    return allNonClosedPKeys.concat(closedRequestsFilterPipe);
  }, [allNonClosedPKeys, closedRequestsFilterPipe]);

  const initialIntersectionList = useMemo(() => {
    return intersection(dateFilterPipe, statusCleanupPipe);
  }, [dateFilterPipe, statusCleanupPipe]);

  const sortby = useKanbanStorePersist((state) => state.sortby);

  const internalHelpDeskFilterPipe = useFocusedLiveQuery(() => {
    return getThenaDB()
      .requests.where("internalHelpDeskIndex")
      .equals(isInternal ? "yes" : "no")
      .primaryKeys();
  }, [isInternal]);

  const isLastReplySorted = sortby.field === "lastReplyOrder";
  const isFirstResponseSla = sortby.field === "firstResponseSlaOrder";
  const isFinalResolutionSla = sortby.field === "finalResolutionSlaOrder";
  const isLastCustomerReplySorted = sortby.field === "lastCustomerReplyOrder";

  const lastCustomerReplyNotPresent = useFocusedLiveQuery(
    () => {
      if (isLastCustomerReplySorted) {
        return getThenaDB()
          .requests.where("lastCustomerReplyOrder")
          .equals("NOT_PRESENT")
          .primaryKeys();
      }
      return Promise.resolve([] as any);
    },
    [isLastCustomerReplySorted],
    []
  );

  const lastCustomerReplyWithoutNotPresent: any = useFocusedLiveQuery(
    () => {
      if (isLastCustomerReplySorted) {
        return getThenaDB()
          .requests.where("lastCustomerReplyOrder")
          .noneOf(["NOT_PRESENT"])
          .primaryKeys();
      }
      return Promise.resolve([] as any);
    },
    [isLastCustomerReplySorted],
    []
  );

  useEffect(() => {
    getThenaDB().tables.forEach((table) => {
      table.hook("creating", function (...creationProps) {
        // console.log("creating", table.name);
        if (table.name === "requests") {
          const createdRequest = creationProps[1];
          insertOrUpdateDataToDuckDB({
            table: "requests",
            dataList: [createdRequest],
          });
        }
      });
      table.hook("updating", function (...updationProps) {
        // console.log("updating", table.name);
        if (table.name === "requests") {
          const updatedRequest = updationProps[2];
          insertOrUpdateDataToDuckDB({
            table: "requests",
            dataList: [updatedRequest],
          });
        }
      });
    });
  }, []);

  const lastReplyNotPresent = useFocusedLiveQuery(
    () => {
      if (isLastReplySorted) {
        return getThenaDB()
          .requests.where("lastReplyOrder")
          .equals("NOT_PRESENT")
          .primaryKeys();
      }
      return Promise.resolve([] as any);
    },
    [isLastReplySorted],
    []
  );

  const lastReplyWithoutNotPresent: any = useFocusedLiveQuery(
    () => {
      if (isLastReplySorted) {
        return getThenaDB()
          .requests.where("lastReplyOrder")
          .noneOf(["NOT_PRESENT"])
          .primaryKeys();
      }
      return Promise.resolve([] as any);
    },
    [isLastReplySorted],
    []
  );

  const slaSortListWithoutNotPresent: any = useFocusedLiveQuery(() => {
    if (isFirstResponseSla) {
      return getThenaDB()
        .requests.where("firstResponseSlaOrder")
        .noneOf(["NOT_PRESENT"])
        .primaryKeys();
    }
    if (isFinalResolutionSla) {
      return getThenaDB()
        .requests.where("finalResolutionSlaOrder")
        .noneOf(["NOT_PRESENT"])
        .primaryKeys();
    }
    return Promise.resolve([] as any);
  }, [isFirstResponseSla, isFinalResolutionSla]);

  const finalIntersectionList = useMemo(() => {
    const args = [initialIntersectionList];
    if (isAnyFilterApplied) {
      args.push(filterIntersectionList);
    }
    if (internalHelpDeskFilterPipe) {
      args.push(internalHelpDeskFilterPipe);
    }
    return intersection(...args);
  }, [
    initialIntersectionList,
    isAnyFilterApplied,
    internalHelpDeskFilterPipe,
    filterIntersectionList,
  ]);

  const notPresentInterSectionlist: any = useFocusedLiveQuery(
    () => {
      if (isFirstResponseSla) {
        return getThenaDB()
          .requests.where("firstResponseSlaOrder")
          .equals("NOT_PRESENT")
          .primaryKeys();
      }
      if (isFinalResolutionSla) {
        return getThenaDB()
          .requests.where("finalResolutionSlaOrder")
          .equals("NOT_PRESENT")
          .primaryKeys();
      }
      return Promise.resolve([] as any);
    },
    [isFirstResponseSla, isFinalResolutionSla],
    []
  );

  const nullRequests = useFocusedLiveQuery(
    () => {
      const notPresentInterSectionFinalList = intersection(
        notPresentInterSectionlist,
        finalIntersectionList
      ) as any[];
      return getThenaDB()
        .requests.where("_id")
        .anyOf(notPresentInterSectionFinalList)
        .toArray();
    },
    [notPresentInterSectionlist, finalIntersectionList],
    []
  );

  const nullRequestsCustomerReply = useFocusedLiveQuery(
    () => {
      const notPresentInterSectionFinalList = intersection(
        lastCustomerReplyNotPresent,
        finalIntersectionList
      ) as any[];
      return getThenaDB()
        .requests.where("_id")
        .anyOf(notPresentInterSectionFinalList)
        .reverse()
        .toArray();
    },
    [lastCustomerReplyNotPresent, finalIntersectionList],
    []
  );

  const nullRequestsReply = useFocusedLiveQuery(
    () => {
      const notPresentInterSectionFinalList = intersection(
        lastReplyNotPresent,
        finalIntersectionList
      ) as any[];
      return getThenaDB()
        .requests.where("_id")
        .anyOf(notPresentInterSectionFinalList)
        .reverse()
        .toArray();
    },
    [lastReplyNotPresent, finalIntersectionList],
    []
  );

  const isSla = isFirstResponseSla || isFinalResolutionSla;

  const finalIntersectionListWithSLA = useMemo(() => {
    const args = [finalIntersectionList];
    if (isFirstResponseSla || isFinalResolutionSla) {
      args.push(slaSortListWithoutNotPresent);
    }
    if (isLastCustomerReplySorted) {
      args.push(lastCustomerReplyWithoutNotPresent);
    }
    if (isLastReplySorted) {
      args.push(lastReplyWithoutNotPresent);
    }
    return intersection(...args);
  }, [
    isFirstResponseSla,
    isFinalResolutionSla,
    finalIntersectionList,
    isLastCustomerReplySorted,
    slaSortListWithoutNotPresent,
    lastCustomerReplyWithoutNotPresent,
    lastReplyWithoutNotPresent,
    isLastReplySorted,
  ]);

  const requests = useFocusedLiveQuery(() => {
    if (sortby.order === "ASC") {
      return getThenaDB()
        .requests.where("_id")
        .anyOf(finalIntersectionListWithSLA as any[])
        .sortBy(sortby.field)
        .then((list) => {
          if (isSla) {
            return list.concat(nullRequests);
          }
          if (isLastCustomerReplySorted) {
            return list.concat(nullRequestsCustomerReply);
          }
          if (isLastReplySorted) {
            return list.concat(nullRequestsReply);
          }
          return list;
        });
    }
    // descending order
    return getThenaDB()
      .requests.where("_id")
      .anyOf(finalIntersectionListWithSLA as any[])
      .reverse()
      .sortBy(sortby.field)
      .then((list) => {
        if (isSla) {
          return list.concat(nullRequests);
        }
        if (isLastCustomerReplySorted) {
          return list.concat(nullRequestsCustomerReply);
        }
        if (isLastReplySorted) {
          return list.concat(nullRequestsReply);
        }
        return list;
      });
  }, [
    finalIntersectionListWithSLA,
    sortby,
    nullRequests,
    isSla,
    isLastCustomerReplySorted,
    isLastReplySorted,
    nullRequestsCustomerReply,
    nullRequestsReply,
  ]);

  // SEARCH LOGIC

  const relationshipIDs = useMemo(() => {
    const ids = requests?.map((req) => req.relationship_id);
    const uniqueIDs = new Set(ids);
    return Array.from(uniqueIDs);
  }, [requests]);

  const allRelations = useFocusedLiveQuery(() => {
    return getThenaDB()
      .relationships.bulkGet(relationshipIDs)
      .then((relationships) =>
        relationships.map((relationship) => {
          return {
            relationship_id: relationship?._id,
            channelName: relationship?.channel_name,
            customerName: relationship?.customer_name,
          };
        })
      );
  }, [relationshipIDs]);

  const searchData = useMemo(() => {
    return requests?.map((req) => {
      const { request_id, original_message_text, relationship_id } = req;
      const relationObj = allRelations?.find(
        (rel) => rel?.relationship_id === relationship_id
      );
      return {
        originalRequestObject: req,
        original_message_text,
        request_id: request_id?.toString(),
        channelName: relationObj?.channelName,
        customerName: relationObj?.customerName,
      };
    });
  }, [requests, allRelations]);

  const searchedRequests = useMemo(() => {
    if (isEmpty(searchQuery)) return [];

    const filteredData = (searchData || []).filter((con) => {
      return [
        con.channelName,
        con.original_message_text,
        con.customerName,
        con.request_id,
      ].some((field) => includesIgnoreCase(field, searchQuery));
    });
    return filteredData.map((con) => con.originalRequestObject);
  }, [searchQuery, searchData]);

  const isSearchAvailable = searchQuery.length > 0;

  const mutateAPICount = useKanbanStore((state) => state.mutateAPICount);
  const isMutateAPIPending = mutateAPICount > 0;

  useEffect(() => {
    const init = async () => {
      if (isMutateAPIPending) {
        return;
      }

      const requestIds = (
        isSearchAvailable ? searchedRequests : requests || []
      ).map((req) => req._id);

      const currentRequestsFromDB =
        await getThenaDB().requests.bulkGet(requestIds);

      const oldRequestMap = useKanbanStore.getState().requestMap;

      const newRequestMap = currentRequestsFromDB.reduce(
        (acc: RequestType, item) => {
          if (!item) {
            return acc;
          }
          const oldItem = oldRequestMap[item._id];
          if (
            oldItem &&
            oldItem.updated_at_date &&
            item.updated_at_date &&
            item.updated_at_date <= oldItem.updated_at_date
          ) {
            acc[item._id] = oldItem;
          } else {
            acc[item._id] = item;
          }
          return acc;
        },
        {} as RequestType
      );

      useKanbanStore.dispatch({
        type: "SET_REQUEST_MAP",
        payload: {
          requestMap: newRequestMap,
        },
      });
    };

    init();
  }, [requests, searchedRequests, isSearchAvailable, isMutateAPIPending]);

  useEffect(() => {
    let timerId: null | NodeJS.Timeout = null;
    if (requests?.length) {
      useGlobalStore.getState().setIsKanbanReady(true);
    } else {
      timerId = setTimeout(() => {
        useGlobalStore.getState().setIsKanbanReady(true);
      }, 20 * 1000);
    }

    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [requests?.length]);

  /**
   * INSERT TO STORE
   */

  const subStatuses = useFocusedLiveQuery(() => {
    return getThenaDB()
      .subStatuses.where("parent")
      .anyOf(STATUS_ORDER)
      .toArray();
  }, []);

  const columnOrder = useFocusedLiveQuery(() => {
    return getThenaDB()
      .views.where("name")
      .equals(views.KANBAN_COLUMN_ORDER)
      .first()
      .then((response) => response?.additionalPrefs?.ordered_columns);
  }, []);

  const currentViewDetails = useFocusedLiveQuery(() => {
    return getThenaDB().views.get(currentView ?? "");
  }, [currentView]);

  const defaultViewDetails = useFocusedLiveQuery(() => {
    return getThenaDB()
      .views.where("name")
      .anyOf(["KANBAN_DISPLAY_OPTIONS", "HELPDESK_DISPLAY_OPTIONS"])
      .toArray();
  }, []);

  const getTableMetadata = useCallback(() => {
    /**
     * Get current views column metadata
     * If no view is selected, get default view column metadata
     */
    const searchParams = new URLSearchParams(window.location.search);
    const viewID = searchParams.get("view");
    let columnMetadata:
      | ViewsType["additionalPrefs"]["columnMetadata"]
      | undefined;
    if (viewID) {
      columnMetadata = currentViewDetails?.additionalPrefs?.columnMetadata;
    }

    if (!viewID) {
      const tabName = searchParams.get("tab");
      if (tabName === "internal-helpdesk") {
        columnMetadata = defaultViewDetails?.find(
          (item) => item.name === "HELPDESK_DISPLAY_OPTIONS"
        )?.additionalPrefs?.columnMetadata;
      } else {
        columnMetadata = defaultViewDetails?.find(
          (item) => item.name === "KANBAN_DISPLAY_OPTIONS"
        )?.additionalPrefs?.columnMetadata;
      }
    }

    // If no metadata is found for the view, set default metadata
    if (!columnMetadata) {
      columnMetadata = {} as ViewsType["additionalPrefs"]["columnMetadata"];
      STATUS_ORDER.forEach((status) => {
        columnMetadata![status] = { isHidden: false };
      });

      subStatuses?.forEach((subStatus) => {
        // TODO: change subStatus.name to subStatus._id when we start storing id's instead of name
        columnMetadata![subStatus.name] = { isHidden: false };
      });
    }

    return columnMetadata;
  }, [currentViewDetails, defaultViewDetails, subStatuses]);

  useEffect(() => {
    const list = Object.values(requestMap) as unknown as RequestType[];
    const statusOrderMap = {};
    const columnMetadata = getTableMetadata();
    let finalColumnOrder = {};

    if (!isEqual(columnOrder, subStatuses)) {
      Object.keys(columnOrder || DEFAULT_COLUMN_ORDER).forEach((key) => {
        const subStatusesOfParent = subStatuses?.filter(
          (status) => status?.parent === key
        );

        finalColumnOrder[key] = union(
          columnOrder?.[key],
          subStatusesOfParent?.map((status) => status._id)
        );
      });
    } else {
      finalColumnOrder = columnOrder;
    }

    STATUS_ORDER.forEach((parentStatus) => {
      const subStatusIds = finalColumnOrder?.[parentStatus] ?? [];

      const subStatusesOfParent = subStatusIds
        .map((statusId) =>
          subStatuses?.filter((subStatus) => subStatus._id === statusId)
        )
        .flat();

      if (subStatusesOfParent?.length) {
        subStatusesOfParent.forEach((item) => {
          if (!item) {
            return;
          }
          const requests = list.filter(
            (request) => request.sub_status === item._id
          );
          statusOrderMap[item.name] = requests.map((request) => request._id);
        });
      } else {
        statusOrderMap[parentStatus] = list
          .filter((item) => item.status === parentStatus)
          .map((item) => item._id);
      }
    });
    useKanbanStore.dispatch({
      type: "SET_STATUS_ORDER_MAP",
      payload: { statusOrderMap, columnMetadata },
    });

    useKanbanCacheStore.getState().setSubStatusCache(subStatuses || []);
  }, [requestMap, columnOrder, subStatuses, getTableMetadata]);

  const requestsMeta = useFocusedLiveQuery(() => {
    return getThenaDB()
      .requests.bulkGet(initialIntersectionList)
      .then((res) => {
        if (!res) return {};
        const relationIds = res.map((req) => req?.relationship_id || "");
        const assigneeIds = res.map((req) => req?.assigned_to_user_id || "");
        const creatorIds = res.map(
          (req) => req?.original_message_user_id || ""
        );
        return {
          relationIds,
          assigneeIds,
          creatorIds,
        };
      });
  }, [initialIntersectionList]);

  const setRelationCache = useKanbanCacheStore(
    (state) => state.setRelationCache
  );

  const setUserCache = useKanbanCacheStore((state) => state.setUserCache);
  const setCustomerCache = useKanbanCacheStore(
    (state) => state.setCustomerCache
  );

  const cardDisplayOptions = useKanbanStorePersist(
    (store) => store.displayOptions.card
  );

  const drawerDisplayOptions = useKanbanStorePersist(
    (store) => store.displayOptions.drawer
  );

  const cardCRMIds = useMemo(() => {
    return cardDisplayOptions?.visibleCrmFields?.map((item) => item.value);
  }, [cardDisplayOptions?.visibleCrmFields]);

  const drawerCRMIds = useMemo(() => {
    return drawerDisplayOptions?.visibleCrmFields?.map((item) => item.value);
  }, [drawerDisplayOptions.visibleCrmFields]);

  const selectedCRMIds = useMemo(() => {
    return Array.from(
      new Set([...(cardCRMIds || []), ...(drawerCRMIds || [])])
    );
  }, [cardCRMIds, drawerCRMIds]);

  const crmInfo = useFocusedLiveQuery(() => {
    return getThenaDB()
      .crmFields.toArray()
      .then((res) => {
        const currentCRM = res[0] || {};
        const result = {
          crm: currentCRM.crm ?? "",
          fields: [] as any[],
        };
        const redactedKeyList = selectedCRMIds.map(getRedactedKey);
        currentCRM.fields?.forEach((field) => {
          if (redactedKeyList.includes(field.name)) {
            result.fields.push({
              field_name: field.label,
              _id: getFormattedKey(field.name),
            });
          }
        });
        return result;
      });
  }, [selectedCRMIds]);

  const crmAccountOwnerValues = useFocusedLiveQuery(
    () => {
      return getThenaDB()
        .filters.toArray()
        .then((list) => {
          return (
            list.find(
              (item) =>
                item.indexed_key === "crm_hubspot_owner_id" ||
                item.indexed_key === "crm_OwnerId"
            )?.values || []
          );
        });
    },
    [],
    []
  );

  useFocusedLiveQuery(() => {
    return getThenaDB()
      .relationships.bulkGet(requestsMeta?.relationIds || [])
      .then((res) => {
        const map = res.reduce((acc, relation) => {
          if (!relation) return acc;
          const crmValuesList: any[] = [];
          const crmObject = relation?.crm_values?.sinked_crm_object || {};

          crmInfo?.fields?.forEach((field) => {
            const crmKey = getRedactedKey(field._id);
            const valueToAdd = crmObject[crmKey];
            let resultList;
            if (Array.isArray(valueToAdd)) {
              resultList = [...getFormattedOverflowValue(valueToAdd)];
            } else {
              resultList = [getFormattedOverflowValue(valueToAdd)];
            }

            crmValuesList.push({
              ...field,
              values: resultList,
            });
          });

          acc[relation._id] = {
            connectedCRM: crmInfo?.crm,
            crmValuesList,
            channel_name: relation.channel_name,
            customer_name: relation.customer_name,
            customer_team_icon: relation.customer_team?.icon?.image_68 || "",
            channel_id: relation.channel_id,
            channel_is_private: relation.channel_is_private,
          };
          return acc;
        }, {});
        setRelationCache(map);
      });
  }, [requestsMeta?.relationIds, crmInfo, crmAccountOwnerValues]);

  useFocusedLiveQuery(() => {
    return getThenaDB()
      .customerContacts.where("user_id")
      .anyOf(requestsMeta?.creatorIds || [])
      .toArray()
      .then((res) => {
        const map = res.reduce((acc, item) => {
          if (!item || !item?.user_id) return acc;
          acc[item.user_id] = {
            name: item.name,
            slack_profile_image_24:
              item?.metadata?.slack?.profile_image_48 || "",
          };
          return acc;
        }, {});
        setCustomerCache(map);
      });
  }, [requestsMeta?.creatorIds]);

  useFocusedLiveQuery(() => {
    return getThenaDB()
      .users.bulkGet(requestsMeta?.assigneeIds || [])
      .then((res) => {
        const map = res.reduce((acc, item) => {
          if (!item || !item?._id) return acc;
          acc[item._id] = {
            name: item.name,
            slack_profile_image_24: item.slack_profile_image_24,
          };
          return acc;
        }, {});
        setUserCache(map);
      });
  }, [requestsMeta?.assigneeIds]);

  useEffect(() => {
    return () => {
      useKanbanCacheStore.getState().resetCache();
    };
  }, []);
};

export default useRequestsInit;
