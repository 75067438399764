import { getDBInfo, getThenaDB } from "@/db";
import { emptyObject } from "./empty";
import { startCase, toLower, truncate } from "lodash";
import ClosedBoardIcon from "@/images/icons/closed-board.svg";
import InProgressBoardIcon from "@/images/icons/inprogress-board.svg";
import OnholdBoardIcon from "@/images/icons/onhold-board.svg";
import OpenBoardIcon from "@/images/icons/open-board.svg";

export const getSearchedRequests = async (
  query: string = "",
  controller?: AbortController
): Promise<any[]> => {
  return new Promise((resolve, reject) => {
    const { dbName } = getDBInfo();
    const dbRequest = indexedDB.open(dbName);
    const matches: any[] = [];
    let db;

    dbRequest.onerror = function (event: any) {
      console.error("Database error:", event.target.error);
      db?.close();
      reject([]);
    };

    dbRequest.onsuccess = function (event: any) {
      db = event.target.result;
      const transaction = db.transaction("requests", "readonly");
      const store = transaction.objectStore("requests");
      const cursorRequest = store.openCursor();

      if (controller) {
        controller.signal.addEventListener("abort", () => {
          try {
            transaction?.abort();
            db?.close();
          } catch (error) {
            //
          }
          resolve([]);
        });
      }

      cursorRequest.onsuccess = async function (event) {
        const cursor = event.target.result;
        if (cursor) {
          const value = cursor.value;

          getThenaDB()
            .relationships.get(value.relationship_id)
            .then((relation) => {
              const conditions = [
                value.original_message_text?.toLowerCase()?.includes(query),
                value.request_id?.toString()?.toLowerCase()?.includes(query),
                relation?.channel_name?.toLowerCase()?.includes(query),
                relation?.customer_name?.toLowerCase()?.includes(query),
              ];
              if (conditions.some(Boolean)) {
                matches.push({
                  ...value,
                  customer_team: relation?.customer_team,
                });
              }
            });

          cursor.continue();
        } else {
          db?.close();
          resolve(matches);
        }
      };

      cursorRequest.onerror = function (event) {
        console.error("Cursor request error:", event.target.error);
        db?.close();
        reject([]);
      };
    };
  });
};

const COLOR_SETS = [
  `color: #f56a00; background-color: #fde3cf;`,
  `color: #007bff; background-color: #cfe2ff;`,
  `color: #8b008b; background-color: #f8e0ff;`,
  `color: #228b22; background-color: #e0ffe0;`,
  `color: #800080; background-color: #f4e3f5;`,
];

export const getNameSnippet = (name: string) => {
  const snippet = name
    .toUpperCase()
    .split(" ")
    .map((word) => word[0])
    .join("");

  return snippet.substring(0, 2);
};

// @ts-expect-error - TS doesn't know about this function
window.updateImageInCommandBarRequest = (id) => {
  if (document) {
    const img = document.querySelector(`.img-request-id-${id}`);
    if (img) {
      // @ts-expect-error - TS doesn't know about this method
      img.style.display = "none";
    }
    const nameSnippet = document.querySelector(
      `.name-snippet-request-id-${id}`
    );
    if (nameSnippet) {
      // @ts-expect-error - TS doesn't know about this method
      nameSnippet.style.display = "flex";
    }
  }
};

export class RequestResult {
  item: any;

  constructor(item: any) {
    this.item = item;
  }

  getRequestInfo() {
    const {
      id = 0,
      status = "OPEN",
      tags = [],
      label = "",
      assigned_to,
      customer_team,
    } = this.item || emptyObject;

    const {
      icon: { image_44: workspaceIcon = "" } = {},
      name: workspaceName = "",
    } = customer_team || emptyObject;

    const { image: assignedToImage = "", name: assignedToName = "" } =
      assigned_to || emptyObject;

    return {
      id,
      tags,
      label,
      status,
      workspaceIcon,
      workspaceName,
      assignedToName,
      assignedToImage,
    };
  }

  getStatusHTML() {
    const { status } = this.getRequestInfo();
    let statusText = "";
    let statusIcon = "";
    let statusColor = "";

    switch (status) {
      case "OPEN":
        statusText = "Open";
        statusIcon = OpenBoardIcon;
        statusColor = "rgba(53, 93, 224)";
        break;

      case "INPROGRESS":
        statusText = "In Progress";
        statusIcon = InProgressBoardIcon;
        statusColor = "rgba(237, 150, 64)";
        break;

      case "ONHOLD":
        statusText = "On Hold";
        statusIcon = OnholdBoardIcon;
        statusColor = "rgba(111, 111, 117)";
        break;

      case "CLOSED":
        statusText = "Closed";
        statusIcon = ClosedBoardIcon;
        statusColor = "rgba(38, 159, 125)";
        break;

      default:
        statusText = startCase(toLower(status));
    }

    const backgroundColor = statusColor.replace(")", ", 0.2)");

    const statusHTML = `
            <div class="status-container" 
                 style="color: ${statusColor}; 
                        border: 1px solid ${statusColor}; 
                        background-color:${backgroundColor}; 
            ">
              ${statusIcon ? `<img src="${statusIcon}" alt="" />` : ""}
              <div>${statusText}</div>
            </div>`;

    return statusHTML;
  }

  getAssignedToHTML() {
    const { assignedToName, assignedToImage, id } = this.getRequestInfo();
    const imageUrl = assignedToName === "Unassigned" ? "" : assignedToImage;
    const randomIndex = Math.floor(Math.random() * COLOR_SETS.length);
    const randomColorSet = COLOR_SETS[randomIndex];

    const assignedToHTML = `
            <div class="assigned-to-container">
              <img 
                src="${imageUrl}" 
                alt="${assignedToName}" 
                class="assigned-to-img img-request-id-${id}" 
                onerror="updateImageInCommandBarRequest(${id})" />
              <div class="assigned-to-name-snippet name-snippet-request-id-${id}" style="${randomColorSet}">
                ${getNameSnippet(assignedToName)}
              </div>
            </div>`;

    return assignedToHTML;
  }

  getHTML() {
    const { id, tags, label } = this.getRequestInfo();

    const requestIdHTML = `
                <div class="request-id-container">
                  ID-${id}
                </div>`;

    const reuqestMetaInfo = `
                <div class="request-meta-info">
                  ${requestIdHTML}
                  ${this.getStatusHTML()} 
                </div>`;

    const tagsHTML = `
                <div class="tags-container">
                  ${tags
                    .map(
                      (tag: string) => `<div class="request-tag">${tag}</div>`
                    )
                    .join("")}
                </div>`;

    const infoContainerHTML = `
                <div class="info-container">
                  <div class="request-label">
                    ${truncate(label, {
                      length: 85,
                    })} 
                    <span class="hidden-text">${".".repeat(250)}</span>
                  </div>
                  ${tagsHTML}
                  ${reuqestMetaInfo}
                </div>`;

    return `<div class="request-wrapper">
                ${infoContainerHTML}
              </div>`;
  }
}
