import { fetchBootstrapData } from "@/api/bootstrap";
import { BootstrapQuery, BootstrapResponse } from "@/types/bootstrap";
import { InfiniteData, useInfiniteQuery } from "@tanstack/react-query";
import { useEffect } from "react";

type Props = {
  query: BootstrapQuery;
  onFetchingDone: (
    data: InfiniteData<BootstrapResponse, unknown> | undefined
  ) => void;
  queryKey: string;
  enabled: boolean;
  onFetch: () => void;
  onError: () => void;
};

const useInfiniteFetcher = ({
  query,
  onFetchingDone,
  queryKey,
  enabled,
  onFetch,
  onError,
}: Props) => {
  const { data, error, fetchNextPage, hasNextPage, isFetching } =
    useInfiniteQuery({
      initialPageParam: "",
      queryKey: [queryKey],
      queryFn: (params) => {
        onFetch();
        return fetchBootstrapData({
          ...query,
          next_cursor: params.pageParam,
        });
      },
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      enabled,
    });

  useEffect(() => {
    if (!isFetching && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, hasNextPage]);

  useEffect(() => {
    if (!isFetching && !hasNextPage && data && !error) {
      onFetchingDone(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, hasNextPage, isFetching, error]);

  useEffect(() => {
    if (error) {
      console.log("Error fetching data", error);
      onError();
    }
  }, [error, onError]);
};

export default useInfiniteFetcher;
