import { logoutHelper } from "@/utils/auth";
import { useEffect } from "react";

const Logout = () => {
  useEffect(() => {
    logoutHelper(true);
  }, []);

  return (
    <div className="fixed left-0 right-0 top-0 bottom-0 z-10 bg-background text-foreground">
      <div className="text-sm m-2">Logging out...</div>
    </div>
  );
};

export default Logout;
