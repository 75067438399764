import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";

function BlockScreen({ route }) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center gap-3 h-full">
      <div className="relative flex flex-col items-center justify-center gap-3 h-full -top-10 -left-10">
        <h3 className="text-xl">Building a new experience</h3>
        <p className="text-sm">Switch to our old app in the meanwhile</p>
        <div className="flex gap-4">
          <Button variant="outline" onClick={() => navigate("/requests")}>
            Go back to Kanban
          </Button>
          <Button
            onClick={() => {
              window.open(`https://v1.thena.ai/${route}`, "_blank");
            }}
          >
            Proceed
          </Button>
        </div>
      </div>
    </div>
  );
}

export default BlockScreen;
