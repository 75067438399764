import { useEffect, useMemo, useState } from "react";
import {
  fetchShortcutIntegration,
  saveShortcutToken,
} from "../../../api/integrations";
import { API_ROOT } from "../../../config";
import { useShortcutProvider } from "./ShortcutProvider";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Separator } from "@/components/ui/separator";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Check, Copy, Pencil } from "lucide-react";

export const ShortcutIntegration = () => {
  const { isEditing, setIsEditing } = useShortcutProvider();
  const onSave = (data: any) => {
    saveShortcutIntegration.mutate(data.token);
  };
  const queryClient = useQueryClient();

  const saveShortcutIntegration = useMutation({
    mutationFn: saveShortcutToken,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["fetchShortcutIntegration"] });
      isEditing && setIsEditing(false);
    },
    onError: () => {
      toast({ title: "Failed to generate API key." });
    },
  });

  const { data } = useQuery({
    queryKey: ["fetchShortcutIntegration"],
    queryFn: fetchShortcutIntegration,
    refetchOnMount: true,
  });

  const formSchema = z.object({
    token: z.string().trim().nonempty({ message: "Token is required" }),
  });

  const form: any = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: useMemo(() => {
      return {
        token: data?.shortcutSink?.apiKey,
      };
    }, [data]),
  });

  // useEffect(() => {
  //   // API call on Mount
  //   refetch();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    //   Setting state
    form.setValue("token", data?.shortcutSink?.apiKey);
  }, [data]);

  const maskNumber = (inputString: string) => {
    const parts = inputString.split("-");

    if (parts.length !== 5) {
      return inputString;
    }

    const maskedParts = parts
      .slice(0, 1) // Keep the first part as is
      .concat(["****", "****", "****"]) // Mask the 2nd, 3rd, and 4th parts
      .concat(parts.slice(4)); // Keep the last part as is

    return maskedParts.join("-");
  };
  const [isCopied, setIsCopied] = useState(false);
  const onCopy = async (path: string) => {
    try {
      await window.navigator.clipboard.writeText(path!);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <main className="w-full">
      {data?.shortcutSink?.webhookPath !== undefined && (
        <>
          <div className="flex flex-col space-y-4 mt-3">
            <div className="text-justify">
              We have successfully stored your Shortcut API Token and you can
              start linking Shortcut stories with Thena Requests now.
            </div>
            <div className="text-justify">
              In order to sync the status of your Shortcut Stories real time,
              you need to add the below URL as a webhook on Shortcut as a
              Webhook Integration.
            </div>
            <div className="flex items-baseline gap-[0.5px] w-[650px]">
              <div className="font-medium">URL: </div>
              <span
                className="w-[600px] text-[color:var(--color-text-info)] cursor-pointer truncate"
                onClick={() =>
                  window.open(
                    `${API_ROOT}${data?.shortcutSink?.webhookPath}`,
                    "_blank"
                  )
                }
              >
                {`${API_ROOT}${data?.shortcutSink?.webhookPath}`}
              </span>
              <Tooltip delayDuration={0}>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="p-1 w-6 h-6"
                    onClick={() =>
                      onCopy(`${API_ROOT}${data?.shortcutSink?.webhookPath}`)
                    }
                  >
                    {isCopied ? <Check size={14} /> : <Copy size={14} />}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{isCopied ? "Copied" : "Copy"}</TooltipContent>
              </Tooltip>
            </div>
            <Separator />
          </div>
        </>
      )}
      <div className="space-y-4 mt-3">
        <div className="flex space-x-2 mt-1">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSave)}
              className="space-y-6 w-full"
            >
              <FormField
                control={form.control}
                name="token"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Shortcut API Token</FormLabel>
                    <FormControl>
                      {data?.shortcutSink?.webhookPath !== undefined ? (
                        isEditing ? (
                          <Input
                            {...field}
                            placeholder={
                              data?.shortcutSink?.isEnabled
                                ? "Update Shortcut API Token"
                                : "Enter Shortcut API Token"
                            }
                            className="mt-1 w-full"
                          />
                        ) : (
                          <div className="flex items-center gap-1">
                            <div>{maskNumber(data?.shortcutSink?.apiKey)}</div>
                            <Tooltip delayDuration={0}>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="ghost"
                                  size="sm"
                                  className="p-1 w-6 h-6"
                                  onClick={() => setIsEditing(true)}
                                >
                                  <Pencil />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>Edit</TooltipContent>
                            </Tooltip>
                          </div>
                        )
                      ) : (
                        <Input
                          {...field}
                          placeholder={
                            data?.shortcutSink?.isEnabled
                              ? "Update Shortcut API Token"
                              : "Enter Shortcut API Token"
                          }
                          className="mt-1 w-full"
                        />
                      )}
                    </FormControl>
                  </FormItem>
                )}
              />
              <div className="flex justify-end">
                {data?.shortcutSink?.webhookPath !== undefined ? (
                  isEditing ? (
                    <Button
                      className="text-primary border-primary/40 mt-10 mb-8 font-bold px-8"
                      type="submit"
                      variant={"outline"}
                      size={"sm"}
                      loading={saveShortcutIntegration.isPending}
                    >
                      Update
                    </Button>
                  ) : (
                    <></>
                  )
                ) : (
                  <Button
                    className="text-primary border-primary/40 mt-10 mb-8 font-bold px-8"
                    variant={"outline"}
                    size={"sm"}
                    type="submit"
                    loading={saveShortcutIntegration.isPending}
                  >
                    Save
                  </Button>
                )}
              </div>
            </form>
          </Form>
        </div>
      </div>
    </main>
  );
};
