export const getTableDateHeader = (tabValue: string) => {
  if (tabValue === "sent") {
    return "Sent on";
  } else if (tabValue === "draft") {
    return "Updated";
  } else if (tabValue === "archived") {
    return "Archived";
  } else {
    return "Scheduled for";
  }
};

export const getSortingDateFormat = (rowA: string, rowB: string) => {
  if (!rowA) {
    return 1;
  }
  if (!rowB) {
    return 1;
  }
  const date1 = new Date(rowA);
  const date2 = new Date(rowB);
  if (date1 < date2) {
    return -1;
  } else if (date1 > date2) {
    return 1;
  }
  return 0;
};

export const getStatusWiseTimeStamp = (row) => {
  const status = row?.original?.campaign_status;
  const isArchived = row?.original?.is_deleted;
  if (isArchived) {
    return row?.original?.deleted_on;
  }
  if (status === "SENT" || status === "SENDING") {
    return row?.original?.sent_on;
  } else if (status === "SCHEDULED") {
    return row?.original?.campaign_scheduled_date;
  }
  return row?.original?.updated_at;
};

export const getSortedStatusWiseTimestamp = (tabValue: string, rowA, rowB) => {
  if (tabValue === "sent") {
    return getSortingDateFormat(rowA.original?.sent_on, rowB.original?.sent_on);
  } else if (tabValue === "scheduled") {
    return getSortingDateFormat(
      rowA.original?.campaign_scheduled_date,
      rowB.original?.campaign_scheduled_date
    );
  } else if (tabValue === "draft") {
    return getSortingDateFormat(
      rowA.original?.updated_at,
      rowB.original?.updated_at
    );
  }
  return getSortingDateFormat(
    rowA.original?.deleted_on,
    rowB.original?.deleted_on
  );
};

export const processCampaignData = (data) => {
  return {
    ...data,
    updated_at_date: new Date(data.updated_at),
  };
};
