import { useEffect, useState } from "react";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { EditorToolbar } from "./EditorToolBar";
import { EditorContent, useEditor } from "@tiptap/react";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { Button } from "../ui/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addMacro } from "@/api/kanban";
import { toast } from "sonner";
import StarterKit from "@tiptap/starter-kit";
import { useHotkeys } from "react-hotkeys-hook";
import Link from "@tiptap/extension-link";

interface Iprops {
  refetch: any;
  setOpen: any;
  defaultContent?: any;
  convoEditor?: any;
  isInsert?: boolean;
}

const removeLastSlashFromTipTapJSON = (contentJson) => {
  const content = JSON.stringify(contentJson);
  let newContent = content;
  function traverseAndModify(node) {
    if (node.type === "text") {
      const text = node.text;
      const lastSlashIndex = text.lastIndexOf("/");

      if (lastSlashIndex !== -1) {
        node.text = text.substring(0, lastSlashIndex - 1);
        newContent = node;
      }
    } else if (node.content) {
      node.content.forEach(traverseAndModify);
      newContent = node;
    }
  }
  traverseAndModify(JSON.parse(content));
  return newContent;
};

const AddMacro = ({
  refetch,
  setOpen,
  defaultContent,
  convoEditor,
  isInsert = false,
}: Iprops) => {
  const queryClient = useQueryClient();
  const [macroForm, setMacroForm] = useState({
    name: "",
    content: defaultContent
      ? JSON.stringify(removeLastSlashFromTipTapJSON(defaultContent))
      : "",
    scope: "USER",
  });

  const { mutate, isPending } = useMutation({
    mutationFn: () => {
      return addMacro(macroForm);
    },
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ["macros"] });
      const previousMacros = queryClient.getQueryData(["macros"]);
      return { previousMacros };
    },
    onSuccess: (data, _, context) => {
      refetch();
      const prevData: any = context.previousMacros;
      queryClient.setQueryData(["macros"], (old: any) => [...prevData, data]);
      setOpen(false);
      editor?.commands.setContent("");
      editor?.commands.focus();
      convoEditor?.commands?.focus();
      toast("Snippet created successfully");
    },
  });

  const onStateChange = (val, name) => {
    setMacroForm((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
    ],
    content: defaultContent
      ? removeLastSlashFromTipTapJSON(defaultContent)
      : macroForm.content,
    onUpdate: ({ editor }) => {
      return onStateChange(JSON.stringify(editor.getJSON()), "content");
    },
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && (event.ctrlKey || event.metaKey)) {
        if (editor?.isFocused) {
          handelAddMacro(isInsert);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [editor, macroForm]);

  useHotkeys("tab", (event) => {
    event.preventDefault();
    if (editor) {
      editor.commands.focus();
    }
  });

  useHotkeys("mod+enter", (event) => {
    event.preventDefault();
    handelAddMacro(isInsert);
  });
  const handelAddMacro = (isInsertation) => {
    if (macroForm.name?.length === 0) {
      return toast.error("Snippet name is required");
    }
    if (macroForm.content?.length < 3) {
      return toast.error("Snippet content is required");
    }

    if (convoEditor && isInsertation) {
      convoEditor.commands.setContent(JSON.parse(macroForm.content));
    }
    mutate();
  };

  return (
    <DialogContent className="sm:max-w-[640px] p-8 z-[100000]">
      <DialogHeader>
        <DialogTitle>Create snippet</DialogTitle>
      </DialogHeader>
      <div className="grid gap-8 py-4">
        <div className="flex flex-col gap-2 items-start">
          <Label htmlFor="name" className="text-right">
            Snippet name <sup className="text-[var(--color-text-error)]">*</sup>
          </Label>
          <Input
            id="name"
            placeholder="Give a name"
            onChange={(e) => onStateChange(e.target.value, "name")}
            className="col-span-3 bg-transparent"
          />
        </div>
        <div className="flex flex-col gap-2 items-start">
          <Label htmlFor="username" className="text-right">
            Content <sup className="text-[var(--color-text-error)]">*</sup>
          </Label>
          <div className="border w-full rounded">
            <EditorToolbar editor={editor} />
            <div className="h-40 overflow-scroll max-w-[580px]">
              <EditorContent
                editor={editor}
                className="h-40 p-4 outline-none *:outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2 items-start">
          <Label htmlFor="name" className="text-right">
            Access <sup className="text-[var(--color-text-error)]">*</sup>
          </Label>
          <RadioGroup
            defaultValue="USER"
            className="flex gap-6"
            onValueChange={(val) => onStateChange(val, "scope")}
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="USER" id="r1" />
              <Label htmlFor="r1">Only me </Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="GLOBAL" id="r2" />
              <Label htmlFor="r2">Everyone</Label>
            </div>
          </RadioGroup>
        </div>
      </div>
      <DialogFooter>
        {isInsert ? (
          <>
            <Button
              variant={"outline"}
              type="submit"
              disabled={isPending}
              loading={isPending}
              onClick={() => handelAddMacro(false)}
            >
              Save
            </Button>
            <Button
              type="submit"
              disabled={isPending}
              onClick={() => handelAddMacro(true)}
            >
              Save and insert
            </Button>
          </>
        ) : (
          <Button
            variant={"default"}
            type="submit"
            disabled={isPending}
            loading={isPending}
            onClick={() => handelAddMacro(false)}
          >
            Save
          </Button>
        )}
      </DialogFooter>
    </DialogContent>
  );
};

export default AddMacro;
