import { create } from "zustand";
import { devtools, persist, redux } from "zustand/middleware";

export type StoreType = {
  integrations: any;
  loader: boolean;
  showConnectedApps: boolean;
};

type ActionType = {
  type: "INIT" | "SET_LOADER" | "SET_CONNECTED_APPS";
  payload?: any;
};

const reducer = (state: StoreType, action: ActionType): StoreType => {
  const { type, payload } = action;

  switch (type) {
    case "INIT": {
      return {
        ...state,
        integrations: payload,
      };
    }
    case "SET_LOADER": {
      return {
        ...state,
        loader: payload,
      };
    }

    case "SET_CONNECTED_APPS": {
      return {
        ...state,
        showConnectedApps: payload,
      };
    }
    default:
      return state;
  }
};

const initalState: StoreType = {
  integrations: [],
  loader: false,
  showConnectedApps: false,
};

const useIntegrationStorePersist = create(
  persist(
    devtools(redux(reducer, initalState), {
      name: "useIntegrationStorePersist",
    }),
    {
      name: "useIntegrationStorePersist",
      version: 0.2,
    }
  )
);

export { useIntegrationStorePersist };
