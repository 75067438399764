import { useEffect } from "react";
import { debounce, isEqual } from "lodash";

import { fetchBootstrapUpdate, fetchBulkBatchData } from "@/api/bootstrap";
import { processTicketsDataBeforeAddingInDb } from "@/components/Tickets/utils";
import { PUSHER_APP_CLUSTER, PUSHER_APP_KEY } from "@/config/constants";
import { getThenaDB } from "@/db";
import { useAuthStorePersist } from "@/store/authStorePersist";
import { useGlobalStore } from "@/store/globalStore";
import { useGlobalStorePersist } from "@/store/globalStorePersist";
import { processCampaignData } from "@/utils/broadcasts";
import { processChannelData } from "@/utils/channel";
import { processCustomerContactData } from "@/utils/customerContact";
import { emitInfoToSW } from "@/utils/domUtils";
import { processExternalLinkData } from "@/utils/externalLink";
import { processRelationships } from "@/utils/relationships";
import { processRequestData } from "@/utils/requests";
import { processUserData } from "@/utils/user";
import useIsEnabledRoute from "./useIsEnabledRoute";
import { smartUpdateDBWithTx } from "@/utils/dbUtils";
import { processSubstatusData } from "@/utils/subStatus";
import { CustomerContactType, UserType } from "@/types/users";
import { ExternalLink, RequestType, SubStatusType } from "@/types/requests";
import { ChannelType } from "@/types/channel";
import { RelationshipType } from "@/types/relationships";
import { TicketType } from "@/types/tickets";
import { CampaignsTableType } from "@/types/campaignsTable";

const getLastUpdatedAtTs = (item) => {
  try {
    return item.updated_at_date.getTime().toString();
  } catch (e) {
    return "0";
  }
};

const getProcessedResult = (table, result) => {
  switch (table) {
    case "requests":
      return result.map(processRequestData);

    case "relationships":
      return result.map(processRelationships);

    case "users":
      return result.map(processUserData);

    case "channels":
      return result.map(processChannelData);

    case "externalLinks":
      return result.map(processExternalLinkData);

    case "customerContacts":
      return result.map(processCustomerContactData);

    case "campaigns":
      return result.map(processCampaignData);

    case "tickets":
      return result.map(processTicketsDataBeforeAddingInDb);

    case "subStatuses":
      return result.map(processSubstatusData);

    default:
      return [];
  }
};

const updateSubstatuses = async (newSubstatuses: SubStatusType[]) => {
  try {
    const db = getThenaDB();
    await db.transaction("rw", db.subStatuses, async () => {
      const oldSubstatuses = await db.subStatuses.toArray();
      if (isEqual(oldSubstatuses, newSubstatuses)) {
        return;
      }

      await db.subStatuses.clear();
      await db.subStatuses.bulkPut(newSubstatuses);
    });
  } catch (error) {
    console.error("Error in bulk updating substatuses", error);
  }
};

export const ENABLED_ROUTES = [
  "configuration/crm-connect",
  "/slack-broadcasting",
  "/analytics",
  "/tickets",
  "configuration/setup",
  "configuration/accounts",
];

const onHeartbeat = async () => {
  const isKanbanReady = useGlobalStore.getState().isKanbanReady;

  const isEnabledRoute = ENABLED_ROUTES.some((path) =>
    window.location.pathname.includes(path)
  );

  if (!isKanbanReady && !isEnabledRoute) {
    return;
  }

  const { id, team_id, displayName } = useAuthStorePersist.getState().user;
  emitInfoToSW({
    type: "HEARTBEAT",
    user: {
      id,
      team_id,
      displayName,
    },
    pusher: {
      PUSHER_APP_KEY,
      PUSHER_APP_CLUSTER,
    },
  });

  const boot = useGlobalStorePersist.getState().boot;

  const isBootCompleted =
    boot.model.users.completed &&
    boot.model.channels.completed &&
    boot.model.requests.completed &&
    boot.model.relationships.completed &&
    boot.model.external_links.completed &&
    boot.model.customer_contacts.completed &&
    boot.model.tickets.completed;
  // boot.model.campaigns.completed;

  if (!isBootCompleted) {
    return;
  }

  const [
    lastUser,
    lastChannel,
    lastRequest,
    lastRelationship,
    lastExternalLink,
    lastCustomerContact,
    lastTicket,
    lastCampaign,
    // lastSubStatus,
  ] = await Promise.allSettled([
    getThenaDB().users.orderBy("updated_at_date").last(),
    getThenaDB().channels.orderBy("updated_at_date").last(),
    getThenaDB().requests.orderBy("updated_at_date").last(),
    getThenaDB().relationships.orderBy("updated_at_date").last(),
    getThenaDB().externalLinks.orderBy("updated_at_date").last(),
    getThenaDB().customerContacts.orderBy("updated_at_date").last(),
    getThenaDB().tickets.orderBy("updated_at_date").last(),
    getThenaDB().campaigns.orderBy("updated_at_date").last(),
    // getThenaDB().subStatuses.orderBy("updated_at_date").last(),
  ]);

  try {
    const data = await fetchBulkBatchData({
      users: getLastUpdatedAtTs(
        (lastUser as PromiseFulfilledResult<UserType>)?.value
      ),
      requests: getLastUpdatedAtTs(
        (lastRequest as PromiseFulfilledResult<RequestType>)?.value
      ),
      channels: getLastUpdatedAtTs(
        (lastChannel as PromiseFulfilledResult<ChannelType>)?.value
      ),
      relationships: getLastUpdatedAtTs(
        (lastRelationship as PromiseFulfilledResult<RelationshipType>)?.value
      ),
      external_links: getLastUpdatedAtTs(
        (lastExternalLink as PromiseFulfilledResult<ExternalLink>)?.value
      ),
      customer_contacts: getLastUpdatedAtTs(
        (lastCustomerContact as PromiseFulfilledResult<CustomerContactType>)
          ?.value
      ),
      external_tickets: getLastUpdatedAtTs(
        (lastTicket as PromiseFulfilledResult<TicketType>)?.value
      ),
      campaigns: getLastUpdatedAtTs(
        (lastCampaign as PromiseFulfilledResult<CampaignsTableType>)?.value
      ),
      request_sub_status: "0",
    });

    if (data && Object.keys(data || {}).length) {
      for (const key in data) {
        let table = key;
        if (key === "external_links") {
          table = "externalLinks";
        } else if (key === "customer_contacts") {
          table = "customerContacts";
        } else if (key === "external_tickets") {
          table = "tickets";
        } else if (key === "request_sub_status") {
          table = "subStatuses";
        }
        const result = data[key];
        if (table === "subStatuses" && Array.isArray(result)) {
          updateSubstatuses(getProcessedResult("subStatuses", result));
        } else if (Array.isArray(result) && result.length) {
          const processedResult = getProcessedResult(table, result);
          if (table === "requests") {
            await smartUpdateDBWithTx(processedResult, "requests");
          } else {
            await getThenaDB().table(table).bulkPut(processedResult);
          }
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export const onHeartbeatDebounced = debounce(onHeartbeat, 300);

const useHeartbeatInit = () => {
  const userId = useAuthStorePersist((state) => state.user?._id);
  const isKanbanReady = useGlobalStore((state) => state.isKanbanReady);

  const isEnabledRoute = useIsEnabledRoute();

  useEffect(() => {
    if (isKanbanReady || isEnabledRoute) {
      setTimeout(() => {
        onHeartbeatDebounced();
      }, 500);
    }
  }, [isKanbanReady, isEnabledRoute]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        onHeartbeatDebounced();
      }
    };
    const handleFocus = () => onHeartbeatDebounced();
    if (userId) {
      window.addEventListener("visibilitychange", handleVisibilityChange);
      window.addEventListener("focus", handleFocus);
    }
    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleFocus);
    };
  }, [userId]);
};

export default useHeartbeatInit;

export const fetchModelUpdates = async (model: string) => {
  try {
    // prettier-ignore
    const lastModelItem = await getThenaDB()[model].orderBy("updated_at_date")
      .last();
    const data = await fetchBootstrapUpdate({
      model,
      afterEpoch: getLastUpdatedAtTs(lastModelItem),
    });

    if (data?.length) {
      const processedResult = getProcessedResult(model, data);
      if (model === "requests") {
        await smartUpdateDBWithTx(processedResult, "requests");
      } else {
        await getThenaDB().table(model).bulkPut(processedResult);
      }
      return true;
    }
  } catch (error) {
    console.error({ error });
  }
};
