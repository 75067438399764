import { cn } from "@/utils";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ArrowDownUp, Ellipsis } from "lucide-react";
import moment from "moment";
import { memo, useMemo, useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTrigger,
} from "../ui/dialog";
import { DialogDescription } from "@radix-ui/react-dialog";
import { Button } from "../ui/button";
import { useMutation } from "@tanstack/react-query";
import { deleteMacro } from "@/api/kanban";
import { toast } from "sonner";
import useFocusedLiveQuery from "@/hooks/useFocusedLiveQuery";
import { getThenaDB } from "@/db";
import MacroEdit from "./MacroEdit";
export function findParagraphs(object) {
  if (object.type === "paragraph") {
    return object;
  } else if (object.content) {
    for (const item of object.content) {
      const paragraph = findParagraphs(item);
      if (paragraph) {
        return paragraph;
      }
    }
  }
  return null;
}

const MacrosTable = ({ data, refetch }) => {
  const [sorting, setSorting] = useState<any>([]);

  const allUsersIDsinMacros = useMemo(() => {
    const uniqueUserIds = new Set(data.map((macro) => macro?.createdBy));
    return Array.from(uniqueUserIds);
  }, [data]);

  const allUsers = useFocusedLiveQuery(() => {
    return getThenaDB()
      .webUsers.where("_id")
      .anyOf(allUsersIDsinMacros as string[])
      .toArray();
  }, [allUsersIDsinMacros]);

  const { mutate, isPending } = useMutation({
    mutationFn: (id) => {
      return deleteMacro(id);
    },
    onMutate: async (id: string) => {
      const macroList = await getThenaDB().macros.get(id ?? "");
      await getThenaDB().macros.delete(id);
      return { macroList };
    },
    onSuccess: () => {
      refetch();
      toast("Successfully deleted");
    },
    onError: async (err, id: string, context) => {
      toast.error("Error deleting Snippet");
      const prevMacroList = context?.macroList;
      if (prevMacroList) {
        await getThenaDB().macros.put(prevMacroList);
      }
    },
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "name",
        cell: (info) => {
          const name = info.getValue();
          return (
            <div className="flex items-center gap-3 w-56 truncate text-color-icon-muted text-sm">
              {name}
            </div>
          );
        },
        header: () => <span>Name</span>,
      },
      {
        accessorKey: "content",
        header: () => <span>Content</span>,
        cell: (row) => {
          const content = JSON.parse(row.getValue());
          const macro = row?.row?.original;
          const paragraph = findParagraphs(content);
          let paragraphContent = "";
          paragraph?.content
            ?.filter((item) => item.text)
            ?.map((item) => (paragraphContent += item.text));

          return (
            <Dialog>
              <DialogTrigger>
                <div className="truncate w-96 text-left">
                  {paragraphContent.length > 0 ? paragraphContent : "-"}
                </div>
              </DialogTrigger>
              <MacroEdit macro={macro} refetch={refetch} />
            </Dialog>
          );
        },
      },
      {
        accessorKey: "scope",
        header: () => <div className="flex items-center gap-2 ">Access</div>,
        cell: (info) => {
          const scope = info.getValue();
          return (
            <div className="whitespace-nowrap text-sm capitalize">
              {scope === "USER" ? "Me" : "Everyone"}
            </div>
          );
        },
        // size: 100,
      },
      {
        accessorKey: "createdBy",
        header: () => <span>Created by</span>,
        cell: (info) => {
          const createdByID = info.getValue();
          const createdBy = allUsers?.find((user) => user._id === createdByID);
          return (
            <div className="flex items-center gap-2 whitespace-nowrap text-sm">
              {createdBy ? (
                <>
                  {createdBy?.user_image && (
                    <img
                      src={createdBy?.user_image}
                      alt="assignee"
                      className="h-6 w-6 rounded-full"
                    />
                  )}
                  {createdBy?.displayName}
                </>
              ) : (
                "-"
              )}
            </div>
          );
        },
        // size: 100,
      },
      {
        accessorKey: "updatedAt",
        header: () => (
          <div className="flex items-center gap-2">
            Updated
            <ArrowDownUp size={16} />
          </div>
        ),
        cell: (info) => {
          const updatedAt = info.getValue();
          return (
            <div className="whitespace-nowrap text-sm">
              {moment(updatedAt).fromNow()}
            </div>
          );
        },
      },
      {
        accessorKey: "e",
        header: () => " ",
        cell: ({ row }) => {
          const macroId = row?.original?._id;
          const macro = row?.original;
          return (
            <div className="whitespace-nowrap text-sm">
              <Popover>
                <PopoverTrigger>
                  <Ellipsis size={16} />
                </PopoverTrigger>
                <PopoverContent className="w-20 p-2 text-sm flex flex-col gap-2 items-start">
                  <>
                    <Dialog>
                      <DialogTrigger>
                        <div className="flex flex-col gap-2">
                          <span>Edit</span>
                        </div>
                      </DialogTrigger>
                      <MacroEdit macro={macro} refetch={refetch} />
                    </Dialog>
                    <Dialog>
                      <DialogTrigger>
                        <div className="flex flex-col gap-2">
                          <span>Delete</span>
                        </div>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogDescription>
                          {" "}
                          Are you sure you want to delete this snippet?
                        </DialogDescription>
                        <DialogFooter>
                          <Button
                            disabled={isPending}
                            loading={isPending}
                            onClick={() => {
                              mutate(macroId);
                            }}
                          >
                            Delete
                          </Button>
                        </DialogFooter>
                      </DialogContent>
                    </Dialog>
                  </>
                </PopoverContent>
              </Popover>
            </div>
          );
        },
      },
    ],
    [allUsers]
  );

  const table = useReactTable({
    data: (data || []).sort(
      (a: any, b: any) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    ),
    columns,
    state: {
      sorting,
    },
    onSortingChange: (sortFn: any) => {
      const newSorting = sortFn();
      const isValidSorting = newSorting.filter(
        (sort) => sort.id === "updatedAt"
      );
      setSorting(isValidSorting.length > 0 ? newSorting : sorting);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const { rows } = table.getRowModel();

  return (
    <TableVirtuoso
      totalCount={rows.length}
      components={{
        Table: ({ style, ...props }) => {
          return (
            <table
              {...props}
              style={{
                ...style,
                width: "100%",
                borderSpacing: 0,
              }}
            />
          );
        },
        TableRow: (props) => {
          const index = props["data-index"];
          const row = rows[index];

          return (
            <tr {...props} className="border-y">
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="p-4">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        },
      }}
      fixedHeaderContent={() => {
        return table.getHeaderGroups().map((headerGroup) => (
          <tr
            key={headerGroup.id}
            className="rounded-lg bg-[var(--color-bg-subtle)] border-b"
          >
            {headerGroup.headers.map((header) => {
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  className="text-left p-4 whitespace-nowrap text-[var(--color-icon-muted)] font-normal text-sm"
                >
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        style: header.column.getCanSort()
                          ? { cursor: "pointer", userSelect: "none" }
                          : {},
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                      className={cn(
                        header.id === "name" && "w-56",
                        header.id === "scope" && "w-12",
                        header.id === "content" && "w-80"
                      )}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        ));
      }}
    />
  );
};

const MemoizedMacrosTable = memo(MacrosTable);

export default MemoizedMacrosTable;
