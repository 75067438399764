/* eslint-disable @typescript-eslint/no-explicit-any */
import { MODAL_TYPES } from "@/constants/kanban";
import { RequestType, ViewsType } from "@/types/requests";
import { cloneDeep } from "lodash";
import { create } from "zustand";
import { devtools, redux } from "zustand/middleware";

type ModalType = null | {
  data: any;
  type: keyof typeof MODAL_TYPES;
};

export type StoreType = {
  requestMap: Record<string, RequestType>;
  statusOrderMap: Record<string, string[]>;
  modal: ModalType;
  isConversationLoading: boolean;
  checkedList: string[];
  manageStatuses: boolean;
  showMandatoryFieldError: boolean;
  currentView: string | null;
  droppedId: string;
  currentScrollingCol: string;
  columnMetadata: ViewsType["additionalPrefs"]["columnMetadata"];
  currentRequestId: string;
  scrollTimeoutId: number | undefined;
  scrollEndAnimationTimeoutId: number | undefined;
  mutateAPICount: number;
  externalLinksTab: {
    // Filters in external links tab
    externalLinkStatus: { value: string; label: string; avatar: string }[];
  };
};

type ActionType = {
  type:
    | "SET_REQUEST_MAP"
    | "BULK_UPDATE_REQUEST_MAP"
    | "SET_STATUS_ORDER_MAP"
    | "SET_MODAL"
    | "SET_HOVERING_ON_CARD"
    | "SET_CONVERSATION_LOADING"
    | "UPDATE_REQUEST_STORE"
    | "SET_CHECKED_LIST"
    | "SET_MANAGE_STATUS"
    | "SET_SHOW_MANDATORY_FIELD_ERROR"
    | "SET_CURRENT_VIEW"
    | "UPDATE_CUSTOM_FIELDS"
    | "SET_DROPPED_ID"
    | "SET_CURRENT_SCROLLING_COL"
    | "HIDE_COLUMN_TOGGLE"
    | "SET_CURRENT_REQUEST_ID"
    | "SET_SCROLL_TIMEOUT_IDS"
    | "INCREMENT_MUTATE_API_COUNT"
    | "DECREMENT_MUTATE_API_COUNT"
    | "SET_EXTERNAL_LINKS_FILTERS";
  payload?: NonNullable<any>;
};

const reducer = (state: StoreType, action: ActionType): StoreType => {
  const { type, payload } = action;

  switch (type) {
    case "SET_REQUEST_MAP":
      return {
        ...state,
        requestMap: payload?.requestMap as StoreType["requestMap"],
      };
    case "BULK_UPDATE_REQUEST_MAP":
      return {
        ...state,
        requestMap: {
          ...state.requestMap,
          ...payload.records,
        },
      };

    case "SET_STATUS_ORDER_MAP":
      return {
        ...state,
        statusOrderMap: payload?.statusOrderMap as StoreType["statusOrderMap"],
        columnMetadata: payload?.columnMetadata as StoreType["columnMetadata"],
      };

    case "SET_MODAL": {
      if (payload?.modal) {
        return {
          ...state,
          modal: payload.modal as ModalType,
        };
      }
      return state;
    }

    case "SET_CONVERSATION_LOADING": {
      return {
        ...state,
        isConversationLoading: payload.isConversationLoading as boolean,
      };
    }

    case "UPDATE_REQUEST_STORE": {
      if (
        Object.keys(payload?.payload || {}).length === 1 &&
        Object.keys(payload?.payload || {})[0] === "ai_metadata"
      ) {
        return {
          ...state,
          requestMap: {
            ...state.requestMap,
            [payload._id]: {
              ...state.requestMap[payload._id],
              ai_metadata: {
                ...(state.requestMap[payload._id]?.ai_metadata || {}),
                ...payload.payload.ai_metadata,
              },
            },
          },
        };
      }

      return {
        ...state,
        requestMap: {
          ...state.requestMap,
          [payload._id]: {
            ...state.requestMap[payload._id],
            ...payload.payload,
          },
        },
      };
    }

    case "SET_CURRENT_VIEW":
      return {
        ...state,
        currentView: payload.currentView,
      };
    case "UPDATE_CUSTOM_FIELDS": {
      const { _id, categoryName, type, selectedValues, requestFailed } =
        payload;
      const request = state.requestMap[_id];
      if (!request) {
        return state;
      }

      if (requestFailed) {
        return {
          ...state,
          requestMap: { ...state.requestMap, [_id]: payload.payload },
        };
      }

      const selectedCategoryIndex = request.categories.findIndex(
        (category) => category.category_name === categoryName
      );

      const newState = cloneDeep(state);

      // if type is either of below
      // debugger;
      if (["select", "radio", "link"].includes(type)) {
        // If category already exists in the store
        if (selectedCategoryIndex >= 0) {
          newState.requestMap[_id].categories[selectedCategoryIndex].values[0] =
            {
              ...newState.requestMap[_id].categories[selectedCategoryIndex]
                .values[0],
              value: selectedValues[0],
              name: selectedValues[0],
            };
        } else {
          // If category does not exist in the store
          newState.requestMap[_id].categories = newState.requestMap[
            _id
          ].categories.concat([
            {
              values: [{ value: selectedValues[0], name: selectedValues[0] }],
              category_name: categoryName,
            } as any,
          ]);
        }
      }

      if (type === "multiselect") {
        // If category already exists in the store
        if (selectedCategoryIndex >= 0) {
          newState.requestMap[_id].categories[selectedCategoryIndex].values = (
            selectedValues || []
          ).map((value = "") => ({
            name: value,
            value: value,
          }));
        } else {
          newState.requestMap[_id].categories = newState.requestMap[
            _id
          ].categories.concat([
            {
              values: (selectedValues || []).map((value = "") => ({
                name: value,
                value: value,
              })),
              category_name: categoryName,
            } as any,
          ]);
          // If category does not exist in the store
        }
      }
      return newState;
    }

    case "SET_CHECKED_LIST":
      return { ...state, checkedList: payload.checkedList };

    case "SET_MANAGE_STATUS":
      return { ...state, manageStatuses: payload.manageStatuses };

    case "SET_SHOW_MANDATORY_FIELD_ERROR": {
      return {
        ...state,
        showMandatoryFieldError: payload.showMandatoryFieldError,
      };
    }
    case "SET_DROPPED_ID":
      return {
        ...state,
        droppedId: payload.droppedId,
      };
    case "SET_CURRENT_SCROLLING_COL":
      return { ...state, currentScrollingCol: payload.currentScrollingCol };

    case "HIDE_COLUMN_TOGGLE": {
      const { column, isHidden } = payload;

      return {
        ...state,
        columnMetadata: {
          ...state.columnMetadata,
          [column]: { isHidden },
        },
      };
    }

    case "SET_CURRENT_REQUEST_ID": {
      return {
        ...state,
        currentRequestId: payload.currentRequestId,
      };
    }

    case "SET_SCROLL_TIMEOUT_IDS": {
      return {
        ...state,
        scrollTimeoutId: payload.scrollTimeoutId,
        scrollEndAnimationTimeoutId: payload.scrollEndAnimationTimeoutId,
      };
    }

    case "INCREMENT_MUTATE_API_COUNT": {
      return {
        ...state,
        mutateAPICount: state.mutateAPICount + 1,
      };
    }

    case "DECREMENT_MUTATE_API_COUNT": {
      return {
        ...state,
        mutateAPICount: state.mutateAPICount - 1,
      };
    }

    case "SET_EXTERNAL_LINKS_FILTERS": {
      return {
        ...state,
        externalLinksTab: {
          externalLinkStatus: payload.externalLinkStatus,
        },
      };
    }

    default:
      return state;
  }
};

const initalState: StoreType = {
  modal: null,
  requestMap: {},
  statusOrderMap: {},
  isConversationLoading: false,
  checkedList: [],
  manageStatuses: false,
  showMandatoryFieldError: false,
  currentView: null,
  droppedId: "",
  currentScrollingCol: "",
  columnMetadata: {},
  currentRequestId: "",
  scrollTimeoutId: undefined,
  scrollEndAnimationTimeoutId: undefined,
  mutateAPICount: 0,
  externalLinksTab: {
    externalLinkStatus: [],
  },
};

const useKanbanStore = create(
  devtools(redux(reducer, initalState), {
    name: "useKanbanStore",
  })
);

export { useKanbanStore };
