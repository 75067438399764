import { CustomerRequests } from "@/types/customerPortal";
import { FilterValueType } from "@/types/filters";
import { getDateObjectsFromRange } from "./dateUtils";
import { uniqBy } from "lodash";

export const MEMBER_ROLES = [
  {
    value: "admin",
    label: "Admin",
  },
  {
    value: "member",
    label: "Member",
  },
];

export const processRequests = (request: any) => {
  const replyOrder = Math.max(
    request.last_reply_by_customer_ts,
    request.last_reply_by_vendor_ts
  );

  return {
    ...request,
    status: request.status === "ASSIGNED" ? "INPROGRESS" : request.status,
    last_reply_ts_order: isNaN(replyOrder)
      ? Number.NEGATIVE_INFINITY
      : replyOrder,
    created_at_epoch: new Date(request.created_at).getTime(),
  };
};

type SortRequestsType = {
  sortOrder: "ASC" | "DESC";
  requestList: CustomerRequests[];
  sortBy: string;
};

export const sortRequests = ({
  sortBy,
  sortOrder,
  requestList,
}: SortRequestsType) => {
  return [...requestList].sort((a, b) => {
    let item1 = a[sortBy];
    let item2 = b[sortBy];

    switch (sortBy) {
      case "created_at":
        item1 = new Date(a[sortBy]).getTime();
        item2 = new Date(b[sortBy]).getTime();
        break;

      default:
        break;
    }

    if (sortOrder === "ASC") {
      return item1 - item2;
    }
    return item2 - item1;
  });
};

type FilteredAndSortedRequestsType = {
  filterMap: Record<
    string,
    {
      values: FilterValueType[];
      id: string;
    }
  >;
  requestList: CustomerRequests[];
  sortBy: {
    field: string;
    order: "ASC" | "DESC";
  };
  searchQuery: string;
  dateRange: null | {
    from: Date | undefined;
    to: Date | undefined;
  };
  preselectedRange: "24 hours" | "7 days" | "30 days" | "All time" | null;
};

const KEYS_TO_SEARCH = ["customer_team_name", "description", "request_id"];

export const getFilteredAndSortedRequests = ({
  sortBy,
  filterMap,
  dateRange,
  requestList,
  searchQuery,
  preselectedRange,
}: FilteredAndSortedRequestsType) => {
  let filteredRequests = [...requestList];

  let updatedRange = dateRange;
  if (preselectedRange) {
    updatedRange = getDateObjectsFromRange(preselectedRange);
  }

  const fromDate = updatedRange?.from
    ? new Date(updatedRange.from).getTime()
    : Number.NEGATIVE_INFINITY;

  const toDate = updatedRange?.to
    ? new Date(updatedRange.to).getTime()
    : Number.POSITIVE_INFINITY;

  filteredRequests = filteredRequests.filter((request) => {
    const requestCreatedAt = request.created_at_epoch;
    return requestCreatedAt >= fromDate && requestCreatedAt <= toDate;
  });

  Object.keys(filterMap).forEach((filterKey) => {
    const filter = filterMap[filterKey];
    if (filter.values.length) {
      filteredRequests = filteredRequests.filter((request) => {
        return filter.values.some(
          (value) => request[filter.id] === value.value
        );
      });
    }
  });

  if (searchQuery) {
    filteredRequests = filteredRequests.filter((request) => {
      return KEYS_TO_SEARCH.some((key) => {
        return (request[key] || "")
          .toString()
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      });
    });
  }

  const sortedRequests = sortRequests({
    sortBy: sortBy.field,
    sortOrder: sortBy.order,
    requestList: filteredRequests,
  });

  return sortedRequests;
};

export const getRequestorList = (requests: CustomerRequests[]) => {
  const list = requests.map((request) => ({
    value: request.requestor_id,
    label: request.requestor_name,
    avatar: request.requestor_image,
  }));

  return uniqBy(list, "value");
};
