import { isEmpty } from "lodash";
import "./EmojiList.css";

import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

export const InternalMentionList = forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const getLabel = (item) => {
    let labelValue = "";
    if (!isEmpty(item.slack_profile_display_name)) {
      labelValue = item.slack_profile_display_name;
    } else {
      labelValue = item.slack_profile_real_name ?? item.name;
    }
    return labelValue;
  };

  const selectItem = (index) => {
    const item = props.items[index];
    if (!isEmpty(item)) {
      const label = getLabel(item);
      props.command({
        label: label,
        id: item?.slack_id,
      });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + props.items.length - 1) % props.items.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="items">
      {props.items.length ? (
        props.items.map((item, index) => {
          const label = getLabel(item);
          return (
            <>
              <div
                onClick={() => selectItem(index)}
                className={`item ${index === selectedIndex ? "is-selected" : ""}`}
              >
                <Avatar className="w-5 h-5">
                  <AvatarImage
                    className="aspect-video"
                    src={item?.slack_profile_image_24}
                    alt={label}
                  />
                  <AvatarFallback>{label.substring(0, 2)}</AvatarFallback>
                </Avatar>
                <button key={index}>{label}</button>
              </div>
            </>
          );
        })
      ) : (
        <div className="item">No result</div>
      )}
    </div>
  );
});
