import { useGlobalStore } from "@/store/globalStore";
import { useLiveQuery } from "dexie-react-hooks";
import { useRef } from "react";

type Props = {
  queryFn: () => any;
  deps?: any[];
  defaultValue?: any;
};

const useFocusedLiveQuery = (
  queryFn: Props["queryFn"],
  deps: Props["deps"] = [],
  defaultValue: Props["defaultValue"] = undefined
) => {
  const isWindowVisible = useGlobalStore((state) => state.isWindowVisible);
  const dbPromiseRef = useRef(Promise.resolve(defaultValue));

  return useLiveQuery(
    () => {
      if (isWindowVisible) {
        dbPromiseRef.current = queryFn();
        return dbPromiseRef.current;
      }
      return dbPromiseRef.current;
    },
    [...deps, isWindowVisible],
    defaultValue
  );
};

export default useFocusedLiveQuery;
