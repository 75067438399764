import { api } from ".";
import {
  ConfigResponseType,
  ConnectorFieldType,
  ConnectorPatchPayload,
  FormJSONType,
  FormResponseType,
  FormType,
  HelpdeskType,
  MappingFieldType,
  TestConnectionPayloadType,
  ZendeskSyncType,
} from "../modules/ZendeskIntegration/types";

export const fetchAllHelpdesks = (): Promise<HelpdeskType[]> =>
  api.get("v2/zendesk/helpdesks").then(({ data }) => data.data);

export const fetchTestConnection = (
  data: TestConnectionPayloadType
): Promise<HelpdeskType> =>
  api.post("v2/zendesk/helpdesks", data).then(({ data }) => data.data);

export const fetchHelpdeskSyncStatus = (
  helpdeskId: string
): Promise<ZendeskSyncType> =>
  api
    .get(`v2/zendesk/helpdesk-sync-status/${helpdeskId}`)
    .then(({ data }) => data.data);

export const fetchRetryHelpdeskSync = (
  helpdeskId: string
): Promise<ZendeskSyncType> =>
  api
    .post(`v2/zendesk/helpdesk-retry/${helpdeskId}`)
    .then(({ data }) => data.data);

export const fetchHelpdeskConfigs = (
  helpdeskId: string | string[]
): Promise<ConfigResponseType> => {
  let helpdeskIdParam = "";
  if (!Array.isArray(helpdeskId)) {
    helpdeskIdParam = `helpdeskIds[]=${helpdeskId}`;
  } else {
    helpdeskId.forEach((id) => {
      helpdeskIdParam += `helpdeskIds[]=${id}&`;
    });
  }

  return api
    .get(`v2/zendesk/helpdesks-configuration?${helpdeskIdParam}`)
    .then(({ data }) => data.data);
};

export const fetchMappingFields = (
  helpdeskId: string
): Promise<MappingFieldType[]> =>
  api
    .get(`v2/zendesk/helpdesks/${helpdeskId}/mapping-fields`)
    .then(({ data }) => data.data);

export const fetchFormsList = (helpdeskId: string): Promise<FormType[]> =>
  api.get(`v2/zendesk/${helpdeskId}/forms`).then(({ data }) => data.data);

export const fetchDeleteHelpdesk = (helpdeskId: string): Promise<FormType[]> =>
  api
    .delete(`v2/zendesk/helpdesks/${helpdeskId}`)
    .then(({ data }) => data.data);

export const fetchConnectorSettings = (): Promise<ConnectorFieldType[]> =>
  api.get("v2/settings/connectors").then(({ data }) => data.data);

export const fetchPatchHelpdesk = (
  helpdeskId: string,
  data: {
    token: string;
    username: string;
  }
): Promise<ZendeskSyncType> =>
  api
    .patch(`v2/zendesk/helpdesks/${helpdeskId}`, data)
    .then(({ data }) => data.data);

export const fetchPatchHelpdeskConfigs = (
  helpdeskId: string | string[],
  data: ConfigResponseType
): Promise<ConfigResponseType> => {
  let helpdeskIdParam = "";
  if (!Array.isArray(helpdeskId)) {
    helpdeskIdParam = `helpdeskIds[]=${helpdeskId}`;
  } else {
    helpdeskId.forEach((id) => {
      helpdeskIdParam += `helpdeskIds[]=${id}&`;
    });
  }

  return api
    .patch(`v2/zendesk/helpdesks-configuration?${helpdeskIdParam}`, data)
    .then(({ data }) => data.data);
};

export const fetchAllFormsList = (): Promise<FormType[]> =>
  api.get("v2/zendesk/forms").then(({ data }) => data.data);

export const fetchAllMappingFields = (): Promise<MappingFieldType[]> =>
  api
    .get("v2/zendesk/helpdesks/all/mapping-fields")
    .then(({ data }) => data.data);

export const fetchPatchConnectorSettings = (
  data: ConnectorPatchPayload
): Promise<ConnectorFieldType[]> =>
  api.patch("v2/settings/connectors", data).then(({ data }) => data.data);

export const fetchHelpdeskForm = (
  helpdeskId: string,
  formId: string
): Promise<FormResponseType> =>
  api
    .get(`v2/zendesk/${helpdeskId}/forms/${formId}`)
    .then(({ data }) => data.data);

export const fetchPatchHelpdeskForm = (
  helpdeskId: string,
  formId: string,
  data: FormJSONType[]
): Promise<FormResponseType> =>
  api
    .patch(`v2/zendesk/${helpdeskId}/forms/${formId}`, data)
    .then(({ data }) => data.data);
