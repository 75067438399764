import { useCurrentEditor } from "@tiptap/react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";

import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Link } from "lucide-react";
import { useCallback, useState } from "react";
export const LinkComponent = ({ isRequestMerged }) => {
  const { editor } = useCurrentEditor();
  const [currentLink, setCurrentLink] = useState(
    editor?.getAttributes("link").href ?? ""
  );
  const setLink = useCallback(() => {
    if (editor) {
      // cancelled
      if (currentLink === null) {
        return;
      }
      // empty
      if (currentLink === "") {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }
      // update link
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: currentLink })
        .run();
    }
  }, [editor, currentLink]);

  if (editor) {
    const selection = editor.state.selection;
    let selectedText = "";
    if (!selection.empty) {
      const { from, to } = selection;
      selectedText = editor.state.doc.textBetween(from, to, " ");
    }

    return (
      <Dialog>
        <DialogTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className={`${
              editor.isActive("link") ? "is-active w-9 h-9" : "w-9 h-9"
            }`}
            disabled={isRequestMerged}
          >
            <Link size={15} />
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px] bg-modal-bg">
          <DialogHeader>
            <DialogTitle>Add link</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4 ">
            <div className="flex flex-col items-start justify-center gap-4">
              <Label htmlFor="name" className="text-right">
                Text
              </Label>
              <Input
                id="text"
                defaultValue={selectedText}
                className="col-span-3"
              />
            </div>
            <div className="flex flex-col items-start justify-center gap-4">
              <Label htmlFor="username" className="text-right">
                Link
              </Label>
              <Input
                id="username"
                defaultValue={editor.getAttributes("link").href}
                className="col-span-3"
                onChange={(e) => setCurrentLink(e.target.value)}
              />
            </div>
          </div>
          <DialogFooter>
            <DialogClose>
              <Button
                variant={"outline"}
                className="bg-[var(--modal-bg)] hover:bg-[var(--color-label-bg)]"
              >
                Cancel
              </Button>
            </DialogClose>
            <DialogClose>
              <Button onClick={setLink}>Save changes</Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }
  return null;
};
