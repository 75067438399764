export const STREAM_EVENTS = {
  DONE: "done",
  CREATED: "thread.message.created",
  DELTA: "thread.message.delta",
  COMPLETE: "thread.run.completed",
  INCOMPLETE: "thread.run.incomplete",
  ERROR: "thread.message.error",
};

export const ASSISTANT_TYPE = {
  INTERNAL_HELPDESK: "INTERNAL_HELPDESK",
  CUSTOMER_SUPPORT: "CUSTOMER_SUPPORT",
};
export const AI_ASSISTANT_QUERY_KEY = "aiAssistantQueryKey";
export const AI_ASSISTANT_SOURCES_KEY = "assistantSources";

export const AI_ASSIST_MODAL_TYPE = {
  NO_ASSISTANT: "NO_ASSISTANT",
  NO_SOURCES: "NO_SOURCES",
  REGENERATE_WARNING: "REGENERATE_WARNING",
};

export const AI_ASSIST_MODAL_DATA: {
  [x: string]: {
    content: {
      title: string;
      subtitle: string;
    };
    primaryCTAText: string;
    primaryCTAVariant: string;
  };
} = {
  [AI_ASSIST_MODAL_TYPE.NO_ASSISTANT]: {
    content: {
      title: "Set up AI assist",
      subtitle:
        "Set up a customer support AI assistant to generate AI responses. Go to AI assist to get started.",
    },
    primaryCTAText: "Go to AI assist",
    primaryCTAVariant: "default",
  },
  [AI_ASSIST_MODAL_TYPE.NO_SOURCES]: {
    content: {
      title: "Sources not found",
      subtitle: "Add sources for AI to generate effective responses.",
    },
    primaryCTAText: "Go to AI assist",
    primaryCTAVariant: "default",
  },
  [AI_ASSIST_MODAL_TYPE.REGENERATE_WARNING]: {
    content: {
      title: "AI will override your text",
      subtitle:
        "AI will generate a reply based on the conversation context, replacing your current text.",
    },
    primaryCTAText: "Replace Text",
    primaryCTAVariant: "default",
  },
};

export const EMPTY_FUNC = () => {};
export const AI_ASSIST_SETTINGS_PATH = {
  [ASSISTANT_TYPE.CUSTOMER_SUPPORT]: "customer-support/ai-assist",
  [ASSISTANT_TYPE.INTERNAL_HELPDESK]: "DEinternal-helpdesk/ai-assist",
};

export const SOURCE_ACTIVITY_STATUS = {
  STARTED: "started",
  COMPLETED: "completed",
  FAILED: "failed",
};

export const SOURCES_HEADER_CONTENT = {
  title: "Manage sources",
  subtitle: "Upload and manage documents, links, and other references for AI.",
};

export const AI_ASSIST_PAGE_HEADER_CONTENT = {
  title: "AI assist",
  subtitle: "Configure AI assistant and add your sources.",
};

export const ADD_SOURCE_MODAL_HEADER = {
  title: "Add source",
  subtitle: "Provide sources for AI to generate effective responses.",
};

export const AI_ASSIST_MESSAGES = {
  ACTIVATE_ASSISTANT: {
    SUCCESS: "Assistant activation successful.",
    FAILED: "Assistant activation failed.",
  },
  DEACTIVATE_ASSISTANT: {
    SUCCESS: "Assistant deactivation successful.",
    FAILED: "Assistant deactivation failed.",
  },
  ADD_SOURCE: {
    SUCCESS: "Source creation successful.",
    FAILED: "Source creation failed.",
    DUPLICATE: "Source creation failed. URL already present.",
  },
  UPDATE_SOURCE: {
    SUCCESS: "Source updation successful.",
    FAILED: "Source updation failed.",
  },
  STREAMING_FAILED: {
    SUCCESS: "",
    FAILED: "AI prompt failed. Try again.",
  },
  DELETE_SOURCE: {
    SUCCESS: "Source deletion successful.",
    FAILED: "Source deletion failed.",
  },
};

export const SOURCE_TYPE_MAP = {
  web_link: { key: "web_link", value: "URL" },
  thena_request: { key: "thena_request", value: "Requests" },
  doc: { key: "doc", value: "File" },
};
export const AI_ASSIST_TOOLTIP_TEXT = "Generate AI response";

export const DELETE_SOURCE_MODAL_CONTENT = {
  title: "Are you sure you want to delete this source?",
  subtitle: "This action is permanent and cannot be undone.",
};
export const AI_ASSIST_EXPERIMENTAL_BANNER_TEXT =
  "This is an experimental generative AI feature. All information should be verified prior to use.";
export const AI_ASSIST_SOURCES_PROCESSING_INFO =
  "We have begun processing your source. This may take some time. Feel free to leave this page and come back.";
export const THENA_REQUEST_SOURCE_BANNER =
  "Thena requests from the last 90 days are loaded into the assistant. Data updates every 24 hours with all requests from your workspace.";
export const ALLOWED_SOURCE_FILE_TYPES = [
  ".pdf",
  ".txt",
  ".html",
  ".docx",
  ".doc",
];
export const URL_ERROR_TEXT = `Please enter a valid URL that starts with "https://".`;
export const SOURCE_TYPES_VALUES: readonly [string, ...string[]] = [
  "url",
  "file",
  "thena_requests",
];
export const SOURCE_TYPES = {
  URL: { name: "URL", value: "url", key: "web_link" },
  // FILE: { name: "File", value: "file", key: "doc" },
  THENA: {
    name: "Thena requests",
    value: "thena_requests",
    key: "thena_request",
  },
};
export const NO_SOURCE_FILE_ADDED_ERROR = "No files Added";
export const ADD_SOURCE_FILES_INPUT_TEXT =
  "Drag and drop your source file here";
