import "./App.css";
import "./globals.css";
import useAuth from "./hooks/useAuth";
import AppRoutes from "./routes/Routes";
import { Toaster } from "@/components/ui/toaster";
import Clock from "./components/Clock";
import { Toaster as SonnerToaster } from "sonner";
import useHeartbeatInit from "./hooks/useHeartbeatInit";
import useCRMFilterInit from "@/hooks/useCRMFilterInit";
import { useGlobalStore } from "./store/globalStore";
import { useEffect } from "react";
import useFetchBroadcastAnalytics from "./hooks/useFetchBroadcastAnalytics";
import useIsEnabledRoute from "./hooks/useIsEnabledRoute";

const Init = () => {
  useHeartbeatInit();
  return null;
};

const CRMInit = () => {
  useCRMFilterInit();
  return null;
};

const BroadcastInit = () => {
  useFetchBroadcastAnalytics();
  return null;
};

function App() {
  const user = useAuth();
  const isKanbanReady = useGlobalStore((state) => state.isKanbanReady);
  const isEnabledRoute = useIsEnabledRoute();

  useEffect(() => {
    const handleVisibilityChange = () => {
      useGlobalStore
        .getState()
        .setIsWindowVisible(document.visibilityState === "visible");
    };
    window.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <>
      {user?._id ? (
        <>
          <Init />
          {(isKanbanReady || isEnabledRoute) && <CRMInit />}
          {isEnabledRoute && <BroadcastInit />}
        </>
      ) : null}
      <AppRoutes />
      <Toaster />
      <SonnerToaster richColors duration={3000} />
      <Clock />
    </>
  );
}

export default App;
