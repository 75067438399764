import { useFlags } from "launchdarkly-react-client-sdk";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ConnectedAppProvider } from ".";
import {
  AuthenticatedConnectUser,
  IntegrationUninstallEvent,
} from "../../../paragon";
import { disableParagonIntegration } from "../../api/integrations";
import { IntegrationCard, ParagonIntegrationCard } from "../../components";
import { useParagonStore } from "./../../store/paragonStore";
import { IntegrationsList } from "./definitions";
import useParagonAuth from "@/hooks/useParagonAuth";
import { paragon } from "@useparagon/connect";
import { Separator } from "@/components/ui/separator";
import { IntegrationModal } from "./IntegrationModal/IntegrationModal";
import { usePrefetchZendeskApis } from "@/hooks/usePrefetchZendeskApis";
import ThenaLoader from "@/components/common/ThenaLoader";
import { useIntegrationStorePersist } from "@/store/integrationStorePersist";

const itemToExclude = ["Salesforce", "HubSpot", "Intercom", "Freshdesk"];

export const ConnectedApps = () => {
  const { user: paragonUser } = useParagonAuth();
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const [activeIntegrationId, setActiveIntegrationId] = useState("");

  const queryClient = useQueryClient();
  const [showParagonConnectedApp, setShowParagonConnectedApp] =
    useState<boolean>(false);

  const paragonData = useParagonStore((state) => state);

  const paragonInstance = useParagonStore((state) => state?.paragon);

  const deleteParagonIntegration = useMutation({
    mutationFn: disableParagonIntegration,
  });

  useEffect(() => {
    const handler = (
      event: IntegrationUninstallEvent,
      user: AuthenticatedConnectUser
    ) => {
      deleteParagonIntegration.mutate(
        // Disabling this because line 54 checks for existance of paragonData.user?.userId
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        { event, user, teamid: paragonData?.paragonUser?.userId! },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["integrations"] });
          },
        }
      );
    };

    if (paragonInstance && paragonData?.paragonUser?.userId) {
      paragonInstance.subscribe("onIntegrationUninstall", handler);
    }

    return () => {
      paragonInstance?.unsubscribe("onIntegrationUninstall", handler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paragonInstance, paragonData?.paragonUser]);

  const flags = useFlags();

  useEffect(() => {
    const id = setTimeout(() => {
      setIsLocalLoading(false);
    }, 2 * 1000);

    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    if (paragonUser) {
      const isEnabled = Object.values(paragonUser?.integrations || {}).some(
        (item: any) => item?.enabled
      );
      isEnabled
        ? setShowParagonConnectedApp(true)
        : setShowParagonConnectedApp(false);
    }
  }, [paragonUser]);

  usePrefetchZendeskApis();

  const paragonIntegrations =
    paragon &&
    paragon.getIntegrationMetadata &&
    paragon?.getIntegrationMetadata();

  const loader = useIntegrationStorePersist((state) => state.loader);
  const integrations = useIntegrationStorePersist(
    (state) => state.integrations
  );
  const showConnectedApps = useIntegrationStorePersist(
    (state) => state.showConnectedApps
  );
  if (loader || isLocalLoading) return <ThenaLoader />;

  if (showConnectedApps || showParagonConnectedApp) {
    return (
      <div className="p-6 h-[calc(100vh-52px)] overflow-auto">
        <div className="items-center justify-between">
          <h4 className="text-lg 3xl:text-[length:var(--lg)] font-semibold">
            Connected apps
          </h4>
          <p className="font-medium text-color-icon-muted text-sm 3xl:text-[length:var(--sm)]">
            Your connected apps
          </p>
        </div>
        <div className="mt-3 py-2 flex items-stretch justify-start flex-wrap gap-5">
          {integrations
            ?.filter(
              (integration) =>
                integration?.hasConnected &&
                !itemToExclude.includes(integration.title)
            )
            .map((integration) => (
              <IntegrationCard
                key={integration.id}
                {...integration}
                inConnectedApps
                setActiveIntegrationId={setActiveIntegrationId}
              />
            ))}
          {paragonIntegrations
            ?.filter((integration) => !itemToExclude.includes(integration.name))
            .map((integration: any) => {
              const integrationEnabled =
                paragonUser?.integrations?.[integration.type]?.enabled;
              return (
                integrationEnabled && (
                  <ParagonIntegrationCard
                    integration={integration}
                    integrationEnabled={integrationEnabled}
                    loading={false}
                  />
                )
              );
            })}
        </div>

        <Separator className="mt-6" />
        <div id={showConnectedApps ? "1" : "0"}></div>
        <h4 className="mt-6 font-semibold text-lg">Add more apps</h4>
        <p className="font-medium text-color-icon-muted text-sm 3xl:text-[length:var(--sm)]">
          Select to connect more apps
        </p>
        <div className="mt-3 py-2 flex items-stretch justify-start flex-wrap gap-5">
          {paragonIntegrations
            ?.filter((integration) => !itemToExclude.includes(integration.name))
            .map((integration: any) => {
              const integrationEnabled =
                paragonUser?.integrations?.[integration.type]?.enabled;
              return (
                !integrationEnabled &&
                (integration.type != "hubspot" ||
                  (integration.type == "hubspot" && flags.hubSpotCrm)) && (
                  <ParagonIntegrationCard
                    integration={integration}
                    integrationEnabled={integrationEnabled}
                    loading={false}
                  />
                )
              );
            })}
          {integrations
            ?.filter(
              (integration) =>
                !itemToExclude.includes(integration?.title) &&
                !integration?.hasConnected
            )
            .map((integration) => (
              <IntegrationCard
                key={integration.id}
                {...integration}
                setActiveIntegrationId={setActiveIntegrationId}
              />
            ))}
        </div>
        {activeIntegrationId !== "" && (
          <IntegrationModal
            activeIntegrationId={activeIntegrationId}
            setActiveIntegrationId={setActiveIntegrationId}
            integrations={integrations}
          />
        )}
      </div>
    );
  }

  // All Integrations
  return (
    <ConnectedAppProvider>
      <div className="p-6 h-[calc(100vh-52px)] overflow-auto">
        <div className="">
          <h4 className="font-bold text-lg">Connect apps</h4>
        </div>
        <div id={showConnectedApps ? "1" : "0"}></div>
        <p className="text-sm 3xl:text-[length:var(--sm)]">
          Choose from a list of apps to integrate with Thena
        </p>
        <Separator className="py-0 my-4" />
        <div className="py-2 flex items-stretch justify-start flex-wrap gap-5">
          {paragonIntegrations
            ?.filter((integration) => !itemToExclude.includes(integration.name))
            .map((integration: any) => {
              const integrationEnabled =
                paragonUser?.integrations?.[integration.type]?.enabled;
              return (
                !integrationEnabled &&
                (integration.type != "hubspot" ||
                  (integration.type == "hubspot" && flags.hubSpotCrm)) && (
                  <ParagonIntegrationCard
                    integration={integration}
                    integrationEnabled={integrationEnabled}
                    loading={false}
                  />
                )
              );
            })}
          {integrations
            ?.filter(
              (integration) => !itemToExclude.includes(integration.title)
            )
            .map((integration) => (
              <IntegrationCard
                key={integration.id}
                {...integration}
                setActiveIntegrationId={setActiveIntegrationId}
              />
            ))}
        </div>
      </div>
      {activeIntegrationId !== "" && (
        <IntegrationModal
          activeIntegrationId={activeIntegrationId}
          setActiveIntegrationId={setActiveIntegrationId}
          integrations={integrations}
        />
      )}
    </ConnectedAppProvider>
  );
};
