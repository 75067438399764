import { Node, mergeAttributes} from "@tiptap/core";
const CustomAINode = Node.create({

    name: 'custom-ai-node',
    draggable:false,
    content: 'text*', 
    group: 'block',
    marks: "", // This node will behave like a block-level element
    selectable: true, // Allows selection of the node
    parseDOM: [{ // Defines how to parse the custom node from the DOM, 
    }],
    parseHTML() {
        return [
          {
            tag: "empty-ui",
          },
        ];
    },
    addAttributes() {
        return {
          className: {
            default: "text-gray-500 animate-typing overflow-hidden whitespace-nowrap",
          },
          content:{
            default: "AI is writing..."
          }
        };
    },
    renderHTML({ HTMLAttributes }) {
        return ["empty-ui", mergeAttributes(HTMLAttributes), [
            "p", {
                class: HTMLAttributes.className
            }, HTMLAttributes.content
        ]];
    }

})
export { CustomAINode }