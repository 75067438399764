export const SlackCodeStyle = () => {
  const codeBlockStyle = `
    code {
    word-wrap: break-word;
    font-family: "JetBrainsMono", monospace;
    font-size: 12px;
    font-variant-ligatures: none;
    line-height: 1.50001;
    -webkit-tab-size: 4;
    -moz-tab-size: 4;
    tab-size: 4;
    white-space: pre;
    white-space: pre-wrap;
    word-break: normal;
    border: 1px solid var(--slack-code-border);
    border-radius: 3px;
    color: var(--slack-code-color);
    padding: 2px 3px 1px;
    background: var(--slack-code-block-bg);
    }
    `;

  const preCodeBlockStyle = `
    pre {
    font-family: "JetBrainsMono", monospace;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid var(--slack-code-border);
    background: var(--slack-code-block-bg);

    code {
        color: var(--color-text);
        padding: 0;
        background: none;
        font-size: 0.8rem;
        border: none;
    }
    }
    `;
  const blockquoteStyle = `
    blockquote {
      padding-left: 1rem;
      position: relative;
    }
    blockquote::before {
      content: "";
      border-radius: 8px;
      width: 4px;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background: var(--slack-blockquote-left-border);
    }
    `;
  return (
    <>
      <style>{codeBlockStyle}</style>
      <style>{preCodeBlockStyle}</style>
      <style>{blockquoteStyle}</style>
    </>
  );
};
