import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useMemo } from "react";

const getTiptapEmojis = () => {
  return axios.get("https://dwua6jkk624sw.cloudfront.net/tiptap_emojis.json");
};

export const useTiptapEmojis = () => {
  const { data: emojis } = useQuery({
    queryKey: ["fetch-tiptap-emojis"],
    queryFn: getTiptapEmojis,
    staleTime: Infinity,
  });

  /**
   * Shape of emoji (as expected by Tiptap)
   * https://tiptap.dev/docs/editor/api/nodes/emoji#:~:text=It%E2%80%99s%20super%20easy%20to%20add%20your%20own%20custom%20emojis.
   */
  const finalEmojis = useMemo(() => {
    if (!Array.isArray(emojis?.data)) {
      return [];
    }

    return emojis.data;
  }, [emojis?.data]);

  return finalEmojis;
};
