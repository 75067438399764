import { SubStatusType } from "@/types/requests";
import { create } from "zustand";

type RelationCacheType = {
  channel_id: string;
  channel_is_private: boolean;
  channel_name: string;
  customer_name: string;
  customer_team_icon: string;
  crmValuesList: any;
  connectedCRM: string;
};

type UserCacheType = {
  name?: string;
  slack_profile_image_24?: string;
};

interface StateType {
  userCache: Record<string, UserCacheType>;
  customerCache: Record<string, UserCacheType>;
  relationCache: Record<string, RelationCacheType>;
  requestIdsToPrefetchSuccessList: string[];
  subStatusCache: SubStatusType[];
  externalListForTable: any[];
  resetCache: () => void;
  setUserCache: (data: Record<string, UserCacheType>) => void;
  setCustomerCache: (data: Record<string, UserCacheType>) => void;
  setRelationCache: (data: Record<string, RelationCacheType>) => void;
  addToSetOfRequestIdsToPrefetchSuccessList: (data: string[]) => void;
  setSubStatusCache: (data: Record<string, string>) => void;
  setExternalListForTable: (data: any) => void;
}

export const useKanbanCacheStore = create<StateType>((set) => ({
  userCache: {},
  relationCache: {},
  customerCache: {},
  requestIdsToPrefetchSuccessList: [],
  subStatusCache: [],
  externalListForTable: [],
  resetCache: () =>
    set({ relationCache: {}, userCache: {}, customerCache: {} }),
  setUserCache: (data) => set({ userCache: data }),
  setCustomerCache: (data) => set({ customerCache: data }),
  setRelationCache: (data) => set({ relationCache: data }),
  addToSetOfRequestIdsToPrefetchSuccessList: (data) =>
    set((state) => ({
      requestIdsToPrefetchSuccessList: Array.from(
        new Set([...state.requestIdsToPrefetchSuccessList, ...data])
      ),
    })),
  setSubStatusCache: (data: any) => set({ subStatusCache: data }),
  setExternalListForTable: (data) => set({ externalListForTable: data }),
}));
