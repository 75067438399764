import { getThenaDB } from "@/db";
import { useKanbanStore } from "@/store/kanbanStore";
import { RequestType } from "@/types/requests";
import { emptyObject } from "@/utils/empty";
import { useLiveQuery } from "dexie-react-hooks";
import { useSearchParams } from "react-router-dom";

const useQueryCardInfo = () => {
  const requestId = useSearchParams()[0].get("requestId");
  const currentRequestId = useKanbanStore((state) => state.currentRequestId);

  const cardInfo = useKanbanStore(
    (state) => state.requestMap[currentRequestId]
  );

  const isRequestPresent = !!cardInfo?._id;

  const cardFromDb = useLiveQuery(
    () => {
      if (isRequestPresent || !requestId) {
        return Promise.resolve(emptyObject);
      }
      return getThenaDB().requests.get({ request_id: Number(requestId) || 0 });
    },
    [requestId, isRequestPresent],
    emptyObject
  );

  return (isRequestPresent ? cardInfo : cardFromDb) as unknown as RequestType;
};

export default useQueryCardInfo;
