import { fetchSettingUpStatus } from "@/api/misc";
import { Card } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import useProgressBar from "@/hooks/useProgressBar";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useNavigate } from "react-router";

export const SettingUp = () => {
  const navigate = useNavigate();

  const apiSelectFn = useCallback(
    (data) => {
      if (data && data.onboarded) {
        navigate("/requests?from=onboarding");
        setTimeout(() => {
          window.location.reload();
        }, 0);
      }
      return data;
    },
    [navigate]
  );

  const { data } = useQuery({
    queryKey: ["setting-up-status"],
    queryFn: fetchSettingUpStatus,
    refetchInterval: 3 * 1000,
    select: apiSelectFn,
  });

  const hideSpinner = data?.onboarded;
  const progress = useProgressBar(hideSpinner);

  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <Card className="w-[450px]">
        <div className="h-[180px] flex flex-col justify-center">
          <h1 className="flex items-center justify-center">
            Syncing users and channels
          </h1>
          <div className="h-[20px] flex items-center justify-center mt-2">
            <Progress value={progress} className="w-[300px] h-[6px]" />
          </div>
        </div>
      </Card>
    </div>
  );
};
