import { Slack } from "lucide-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import GoogleIcon from "@/components/common/GoogleIcon";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { CUSTOMER_LOGIN_URL } from "@/constants";
import Logo from "@/assets/logos/thena_logo.svg";
import { Separator } from "@/components/ui/separator";

const LOGIN_TYPES = {
  GOOGLE: "google",
  SLACK: "slack",
  EMAIL: "email",
};

const LoginForm = () => {
  const [loading, setLoading] = useState({
    email: false,
    google: false,
    slack: false,
  });

  const navigate = useNavigate();

  const isAnyLoading = Object.values(loading).some((val) => val);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const email = (e.currentTarget.elements[0] as HTMLInputElement).value;
    setLoading((prev) => {
      return { ...prev, email: true };
    });
    window.open(
      `${CUSTOMER_LOGIN_URL}?type=${LOGIN_TYPES.EMAIL}&email=${encodeURIComponent(email)}`,
      "_self"
    );
  };

  const onGoogleClick = () => {
    setLoading((prev) => {
      return { ...prev, google: true };
    });
    window.open(`${CUSTOMER_LOGIN_URL}?type=${LOGIN_TYPES.GOOGLE}`, "_self");
  };

  const onSlackClick = () => {
    setLoading((prev) => {
      return { ...prev, slack: true };
    });
    window.open(`${CUSTOMER_LOGIN_URL}?type=${LOGIN_TYPES.SLACK}`, "_self");
  };

  return (
    <div className="container relative h-full flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
        <div
          className="absolute inset-0"
          style={{
            background:
              "radial-gradient(159.92% 112.14% at 50.75% 0%, #1C003F 0%, #16042E 100%)",
          }}
        />
        <div className="relative z-20 flex items-center text-lg font-medium">
          <img
            src={Logo}
            alt="assistant"
            style={{ height: "40px", marginRight: "12px" }}
          />
        </div>
        <div className="relative z-20 mt-auto">
          <Link target="_blank" to="https://www.thena.ai/" className="">
            Visit website
          </Link>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-2">
          <div className="mx-auto flex flex-col space-y-2 text-center">
            <h1 className=" text-[30px] font-semibold ">Welcome to Thena</h1>
            <p className="text-sm text-muted-foreground text-[18px]">
              The best way to work with your customers
            </p>
          </div>
          <div className="flex justify-center flex-col items-center">
            <div className="mt-3">
              <div>
                <form onSubmit={onSubmit} className="flex flex-col gap-4">
                  <label htmlFor="email">
                    <Input
                      placeholder="Email"
                      required
                      id="email"
                      type="email"
                      className="mt-2"
                      disabled={isAnyLoading}
                    />
                  </label>
                  <Button
                    type="submit"
                    className="w-[300px]"
                    loading={loading.email}
                    disabled={isAnyLoading}
                  >
                    Continue
                  </Button>
                </form>
                <div className="my-4 text-center">Or</div>
                <div className="flex flex-col gap-4">
                  <Button
                    loading={loading.google}
                    onClick={onGoogleClick}
                    className="w-[300px]"
                    disabled={isAnyLoading}
                  >
                    <GoogleIcon className="mr-2" />
                    Continue with Google
                  </Button>
                  <Button
                    className="w-[300px]"
                    onClick={onSlackClick}
                    loading={loading.slack}
                    disabled={isAnyLoading}
                  >
                    <Slack size={16} className="mr-2" /> Continue with Slack
                  </Button>
                </div>
                <div className="flex items-center justify-center mt-6 gap-2">
                  <span className="text-base text-color-icon-muted">
                    Not a customer?
                  </span>
                  <Button
                    variant="link"
                    className="text-base font-medium p-0"
                    onClick={() => navigate("/")}
                  >
                    Sign in as a vendor
                  </Button>{" "}
                </div>
              </div>
            </div>
            <Separator className="mt-6 w-[400px]" />
          </div>
          <div className="px-8 text-center text-sm text-muted-foreground">
            <div className="flex gap-2 justify-center items-center">
              <div className="">
                <Link
                  target="_blank"
                  to="https://www.thena.ai/policy/privacy"
                  className="  hover:text-primary"
                >
                  <span>Privacy</span>
                </Link>{" "}
              </div>
              <Separator className="w-6 rotate-90" />
              <div className="">
                <Link
                  target="_blank"
                  to="https://www.thena.ai/policy/product"
                  className="  hover:text-primary"
                >
                  Terms & conditions
                </Link>
              </div>
            </div>
          </div>
          <div
            className="relative flex justify-center items-center gap-2"
            style={{
              top: "150px",
            }}
          >
            <div className="text-muted-foreground text-[16px]">
              Don't have an account?
            </div>
            <div>
              <Link
                target="_blank"
                to="https://www.thena.ai/request-a-demo-v2"
                className=""
              >
                Request a demo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
