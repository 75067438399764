import { Button } from "@/components/ui/button";
import React from "react";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <div className="flex items-center justify-center h-screen text-color-text">
      <div className="text-center flex flex-col gap-3">
        <h1 className="text-6xl font-bold">404</h1>
        <p className="text-2xl text-muted-text">
          Oops! The page you're looking for doesn't exist.
        </p>
        <Link to="/">
          <Button>Go Home</Button>
        </Link>
      </div>
    </div>
  );
};

export default Page404;
