export const API_ROOT = process.env.REACT_APP_API_ROOT || "";
export const LOGIN_URL = `${API_ROOT}/login`;
export const LOGOUT_URL = `${API_ROOT}/logout`;
export const SOCKET_URI = process.env.REACT_APP_SOCKET_URI || "";
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || "";
export const ADD_TO_SLACK_URL = process.env.REACT_APP_ADD_TO_SLACK_URL || "";
export const NODE_ENV = process.env.NODE_ENV || "";
export const ANALYTICS_PERIOD_OPTIONS =
  process.env.REACT_APP_ANALYTICS_PERIOD_OPTIONS || "";
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || "";
export const HUBSPOT_APP_ID = process.env.REACT_APP_HUBSPOT_APP_ID || "";
export const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY || "";
export const CALENDLY_LINK = process.env.REACT_APP_CALENDLY_LINK || "";
export const LOG_ROCKET_APP_ID = process.env.REACT_APP_LOG_ROCKET_APP_ID || "";
export const LC_CLIENT_KEY = process.env.REACT_APP_LC_CLIENT_KEY || "";
export const PARAGON_PROJECT_ID =
  process.env.REACT_APP_PARAGON_PROJECT_ID || "";
export const CLOUDINARY_CLOUDNAME =
  process.env.REACT_APP_CLOUDINARY_CLOUDNAME || "";
export const KNOCK_PUBLIC_API_KEY =
  process.env.REACT_APP_KNOCK_PUBLIC_API_KEY || "";
export const KNOCK_SECRET_KEY = process.env.REACT_APP_KNOCK_SECRET_KEY || "";
export const KNOCK_FEED_CHANNEL_ID =
  process.env.REACT_APP_KNOCK_FEED_CHANNEL_ID || "";
export const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY || "";
export const PUSHER_APP_CLUSTER =
  process.env.REACT_APP_PUSHER_APP_CLUSTER || "";
export const COMMANDBAR_ORG_ID = "f5bbf218";
export const IS_SENTRY_ENABLED = process.env.REACT_APP_IS_SENTRY_ENABLED || "";
