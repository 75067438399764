import { Separator } from "@radix-ui/react-separator";
import { FreshdeskSetUp } from "./FreshdeskSetup";
type Props = {
  requestType: {
    label: string;
    value: string;
    disabled: boolean;
  }[];
};
export const FreshdeskIntegration = ({ requestType }: Props) => {
  return (
    <main className="w-full">
      <Separator className="my-5 py-0" />
      <FreshdeskSetUp requestType={requestType} />
    </main>
  );
};
