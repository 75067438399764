// TODO: Move all fallbacks added in the useViewsInit for the unique values to the backend
export const views = {
  REQUEST_PAGE_DEFAULT: "KANBAN_DISPLAY_OPTIONS", // unique
  INTERNAL_HELPDESK_DEFAULT: "HELPDESK_DISPLAY_OPTIONS", // unique
  EXTERNAL_LINKS_DEFAULT: "EXTERNAL_LINKS_DEFAULT", // unique - not yet implemented
  TICKETS_DEFAULT: "TICKETS_DEFAULT", // unique - not yet implemented
  INTERNAL_HELPDESK_SAVED_VIEW: "INTERNAL_REQUESTS",
  REQUEST_PAGE_SAVED_VIEW: "REQUESTS",
  EXTERNAL_LINKS_SAVED_VIEW: "EXTERNAL_LINKS_SAVED_VIEW",
  TICKETS_SAVED_VIEW: "TICKETS_SAVED_VIEW",
  BROADCAST_LIST: "BROADCAST_LIST",
  BROADCAST_FILTERS: "BROADCAST_FILTERS",
  KANBAN_COLUMN_ORDER: "KANBAN_COLUMN_ORDER", // unique
} as const;
