import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const AuthRedirectPage = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const success = searchParams.get("success");
    const message = searchParams.get("message");

    window.opener?.postMessage(
      { success: success, message: message },
      window.location.origin
    );
    window.close();
  }, [searchParams]);

  return <div>This is going to be closed</div>;
};

export default AuthRedirectPage;
