import { toInteger } from "lodash";
import moment, { Moment } from "moment";
import { pluralize } from "./textUtils";

export const formatSecondsToHourMins = (
  seconds = 0,
  showSeconds = false
): string => {
  const duration = moment.duration(seconds * 1000);

  const years = duration.years();
  const yearsStr = years ? `${years} ${pluralize("yr", years)}` : "";
  const months = duration.months();
  const monthsStr = months ? `${months} ${pluralize("mo", months)}` : "";
  const days = duration.days();
  const daysStr = days ? `${days} ${pluralize("day", days)}` : "";
  const hours = duration.hours();
  const hoursStr = hours ? `${hours} ${pluralize("hr", hours)}` : "";
  const minutes = duration.minutes();
  const minsStr = minutes ? `${minutes} ${pluralize("min", minutes)}` : "";
  const secs = duration.seconds();
  const secondsStr = secs ? `${secs} ${pluralize("sec", secs)}` : "";

  if (years) {
    return `${yearsStr} ${monthsStr}`;
  }
  if (months) {
    return `${monthsStr} ${daysStr}`;
  }
  if (days) {
    return `${daysStr} ${hoursStr}`;
  }
  if (hours) {
    return `${hoursStr} ${minsStr}`;
  }
  if (minutes) {
    if (showSeconds) {
      return `${minsStr} ${secondsStr}`;
    }
    return minsStr;
  }
  if (showSeconds && secs) {
    return secondsStr;
  }
  return "NA";
};

export type DateRangeType = "7_DAYS" | "14_DAYS" | "30_DAYS" | "ALL_TIME";

export const getDateRangeByType = (rangeType: DateRangeType) => {
  switch (rangeType) {
    case "7_DAYS":
      return [moment().subtract(7, "d"), moment()];

    case "14_DAYS":
      return [moment().subtract(14, "d"), moment()];

    case "30_DAYS":
      return [moment().subtract(30, "d"), moment()];

    default:
      return null as unknown as [Moment, Moment];
  }
};

export const getSortedDates = (dateArray: string[]) => {
  return dateArray.sort((a, b) => {
    const momentA = moment(a, "YYYY-MM-DD");
    const momentB = moment(b, "YYYY-MM-DD");
    return momentA.diff(momentB);
  });
};

export const getTimestampsForInterval = (
  value: number,
  type: "weeks" | "months"
) => {
  const currentDate = moment();
  let pastDate = moment();

  if (type === "weeks") {
    pastDate = moment().subtract(value * 7, "days");
  }
  if (type === "months") {
    pastDate = moment().subtract(value * 28, "days");
  }

  let start = pastDate.clone().add(1, "days").startOf("day");
  let end = currentDate.endOf("day");

  if (start.isDST()) {
    start = start.add(1, "h");
  }
  if (end.isDST()) {
    end = end.subtract(1, "h");
  }

  return {
    startTime: start.valueOf(),
    endTime: end.valueOf(),
  };
};

export const computeTimestamp = (intervalOption: string) => {
  const intervalType = intervalOption.includes("W") ? "W" : "M";
  const [value] = intervalOption.split(intervalType);
  const { startTime, endTime } = getTimestampsForInterval(
    Number(value),
    intervalType === "W" ? "weeks" : "months"
  );

  return { startTime, endTime };
};

export const getCurrentTimeStamp = () => {
  return moment().valueOf();
};

export const isDateOlderThan24Hours = (timeStamp: number) => {
  const date = moment(timeStamp);
  return date.isBefore(moment().subtract(24, "hours"));
};

export const getMomentForSecondsTs = (ts: any) => {
  // seconds * 1000 = milliseconds
  return moment(toInteger(ts) * 1000);
};
import { getTime, endOfDay, subDays, startOfDay, subHours } from "date-fns";

export const getTodayLastEpoch = () => {
  const now = endOfDay(new Date());
  return getTime(now);
};

export const getEpochByDifference = (diff: "LAST_30_DAYS") => {
  const today = endOfDay(new Date());

  if (diff === "LAST_30_DAYS") {
    const dateBefore30Days = subDays(today, 30);
    return getTime(startOfDay(dateBefore30Days));
  }

  return "";
};

export const getDateObjectsFromRange = (range: string | null) => {
  switch (range) {
    case "24 hours":
      return {
        from: subHours(new Date(), 24),
        to: new Date(),
      };

    case "7 days":
      return {
        from: subDays(new Date(), 7),
        to: new Date(),
      };

    case "14 days":
      return {
        from: subDays(new Date(), 14),
        to: new Date(),
      };

    case "30 days":
      return {
        from: subDays(new Date(), 30),
        to: new Date(),
      };

    case "All time":
      return {
        from: undefined,
        to: new Date(),
      };

    default:
      return {
        from: undefined,
        to: undefined,
      };
  }
};

export const getCurrentEpoch = () => {
  return Date.now().toString();
};
export const createdAtFormattedInHoursAgo = (createdAt: string) => {
  const momentObj = moment(createdAt);

  const duration = moment.duration(moment().diff(momentObj));
  const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes());

  if (days > 1) {
    return Math.round(days) + " days ago";
  } else if (days === 1) {
    return Math.round(days) + " day ago";
  } else if (hours > 1) {
    return Math.round(hours) + " hours ago";
  } else if (hours === 1) {
    return Math.round(hours) + " hour ago";
  } else if (minutes > 1) {
    return Math.round(minutes) + " minutes ago";
  } else if (minutes === 1) {
    return Math.round(minutes) + " minute ago";
  } else {
    return "Few seconds ago";
  }
};
