import { fetchBootstrapData } from "@/api/bootstrap";
import { addCustomIndexes, getThenaDB } from "@/db";
import { CustomFieldType } from "@/types/requests";
import { get, isEqual } from "lodash";
import moment from "moment";
import { noOp } from "./empty";
import { buildCustomFilters } from "./filters";

const FIXED_DECIMAL_LENGTH = 11;
const BASE_VALUE_ACHIEVED = 10;
const BASE_VALUE_DELAYED = 20;
const BASE_VALUE_BREACHED = 40;
const BASE_VALUE_REMAINING = 30;
const SORT_PREFERENCE_POSITIVE = 1;
const SORT_PREFERENCE_NEGATIVE = -1;

const getAbsoluteTimeDifference = (t1, t2) => Math.abs(t1.diff(t2, "seconds"));

const getFixedLengthDecimalPart = (num) =>
  parseFloat(`0.${num.toString().padStart(FIXED_DECIMAL_LENGTH, "0")}`);

function calculateScore(baseValue, timeDifference, sortPreference) {
  const fixedLengthDecimalPart = getFixedLengthDecimalPart(timeDifference);
  const score = parseFloat(
    String(baseValue + fixedLengthDecimalPart * sortPreference)
  );
  return score;
}

function getFirstResponseSortingScore(item) {
  const checkBreachOnTs =
    item?.sla_metrics?.first_response_sla?.check_breach_status_on_ts ||
    item?.check_first_sla_breach_on_ts;

  if (!checkBreachOnTs) {
    return "NOT_PRESENT";
  }

  const breachAtMoment = moment.unix(checkBreachOnTs);
  const isFirstResponsePresent = !!item?.first_response_time?.ts;

  if (isFirstResponsePresent) {
    const achievedAtMoment = moment.unix(item?.first_response_time?.ts);
    const createdAtMoment = moment(item.created_at);

    if (achievedAtMoment.isSameOrBefore(breachAtMoment)) {
      return calculateScore(
        BASE_VALUE_ACHIEVED,
        getAbsoluteTimeDifference(achievedAtMoment, createdAtMoment),
        SORT_PREFERENCE_POSITIVE
      );
    }
    return calculateScore(
      BASE_VALUE_DELAYED,
      getAbsoluteTimeDifference(achievedAtMoment, breachAtMoment),
      SORT_PREFERENCE_POSITIVE
    );
  }
  const presetTs = moment().unix();
  const isBreached =
    item?.sla_metrics?.first_response_sla?.is_breached ||
    breachAtMoment.unix() < presetTs;

  return calculateScore(
    isBreached ? BASE_VALUE_BREACHED : BASE_VALUE_REMAINING,
    breachAtMoment.valueOf(),
    isBreached ? SORT_PREFERENCE_NEGATIVE : SORT_PREFERENCE_POSITIVE
  );
}

function getFinalResolutionSortingScore(item) {
  const checkBreachOnTs =
    item?.sla_metrics?.resolution_sla?.check_breach_status_on_ts ||
    item?.check_req_closure_breach_on_ts;

  if (!checkBreachOnTs) {
    return "NOT_PRESENT";
  }

  const breachAtMoment = moment.unix(checkBreachOnTs);
  const isFinalResolutionPresent =
    !!item?.closed_on && item?.status === "CLOSED";

  if (isFinalResolutionPresent) {
    const achievedAtMoment = moment(item?.closed_on);
    const createdAtMoment = moment(item.created_at);
    if (achievedAtMoment.isSameOrBefore(breachAtMoment)) {
      return calculateScore(
        BASE_VALUE_ACHIEVED,
        getAbsoluteTimeDifference(achievedAtMoment, createdAtMoment),
        SORT_PREFERENCE_POSITIVE
      );
    }
    return calculateScore(
      BASE_VALUE_DELAYED,
      getAbsoluteTimeDifference(achievedAtMoment, breachAtMoment),
      SORT_PREFERENCE_POSITIVE
    );
  }
  const presetTs = moment().unix();
  const isBreached =
    item?.sla_metrics?.resolution_sla?.is_breached ||
    breachAtMoment.unix() < presetTs;

  return calculateScore(
    isBreached ? BASE_VALUE_BREACHED : BASE_VALUE_REMAINING,
    breachAtMoment.valueOf(),
    isBreached ? SORT_PREFERENCE_NEGATIVE : SORT_PREFERENCE_POSITIVE
  );
}

function getLastCustomerReplyOrder(item) {
  const lastReplyPresent =
    item?.last_reply_by_customer_ts !== undefined &&
    item?.last_reply_by_customer_ts !== null;
  const isLastCustomerReply = hasLastCustomerReply(item);
  if (!lastReplyPresent) {
    return "NOT_PRESENT";
  }
  if (isLastCustomerReply === "no") {
    return "NOT_PRESENT";
  }
  return parseFloat(item?.last_reply_by_customer_ts);
}

function getLastReplyOrder(item) {
  const customerReplyPresent =
    item?.last_reply_by_customer_ts !== undefined &&
    item?.last_reply_by_customer_ts !== null;
  const vendorReplyPresent =
    item?.last_reply_by_vendor_ts !== undefined &&
    item?.last_reply_by_vendor_ts !== null;

  if (!customerReplyPresent && !vendorReplyPresent) {
    return "NOT_PRESENT";
  }

  const customerTimestamp = parseFloat(item?.last_reply_by_customer_ts);
  const vendorTimestamp = parseFloat(item?.last_reply_by_vendor_ts);

  if (!vendorReplyPresent && !isNaN(customerTimestamp)) {
    return customerTimestamp;
  }
  if (!customerReplyPresent && !isNaN(vendorTimestamp)) {
    return vendorTimestamp;
  }
  return Math.max(customerTimestamp, vendorTimestamp);
}

export const processRequestData = (item) => {
  const result = {
    ...item,
    status: item.status === "ASSIGNED" ? "INPROGRESS" : item.status,
    created_at_date: new Date(item.created_at),
    updated_at_date: new Date(item.updated_at),
    urgencyOrder: getUrgencyOrder(item.ai_metadata?.urgency),
    internalHelpDeskIndex: getInternalHelpdeskBoolean(
      item.is_internal_helpdesk
    ),
    firstResponseSlaOrder: getFirstResponseSortingScore(item),
    finalResolutionSlaOrder: getFinalResolutionSortingScore(item),
    assigned_to_user_id: item.assigned_to_user_id || "Unassigned",
    lastCustomerReply: hasLastCustomerReply(item),
    slaBreached: isSLABreached(item),
    lastCustomerReplyOrder: getLastCustomerReplyOrder(item),
    lastReplyOrder: getLastReplyOrder(item),
  };
  const customFields = item.categories || [];
  if (!customFields.length) return result;
  const hashValueMap = customFields.reduce((acc, item) => {
    acc[`custom_${item.hash}`] = item.values.map((item) => item.value);
    return acc;
  }, {});
  return { ...result, ...hashValueMap };
};

const getUrgencyOrder = (urgency) => {
  if (urgency === "High") return 30;
  if (urgency === "Medium") return 20;
  if (urgency === "Low") return 10;
  return 0;
};

const getInternalHelpdeskBoolean = (bool) => {
  if (bool) {
    return "yes";
  }
  return "no";
};
const findLatestTimestamp = (item) => {
  if (
    item?.last_reply_by_customer_ts !== undefined &&
    item?.last_reply_by_customer_ts !== null
  ) {
    if (
      item?.last_reply_by_vendor_ts === undefined ||
      item?.last_reply_by_vendor_ts === null
    ) {
      return Math.max(parseFloat(item?.last_reply_by_customer_ts));
    }
    return Math.max(
      parseFloat(item?.last_reply_by_vendor_ts ?? ""),
      parseFloat(item?.last_reply_by_customer_ts)
    );
  }
};

const hasLastCustomerReply = (item) => {
  const latestTimestamp = findLatestTimestamp(item);
  return latestTimestamp
    ? item.last_reply_by_customer_ts !== undefined &&
      item.last_reply_by_customer_ts !== null &&
      latestTimestamp === parseFloat(item.last_reply_by_customer_ts ?? "")
      ? "yes"
      : "no"
    : "no";
};

const isSLABreached = (item) => {
  const firstResponseSorting = getFirstResponseSortingScore(item);
  const isBreached =
    firstResponseSorting === "NOT_PRESENT"
      ? false
      : firstResponseSorting >= 39 && firstResponseSorting <= 40;
  return isBreached ? "yes" : "no";
};

export const getCustomFieldHashes = (customFields: CustomFieldType[]) => {
  return customFields
    .filter(
      (item) =>
        item?.type === "select" ||
        item.type === "multiselect" ||
        item.type === "radio"
    )
    .map((item) => item.hash);
};

export const getIDBInfo = (dbName) => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = function (event) {
      try {
        // @ts-expect-error - TS doesn't know about target
        const db = event.target.result;
        const dbVersion = db.version;
        const objectStoreNames = Array.from(db.objectStoreNames);
        const schemaOutput = {};

        const transaction = db.transaction(objectStoreNames, "readonly");

        objectStoreNames.forEach((storeName) => {
          const store = transaction.objectStore(storeName);
          const indexDetails = [store.keyPath];
          Array.from(store.indexNames).forEach((indexName) => {
            const index = store.index(indexName);
            const detail = index.multiEntry
              ? `*${index.keyPath}`
              : index.keyPath;
            indexDetails.push(detail);
          });
          // @ts-expect-error - TS doesn't know about storeName
          schemaOutput[storeName] = indexDetails.join(",");
        });

        transaction.oncomplete = function () {
          db.close();
          resolve({ version: dbVersion, schema: schemaOutput });
        };
      } catch (error) {
        reject(error);
      }
    };
  });
};

export const getFeedbackNumber = (feedback) => {
  if (typeof feedback === "number") {
    return feedback;
  }
  if (typeof feedback === "string") {
    return feedback.includes("thumbsup") ? 5 : 1;
  }
  return 1;
};

const getCleanedListForComparison = (list: any[] = []) => {
  return (
    list.filter(
      (item: any) =>
        item?.type === "select" ||
        item?.type === "multiselect" ||
        item?.type === "radio"
    ) || []
  );
};

export const handleCustomFieldsTableUpdate = async () => {
  try {
    const fieldsFromDB = await getThenaDB().customFields.toArray();
    const hashListFromDB = getCleanedListForComparison(fieldsFromDB).map(
      (item) => item.hash
    );
    const res = await fetchBootstrapData({
      type: "partial",
      model: "setup",
    });
    const fieldsFromAPI = get(
      res,
      "results[0].setup.features.requests.categories",
      []
    );

    getThenaDB()
      .customFields.clear()
      .then(() => {
        getThenaDB().customFields.bulkPut(fieldsFromAPI);
      });

    const hashListFromAPI = getCleanedListForComparison(fieldsFromAPI).map(
      (item) => item.hash
    );

    getThenaDB()
      .filters.filter((filter) => {
        if (filter.indexed_key.includes("custom_")) {
          return true;
        }
        return false;
      })
      .delete()
      .then(async () => {
        const customKeys = hashListFromAPI.map((item) => `custom_${item}`);
        const customFieldFilters = await buildCustomFilters(customKeys);
        await getThenaDB().filters.bulkPut(customFieldFilters);
      });

    const isSame = isEqual(hashListFromDB, hashListFromAPI);
    if (!isSame) {
      if (hashListFromAPI.length) {
        await addCustomIndexes(
          "requests",
          hashListFromAPI.map((item) => `*custom_${item}`),
          noOp
        );
      } else {
        console.log("Delete all custom fields from DB");
        await getThenaDB().customFields.clear();
      }
    }
  } catch (error) {
    console.error("Error checking if custom fields table is dirty", error);
  }
};

export const getRelationshipFilterKey = (key: string) => {
  if (key === "Account owners") {
    return "csmUsers";
  }
  if (key === "Solution Engineer") {
    return "solutionEngineers";
  }
  if (key === "Channel") {
    return "channel_id";
  }
  // default for Customers
  return "customer_name";
};

export const includesIgnoreCase = (source, target) =>
  source?.toLowerCase().includes(target.toLowerCase());

export const getDateRangeByPeriod = (period: string) => {
  switch (period) {
    case "past-month":
      return "30 days";

    case "past-week":
      return "7 days";

    case "all":
    default:
      return null;
  }
};
