import { useKanbanCacheStore } from "@/store/kanbanCacheStore";
import { useMemo } from "react";

const getProcessedValue = (value: any) => {
  if (typeof value === "string") {
    return value;
  }
  if (typeof value === "number") {
    return value.toString();
  }
  if (typeof value === "object" && value?.reference) {
    return value.name;
  }
  return "-";
};

export const getFormattedOverflowValue = (value: unknown) => {
  if (Array.isArray(value)) {
    return value.map(getProcessedValue);
  }
  return getProcessedValue(value);
};

export const useCRMInfo = ({ relationship_id, visibleCrmIds }) => {
  const relationship = useKanbanCacheStore(
    (state) => state.relationCache[relationship_id]
  );

  return useMemo(() => {
    const resultList: any = [];
    (visibleCrmIds || []).forEach((key) => {
      const val = relationship?.crmValuesList?.find((item) => item._id === key);
      if (val) {
        resultList.push({
          ...val,
          values: val.values?.length ? val.values : ["-"],
        });
      }
    });
    return {
      crm: relationship?.connectedCRM || "",
      list: resultList,
    };
  }, [relationship?.connectedCRM, relationship?.crmValuesList, visibleCrmIds]);
};
