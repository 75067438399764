import { IntegrationType } from ".";

import { Salesforce, Shortcut, Zendesk } from "@/assets/logos";
export const IntegrationsList: IntegrationType[] = [
  {
    title: "Salesforce Cases",
    id: "salesforce",
    description: "Create Salesforce Cases from Thena",
    icon: Salesforce,
    className: "w-[57px] h-[42px]",
    faq: 133830,
  },
  {
    title: "Zendesk",
    id: "zendesk",
    description: "Create Zendesk tickets from Thena",
    icon: Zendesk,
    className: "w-[51px] h-[42px]",
    faq: 133829,
  },
  {
    title: "Shortcut",
    id: "shortcut",
    description: "Link Shortcut stories with Thena Requests",
    icon: Shortcut,
    className: "w-[42px] h-[42px]",
    beta: false,
    faq: 238862, // Todo: add faq id
  },
];
