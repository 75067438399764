import { subDays } from "date-fns";
import { create } from "zustand";
import { devtools, persist, redux } from "zustand/middleware";

export type StoreType = {
  currentToggle: string;
  filterMap: any;
  dateRange: any;
  selectedFiltersInLane: any;
  oldestDate: string;
  sliceBy: any;
  enableTrendsOnHover: boolean;
};

type ActionType = {
  type:
    | "SET_TOGGLE"
    | "SET_DATE_RANGE"
    | "SET_FILTER_LANE"
    | "SET_FILTER_MAP"
    | "SET_OLD_DATE"
    | "SET_SlICE_BY"
    | "SET_ENABLE_TRENDS_ON_HOVER";
  payload?: Record<string, unknown>;
};

const reducer = (state: StoreType, action: ActionType): StoreType => {
  const { type, payload } = action;

  switch (type) {
    case "SET_TOGGLE": {
      const currentToggle =
        payload?.currentToggle as StoreType["currentToggle"];
      return {
        ...state,
        currentToggle,
      };
    }

    case "SET_DATE_RANGE": {
      const dateRange = payload?.dateRange as StoreType["dateRange"];
      return {
        ...state,
        dateRange,
      };
    }

    case "SET_FILTER_LANE": {
      const selectedFiltersInLane = payload?.selectedFiltersInLane as any;
      return {
        ...state,
        selectedFiltersInLane,
      };
    }

    case "SET_FILTER_MAP": {
      const filterMap = payload?.filterMap as any;
      return {
        ...state,
        filterMap,
      };
    }

    case "SET_OLD_DATE": {
      const oldestDate = payload?.oldestDate as StoreType["oldestDate"];
      return {
        ...state,
        oldestDate,
      };
    }

    case "SET_SlICE_BY": {
      const sliceBy = payload?.sliceBy as StoreType["sliceBy"];
      return {
        ...state,
        sliceBy,
      };
    }
    case "SET_ENABLE_TRENDS_ON_HOVER":
      return {
        ...state,
        enableTrendsOnHover: !state.enableTrendsOnHover,
      };

    default:
      return state;
  }
};

const initalState: StoreType = {
  currentToggle: "30 days",
  filterMap: {},
  dateRange: {
    from: subDays(new Date(), 30),
    to: new Date(),
  },
  selectedFiltersInLane: [],
  oldestDate: "",
  sliceBy: { label: "Assignee", value: "assignee" },
  enableTrendsOnHover: true,
};

const useAnalyticsPersist = create(
  persist(
    devtools(redux(reducer, initalState), {
      name: "useAnalyticsPersist",
    }),
    {
      name: "useAnalyticsPersist",
      version: 0.4,
    }
  )
);

export { useAnalyticsPersist };
