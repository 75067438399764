import { create } from "zustand";
import { devtools, redux } from "zustand/middleware";

const clockInitialState = {
  time: new Date(),
};

const clockReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case "UPDATE_TIME_EVERY_MIN":
      return { ...state, time: payload };
    default:
      return state;
  }
};
const useClockStore = create(
  devtools(redux(clockReducer, clockInitialState), {
    name: "useClockStoreStore",
  })
);

export { useClockStore };
