import { cloneDeep } from "lodash";
import { ReactNode } from "react";
import { create } from "zustand";
import { devtools, redux } from "zustand/middleware";
import {
  ConnectorFieldType,
  FieldType,
  FormType,
  HelpdeskType,
  TagType,
} from "../modules/ZendeskIntegration/types";

type ActionType = {
  type:
    | "SET_IS_LOADING"
    | "SET_MODAL_STATE"
    | "SET_PRIMARY_ACTION"
    | "SET_SECONDARY_ACTION"
    | "SET_ACTION_ERROR"
    | "SET_STEP"
    | "SET_ACTIVE_HELPDESK"
    | "SET_HELPDESK_CONFIG"
    | "SET_FORM_MAPPING"
    | "SET_FIELD_TYPE_MAPPING"
    | "SET_ACTIVE_TAB"
    | "SET_SHOW_FOOTER"
    | "SET_ALL_HELPDESKS"
    | "SET_AUTH_FORM"
    | "SET_ADVANCED_CONFIGURATION"
    | "SET_AUTO_REDIRECT_IN_STEP_2"
    | "RESET";
  payload?: any;
};

export type Forms = Array<{
  title: string;
  availableValues?: OptionType;
  selectedValues: OptionType;
}>;

export type OptionType = Array<{
  label: string | ReactNode;
  value: string;
}>;

type StateType = {
  isLoading: boolean;
  modalState: {
    title: string;
    isOpen: boolean;
    type: "ALL_INTEGRATIONS" | "CREATE_INTEGRATION" | "EDIT_INTEGRATION" | null;
  };
  primaryAction: {
    text: string;
    show: boolean;
    disabled: boolean;
    loading: boolean;
  };
  secondaryAction: {
    text: string;
    show: boolean;
    disabled: boolean;
    loading: boolean;
  };
  actionError: string;
  step: number;
  authForm: {
    username: {
      value: string;
      error: string;
    };
    token: {
      value: string;
      error: string;
    };
    baseURL: {
      value: string;
      error: string;
    };
  };
  activeHelpdesk: HelpdeskType | null;
  helpdeskConfig: {
    formMapping: {
      global: string[];
      forms: FormType[];
    };
    fieldTypeMapping: FieldType[];
    tagsData: TagType[];
  };
  activeTab: "SET_UP" | "FORMS_AND_FIELDS" | "ADVANCED_CONFIGURATION";
  showFooter: boolean;
  allHelpdesks: HelpdeskType[];
  advancedConfiguration: ConnectorFieldType[];
  autoRedirectInStep2: boolean;
};

const initalState: StateType = {
  isLoading: false,
  modalState: {
    title: "",
    type: null,
    isOpen: false,
  },
  primaryAction: {
    text: "Authenticate",
    show: true,
    disabled: true,
    loading: false,
  },
  secondaryAction: {
    text: "Cancel",
    show: true,
    disabled: false,
    loading: false,
  },
  actionError: "",
  step: 1,
  authForm: {
    username: {
      value: "",
      error: "",
    },
    token: {
      value: "",
      error: "",
    },
    baseURL: {
      value: "",
      error: "",
    },
  },
  activeHelpdesk: null,
  helpdeskConfig: {
    formMapping: {
      global: [],
      forms: [],
    },
    fieldTypeMapping: [],
    tagsData: [],
  },
  activeTab: "SET_UP",
  showFooter: true,
  allHelpdesks: [],
  advancedConfiguration: [],
  autoRedirectInStep2: false,
};

const reducer = (state: StateType, action: ActionType): StateType => {
  const { type, payload } = action;

  switch (type) {
    case "SET_IS_LOADING":
      return {
        ...state,
        isLoading: payload.isLoading,
      };

    case "SET_MODAL_STATE":
      return {
        ...state,
        modalState: { ...payload.modalState },
      };

    case "SET_PRIMARY_ACTION":
      return {
        ...state,
        primaryAction: { ...state.primaryAction, ...payload.primaryAction },
      };

    case "SET_SECONDARY_ACTION":
      return {
        ...state,
        secondaryAction: {
          ...state.secondaryAction,
          ...payload.secondaryAction,
        },
      };

    case "SET_ACTION_ERROR":
      return {
        ...state,
        actionError: payload.actionError,
      };

    case "SET_STEP":
      return {
        ...state,
        step: payload.step,
      };

    case "SET_AUTH_FORM":
      return {
        ...state,
        authForm: { ...state.authForm, ...payload.authForm },
      };

    case "SET_ACTIVE_HELPDESK":
      return {
        ...state,
        activeHelpdesk: payload.activeHelpdesk,
      };

    case "SET_HELPDESK_CONFIG":
      return {
        ...state,
        helpdeskConfig: { ...payload.helpdeskConfig },
      };

    case "SET_FORM_MAPPING":
      return {
        ...state,
        helpdeskConfig: {
          ...state.helpdeskConfig,
          formMapping: { ...payload.formMapping },
        },
      };

    case "SET_FIELD_TYPE_MAPPING":
      return {
        ...state,
        helpdeskConfig: {
          ...state.helpdeskConfig,
          fieldTypeMapping: [...payload.fieldTypeMapping],
        },
      };

    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: payload.activeTab,
      };

    case "SET_SHOW_FOOTER":
      return {
        ...state,
        showFooter: payload.showFooter,
      };

    case "SET_ALL_HELPDESKS":
      return {
        ...state,
        allHelpdesks: [...payload.allHelpdesks],
      };

    case "SET_ADVANCED_CONFIGURATION":
      return {
        ...state,
        advancedConfiguration: [...payload.advancedConfiguration],
      };

    case "SET_AUTO_REDIRECT_IN_STEP_2":
      return {
        ...state,
        autoRedirectInStep2: payload.autoRedirectInStep2,
      };

    case "RESET":
      return cloneDeep(initalState);

    default:
      return state;
  }
};

const useZendeskStore = create(
  devtools(redux(reducer, initalState), {
    name: "useZendeskStore",
  })
);

export { useZendeskStore };
