import { UserType } from "@/types/auth";
import { create } from "zustand";
import { devtools, persist, redux } from "zustand/middleware";

export type StoreType = {
  user: UserType;
  isUserAuthorisedForReplyAs: boolean;
};

type ActionType = {
  type: "INIT" | "RESET" | "SET_USER_REPLY_AS_AUTH";
  payload?: Record<string, unknown>;
};

const reducer = (state: StoreType, action: ActionType): StoreType => {
  const { type, payload } = action;

  switch (type) {
    case "INIT": {
      const user = payload?.user as StoreType["user"];
      return {
        ...state,
        user,
      };
    }

    case "RESET": {
      return {
        ...state,
        user: {} as StoreType["user"],
      };
    }
    case "SET_USER_REPLY_AS_AUTH": {
      return {
        ...state,
        isUserAuthorisedForReplyAs:
          payload?.isUserAuthorisedForReplyAs as boolean,
      };
    }

    default:
      return state;
  }
};

const initalState: StoreType = {
  user: {} as StoreType["user"],
  isUserAuthorisedForReplyAs: false,
};

const useAuthStorePersist = create(
  persist(
    devtools(redux(reducer, initalState), {
      name: "useAuthStorePersist",
    }),
    {
      name: "useAuthStorePersist",
      version: 0.1,
    }
  )
);

export { useAuthStorePersist };
