import { EllipsisVertical } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { logoutHelper } from "@/utils/auth";
import { useGlobalStore } from "@/store/globalStore";
import { windowConfirmDialog } from "@/utils/domUtils";

export function ThemePicker() {
  const [open, setOpen] = useState(false);
  const dirtyMap = useGlobalStore((state) => state.formDirtyMap);
  const navigate = useNavigate();
  const onItemClick = async (e: any, itemRoute?: string) => {
    if (itemRoute?.includes("/configuration/settings")) {
      navigate(itemRoute ?? "#");
      setOpen(false);
      useGlobalStore.getState().setFormDirtyMap({});
      return;
    }
    e.preventDefault();
    const isDirty = Object.values(dirtyMap).find((item) => item);
    let flag = true;
    if (isDirty) {
      flag = await windowConfirmDialog(
        "You have made changes, are you sure you want to navigate away without saving?"
      );
    }
    if (flag) {
      navigate(itemRoute ?? "#");
      setOpen(false);
      useGlobalStore.getState().setFormDirtyMap({});
    }
  };

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="p-0 w-7 h-7">
          <EllipsisVertical size={14} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={(e) => onItemClick(e, "/user-preferences")}>
          Preferences
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => logoutHelper(true)}>
          Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
