import styled from "styled-components";

import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";

import { Card } from "@/components/ui/card";

import { paragon } from "@useparagon/connect";
const CRMLabel = styled.span`
  border-radius: 20px;
  background: #e7f0fd;
  padding: 2px 8px;
  color: #3d6eef;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.1px;
`;

const getDescription = (integration: string) => {
  if (integration === "HubSpot") {
    return "O-Auth enabled integration that pulls data from CRM";
  }
  return "Sync Thena Requests with " + integration;
};

const getIntegrationLabel = (integration: string) => {
  if (
    integration === "Linear" ||
    integration === "Jira" ||
    integration === "Thena Webhooks" ||
    integration === "Rootly" ||
    integration === "PagerDuty"
  ) {
    return <>{integration}</>;
  }
  return <>{integration}</>;
};
export const ParagonIntegrationCard = ({
  integration,
  integrationEnabled,
}: {
  integration: any;
  integrationEnabled?: boolean;
  loading: boolean;
}) => {
  return (
    <Card className="hover:border-hover-accent shadow-lg w-[260px] pl-0 pr-0 pb-2 pt-6 relative">
      <div className={`h-auto`}>
        <>
          <div className="flex items-start justify-between">
            <div className="pl-6">
              <img src={integration.icon} width={51} height={42} alt="" />
            </div>
            {integrationEnabled && (
              <div className="bg-[var(--color-bg-success-muted)] text-[var(--color-text-success)] border-[var(--color-border-success)] px-2 mt-2 rounded border  mx-3 mr-6 flex items-center justify-center">
                <div className="text-xs">Connected</div>
              </div>
            )}
          </div>
          <div className="pl-6 pr-5 flex flex-col justify-left items-start gap-2 w-full mt-4">
            <p className="text-sm 3xl:text-[length:var(--sm)] font-bold">
              {getIntegrationLabel(integration.name)}
            </p>
            <p
              style={{ minHeight: "40px" }}
              className="font-normal text-xs 3xl:text-[length:var(--xs)] text-gray-dark"
              title={"Zendesk + Paragon Integration"}
            >
              {getDescription(integration.name)}
            </p>
          </div>
          <div className="flex justify-between">
            {!integrationEnabled && (
              <Button
                key="add-integration"
                variant={"link"}
                className="pl-6 mt-4 hover:text-brand-accent text-[var(--color-text-info)] flex gap-2"
                onClick={() => paragon?.connect(integration?.type, {})}
              >
                Set up <ArrowRight size={14} />
              </Button>
            )}
            {integrationEnabled && (
              <Button
                key="view-integration"
                variant={"link"}
                className="pl-6 flex items-center gap-2 text-[var(--color-text-info)]"
                onClick={() => paragon?.connect(integration?.type, {})}
              >
                Configure <ArrowRight size={14} />
              </Button>
            )}
          </div>
        </>
      </div>
    </Card>
  );
};
