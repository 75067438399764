/* eslint-disable max-lines */
import {
  deleteFileFromS3,
  fetchInternalSlackConversation,
  fetchSlackConversationV2,
  postHTMLComments,
  postHTMLInternalComments,
  uploadFilesToS3,
} from "@/api/kanban";
import { AI_ASSIST_MODAL_DATA, ASSISTANT_TYPE } from "@/constants/aiAssistant";
import { getThenaDB } from "@/db";
import useAuth from "@/hooks/useAuth";
import { useGlobalStore } from "@/store/globalStore";
import { Thread } from "@/types/kanbanTypes";
import { findPrimaryUserImage } from "@/utils/auth";
import { validateFileType } from "@/utils/editor";
import { processRequestData } from "@/utils/requests";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Emoji from "@tiptap-pro/extension-emoji";
import { Extension } from "@tiptap/core";
import { Color } from "@tiptap/extension-color";
import Link from "@tiptap/extension-link";
import ListItem from "@tiptap/extension-list-item";
import Mention from "@tiptap/extension-mention";
import Placeholder from "@tiptap/extension-placeholder";
import TextStyle from "@tiptap/extension-text-style";
import { EditorProvider, useCurrentEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { cloneDeep, get, isEmpty, truncate } from "lodash";
import {
  FileStack,
  Loader2,
  Paperclip,
  SendHorizontal,
  XCircle,
} from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "sonner";
import styled from "styled-components";
import { Button } from "../ui/button";
import { CustomAINode } from "./CustomAINode";
import MacroSuggestions from "./MacrosSuggestion";
import commands from "./commands";
import internalUsersSuggestion from "./internalUsersSuggestion";
import mentionSuggestion from "./mentionSuggestion";
import { BottomMenuBarWrapper, MenuBar, MenuBarItems } from "./menuItems";
import "./styles.css";
import suggestion from "./suggestion";
import { STREAMING_STATES, useStreaming } from "./useStreaming";

import { useTiptapEmojis } from "@/hooks/useTipTapEmojis";
import { AIAssistSettingDialog } from "../GlobalSettings/AiAssistant/utils";

// define your extension array
const Wrapper = styled.div<{ $isInternal?: boolean }>`
  margin-left: 10px;
  width: 580px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 6px;
  border: 1px solid var(--modal-border);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  ${({ $isInternal }) => $isInternal && `width: 350px; margin-left: 2px;`}
`;
const DisableEnter = Extension.create({
  addKeyboardShortcuts() {
    return {
      Escape: ({ editor }) => {
        const isEmojiActive = get(
          editor,
          "state.emojiSuggestion$.active",
          false
        );
        const isMentionActive = get(editor, "state.mention$.active", false);
        // Blur editor only if emoji and mention popup is inactive
        if (!isEmojiActive && !isMentionActive) {
          editor.commands.blur();
        }
        return false;
      },
    };
  },
});

const ShiftEnterCreateExtension = Extension.create({
  addKeyboardShortcuts() {
    return {
      "Shift-Enter": ({ editor }) => {
        editor.commands.enter();
        return true;
      },
      "Mod-Enter": () => {
        return true;
      },
    };
  },
});

const getExtensions = (isInternal: boolean, emojis: any) => {
  const extensions = [
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    StarterKit.configure({
      bulletList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
      orderedList: {
        keepMarks: true,
        keepAttributes: false, // TODO : Making this as `false` becase marks are not preserved when I try to preserve attrs, awaiting a bit of help
      },
    }),
    Placeholder.configure({
      placeholder: isInternal
        ? "Reply to internal comments..."
        : "Reply to the thread...",
    }),
    Link.extend({ exitable: true }).configure({
      openOnClick: false,
      autolink: false,
      protocols: ["https"],
    }),
    commands.configure({
      suggestion: MacroSuggestions,
    }),
    Mention.configure({
      HTMLAttributes: {
        class: "mention",
      },
      renderText({ options, node }) {
        return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
      },
      suggestion: isInternal ? internalUsersSuggestion : mentionSuggestion,
    }),
    Emoji.configure({
      emojis,
      enableEmoticons: false,
      suggestion,
    }),
    DisableEnter,
    ShiftEnterCreateExtension,
    CustomAINode,
  ];
  return extensions;
};

const Tiptap = ({
  setThreadDetails,
  setInternalThreadDetails,
  ts,
  threadTs,
  channelId,
  isInternal = false,
  autoFocus = false,
  item,
  isEmail = false,
  isRequestMerged = false,
  _id,
  isInternalHelpDesk,
}: {
  setThreadDetails?: React.Dispatch<any>;
  setInternalThreadDetails?: React.Dispatch<any>;
  ts: string;
  threadTs: string;
  channelId: string;
  _id: string;
  isInternal?: boolean;
  autoFocus?: boolean;
  item?: Thread;
  isEmail?: boolean;
  isRequestMerged?: boolean;
  isInternalHelpDesk?: boolean;
}) => {
  const [ccEmails, setCcEmails] = useState<string[]>([]);
  const [bccEmails, setBccEmails] = useState<string[]>([]);
  const [toEmails, setToEmails] = useState<string[]>([]);
  const [value, setValue] = useState("");
  const [files, setFiles] = useState<any>([]);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);

  const [onStream, streamingStatus, modal, onCloseModal] = useStreaming(
    (value) => setValue(value),
    _id,
    isInternalHelpDesk
      ? ASSISTANT_TYPE.INTERNAL_HELPDESK
      : ASSISTANT_TYPE.CUSTOMER_SUPPORT
  );
  const [isSlashPresent, setIsSlashPresent] = useState(false);
  const setEnableSlackConversation = useGlobalStore(
    (state) => state.setEnableSlackConversation
  );
  const invalidEmail = isEmail && toEmails.length === 0;
  const setEnableInternalThreadConversation = useGlobalStore(
    (state) => state.setEnableInternalThreadConversation
  );
  const clearEmailFields = () => {
    setToEmails([]);
    setCcEmails([]);
    setBccEmails([]);
  };

  const editorRef = useRef<any>(null);

  const BottomMenuBar = () => {
    const { editor } = useCurrentEditor();
    if (!editor) {
      return null;
    }
    editorRef.current = editor;
    return (
      <BottomMenuBarWrapper
        $isInternal={isInternal}
        className="flex items-center justify-between"
      >
        <MenuBarItems>
          {isRequestMerged ? (
            <Button
              variant="ghost"
              size="icon"
              className="w-9 h-9"
              disabled={true}
            >
              <Paperclip size={16} />
            </Button>
          ) : (
            <div {...getRootProps()} className="dropzone">
              <Button
                variant="ghost"
                size="icon"
                className="w-9 h-9"
                onClick={open}
              >
                <Paperclip size={16} />
              </Button>
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  const { state } = editor.view;
                  const { selection } = state;
                  const { from } = selection;
                  const charBeforeCursor = state.doc.textBetween(
                    from - 1,
                    from,
                    "\n",
                    "\0"
                  );

                  const chain = editor.chain().focus();
                  if (charBeforeCursor === "/") {
                    chain.deleteRange({ from: from - 1, to: from }).run();
                    setIsSlashPresent(false);
                  } else {
                    chain.insertContent(" /").run();
                    setIsSlashPresent(true);
                  }
                }}
                disabled={isRequestMerged}
                className={isSlashPresent ? "is-activ w-9 h-9" : "w-9 h-9"}
              >
                <FileStack size={16} />
              </Button>
            </div>
          )}
        </MenuBarItems>
        <MenuBarItems className="justify-end">
          <Button
            variant="secondary"
            size="icon"
            className="w-8 h-8"
            onClick={() => handleSave(editor)}
            disabled={isSaveButtonDisabled || invalidEmail}
          >
            <SendHorizontal size={16} />
          </Button>
        </MenuBarItems>
      </BottomMenuBarWrapper>
    );
  };
  const user = useAuth();
  const queryClient = useQueryClient();
  const userImage = findPrimaryUserImage(user);
  const tempRequestRef = useRef<any>(null);

  const updateVendorTs = useCallback(async () => {
    if (_id) {
      try {
        const updatedVentorTs = Date.now();
        const request = await getThenaDB().requests.get(_id);
        if (request) {
          const newRequest = cloneDeep(request);
          newRequest.last_reply_by_vendor_ts = updatedVentorTs.toString();
          const processedRequest = processRequestData(newRequest);
          await getThenaDB().requests.put(processedRequest);
          tempRequestRef.current = cloneDeep(request);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [_id]);

  const updateVendorTsOnError = async () => {
    if (_id && tempRequestRef.current?._id === _id) {
      getThenaDB().requests.put(tempRequestRef.current);
    }
  };

  const CommentMutation = useMutation({
    mutationFn: postHTMLComments,
    onError: () => {
      updateVendorTsOnError();
      setThreadDetails &&
        setThreadDetails((prevState: any) =>
          prevState.map((thread: any) => {
            if (thread.ts === ts) {
              return {
                ...thread,
                replies: [...(thread.replies || []).slice(0, -1)],
              };
            }
            return thread;
          })
        );
    },
    onSuccess: async (_response, requestPayload) => {
      if (window.location.search.includes("requestId")) {
        return;
      }
      const { request_mongo_id } = requestPayload;
      const response = await fetchSlackConversationV2(
        { requests_ids: [request_mongo_id] },
        undefined,
        true
      );

      if (Array.isArray(response?.data?.[0]?.conversations)) {
        await getThenaDB().slackConversations.put({
          _id: request_mongo_id,
          data: response.data?.[0]?.conversations,
        });
      }
    },
  });

  const InternalCommentMutation = useMutation({
    mutationFn: postHTMLInternalComments,
    onError: () => {
      setInternalThreadDetails &&
        setInternalThreadDetails((prevData: any) =>
          prevData.filter((data: any) => data.threadTs !== undefined)
        );
    },
    onSuccess: async (_response, requestPayload) => {
      if (window.location.search.includes("requestId")) {
        return;
      }
      const { request_mongo_id } = requestPayload;
      const response = await fetchInternalSlackConversation({
        id: request_mongo_id,
      });

      if (Array.isArray(response?.data)) {
        await getThenaDB().internalThread.put({
          _id: request_mongo_id,
          data: response.data,
        });
      }
    },
  });

  const UploadMutation = useMutation({
    mutationFn: uploadFilesToS3,
    onSuccess: (data: any) => {
      setUploadedFiles([...uploadedFiles, ...data]);
    },
  });

  useEffect(() => {
    if (CommentMutation.isPending) {
      setEnableSlackConversation(false);
      queryClient.cancelQueries({ queryKey: ["fetchSlackConversation"] });
    } else {
      setEnableSlackConversation(true);
    }
  }, [CommentMutation.isPending, queryClient, setEnableSlackConversation]);

  useEffect(() => {
    if (InternalCommentMutation.isPending) {
      setEnableInternalThreadConversation(false);
      queryClient.cancelQueries({ queryKey: ["internal-thread"] });
    } else {
      setEnableInternalThreadConversation(true);
    }
  }, [
    InternalCommentMutation.isPending,
    queryClient,
    setEnableInternalThreadConversation,
  ]);

  const isSaveButtonDisabled =
    ((value === "" || value === "<p></p>") && isEmpty(uploadedFiles)) ||
    UploadMutation.isPending;

  const handleSave = useCallback(
    (editor: any) => {
      const now = Math.floor(Date.now() / 1000);
      console.log("---Logging uploaded files---", uploadedFiles);

      if (isSaveButtonDisabled || invalidEmail) {
        return;
      }
      // cancels slack conversation refetch to prevent any race conditions
      queryClient.cancelQueries({ queryKey: ["fetchSlackConversation"] });
      queryClient.cancelQueries({ queryKey: ["internal-thread"] });
      if (isInternal) {
        setInternalThreadDetails &&
          setInternalThreadDetails((prevData: any) => [
            ...prevData,
            {
              html: isEmpty(uploadedFiles)
                ? editor.getHTML()
                : editor.getHTML() +
                  uploadedFiles.map(
                    (file: any) =>
                      `<p><a href=${file.permalink} target="_blank">${file.name}</a></p>`
                  ),
              ts: now.toString(),
              threadTs: threadTs,
              blocks: [
                {
                  type: "rich_text",
                  block_id: "new_block_id",
                  elements: [
                    {
                      type: "rich_text_section",
                      elements: [
                        {
                          type: "text",
                          html: isEmpty(uploadedFiles)
                            ? editor.getHTML()
                            : editor.getHTML() +
                              uploadedFiles.map(
                                (file: any) =>
                                  `<p><a href=${file.permalink} target="_blank">${file.name}</a></p>`
                              ),
                        },
                      ],
                    },
                  ],
                },
              ],
              user: {
                id: user?.id || "",
                image: userImage,
                real_name: user?.displayName || "",
                display_name: user?.displayName || "",
                team: user?.team_id || "",
                name: user?.name || "",
              },
              file: [],
            },
          ]);
      } else {
        setThreadDetails &&
          setThreadDetails((prevList: any[]) => [
            ...prevList,
            {
              html: isEmpty(uploadedFiles)
                ? editor.getHTML()
                : editor.getHTML() +
                  uploadedFiles.map(
                    (file: any) =>
                      `<p><a href=${file.permalink} target="_blank">${file.name}</a></p>`
                  ),
              ts: now.toString(),
              thread_ts: threadTs ?? "unknown",
              blocks: [
                {
                  type: "rich_text",
                  block_id: "new_block_id",
                  elements: [
                    {
                      type: "rich_text_section",
                      elements: [
                        {
                          type: "text",
                          html: isEmpty(uploadedFiles)
                            ? editor.getHTML()
                            : editor.getHTML() +
                              uploadedFiles.map(
                                (file: any) =>
                                  `<p><a href=${file.permalink} target="_blank">${file.name}</a></p>`
                              ),
                        },
                      ],
                    },
                  ],
                },
              ],
              user_info: {
                id: user?.id || "",
                image: userImage,
                real_name: user?.displayName || "",
                display_name: user?.displayName || "",
                team: user?.team_id || "",
                name: user?.name || "",
              },
              file: [],
              ...(isEmail && {
                email: {
                  to: toEmails,
                  cc: ccEmails,
                  bcc: bccEmails,
                  html: isEmpty(uploadedFiles)
                    ? editor.getHTML()
                    : editor.getHTML() +
                      uploadedFiles.map(
                        (file: any) =>
                          `<p><a href=${file.permalink} target="_blank">${file.name}</a></p>`
                      ),
                },
              }),
            },
          ]);
      }

      const json = editor.getJSON();
      if (!isEmpty(uploadedFiles)) {
        json.content.push({ type: "files", content: uploadedFiles });
      }

      const payload: any = {
        ts: ts,
        message: isEmpty(uploadedFiles)
          ? editor.getHTML()
          : editor.getHTML() +
            uploadedFiles.map(
              (file: any) =>
                `<p><a href=${file.permalink} target="_blank">${file.name}</a></p>`
            ),
        channel_id: channelId,
        request_mongo_id: _id,
        json,
      };

      if (isEmail) {
        payload.emailData = {
          to: cloneDeep(toEmails),
          cc: cloneDeep(ccEmails || []),
          bcc: cloneDeep(bccEmails || []),
        };
      }
      if (isInternal) {
        InternalCommentMutation.mutate(payload);
      } else {
        CommentMutation.mutate(payload);
        updateVendorTs();
      }
      editor.commands.setContent("<p></p>");
      setValue("");
      setFiles([]);
      setUploadedFiles([]);
      clearEmailFields();
    },
    [
      _id,
      CommentMutation,
      InternalCommentMutation,
      bccEmails,
      ccEmails,
      channelId,
      isEmail,
      isInternal,
      isSaveButtonDisabled,
      queryClient,
      setInternalThreadDetails,
      setThreadDetails,
      threadTs,
      toEmails,
      ts,
      updateVendorTs,
      uploadedFiles,
      user?.displayName,
      user?.id,
      user?.name,
      user?.team_id,
      userImage,
      invalidEmail,
    ]
  );
  const { editor } = useCurrentEditor();

  useEffect(() => {
    const handleKeyDown = (event) => {
      const isFromEditor = event.target.className.includes("ProseMirror");
      if (
        isFromEditor &&
        event.key === "Enter" &&
        (event.ctrlKey || event.metaKey)
      ) {
        if (editorRef.current?.isFocused) {
          handleSave(editorRef.current);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleSave]);

  const handleChange = (editor: any) => {
    setValue(editor.getHTML());
  };

  const DeleteMutation = useMutation({
    mutationFn: deleteFileFromS3,
  });

  const onDrop = (acceptedFiles: any) => {
    const validFiles = acceptedFiles.filter((file: any) => {
      const isFileTypeValid = validateFileType(file);
      const isSizeValid = file.size <= 52428800; // 50 MB
      return isFileTypeValid && isSizeValid;
    });
    if (validFiles.length !== acceptedFiles.length) {
      const invalidFiles = acceptedFiles.filter(
        (file: any) => file.size > 52428800
      );
      invalidFiles.forEach((file: any) => {
        toast.error(`File "${file.name}" exceeds the maximum allowed size.`);
      });
    } else {
      setFiles([...files, ...validFiles]);
      const formData = new FormData();
      validFiles.forEach((file: any) => {
        formData.append("files", file);
      });
      UploadMutation.mutate(formData);
    }
  };

  const removeFile = (file: any, index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
    DeleteMutation.mutate(uploadedFiles[index].Key);
    const newUploadedFiles = [...uploadedFiles];
    newUploadedFiles.splice(index, 1);
    setUploadedFiles(newUploadedFiles);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const emojis = useTiptapEmojis();

  const renderEditor = (editor) => {
    if (isInternal || item?.ts === ts) {
      return (
        <>
          <AIAssistSettingDialog
            open={modal.open}
            onOpenChange={onCloseModal}
            title={AI_ASSIST_MODAL_DATA[modal?.type]?.content.title}
            subtitle={AI_ASSIST_MODAL_DATA[modal?.type]?.content.subtitle}
            primaryCTAText={AI_ASSIST_MODAL_DATA[modal?.type]?.primaryCTAText}
            onPrimaryClick={modal.onPrimaryClicked}
          />
          <Wrapper
            className="tiptap dropzone"
            $isInternal={isInternal}
            key={isInternal ? "internal-editor" : "main-editor"}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <EditorProvider
              slotBefore={
                <MenuBar
                  item={item}
                  isEmail={isEmail}
                  autoFocus={autoFocus}
                  ccEmails={ccEmails}
                  setCCEmails={setCcEmails}
                  bccEmails={bccEmails}
                  setBCCEmails={setBccEmails}
                  toEmails={toEmails}
                  setToEmails={setToEmails}
                  isRequestMerged={isRequestMerged}
                  fetchStreamData={onStream}
                  isStreaming={
                    streamingStatus === STREAMING_STATES.LOADING ||
                    streamingStatus === STREAMING_STATES.STREAMING
                  }
                  isInternalHelpdesk={isInternalHelpDesk}
                />
              }
              slotAfter={<BottomMenuBar />}
              extensions={getExtensions(isInternal, emojis)}
              content={value}
              onUpdate={({ editor }) => handleChange(editor)}
              key={
                isInternal
                  ? "internal-editor" + emojis.length
                  : "main-editor" + emojis.length
              }
              editable={!isRequestMerged}
            >
              {null}
              {/* {streamLoading && <AILoader />} */}
            </EditorProvider>
          </Wrapper>
          {!isEmpty(files) && (
            <div className="mt-1 ml-2 mr-3 flex items-center flex-wrap justify-start gap-1 rounded-lg">
              {files.map((file: any, index: number) => (
                <div className="flex items-center gap-[1px] justify-between rounded-[4px] p-1">
                  <div key={index}>
                    {truncate(file?.name, {
                      length: 15,
                    })}
                  </div>
                  <XCircle
                    onClick={() => removeFile(file, index)}
                    size={20}
                    className="cursor-pointer"
                    color="#4236A8"
                  />
                </div>
              ))}
              {UploadMutation.isPending ? (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              ) : null}
            </div>
          )}
        </>
      );
    } else return null;
  };
  return renderEditor(editor);
};
export default Tiptap;
