import { getZustandDBStorage } from "@/store/zustand-idb/db";
import { OnChangeFn, RowSelectionState } from "@tanstack/react-table";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type QueryMap = Record<
  string,
  {
    values: string[];
    indexed_key: string;
  }
>;

interface AudienceStoreState {
  queryMap: QueryMap;
  campaignId: string | null;
  setQueryMap: (val: QueryMap) => void;
  rowSelection: RowSelectionState; //this is object with which stores the selected rows in following form
  // {relationship_id: true}
  // for ex {"65d70941c29020806e14e61f": true, "65ddaea0c29020806edd03ca": true}
  setRowSelection: OnChangeFn<RowSelectionState> | undefined;
  setSelectedCampaignId: (val: string | null) => void;
  isUnsend: boolean;
  setIsUnsend: (val: boolean) => void;
}

const useAudienceStorePersist = create<AudienceStoreState>()(
  persist(
    (set) => ({
      queryMap: {},
      setQueryMap: (val) => set(() => ({ queryMap: val })),
      rowSelection: {},
      setRowSelection: (val) =>
        set((prev) => {
          return {
            rowSelection:
              typeof val === "function" ? val(prev.rowSelection) : val,
          };
        }),
      campaignId: null,
      setSelectedCampaignId: (val) => set(() => ({ campaignId: val })),
      isUnsend: false,
      setIsUnsend: (val) => set(() => ({ isUnsend: val })),
    }),
    {
      name: "useAudienceStorePersist",
      getStorage: getZustandDBStorage,
    }
  )
);

export default useAudienceStorePersist;
