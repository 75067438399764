import { createContext, useContext, useEffect, useState } from "react";

type Theme = "dark" | "light" | "system";

type ThemeProviderProps = {
  children: React.ReactNode;
  defaultTheme?: Theme;
  storageKey?: string;
};

type ThemeProviderState = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
};

const initialState: ThemeProviderState = {
  theme: "system",
  setTheme: () => null,
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({
  children,
  defaultTheme = "system",
  storageKey = "vite-ui-theme",
  ...props
}: ThemeProviderProps) {
  const [theme, setTheme] = useState<Theme>(
    () => (localStorage.getItem(storageKey) as Theme) || defaultTheme
  );

  useEffect(() => {
    const handleThemeChange = (event) => {
      if (theme === "system") {
        const newSystemTheme = event.matches ? "dark" : "light";
        applyTheme(newSystemTheme);
      }
    };

    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)");
    mediaQueryList.addEventListener("change", handleThemeChange);

    // Apply the current system theme on initial load if theme is "system"
    if (theme === "system") {
      const initialSystemTheme = mediaQueryList.matches ? "dark" : "light";
      applyTheme(initialSystemTheme);
    }

    return () => {
      mediaQueryList.removeEventListener("change", handleThemeChange);
    };
  }, [theme]);

  useEffect(() => {
    const systemPreference = window.matchMedia("(prefers-color-scheme: dark)");
    const handleChange = () => {
      setTheme(systemPreference.matches ? "dark" : "light");
    };

    systemPreference.addListener(handleChange);

    return () => systemPreference.removeListener(handleChange);
  }, []);

  useEffect(() => {
    if (theme !== "system") {
      applyTheme(theme);
      localStorage.setItem(storageKey, theme);
    } else {
      // Store "system" in local storage
      localStorage.setItem(storageKey, "system");
    }
  }, [theme]);

  const applyTheme = (themeToApply: Theme) => {
    const root = window.document.documentElement;
    root.classList.remove("light", "dark");

    if (themeToApply === "system") {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
        .matches
        ? "dark"
        : "light";

      window?.CommandBar?.setTheme(systemTheme);
      root.classList.add(systemTheme);
    } else {
      window?.CommandBar?.setTheme(themeToApply);
      root.classList.add(themeToApply);
    }
  };

  const value = {
    theme,
    setTheme: (newTheme: Theme) => {
      setTheme(newTheme);
    },
  };

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
}

export const useTheme = () => {
  const context = useContext(ThemeProviderContext);

  if (context === undefined)
    throw new Error("useTheme must be used within a ThemeProvider");

  return context;
};
