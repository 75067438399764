import { Skeleton } from "@/components/ui/skeleton";

function ConversationLoader() {
  return (
    <div className={"flex flex-col"}>
      {[0, 1, 2, 3, 4, 5].map((i) => (
        <div className="flex p-5">
          <Skeleton className={"w-12 h-12 rounded-full"} />
          <div className={"flex-1 ml-4"}>
            <Skeleton className={"w-96 h-4"} />
            <Skeleton className={"w-48 h-4 mt-3"} />
          </div>
        </div>
      ))}
    </div>
  );
}

export default ConversationLoader;
