import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  fetchConnectorConfiguration,
  saveConnectorConfiguration,
} from "../../../api/integrations";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/utils";
import { Separator } from "@radix-ui/react-separator";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import { ChevronsUpDown, Loader2 } from "lucide-react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";

interface Props {
  className?: string;
  isEdition: boolean;
  connector?: string;
}

export const ExtendedConnectorConfiguration = ({
  className,
  isEdition = false,
  connector = "",
}: Props) => {
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [isConfigureOpen, setIsConfigureOpen] = useState<boolean>(!isEdition);
  const { toast } = useToast();

  const { data: configurations, refetch } = useQuery({
    queryKey: ["fetch-connector-configurations"],
    queryFn: fetchConnectorConfiguration,
    select: (data) => {
      return {
        send_threads_to_connectors: data?.send_threads_to_connectors,
        send_ai_summary_to_connectors: data?.send_ai_summary_to_connectors,
        do_not_show_ticket_modal: data?.do_not_show_ticket_modal,
        auto_create_ticket_from_request: data?.auto_create_ticket_from_request,
        hide_priority: data?.hide_priority,
        invisible_only: data?.invisible_only,
      };
    },
  });

  useEffect(() => {
    // On Mount Refetch configuration
    refetch();
  }, []);

  const { handleSubmit, reset, control } = useForm();

  useEffect(() => {
    reset(configurations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurations]);

  const saveConfigurationMutation = useMutation({
    mutationFn: saveConnectorConfiguration,
    onSuccess: () => {
      toast({
        title: "Connector configuration saved.",
      });
      queryClient.invalidateQueries({
        queryKey: ["fetch-freshdesk-integration"],
      });
    },
    onError: (error: any) => {
      console.log(error);
      toast({
        title: "Failed to save connector configuration.",
      });
    },
  });
  const onSave = (data: any) => {
    saveConfigurationMutation?.mutate({
      send_threads_to_connectors: data.send_threads_to_connectors,
      send_ai_summary_to_connectors: data.send_ai_summary_to_connectors,
      auto_create_ticket_from_request: data.auto_create_ticket_from_request,
      do_not_show_ticket_modal: data.do_not_show_ticket_modal,
      hide_priority: data.hide_priority,
      invisible_only: data.invisible_only,
    });
  };

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className={cn(
        className,
        "mt-5 rounded-lg border border-solid border-brand-border"
      )}
      onChange={() => {
        setIsConfigureOpen((p) => !p);
      }}
    >
      <CollapsibleTrigger className="h-16" asChild>
        <Button
          variant="ghost"
          size="sm"
          className="w-full flex justify-between"
        >
          <div className="flex justify-start items-start flex-col gap-1">
            <h4 className={cn("m-0 font-bold")}>Connector Configuration</h4>
            {isConfigureOpen && (
              <p className="text-grey font-medium mb-0">
                Miscellaneous configurations for connector
              </p>
            )}
          </div>
          <ChevronsUpDown className="h-4 w-4" />
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent className="space-y-2" key="1">
        <div className="p-4">
          {connector !== "INTERCOM" && connector !== "HUBSPOT" && (
            <div className="mt-4">
              <div className="flex items-center justify-between">
                <div>
                  <div>Hide Urgency in Ticket preview of Slack</div>
                </div>
                <div>
                  <Controller
                    name="hide_priority"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        onCheckedChange={field.onChange}
                        defaultChecked={false}
                        {...field}
                        checked={field.value}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          )}
          {connector !== "INTERCOM" && connector !== "HUBSPOT" && (
            <div className="mt-4">
              <div className="flex items-center justify-between">
                <div>Hide Ticket preview</div>
                <div>
                  <Controller
                    name="invisible_only"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        onCheckedChange={field.onChange}
                        defaultChecked={false}
                        {...field}
                        checked={field.value}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          )}
          {connector !== "HUBSPOT" && connector !== "SALESFORCE" && (
            <div className="mt-4">
              <div className="flex items-center justify-between">
                <div>Send message thread to connector</div>
                <div>
                  <Controller
                    name="send_threads_to_connectors"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        onCheckedChange={field.onChange}
                        defaultChecked={false}
                        {...field}
                        checked={field.value}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          )}
          {connector !== "HUBSPOT" && connector !== "SALESFORCE" && (
            <div className="mt-3">
              <div className="flex items-center justify-between">
                <div>Send AI Summary to connector</div>
                <div>
                  <Controller
                    name="send_ai_summary_to_connectors"
                    control={control}
                    render={({ field }) => (
                      <Switch
                        onCheckedChange={field.onChange}
                        defaultChecked={false}
                        {...field}
                        checked={field.value}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          )}
          {connector !== "INTERCOM" && (
            <>
              <div className="mt-3">
                <div className="flex items-center justify-between">
                  <div>
                    Show Ticket Creation Modal
                    <div> (using 🎫 emoji)</div>
                  </div>
                  <div>
                    <Controller
                      name="do_not_show_ticket_modal"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          onCheckedChange={field.onChange}
                          defaultChecked={false}
                          {...field}
                          onChange={(e) => {
                            field.onChange(!e);
                          }}
                          checked={!field.value}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <div className="flex items-center justify-between">
                  <div>Auto Send Request</div>
                  <div>
                    <Controller
                      name="auto_create_ticket_from_request"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          onCheckedChange={field.onChange}
                          defaultChecked={false}
                          {...field}
                          checked={field.value}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <div className="flex justify-end w-full gap-3 my-3">
            <Button
              variant={"outline"}
              className="text-primary border-primary/40 mt-10 mb-2 font-bold px-8"
              disabled={saveConfigurationMutation?.isPending}
              onClick={handleSubmit(onSave)}
            >
              {saveConfigurationMutation?.isPending && (
                <Loader2 className="animate-spin" />
              )}
              Save Configuration
            </Button>
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};
