import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

type Props = {
  children: React.ReactNode;
  tooltipContent: React.ReactNode;
  asChild?: boolean; // if this is false and children is a button, then a button gets rendered inside another button -> invalid HTML
  delayDuration?: number;
  triggerClassname?: string;
  disableHoverableContent?: boolean;
  side?: "top" | "right" | "bottom" | "left";
  tooltipContentClassname?: string;
};

function TooltipWrapper({
  children,
  tooltipContent,
  asChild = false,
  delayDuration = 300,
  triggerClassname,
  disableHoverableContent = true,
  side,
  tooltipContentClassname,
}: Props) {
  return (
    <Tooltip
      disableHoverableContent={disableHoverableContent}
      delayDuration={delayDuration}
    >
      <TooltipTrigger asChild={asChild} className={triggerClassname}>
        {children}
      </TooltipTrigger>
      <TooltipContent className={tooltipContentClassname} side={side}>
        {tooltipContent}
      </TooltipContent>
    </Tooltip>
  );
}

export default TooltipWrapper;
