import { cn } from "@/utils";
import { Button } from "../ui/button";
import { Tooltip, TooltipContent, TooltipTrigger } from "../ui/tooltip";

type Props = {
  Icon: JSX.ElementType;
  tooltip?: React.ReactNode;
  tooltipSide?: "top" | "right" | "bottom" | "left";
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  delayDuration?: number;
  size?: number;
  className?: string;
  loading?: boolean;
  buttonVariant?:
    | "link"
    | "default"
    | "destructive"
    | "outline"
    | "secondary"
    | "ghost"
    | null
    | undefined;
  disabled?: boolean;
  iconClassName?: string;
};

function IconButton({
  Icon,
  tooltip = "",
  tooltipSide = "bottom",
  onClick = () => {},
  delayDuration = 200,
  size = 14,
  className = "",
  loading = false,
  buttonVariant = "outline",
  disabled = false,
  iconClassName = "",
}: Props) {
  return (
    <Tooltip delayDuration={delayDuration}>
      <TooltipTrigger asChild>
        <Button
          variant={buttonVariant}
          className={cn("p-0 w-7 h-7", className)}
          onClick={onClick}
          type="button"
          loading={loading}
          disabled={disabled}
        >
          <Icon size={size} className={iconClassName} />
        </Button>
      </TooltipTrigger>
      <TooltipContent side={tooltipSide} className="flex items-center gap-4">
        {tooltip}
      </TooltipContent>
    </Tooltip>
  );
}

export default IconButton;
