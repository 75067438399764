import { createContext, useContext, useState } from "react";
interface ShortcutContextType {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}
const ShortcutDefaults: ShortcutContextType = {
  isEditing: false,
  setIsEditing: () => {},
};
const ShortcutContext = createContext<ShortcutContextType>(ShortcutDefaults);

const ShortcutProvider = ({ children }: { children: React.ReactNode }) => {
  const [isEditing, setIsEditing] = useState(ShortcutDefaults.isEditing);
  return (
    <ShortcutContext.Provider value={{ isEditing, setIsEditing }}>
      {children}
    </ShortcutContext.Provider>
  );
};

const useShortcutProvider = () => useContext(ShortcutContext);

export { useShortcutProvider, ShortcutProvider };
