import { isEmpty } from "lodash";
import "./EmojiList.css";

import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useQueryCardInfo from "@/hooks/useQueryCardInfo";
import { getThenaDB } from "@/db";
import { useLiveQuery } from "dexie-react-hooks";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

export const MentionList = forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const cardData = useQueryCardInfo();
  const filteredExternalUsers = props.items.filter((item) =>
    item?.relationship_ids?.includes(cardData?.relationship_id)
  );
  const internalUsers = useLiveQuery(
    () => getThenaDB().users.toArray(),
    [],
    []
  );
  const getLabel = (item) => {
    let labelValue = "";
    if (!isEmpty(item?.metadata?.slack)) {
      if (!isEmpty(item?.metadata?.slack?.profile_display_name)) {
        labelValue = item?.metadata?.slack?.profile_display_name;
      } else {
        labelValue =
          item?.metadata?.slack?.profile_real_name ??
          item?.metadata?.slack?.name;
      }
    } else {
      if (!isEmpty(item.slack_profile_display_name)) {
        labelValue = item.slack_profile_display_name;
      } else {
        labelValue = item.slack_profile_real_name ?? item.name;
      }
    }
    return labelValue;
  };
  const filteredUsers = [...internalUsers, ...filteredExternalUsers]
    .filter(
      (item) =>
        item?.metadata?.slack?.profile_display_name
          ?.toLowerCase()
          .replace(/ /g, "")
          ?.startsWith(props?.query?.toLowerCase()) ||
        item?.metadata?.slack?.profile_real_name
          ?.toLowerCase()
          .replace(/ /g, "")
          ?.startsWith(props?.query?.toLowerCase()) ||
        item.slack_profile_display_name
          ?.toLowerCase()
          .replace(/ /g, "")
          ?.startsWith(props?.query.toLowerCase()) ||
        item.slack_profile_real_name
          ?.toLowerCase()
          .replace(/ /g, "")
          ?.startsWith(props?.query.toLowerCase())
    )
    .slice(0, 5);
  const selectItem = (index) => {
    const item = filteredUsers[index];
    if (!isEmpty(item)) {
      const label = getLabel(item);
      props.command({
        label: label,
        id: item?.slack_id ?? item?.metadata?.slack?.id,
      });
    }
  };

  const upHandler = () => {
    setSelectedIndex(
      (selectedIndex + filteredUsers.length - 1) % filteredUsers.length
    );
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % filteredUsers.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="items">
      {filteredUsers.length ? (
        filteredUsers.map((item, index) => {
          const label = getLabel(item);
          return (
            <div
              onClick={() => selectItem(index)}
              className={`item ${index === selectedIndex ? "is-selected" : ""}`}
            >
              <Avatar className="w-5 h-5">
                <AvatarImage
                  className="aspect-video"
                  src={
                    item?.metadata?.slack?.profile_image_48 ??
                    item?.slack_profile_image_24
                  }
                  alt={label}
                />
                <AvatarFallback>{label.substring(0, 2)}</AvatarFallback>
              </Avatar>
              <button key={index}>{label}</button>
            </div>
          );
        })
      ) : (
        <div className="item">No result</div>
      )}
    </div>
  );
});
