import { FetchMembersForAdminsType } from "@/api/admin";
import { Extension } from "@tiptap/react";
import { Avatar, AvatarImage } from "@/components/ui/avatar";

export const getCombinedChannelLength = (
  addedChannels: any[],
  nonAddedChannels: any[]
) => {
  return addedChannels.length + nonAddedChannels.length;
};

export const getSelectedChannelLength = (
  addedChannels: any[],
  nonAddedChannels: any[],
  selectedChannels: string[]
) => {
  const selectedIds = new Set();

  for (const idToCheck of selectedChannels) {
    if (
      addedChannels.some((i) => i.id === idToCheck) ||
      nonAddedChannels.some((i) => i.id === idToCheck)
    ) {
      selectedIds.add(idToCheck);
    }
  }

  const selectedIdsLength = selectedIds.size;
  return selectedIdsLength;
};

export const validateFileType = (file: any) => {
  const fileType = file.type.split("/")[0];
  const isExecutable = [
    "application/x-msdownload",
    "application/x-dosexec",
  ].includes(fileType);
  return !isExecutable;
};

export const createTransformedHTML = (content: any) => {
  const transformedHTML = document.createElement("div");
  transformedHTML.innerHTML = content;
  const mentions = transformedHTML.querySelectorAll("span.mention");
  mentions.forEach((mention) => {
    const mentionElement = mention as HTMLElement;
    mentionElement.outerHTML = `<@${mentionElement.dataset.id}>`;
  });
  return transformedHTML;
};

export const renderMentionItem = (item: any) => {
  return (
    <div className="text-sm px-[6px] py-[2px] flex items-center gap-2">
      <Avatar className="w-[16px] h-[16px]">
        <AvatarImage src={item.avatar} />
      </Avatar>
      <div className="w-20 line-clamp-1">{item.value}</div>
    </div>
  );
};

export const renderAtValues = (membersData: FetchMembersForAdminsType) => {
  const atValues = membersData?.members?.map((member: any) => ({
    id: member.id,
    value: member.real_name || member.display_name,
    avatar: member.image,
  }));
  return atValues;
};

export const goToEditor = (
  id,
  isReadyOnly,
  setReadOnly,
  setIsUnsend,
  setSelectedCampaignId,
  navigate
) => {
  if (!isReadyOnly) {
    setReadOnly(false);
  } else {
    setReadOnly(true);
  }
  navigate(`/slack-broadcasting/editor?id=${id}`, {
    state: { tab: null },
  });
  setIsUnsend(false);
  setSelectedCampaignId(id);
};
