import { create } from "zustand";

interface StateType {
  dbInstance: any;
  dbConnection: any;
  setDbInstance: (data: any) => void;
  setDbConnection: (data: any) => void;
}

// 🐣 DB
export const useDuckDBStore = create<StateType>((set) => ({
  dbInstance: null,
  dbConnection: null,
  setDbInstance: (data) => set({ dbInstance: data }),
  setDbConnection: (data) => set({ dbConnection: data }),
}));
