import useThemeToggle from "@/hooks/useThemeToggle";
import MsTeamsLight from "@/assets/logos/MicrosoftTeamsLight.svg";
import MsTeamsDark from "@/assets/logos/MicrosoftTeamsDark.svg";

function MsTeamsLogo() {
  const [MSTeamsLogo] = useThemeToggle({
    dark: MsTeamsDark,
    light: MsTeamsLight,
  });
  return <img src={MSTeamsLogo} alt="" className="w-4 h-4 bg-inherit" />;
}

export default MsTeamsLogo;
