import { useLayoutStorePersist } from "@/store/layoutStorePersist";
import { ReactNode, useMemo, useRef } from "react";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import Sidebar from "./Sidebar/Sidebar";
import useBodyWidth from "@/hooks/useBodyWidth";
import { ImperativePanelHandle } from "react-resizable-panels";
import WithHeader from "./WithHeader";

type Props = {
  children: ReactNode;
};

const SIDEBAR_WIDTH = 240;
const MIN_SIZE_PERCENT = 13;

const WithSidebar = ({ children }: Props) => {
  const isCollapsed = useLayoutStorePersist((state) => state.isCollapsed);

  const setCollapsed = (isCollapsed: boolean) => {
    useLayoutStorePersist.dispatch({
      type: "SET_COLLAPSED",
      payload: { isCollapsed },
    });
  };

  const ref = useRef<ImperativePanelHandle>(null);

  const bodyWidth = useBodyWidth();

  const sizeInPercent = useMemo(() => {
    const sizeInPercent = Math.floor((SIDEBAR_WIDTH / bodyWidth) * 100);
    return Math.max(sizeInPercent, MIN_SIZE_PERCENT);
  }, [bodyWidth]);

  const collapseSidebar = () => {
    ref.current?.resize(0);
  };

  const expandSidebar = () => {
    ref.current?.resize(sizeInPercent);
  };

  return (
    <ResizablePanelGroup
      direction="horizontal"
      className="rounded-lg border scrollbar-thin scrollbar-thumb-rounded-full dark:scrollbar-thumb-slate-600 scrollbar-thumb-zinc-200 scrollbar-track-transparent"
    >
      <ResizablePanel
        ref={ref}
        collapsible
        maxSize={sizeInPercent}
        minSize={20}
        onExpand={() => setCollapsed(false)}
        onCollapse={() => setCollapsed(true)}
        defaultSize={isCollapsed ? 0 : sizeInPercent}
        className="max-w-[240px] transition-all duration-300 linear"
      >
        <Sidebar collapseSidebar={collapseSidebar} />
      </ResizablePanel>
      <ResizableHandle withHandle={!isCollapsed} />
      <ResizablePanel defaultSize={100 - sizeInPercent}>
        <WithHeader expandSidebar={expandSidebar}>{children}</WithHeader>
      </ResizablePanel>
    </ResizablePanelGroup>
  );
};

export default WithSidebar;
