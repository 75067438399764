import { randCetacean } from "@ngneat/falso";
import chroma from "chroma-js";
import { format } from "date-fns";

// "Hello there" -> "hello_there"
// "Hello-there" -> "hellothere"
export const toLowerCaseUnderScore = (text: string) => {
  return text.replace(/\s/g, "_").replace(/-/g, "").toLowerCase();
};

export const pluralize = (text: string, num: number, pluralText = "s") => {
  if (num > 1) {
    return `${text}${pluralText}`;
  }
  return text;
};

// Replace underscores with spaces
// and capitalize the first letter of each word
export const toTitleCase = (str: string) => {
  const result = str.replace(/_/g, " ");
  const list = result.split(" ").map((word) => {
    return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
  });
  return list.join(" ");
};

export const truncate = (str: string, maxLen = 20) => {
  if (str.length > maxLen) {
    return `${str.substring(0, maxLen)}...`;
  }
  return str;
};

export const copyToClipboard = async (text: string) => {
  let flag = true;
  const [baseUrl, queryString] = text.split("?");

  const modifiedParams: any[] = [];
  const paramPairs = queryString.split("&");
  // Loop through parameter-value pairs
  paramPairs.forEach((pair) => {
    const [param, value] = pair.split("=");
    if (param === "tab" || param === "requestId" || param === "teamId") {
      modifiedParams.push(`${param}=${value}`);
    }
  });
  const newUrl = `${baseUrl}?${modifiedParams.join("&")}`;
  try {
    await window.navigator.clipboard.writeText(newUrl);
  } catch (error) {
    flag = false;
  }
  return flag;
};

export const getNameSnippet = (name: string) => {
  const snippet = name
    .toUpperCase()
    .split(" ")
    .map((word) => word[0])
    .join("");

  return snippet.substring(0, 2);
};

const localhostRegex =
  /^(localhost|127\.0\.0\.1|::1|10\.\d{1,3}\.\d{1,3}\.\d{1,3}|172\.(1[6-9]|2[0-9]|3[0-1])\.\d{1,3}\.\d{1,3}|192\.168\.\d{1,3}\.\d{1,3}|[fF][cCdD][0-9a-fA-F]{2}(?::[0-9a-fA-F]{0,4}){0,7})$/;

const domainRegex = /^(?!-)([A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,}$/;

export const isValidDomain = (domain: string) => {
  const isLocalhost = localhostRegex.test(domain);
  const isDomain = domainRegex.test(domain);
  return isDomain && !isLocalhost;
};

export const getRandomChannelName = () => {
  return `${randCetacean()
    .replace(/[^a-zA-Z0-9 -]/g, "")
    .replace(/[ ]/g, "-")
    .toLowerCase()}-${format(new Date(), "yyyy-MM-dd")}`;
};

export const extractSubdomain = (url: string) => {
  const parts = url.split(".");
  return parts[0] || false;
};

export const getColorByLuminance = (color: string) => {
  const originalColor = color || "#0f052b";
  const luminance = chroma(originalColor).luminance();

  return luminance > 0.5
    ? chroma(originalColor).darken().hex()
    : chroma(originalColor).brighten().hex();
};
