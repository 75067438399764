import { MetaDBType } from "@/types/meta";
import Dexie from "dexie";

class MetaDBClass extends Dexie {
  meta!: Dexie.Table<MetaDBType>;

  constructor() {
    super("MetaDB");
    this.version(1).stores({
      meta: "dbName",
    });
  }
}

export const metaDB = new MetaDBClass();
