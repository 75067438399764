import { BootstrapQuery, BootstrapResponse } from "@/types/bootstrap";
import { api } from ".";
import { SubStatusResponseType } from "@/types/requests";

export const fetchBootstrapData = (
  query: BootstrapQuery
): Promise<BootstrapResponse> => {
  return api
    .get("/v2/kanban/bootstrap", {
      params: query,
    })
    .then(({ data }) => data.data);
};

export const fetchSubStatuses = (): Promise<SubStatusResponseType> => {
  return api
    .get("/v2/requests/config/status")
    .then(({ data }) => data?.data ?? {});
};

/**
 * @param model
 * @param afterEpoch Timestamp in milliseconds
 */

export const fetchBootstrapUpdate = ({
  model,
  afterEpoch,
}: {
  model: string;
  afterEpoch: string;
}) => {
  return api
    .get(`/v2/kanban/batch?model=${model}&after_epoch=${afterEpoch}`)
    .then(({ data }) => data.data);
};

export const fetchEntityData = (model: string, id: string) => {
  return api
    .get(`/v2/kanban/get-entity?model=${model}&id=${id}`)
    .then(({ data }) => data.data);
};

type BulkUpdatePayload = {
  users: string;
  requests: string;
  channels: string;
  relationships: string;
  external_links: string;
  customer_contacts: string;
  external_tickets: string;
  campaigns: string;
  request_sub_status: string;
};

export const fetchBulkBatchData = (payload: BulkUpdatePayload) => {
  return api
    .post("/v2/kanban/bulk-batch", payload)
    .then(({ data }) => data.data);
};
