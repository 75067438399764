import { Suspense, lazy, useEffect, useMemo } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import AuthRedirectPage from "@/components/AuthRedirectPage";
import BlockScreen from "@/components/common/BlockScreen";
import { ASSISTANT_TYPE } from "@/constants/aiAssistant";
import { ConnectedApps } from "@/modules/ConnectedApps";
import Login from "@/pages/Login";
import Logout from "@/pages/Logout";
import { emitInfoToSW } from "@/utils/domUtils";
import RouteRedirects from "./RouteRedirects";
import useRequestsInit from "@/hooks/useRequestsInit";

/**
 * Order matters
 * - promise structure to preload the pages
 * - ref: https://medium.com/hackernoon/lazy-loading-and-preloading-components-in-react-16-6-804de091c82d
 */

const RequestPromise = import("@/pages/Requests");
const Requests = lazy(() => RequestPromise);

const TicketPromise = import("@/pages/Tickets");
const Tickets = lazy(() => TicketPromise);

const SettingsParentPromise = import("@/pages/Configuration/SettingsParent");
const SettingsParent = lazy(() => SettingsParentPromise);

const SettingsPromise = import("@/pages/Configuration/Settings/Settings");
const Settings = lazy(() => SettingsPromise);

const CreateAudiencePromise = import(
  "@/pages/SlackBroadCasting/createList/CreateAudience"
);
const CreateAudience = lazy(() => CreateAudiencePromise);

const SavedViewsPromise = import("@/pages/SavedViews");
const SavedViews = lazy(() => SavedViewsPromise);

const AudienceListPromise = import("@/pages/SlackBroadCasting/AudienceList");
const AudienceList = lazy(() => AudienceListPromise);

const SlackBroadCastingPromise = import(
  "@/pages/SlackBroadCasting/BroadcastParent"
);
const SlackBroadCasting = lazy(() => SlackBroadCastingPromise);

const SlackBroadCastingTemplatePromise = import(
  "@/pages/SlackBroadCasting/SlackBroadCastingTemplate"
);
const SlackBroadCastingTemplate = lazy(() => SlackBroadCastingTemplatePromise);

const TemplateEditorPromise = import(
  "@/pages/SlackBroadCasting/TemplateEditor"
);
const TemplateEditor = lazy(() => TemplateEditorPromise);

const EmailSettingsPromise = import(
  "@/components/GlobalSettings/EmailSettings"
);
const EmailSettings = lazy(() => EmailSettingsPromise);

const WebChatSettingsPromise = import(
  "@/components/GlobalSettings/WebChatSettings"
);
const WebChatSettings = lazy(() => WebChatSettingsPromise);

const CRMConnectPromise = import(
  "@/pages/Configuration/CRMConnect/CRMTabsSection"
);
const CRMConnect = lazy(() => CRMConnectPromise);

const BroadcastAnalyticsPromise = import(
  "@/pages/SlackBroadCasting/Analytics/BroadcastAnalytics"
);
const BroadcastAnalytics = lazy(() => BroadcastAnalyticsPromise);

const MsTeamAuthResultPromise = import("@/pages/MsTeamsAuthResult");
const MsTeamsAuthResult = lazy(() => MsTeamAuthResultPromise);

const AnalyticsPromise = import("@/pages/Analytics/Landing");
const Analytics = lazy(() => AnalyticsPromise);

const ZendeskIntegrationPromise = import(
  "@/modules/ZendeskIntegration/Landing"
);
const ZendeskIntegration = lazy(() => ZendeskIntegrationPromise);

const UserPreferencePromise = import("@/pages/UserPreference/UserPreference");
const UserPreference = lazy(() => UserPreferencePromise);

const AiAssistantSettingsPromise = import(
  "@/components/GlobalSettings/AiAssistant"
);
const AiAssistantSettings = lazy(() => AiAssistantSettingsPromise);

const VendorCustomerPortalPromise = import(
  "@/pages/VendorCustomerPortal/VendorCustomerPortal"
);
const VendorCustomerPortal = lazy(() => VendorCustomerPortalPromise);

const AddThenaToChannelsPromise = import(
  "@/pages/Configuration/SlackSettings/AddThenaToChannels/AddThenaToChannels"
);
const AddThenaToChannels = lazy(() => AddThenaToChannelsPromise);

const MacrosPromise = import("@/pages/Macros/Macros");
const Macros = lazy(() => MacrosPromise);

const APIKeyPromise = import("@/components/GlobalSettings/APIKey");
const APIKey = lazy(() => APIKeyPromise);

const SalesForceMappingTabsPromise = import(
  "@/pages/Configuration/SalesForceMapping/SalesForceMappingTabs"
);
const SalesForceMappingTabs = lazy(() => SalesForceMappingTabsPromise);

const MsteamsIntegrationPromise = import(
  "@/modules/integrations/msteams/MsteamsIntegration"
);
const MsteamsIntegration = lazy(() => MsteamsIntegrationPromise);

const SelectChannelsPromise = import(
  "@/pages/SlackBroadCasting/SendCampaign/SelectChannels"
);
const SelectChannels = lazy(() => SelectChannelsPromise);

const SendCampaignPromise = import(
  "@/pages/SlackBroadCasting/SendCampaign/SendCampaign"
);
const SendCampaign = lazy(() => SendCampaignPromise);

const CustomerPortalLoginFormPromise = import(
  "@/pages/CustomerPortal/LoginForm"
);

const CustomerPortalLoginForm = lazy(() => CustomerPortalLoginFormPromise);

const routes = [
  { path: "/", element: <Login /> },
  { path: "/requests", element: <Requests key="requests" /> },
  { path: "/request", element: <Requests key="internal-helpdesk" /> },
  {
    path: "/external-links",
    element: <Requests subRoute="external-links" key="external-links" />,
  },
  { path: "/saved-views", element: <SavedViews /> },
  { path: "/internal-views", element: <SavedViews /> },
  {
    path: "/analytics",
    element: <Analytics />,
  },
  {
    path: "/configuration/settings",
    element: <Settings isGlobal={true} />,
  },
  { path: "/configuration/setup", element: <AddThenaToChannels /> },
  {
    path: "/configuration/accounts/add-thena",
    element: <BlockScreen route="/configuration/accounts/add-thena" />,
  },
  {
    path: "/configuration/integration",
    element: <ConnectedApps />,
  },
  { path: "/configuration/email", element: <EmailSettings /> },
  {
    path: "/configuration/web-chat",
    element: <WebChatSettings />,
  },
  {
    path: "/configuration/crm-connect",
    element: <CRMConnect />,
  },
  { path: "/configuration/ms-teams", element: <MsteamsIntegration /> },
  {
    path: "/configuration/apikey",
    element: <APIKey />,
  },
  { path: "/tickets", element: <Tickets isInternalHelpdesk={false} /> },
  {
    path: "/internal-helpdesk/tickets",
    element: <Tickets isInternalHelpdesk={true} />,
  },
  {
    path: "/configuration/ms-teams/success",
    element: <MsTeamsAuthResult />,
  },
  {
    path: "/configuration/ms-teams/failed",
    element: <MsTeamsAuthResult />,
  },
  {
    path: "/user-preferences",
    element: <UserPreference />,
  },
  {
    path: "/slack/user/authorize",
    element: <AuthRedirectPage />,
  },
  {
    path: "/internal-helpdesk/ai-assist",
    element: (
      <AiAssistantSettings assistantType={ASSISTANT_TYPE.INTERNAL_HELPDESK} />
    ),
  },
  {
    path: "/customer-support/ai-assist",
    element: (
      <AiAssistantSettings assistantType={ASSISTANT_TYPE.CUSTOMER_SUPPORT} />
    ),
  },
  {
    path: "/admin",
    element: <VendorCustomerPortal />,
  },
  {
    path: "/snippets",
    element: <Macros />,
  },
  {
    path: "/salesforce-cases-integration",
    element: <SalesForceMappingTabs />,
  },
  {
    path: "/configuration/integration/zendesk",
    element: <ZendeskIntegration />,
  },
  {
    path: "/customer-portal/login",
    element: <CustomerPortalLoginForm />,
  },
];

const RequestsInit = () => {
  useRequestsInit();
  return null;
};

const REQUESTS_INIT_PATHS = ["/requests", "/request", "/external-links"];

const AppRoutes = () => {
  useEffect(() => {
    const timer = setInterval(() => {
      emitInfoToSW({
        type: "KEEP_ALIVE",
      });
    }, 2 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const pathname = useLocation().pathname;

  const shouldMountRequestsInitHook = useMemo(() => {
    return REQUESTS_INIT_PATHS.includes(pathname);
  }, [pathname]);

  return (
    <>
      {shouldMountRequestsInitHook && <RequestsInit />}
      <Suspense>
        <Routes>
          {routes.map(({ path, element }, index) => (
            <Route key={index} path={path} element={element} />
          ))}
          <Route path="/slack-broadcasting" element={<SlackBroadCasting />}>
            <Route
              path="template-list"
              element={<SlackBroadCastingTemplate />}
            />
            <Route path="audience" element={<AudienceList />} />
            <Route path="audience/create" element={<CreateAudience />} />
            <Route path="audience/edit" element={<CreateAudience />} />
            <Route path="editor" element={<TemplateEditor />} />
            <Route path="editor/:id" element={<TemplateEditor />} />
            <Route path="analytics/:id" element={<BroadcastAnalytics />} />
            <Route path="select-accounts" element={<SelectChannels />} />
            <Route path="send" element={<SendCampaign />} />
          </Route>
          <Route path="/configuration/accounts" element={<SettingsParent />}>
            <Route path="configure" element={<Settings isGlobal={false} />} />
          </Route>
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<RouteRedirects />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
