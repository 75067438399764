import { ReactNode } from "react";
import { useLocation } from "react-router-dom";
import WithSidebar from "./components/WithSidebar";
import { TooltipProvider } from "@/components/ui/tooltip";

export default function Layout({ children }: { children: ReactNode }) {
  const path = useLocation().pathname;

  if (path === "/" || path === "/customer-portal/login") {
    return children;
  }

  return (
    <div className="flex-col flex h-full">
      <TooltipProvider delayDuration={700}>
        <WithSidebar>{children}</WithSidebar>
      </TooltipProvider>
    </div>
  );
}
