import { api } from "@/api";
import { logoutHelper } from "@/utils/auth";
import * as Sentry from "@sentry/browser";
import { useCallback, useRef } from "react";

// To be called only once in App
const useAuthInit = () => {
  const initRef = useRef(false);

  const init = useCallback(() => {
    if (initRef.current) {
      return;
    }
    initRef.current = true;
    api.interceptors.response.use(
      (response) => response,
      function (error: any) {
        const pathname = window.location.pathname;

        const isMsTeamAuthResultPage =
          pathname.includes("/configuration/ms-teams/success") ||
          pathname.includes("/configuration/ms-teams/failed");

        const shouldIgnoreError =
          (error?.response?.status === 401 &&
            error?.config?.url === "/v1/user-info") ||
          error?.code === "ERR_CANCELED" ||
          isMsTeamAuthResultPage;

        if (shouldIgnoreError) {
          console.log("[API error] not sent to Sentry", error);
        } else {
          Sentry.withScope((scope) => {
            scope.setTag("api-error", "true");
            scope.setLevel("error");
            scope.setExtra("api-url", error?.config?.url || "");
            Sentry.captureException(error);
          });
        }

        if (error.response?.status === 401 && !isMsTeamAuthResultPage) {
          console.log("Logged out because of 401 response.");
          logoutHelper(pathname !== "/");
        }
        return Promise.reject(error);
      }
    );
  }, []);

  init();
};

export default useAuthInit;
