import Dexie, { Table } from "dexie";

export class ZustandDBClass extends Dexie {
  store!: Table<any>;
  constructor() {
    super("ThenaZustandStore");
    this.version(1).stores({
      store: "key,value",
    });
  }
}

export const zustandDB = new ZustandDBClass();

export const getZustandDBStorage = () => ({
  getItem: async (name) => {
    const item = await zustandDB.store.get(name);
    return item ? item.value : null;
  },
  setItem: async (name, value) => {
    await zustandDB.store.put({ key: name, value });
  },
  removeItem: async (name) => {
    await zustandDB.store.delete(name);
  },
});
