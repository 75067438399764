import { ReactNode } from "react";
import Header from "./Header";

type Props = {
  children: ReactNode;
  expandSidebar: () => void;
};

const WithHeader = ({ children, expandSidebar }: Props) => {
  return (
    <>
      <Header expandSidebar={expandSidebar} />
      {children}
    </>
  );
};

export default WithHeader;
