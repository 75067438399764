import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ThenaLoader from "@/components/common/ThenaLoader";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { LOGIN_URL } from "@/constants";
import { useAuthStorePersist } from "@/store/authStorePersist";
import Logo from "../assets/logos/thena_logo.svg";
import LoginForm from "./CustomerPortal/LoginForm";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const user = useAuthStorePersist((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname === "/customer-portal/login") {
    return <LoginForm />;
  }

  if (user._id) {
    return <ThenaLoader />;
  }

  const onClick = async () => {
    setLoading(true);
    window.open(LOGIN_URL, "_self");
  };

  return (
    <div className="container relative h-full flex-col items-center justify-center grid lg:max-w-none lg:grid-cols-2 lg:px-0">
      <div className="relative hidden h-full flex-col bg-muted p-10 text-white lg:flex dark:border-r">
        <div
          className="absolute inset-0"
          style={{
            background:
              "radial-gradient(159.92% 112.14% at 50.75% 0%, #1C003F 0%, #16042E 100%)",
          }}
        />
        <div className="relative z-20 flex items-center text-lg font-medium">
          <img
            src={Logo}
            alt="assistant"
            style={{ height: "40px", marginRight: "12px" }}
          />
        </div>
        <div className="relative z-20 mt-auto">
          <Link target="_blank" to="https://www.thena.ai/" className="">
            Visit website
          </Link>
        </div>
      </div>
      <div className="lg:p-8">
        <div className="mx-auto flex w-full flex-col justify-center space-y-6">
          <div className="mx-auto flex flex-col space-y-2 text-center">
            <h1 className=" text-[30px] font-semibold ">Welcome to Thena</h1>
            <p className="text-sm text-muted-foreground text-[18px]">
              The best way to work with your customers
            </p>
          </div>
          <div className="flex justify-center flex-col items-center">
            <div className="">
              <Button loading={loading} onClick={onClick} className="w-[400px]">
                Sign in to continue
              </Button>
              <div className="flex items-center justify-center mt-6 gap-2">
                <span className="text-base text-color-icon-muted">
                  Not a vendor?
                </span>
                <Button
                  variant="link"
                  className="text-base font-medium p-0"
                  onClick={() => navigate("/customer-portal/login")}
                >
                  Sign in as a customer
                </Button>
              </div>
            </div>
            <Separator className="mt-6 w-[400px]" />
          </div>
          <div className="px-8 text-center text-sm text-muted-foreground">
            <div className="flex gap-2 justify-center items-center">
              <div className="">
                <Link
                  target="_blank"
                  to="https://www.thena.ai/policy/privacy"
                  className="  hover:text-primary"
                >
                  <span>Privacy</span>
                </Link>{" "}
              </div>
              <Separator className="w-6 rotate-90" />
              <div className="">
                <Link
                  target="_blank"
                  to="https://www.thena.ai/policy/product"
                  className="  hover:text-primary"
                >
                  Terms & conditions
                </Link>
              </div>
            </div>
          </div>
          <div
            className="relative flex justify-center items-center gap-2"
            style={{
              top: "250px",
            }}
          >
            <div className="text-muted-foreground text-[16px]">
              Don't have an account?
            </div>
            <div>
              <Link
                target="_blank"
                to="https://www.thena.ai/request-a-demo-v2"
                className=""
              >
                Request a demo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
