import {
  getAiAssistantHasSources,
  getAiAssistantId,
  streamData,
} from "@/api/editorAi";
import {
  AI_ASSIST_MODAL_TYPE,
  STREAM_EVENTS,
  EMPTY_FUNC,
  ASSISTANT_TYPE,
  AI_ASSIST_SETTINGS_PATH,
  AI_ASSIST_MESSAGES,
} from "@/constants/aiAssistant";
import { useAuthStorePersist } from "@/store/authStorePersist";
import { useCallback, useRef, useState } from "react";
import { TextSelection } from "@tiptap/pm/state";
import { marked } from "marked";
import { Editor } from "@tiptap/react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useGlobalStorePersist } from "@/store/globalStorePersist";
import { get } from "lodash";

const STREAMING_STATES = {
  SUCCESS: "success",
  LOADING: "loading",
  STREAMING: "streaming",
  FAILED: "failed",
  INITIAL: "default",
};
type STREAMING_STATES =
  | "success"
  | "loading"
  | "streaming"
  | "failed"
  | "default";

interface MODALSTATE {
  open: boolean;
  type: string | null;
  onPrimaryClicked?: typeof EMPTY_FUNC;
}

const useStreaming = (
  updateEditorValue,
  ticket_id: string,
  assistantType: string
) => {
  const aiAssistantId = useGlobalStorePersist((state) =>
    assistantType === ASSISTANT_TYPE.CUSTOMER_SUPPORT
      ? state.aiAssistants.cs
      : state.aiAssistants.ih
  );
  const aiAssistantHasSources = useGlobalStorePersist((state) =>
    assistantType === ASSISTANT_TYPE.CUSTOMER_SUPPORT
      ? state.aiAssistantSources.cs
      : state.aiAssistantSources.ih
  );
  const [streamingState, setStreamingState] = useState<any>(
    STREAMING_STATES.INITIAL
  );
  const navigate = useNavigate();
  const [modal, setModal] = useState<MODALSTATE>({
    open: false,
    type: null,
    onPrimaryClicked: () => {},
  });
  const controllerRef = useRef<any>();

  const onCloseModal = () => {
    setModal({
      open: false,
      type: null,
      onPrimaryClicked: EMPTY_FUNC,
    });
  };

  const onStreamInit = useCallback((editor: Editor) => {
    editor.commands.setContent({ type: "custom-ai-node" });
    editor.setEditable(true);
    setStreamingState(STREAMING_STATES.LOADING);
  }, []);
  const onStreamStarted = (editor) => {
    // editor.commands.setContent("<p></p>");
    updateEditorValue(editor.getHTML());
    editor.setEditable(false);
    setStreamingState(STREAMING_STATES.STREAMING);
  };
  const onStreamSuccess = (editor) => {
    editor.setEditable(true);
    setStreamingState(STREAMING_STATES.SUCCESS);
  };
  const onStreamFailed = (editor) => {
    setStreamingState(STREAMING_STATES.FAILED);
    editor.setEditable(true);
    toast(AI_ASSIST_MESSAGES.STREAMING_FAILED.FAILED);
    editor.commands.setContent("<p></p>");
  };
  const onStreamingError = (editor) => {
    setStreamingState(STREAMING_STATES.FAILED);
    editor.setEditable(true);
    toast(AI_ASSIST_MESSAGES.STREAMING_FAILED.FAILED);
  };

  const onStream = useCallback(
    (editor) => {
      controllerRef.current = new AbortController();
      const existingContent = editor?.getHTML();
      const { doc } = editor.state;
      const renderer = {
        paragraph(text) {
          return text.replace(/\n\n/g, "<br><br>");
        },
      };
      marked.use({ renderer });

      const onMessageHandler = (event, fetchedText, controller, editor) => {
        if (
          event.event === STREAM_EVENTS.DONE ||
          event.event === STREAM_EVENTS.INCOMPLETE
        ) {
          controller.abort();
          editor.setEditable(true);
          onStreamSuccess(editor);
        } else if (event.event === STREAM_EVENTS.ERROR) {
          onStreamingError(editor);
        } else if (event.event === STREAM_EVENTS.CREATED) {
          onStreamStarted(editor);
          const { doc } = editor.state;
          const endPosition =
            doc.content.size > 2 ? doc.content.size : doc.content.size - 1;
          const selection = TextSelection.create(doc, endPosition);
          const tr = editor.state.tr.setSelection(selection);
          editor.view.dispatch(tr);
          editor.view.focus();
        } else if (event.event === STREAM_EVENTS.DELTA) {
          const parsedData = JSON.parse(event.data);
          if (parsedData?.value) {
            const text = parsedData?.value;
            fetchedText += text;
            const html_text = marked.parse(fetchedText, {
              gfm: true,
              breaks: true,
            });
            editor.commands.setContent(html_text);
            updateEditorValue(editor.getHTML());
          } else {
            const dataObj = parsedData?.data;
            if (dataObj) {
              const text = get(dataObj, "delta.content[0].text.value", "");
              fetchedText += text;
              const html_text = marked.parse(fetchedText, {
                gfm: true,
                breaks: true,
              });
              editor.commands.setContent(html_text);
              updateEditorValue(editor.getHTML());
            }
          }
        }
        return fetchedText;
      };
      const user_id = useAuthStorePersist.getState().user.user_id;
      const body = {
        input: editor?.state?.doc?.textContent + " ",
        ticket_id: ticket_id,
        stream: true,
        user_id,
      };

      const streamFunction = () =>
        streamData(
          editor,
          aiAssistantId || "",
          body,
          onMessageHandler,
          existingContent,
          controllerRef.current,
          onStreamInit,
          onStreamSuccess,
          onStreamFailed,
          onStreamingError
        );
      if (!aiAssistantId) {
        let settingPath =
          AI_ASSIST_SETTINGS_PATH[ASSISTANT_TYPE.CUSTOMER_SUPPORT];
        if (assistantType === ASSISTANT_TYPE.INTERNAL_HELPDESK) {
          settingPath =
            AI_ASSIST_SETTINGS_PATH[ASSISTANT_TYPE.INTERNAL_HELPDESK];
        }
        setModal({
          open: true,
          type: AI_ASSIST_MODAL_TYPE.NO_ASSISTANT,
          onPrimaryClicked: () => {
            navigate("/" + settingPath, {
              replace: true,
            });
          },
        });
      } else if (!aiAssistantHasSources) {
        let settingPath =
          AI_ASSIST_SETTINGS_PATH[ASSISTANT_TYPE.CUSTOMER_SUPPORT];
        if (assistantType === ASSISTANT_TYPE.INTERNAL_HELPDESK) {
          settingPath =
            AI_ASSIST_SETTINGS_PATH[ASSISTANT_TYPE.INTERNAL_HELPDESK];
        }
        setModal({
          open: true,
          type: AI_ASSIST_MODAL_TYPE.NO_SOURCES,
          onPrimaryClicked: () => {
            navigate("/" + settingPath + "?tab=sources", {
              replace: true,
            });
          },
        });
      } else if (doc.content.size > 2) {
        setModal({
          open: true,
          type: AI_ASSIST_MODAL_TYPE.REGENERATE_WARNING,
          onPrimaryClicked: () => {
            onCloseModal();
            streamFunction();
          },
        });
      } else {
        streamFunction();
      }
    },
    [aiAssistantHasSources, aiAssistantId]
  );
  return [onStream, streamingState, modal, onCloseModal];
};

export { useStreaming, STREAMING_STATES };
