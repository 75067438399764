import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { fetchSalesforceIntegration } from "../../../api/integrations";
// import { helphubUtil } from "../../../utils/helphub";
import { useConnectedApp } from "../../ConnectedApps";
import { SalesforceConnectionDetailsType } from "./../definitions";
import { useSalesforce } from "./SalesforceProvider";
import { Button } from "@/components/ui/button";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { CircleCheck, Loader2, TriangleAlert } from "lucide-react";

interface SalesforceIntegrationType {
  userID: string;
  password: string;
  consumerKey: string;
  consumerSecret: string;
  secretToken: string;
  salesforceURL: string;
  requestTypes: string[];
}
type Props = {
  requestType: {
    label: string;
    value: string;
    disabled: boolean;
  }[];
};

export const SalesforceSetup = ({ requestType }: Props) => {
  const { isEdition } = useConnectedApp();
  const {
    isSetUpOpen,
    setIsSetUpOpen,
    connectionPassed,
    connectionFailed,
    saveRequestTypesMutation,
    testSalesforceConnectionMutation,
  } = useSalesforce();

  const { data: salesforceIntegration, isLoading } = useQuery({
    queryKey: ["fetch-salesforce-integration"],
    queryFn: fetchSalesforceIntegration,
    select: (
      data: SalesforceConnectionDetailsType
    ): SalesforceIntegrationType => {
      return {
        userID: data?.username as string,
        password: data?.password as string,
        consumerKey: data?.client_id as string,
        consumerSecret: data?.client_secret as string,
        secretToken: data?.secret_token as string,
        salesforceURL: data?.url as string,
        requestTypes: data?.requestTypes as string[],
      };
    },
  });

  const onSubmit = (data: any) => {
    console.log(data);
    testSalesforceConnectionMutation?.mutate({
      sfdc_username: data.userID,
      sfdc_password: data.password,
      sfdc_url: data.salesforceURL,
      sfdc_secrettoken: data.secretToken,
      sfdc_consumerkey: data.consumerKey,
      sfdc_consumersecret: data.consumerSecret,
      requestTypes: data.requestTypes,
    });
  };

  const onSave = (data: any) => {
    saveRequestTypesMutation?.mutate({
      sfdc_username: data.userID,
      sfdc_password: data.password,
      sfdc_url: data.salesforceURL,
      sfdc_secrettoken: data.secretToken,
      sfdc_consumerkey: data.consumerKey,
      sfdc_consumersecret: data.consumerSecret,
      requestTypes: data.requestTypes,
    });
  };
  const form = useForm();

  useEffect(() => {
    form.reset(salesforceIntegration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesforceIntegration]);

  const disableSave =
    (!isEdition && !connectionPassed) ||
    (isEdition && form.formState.isDirty && !connectionPassed);

  if (isLoading)
    return (
      <div className="w-full h-96 flex justify-center items-center">
        <Loader2 className="mr-2 h-16 w-16 animate-spin" />
      </div>
    );

  return (
    <Accordion type="single" collapsible>
      <AccordionItem value="item-1">
        <AccordionTrigger>Setup Integration</AccordionTrigger>
        <AccordionContent>
          <div className="border boder-solid p-4 rounded-sm">
            <Form {...form}>
              <div className="flex gap-4">
                <div className="grow">
                  <FormField
                    control={form.control}
                    name="userID"
                    disabled={connectionPassed}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>User ID</FormLabel>
                        <FormControl>
                          <Input placeholder="User ID" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="grow">
                  <FormField
                    control={form.control}
                    name="password"
                    disabled={connectionPassed}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Password</FormLabel>
                        <FormControl>
                          <Input placeholder="Password" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <FormField
                control={form.control}
                name="secretToken"
                disabled={connectionPassed}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Secret Token</FormLabel>
                    <FormControl>
                      <Input placeholder="Secret Token" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="consumerKey"
                disabled={connectionPassed}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Consumer Key</FormLabel>
                    <FormControl>
                      <Input placeholder="Consumer Key" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="consumerSecret"
                disabled={connectionPassed}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Consumer Secret</FormLabel>
                    <FormControl>
                      <Input placeholder="Consumer Secret" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex items-end gap-4">
                <div className="grow">
                  <FormField
                    control={form.control}
                    name="salesforceURL"
                    disabled={connectionPassed}
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Salesforce URL</FormLabel>
                        <FormControl>
                          <Input placeholder="Salesforce URL" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="">
                  <Button
                    onClick={form.handleSubmit(onSubmit)}
                    disabled={connectionPassed}
                    loading={testSalesforceConnectionMutation?.isPending}
                    variant={"outline"}
                  >
                    Test connection
                  </Button>
                </div>
              </div>
              <div className="flex space-x-2 mt-1">
                {connectionPassed && (
                  <span className="flex items-center">
                    <CircleCheck className="mr-1" size={14} color="#2BBC58" />{" "}
                    <p className="text-xs text-success font-medium">
                      Connection Successful!
                    </p>
                  </span>
                )}
                {connectionFailed && !connectionPassed && (
                  <span className="flex items-center">
                    <TriangleAlert className="mr-1" size={14} color="#D91029" />{" "}
                    <p className="text-xs text-error font-medium">
                      Test connection failed!
                    </p>
                  </span>
                )}
                {!connectionFailed && !connectionPassed && (
                  <p className="text-xs text-muted font-medium">
                    Testing connection required
                  </p>
                )}
              </div>
              <div className="mt-4 flex items-center">
                <div className="grow ">
                  <Controller
                    name="requestTypes"
                    control={form.control}
                    render={({ field }) => (
                      <RadioGroup {...field} className="flex">
                        {requestType.map((el) => (
                          <div className="flex items-center">
                            <RadioGroupItem
                              key={el.value}
                              value={el.value}
                              disabled={disableSave || el.disabled}
                            ></RadioGroupItem>
                            <Label className="ml-2" htmlFor={el.value}>
                              {el.label}
                            </Label>
                          </div>
                        ))}
                      </RadioGroup>
                    )}
                  />
                </div>
                <div className="">
                  <Button
                    disabled={disableSave}
                    onClick={form.handleSubmit(onSave)}
                    loading={saveRequestTypesMutation?.isPending}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
