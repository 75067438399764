import { getThenaDB } from "@/db";
import { useKanbanStore } from "@/store/kanbanStore";
import { useLiveQuery } from "dexie-react-hooks";
import { isEmpty } from "lodash";
import { useMemo } from "react";

const useGetSlackConversation = (cardId: string) => {
  const isFetching = useKanbanStore((state) => state.isConversationLoading);

  const data = useLiveQuery(() => {
    return getThenaDB().slackConversations.get(cardId || "");
  }, [cardId]);

  const messageCount = useMemo(() => {
    if (!isEmpty(data?.data)) {
      return data?.data?.reduce((acc: number, thread: any) => {
        return acc + 1 + (thread.replies?.length ?? 0);
      }, 0);
    }
    return 0;
  }, [data]);

  const threadCount = useMemo(() => {
    return !isEmpty(data) ? data?.data?.length : 0;
  }, [data]);

  return useMemo(() => {
    return {
      isFetching: !data?.data && threadCount === 0 && isFetching,
      threadCount,
      messageCount,
      data: data?._id === cardId ? data?.data || [] : [],
    };
  }, [cardId, data?._id, data?.data, isFetching, messageCount, threadCount]);
};

export default useGetSlackConversation;
