import { clearBrowserStorage } from "@/utils/auth";
import { create } from "zustand";
import { devtools, persist, redux } from "zustand/middleware";

const REQUEST_INDEXES = [
  // Primary key
  "_id",
  // Indexed Keys
  "status",
  "request_id",
  "installation_id",
  "installation_team_id",
  "message_details_ts",
  "relationship_id",
  "assigned_by_user_id",
  "assigned_to_user_id",
  "ai_metadata.sentiment",
  "ai_metadata.urgency",
  "original_message_source",
  "original_message_user_id",
  "created_at",
  "updated_at",
  "sub_status",
  "created_at_date",
  "urgencyOrder",
  "internalHelpDeskIndex",
  "firstResponseSlaOrder",
  "finalResolutionSlaOrder",
  "lastCustomerReply",
  "slaBreached",
  "lastCustomerReplyOrder",
  "lastReplyOrder",
  "updated_at_date",
  "escalation_type",
  // Multi-entry index
  "*ai_metadata.tags",
];

const TICKET_INDEXES = [
  "_id",
  "relationship_id",
  "user",
  "created_at",
  "created_at_date",
  "updated_at_date",
  "status",
  "assignee_id",
];

const USER_INDEXES = [
  // Primary key
  "_id",
  // Indexed Keys
  "initial_installation_id",
  "name",
  "updated_at_date",
  "slack_id",
];

const RELATIONSHIP_INDEXES = [
  // Primary key
  "_id",
  // Indexed Keys
  "channel_id",
  "channel_name",
  "initial_installation_id",
  "customer_name",
  "vendor_name",
  "updated_at_date",
  // Multi-entry index
  "*csmUsers",
  "*solutionEngineers",
];

const CHANNEL_INDEXES = [
  // Primary key
  "_id",
  // Indexed Keys
  "id",
  "name",
  // Multi-entry index
  "*type",
  "updated_at_date",
];

const SUB_STATUS_INDEXES = [
  // Primary key
  "_id",
  // Indexed Keys
  "name",
  "parent",
  "installation",
  "updated_at_date",
];

const BASE_DB_SCHEMA = {
  requests: REQUEST_INDEXES.join(","),
  users: USER_INDEXES.join(","),
  relationships: RELATIONSHIP_INDEXES.join(","),
  channels: CHANNEL_INDEXES.join(","),
  subStatuses: SUB_STATUS_INDEXES.join(","),
  analytics: "analyticsKey",
  slackConversations: "_id",
  filters: "name",
  customFields: "hash, category_name",
  crmFields: "crm",
  views: "_id, name",
  customerContacts:
    "_id, installation_id, metadata.slack.id, user_id, updated_at_date",
  externalLinks: "_id, updated_at_date",
  meta: "name",
  tickets: TICKET_INDEXES.join(","),
  connectors: "name",
  ticketfilters: "name",
  internalThread: "_id",
  activities: "_id",
  campaigns: "_id, updated_at_date",
  campaignTemplates: "_id",
  webUsers: "_id",
  broadcastAnalytics: "id",
  macros: "_id",
};

export type ModelState = {
  started: boolean;
  completed: boolean;
  isErrored: boolean;
  updatedAt?: string;
};

export type GlobalStoreType = {
  boot: {
    model: {
      users: ModelState;
      requests: ModelState;
      tickets: ModelState;
      channels: ModelState;
      relationships: ModelState;
      subStatuses: ModelState;
      setup: ModelState;
      customer_contacts: ModelState;
      external_links: ModelState;
      campaigns: ModelState;
      campaignTemplates: ModelState;
      web_users: ModelState;
    };
  };
  worker: {
    status: "UNINITIALIZED" | "INITIALIZED" | "ERROR" | "DONE";
  };
  isDBIndexed: boolean;
  isSWActivated: boolean;
  progress: number;
  dbVersion: number;
  dbSchema: Record<string, string>;
  broadcastWorker: {
    status: "UNINITIALIZED" | "INITIALIZED" | "ERROR" | "DONE";
  };
  aiAssistants: {
    cs: string | null;
    ih: string | null;
  };
  aiAssistantSources: {
    cs: boolean;
    ih: boolean;
  };
};
type ActionType = {
  type:
    | "SET_WORKER_STATUS"
    | "SET_BOOT_MODEL_STATUS"
    | "SET_ACTIVE_WORKSPACE_ID"
    | "SET_DB_INDEXED_STATUS"
    | "SET_IS_SW_ACTIVATED"
    | "SET_PROGRESS"
    | "SET_DB_VERSION"
    | "SET_DB_SCHEMA"
    | "SET_BROADCAST_WORKER_STATUS"
    | "SET_AI_ASSISTANT_DATA"
    | "SET_AI_ASSISTANT_DATA_HAS_SOURCES";
  payload?: Record<string, unknown>;
};

const reducer = (
  state: GlobalStoreType,
  action: ActionType
): GlobalStoreType => {
  const { type, payload } = action;

  switch (type) {
    case "SET_WORKER_STATUS": {
      const status = payload?.status as GlobalStoreType["worker"]["status"];
      return {
        ...state,
        worker: { status },
      };
    }

    case "SET_BOOT_MODEL_STATUS": {
      const { model, status, statusValue } = payload as {
        model: keyof GlobalStoreType["boot"]["model"];
        status: keyof ModelState;
        statusValue: boolean;
      };

      return {
        ...state,
        boot: {
          ...state.boot,
          model: {
            ...state.boot.model,
            [model]: {
              ...state.boot.model[model],
              [status]: statusValue,
            },
          },
        },
      };
    }

    case "SET_DB_INDEXED_STATUS": {
      const isDBIndexed = payload?.isDBIndexed as boolean;
      return {
        ...state,
        isDBIndexed,
      };
    }

    case "SET_IS_SW_ACTIVATED": {
      const isSWActivated = payload?.isSWActivated as boolean;
      return {
        ...state,
        isSWActivated,
      };
    }

    case "SET_PROGRESS": {
      return {
        ...state,
        progress: payload?.progress as number,
      };
    }

    case "SET_DB_VERSION": {
      return {
        ...state,
        dbVersion: payload?.dbVersion as number,
      };
    }

    case "SET_DB_SCHEMA": {
      return {
        ...state,
        dbSchema: payload?.dbSchema as GlobalStoreType["dbSchema"],
      };
    }
    case "SET_AI_ASSISTANT_DATA": {
      return {
        ...state,
        aiAssistants: {
          ...state.aiAssistants,
          cs: payload?.cs as string,
          ih: payload?.ih as string,
        },
      };
    }

    case "SET_BROADCAST_WORKER_STATUS": {
      const status =
        payload?.status as GlobalStoreType["broadcastWorker"]["status"];
      return {
        ...state,
        broadcastWorker: { status },
      };
    }
    case "SET_AI_ASSISTANT_DATA_HAS_SOURCES": {
      return {
        ...state,
        aiAssistantSources: {
          ...state.aiAssistants,
          cs: payload?.cs as boolean,
          ih: payload?.ih as boolean,
        },
      };
    }

    default:
      return state;
  }
};

const initialState: GlobalStoreType = {
  boot: {
    model: {
      users: {
        started: false,
        completed: false,
        isErrored: false,
      },
      requests: {
        started: false,
        completed: false,
        isErrored: false,
      },
      tickets: {
        started: false,
        completed: false,
        isErrored: false,
        updatedAt: "",
      },
      channels: {
        started: false,
        completed: false,
        isErrored: false,
      },
      relationships: {
        started: false,
        completed: false,
        isErrored: false,
      },
      subStatuses: {
        started: false,
        completed: false,
        isErrored: false,
      },
      setup: {
        started: false,
        completed: false,
        isErrored: false,
      },
      customer_contacts: {
        started: false,
        completed: false,
        isErrored: false,
      },
      external_links: {
        started: false,
        completed: false,
        isErrored: false,
      },
      campaigns: {
        started: false,
        completed: false,
        isErrored: false,
      },
      campaignTemplates: {
        started: false,
        completed: false,
        isErrored: false,
        updatedAt: "",
      },
      web_users: {
        started: false,
        completed: false,
        isErrored: false,
        updatedAt: "",
      },
    },
  },
  worker: {
    status: "UNINITIALIZED",
  },
  isDBIndexed: false,
  isSWActivated: false,
  progress: 5,
  dbVersion: 1,
  dbSchema: BASE_DB_SCHEMA,
  broadcastWorker: {
    status: "UNINITIALIZED",
  },
  aiAssistants: {
    cs: null,
    ih: null,
  },
  aiAssistantSources: {
    cs: false,
    ih: false,
  },
};

const useGlobalStorePersist = create(
  persist(
    devtools(redux(reducer, initialState), {
      name: "useGlobalStorePersist",
    }),
    {
      name: "useGlobalStorePersist",
      version: 3.6,
      migrate: async () => {
        await clearBrowserStorage();
        window.location.reload();
      },
    }
  )
);

export { useGlobalStorePersist };
