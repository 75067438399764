import { SalesforceSetup } from "./SalesforceSetup";
import { AccountConfiguration } from "./AccountConfiguration";

type Props = {
  requestType: {
    label: string;
    value: string;
    disabled: boolean;
  }[];
};

const SalesforceIntegration = ({ requestType }: Props) => {
  return (
    <div>
      <SalesforceSetup requestType={requestType} />
      <AccountConfiguration />
    </div>
  );
};

export { SalesforceIntegration };
