import styled, { keyframes, withTheme } from "styled-components";

const progressAnimation = keyframes`
  0% {
    background-position: -150% 0, -150% 0;
  }
  66% {
    background-position: 250% 0, -150% 0;
  }
  100% {
    background-position: 250% 0, 250% 0;
  }
`;

const LoaderContainer = styled.div`
  height: 1px;
  width: 100%;
  background:
    linear-gradient(#6100ee, #6100ee) no-repeat,
    linear-gradient(#6100ee, #6100ee) no-repeat,
    ${({ theme }) => theme?.colors?.purpleLight || "#a1a1aa40"};
  background-size: 60% 100%;
  border-radius: 20px;
  animation: ${progressAnimation} 1.5s infinite;
`;

const ConversationLoaderProgress = () => {
  return <LoaderContainer />;
};

export default withTheme(ConversationLoaderProgress);
