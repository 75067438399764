import { cloneDeep } from "lodash";
import BaseDexie, { Table } from "dexie";
import { AnalyticsTableType } from "@/types/analyticsTypes";
import { ChannelType } from "@/types/channel";
import { FilterType } from "@/types/filters";
import { RelationshipType } from "@/types/relationships";
import {
  CustomFieldType,
  ExternalLink,
  MacroType,
  RequestType,
  SlackConversationType,
  SubStatusType,
  ViewsType,
} from "@/types/requests";
import { CustomerContactType, UserType } from "@/types/users";
import { useAuthStorePersist } from "@/store/authStorePersist";
import { CRMType } from "@/types/accountsTypes";
import { useGlobalStorePersist } from "@/store/globalStorePersist";
import { InternalThreadTableType } from "@/types/internalThreadTable";
import { ActivitiesTableType } from "@/types/activitiesTable";
import { TicketType } from "@/types/tickets";
import { ConnectorType } from "@/types/kanbanTypes";
import {
  CampaignTemplatesTableType,
  CampaignsTableType,
} from "@/types/campaignsTable";
import { WebUserType } from "@/types/webUsers";

export type RequestTable = {
  requests: Table<RequestType>;
};

export type TicketTable = {
  tickets: Table<TicketType>;
};

export type ConnectorTable = {
  connectors: Table<ConnectorType>;
};

export type UserTable = {
  users: Table<UserType>;
};

export type RelationshipTable = {
  relationships: Table<RelationshipType>;
};

export type ChannelTable = {
  channels: Table<ChannelType>;
};

export type SubStatusTable = {
  subStatuses: Table<SubStatusType>;
};

export type AnalyticsTable = {
  analytics: Table<AnalyticsTableType>;
};

export type SlackConversationTable = {
  slackConversations: Table<SlackConversationType>;
};

export type FilterTable = {
  filters: Table<FilterType>;
};

export type TicketFilterTable = {
  ticketfilters: Table<FilterType>;
};

export type CustomFieldTable = {
  customFields: Table<CustomFieldType>;
};

export type CrmFieldTable = {
  crmFields: Table<CRMType>;
};

export type ViewTable = {
  views: Table<ViewsType>;
};

export type CustomContactTable = {
  customerContacts: Table<CustomerContactType>;
};

export type ExternalLinksTable = {
  externalLinks: Table<ExternalLink>;
};

export type InternalThreadTable = {
  internalThread: Table<InternalThreadTableType>;
};

export type ActivitiesTable = {
  activities: Table<ActivitiesTableType>;
};

export type CampaignsTable = {
  campaigns: Table<CampaignsTableType>;
};

export type CampaignTemplatesTable = {
  campaignTemplates: Table<CampaignTemplatesTableType>;
};

export type WebUsersTable = {
  webUsers: Table<WebUserType>;
};

export type BroadcastAnalyticsTable = {
  broadcastAnalytics: Table<any>;
};

export type MacrosTable = {
  macros: Table<MacroType>;
};

export type ThenaTables = RequestTable &
  TicketTable &
  UserTable &
  RelationshipTable &
  ChannelTable &
  SubStatusTable &
  AnalyticsTable &
  SlackConversationTable &
  FilterTable &
  TicketFilterTable &
  CustomFieldTable &
  CrmFieldTable &
  ViewTable &
  CustomContactTable &
  ExternalLinksTable &
  InternalThreadTable &
  ActivitiesTable &
  ConnectorTable &
  CampaignsTable &
  CampaignTemplatesTable &
  WebUsersTable &
  BroadcastAnalyticsTable &
  MacrosTable;

export type DexieType<T = ThenaTables> = BaseDexie & T;

const REQUEST_INDEXES = [
  // Primary key
  "_id",
  // Indexed Keys
  "status",
  "request_id",
  "installation_id",
  "installation_team_id",
  "message_details_ts",
  "relationship_id",
  "assigned_by_user_id",
  "assigned_to_user_id",
  "ai_metadata.sentiment",
  "ai_metadata.urgency",
  "original_message_source",
  "original_message_user_id",
  "created_at",
  "updated_at",
  "sub_status",
  "created_at_date",
  "urgencyOrder",
  "internalHelpDeskIndex",
  "firstResponseSlaOrder",
  "finalResolutionSlaOrder",
  "lastCustomerReply",
  "slaBreached",
  "lastCustomerReplyOrder",
  "lastReplyOrder",
  "updated_at_date",
  "escalation_type",
  // Multi-entry index
  "*ai_metadata.tags",
];

const TICKET_INDEXES = [
  "_id",
  "relationship_id",
  "user",
  "created_at",
  "created_at_date",
  "updated_at_date",
  "status",
  "assignee_id",
];

const USER_INDEXES = [
  // Primary key
  "_id",
  // Indexed Keys
  "initial_installation_id",
  "name",
  "updated_at_date",
  "slack_id",
];

const RELATIONSHIP_INDEXES = [
  // Primary key
  "_id",
  // Indexed Keys
  "channel_id",
  "channel_name",
  "initial_installation_id",
  "customer_name",
  "vendor_name",
  "updated_at_date",
  // Multi-entry index
  "*csmUsers",
  "*solutionEngineers",
];

const CHANNEL_INDEXES = [
  // Primary key
  "_id",
  // Indexed Keys
  "id",
  "name",
  // Multi-entry index
  "*type",
  "updated_at_date",
];

const SUB_STATUS_INDEXES = [
  // Primary key
  "_id",
  // Indexed Keys
  "name",
  "parent",
  "installation",
  "updated_at_date",
];

const dbObject: {
  thenaDB: DexieType | null;
} = {
  thenaDB: null,
};

const getThenaDB = () => {
  return dbObject.thenaDB!;
};

const updateThenaDB = (db: DexieType) => {
  dbObject.thenaDB = db;
};

const BASE_DB_SCHEMA = {
  requests: REQUEST_INDEXES.join(","),
  users: USER_INDEXES.join(","),
  relationships: RELATIONSHIP_INDEXES.join(","),
  channels: CHANNEL_INDEXES.join(","),
  subStatuses: SUB_STATUS_INDEXES.join(","),
  analytics: "analyticsKey",
  slackConversations: "_id",
  filters: "name",
  customFields: "hash, category_name",
  crmFields: "crm",
  views: "_id, name",
  customerContacts:
    "_id, installation_id, metadata.slack.id, user_id, updated_at_date",
  externalLinks: "_id, updated_at_date",
  meta: "name",
  internalThread: "_id",
  activities: "_id",
  tickets: TICKET_INDEXES.join(","),
  ticketfilters: "name",
  connectors: "name",
  campaigns: "_id, updated_at_date",
  campaignTemplates: "_id",
  webUsers: "_id",
  broadcastAnalytics: "id",
  macros: "_id",
};

async function addCustomIndexes(
  tableName: string,
  indexList: string[],
  modifier: (record: any) => any
) {
  if (getThenaDB().isOpen()) {
    getThenaDB().close();
  }

  const currentVersion = getThenaDB().verno;
  const nextVersion = currentVersion + 1;

  const currentSchema = getThenaDB().tables.reduce(
    (result, { name, schema }) => {
      result[name] = [
        schema.primKey.src,
        ...schema.indexes.map((idx) => idx.src),
      ].join(",");
      return result;
    },
    {}
  );

  const newSchema = cloneDeep(currentSchema);
  newSchema[tableName] = currentSchema[tableName] + "," + indexList.join(",");

  useGlobalStorePersist.dispatch({
    type: "SET_DB_VERSION",
    payload: {
      dbVersion: nextVersion,
    },
  });

  useGlobalStorePersist.dispatch({
    type: "SET_DB_SCHEMA",
    payload: {
      dbSchema: newSchema,
    },
  });

  getThenaDB()
    .version(nextVersion)
    .stores(newSchema)
    .upgrade((trans) => {
      return trans
        .table(tableName)
        .toCollection()
        .modify((record) => modifier(record));
    });

  return getThenaDB().open();
}

const getDBInfo = () => {
  try {
    const { id, team_id } = useAuthStorePersist.getState().user;
    const dbName = `ThenaDB_${id}_${team_id}`;
    const schema = getThenaDB().tables.reduce((result, { name, schema }) => {
      result[name] = [
        schema.primKey.src,
        ...schema.indexes.map((idx) => idx.src),
      ].join(",");
      return result;
    }, {});
    const version = getThenaDB().verno;
    return { dbName, schema, version };
  } catch (error) {
    console.log("DB Info Error", error);
    return { dbName: "", schema: {}, version: 1 };
  }
};

export {
  addCustomIndexes,
  getThenaDB,
  updateThenaDB,
  BASE_DB_SCHEMA,
  getDBInfo,
};
