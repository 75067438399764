import { capitalize } from "lodash";
import { ChevronRight, Menu } from "lucide-react";
import { useMemo } from "react";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";

import SavedViewsDropdown from "@/components/RequestsHeader/components/savedViews";
import IconButton from "@/components/common/IconButton";
import When from "@/components/common/When";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
} from "@/components/ui/breadcrumb";
import { Separator } from "@/components/ui/separator";
import { BreadcrumbItemType, NameWithLink, breadcrumbs } from "@/constants";
import useAudienceStorePersist from "@/pages/SlackBroadCasting/store/useAudienceStorePersist";
import { useLayoutStorePersist } from "@/store/layoutStorePersist";
import { getIsCustomerPortal } from "@/utils/domUtils";

type Props = {
  expandSidebar: () => void;
};

function isNameWithLink(item: BreadcrumbItemType): item is NameWithLink {
  return (item as NameWithLink).href !== undefined;
}

function BreadCrumbListItem({
  item,
  id,
  length,
}: {
  item: BreadcrumbItemType;
  id: number;
  length: number;
}) {
  const isUnsend = useAudienceStorePersist((state) => state.isUnsend);
  if (isUnsend && (item as NameWithLink)?.name === "Editor") {
    return null;
  }

  return (
    <BreadcrumbItem>
      <div className="flex items-center gap-2">
        <BreadcrumbLink asChild>
          {isNameWithLink(item) ? (
            <NavLink to={item.href}>
              <span className="text-base">{item.name}</span>
            </NavLink>
          ) : (
            <span className="text-base">{item}</span>
          )}
        </BreadcrumbLink>
        {id !== length - 1 && <ChevronRight size="14" />}
      </div>
    </BreadcrumbItem>
  );
}

function Header({ expandSidebar }: Props) {
  const { pathname } = useLocation();
  const searchParams = useSearchParams()[0];
  const currentView = searchParams.get("view");
  const isCollapsed = useLayoutStorePersist((state) => state.isCollapsed);

  const isCustomerPortal = getIsCustomerPortal();

  const getDynamicBreadCrumb = () => {
    const pathSegments = location.pathname
      .split("/")
      .filter((segment) => segment);
    return pathSegments;
  };

  const links = useMemo(() => {
    const id: any = Object.keys(breadcrumbs)?.find((links) =>
      links.includes("/slack-broadcasting/analytics")
        ? getDynamicBreadCrumb()
        : links === pathname
    );
    return breadcrumbs[id];
  }, [pathname]);

  return (
    <>
      <div className="flex py-2 px-4 min-h-11 gap-3">
        {isCollapsed && (
          <IconButton
            Icon={Menu}
            delayDuration={500}
            tooltip="Open Sidebar"
            onClick={() => expandSidebar()}
          />
        )}

        <When condition={!isCustomerPortal}>
          {!currentView ? (
            <Breadcrumb className="flex">
              <BreadcrumbList>
                <div className="flex gap-2 [&>*:first-child]:text-foreground">
                  {links?.map((item, id) => {
                    return (
                      <BreadCrumbListItem
                        item={item}
                        key={id}
                        id={id}
                        length={links.length}
                      />
                    );
                  })}
                </div>
              </BreadcrumbList>
            </Breadcrumb>
          ) : (
            <Breadcrumb className="flex">
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink asChild>
                    <span className="text-color-text text-base">Views</span>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <div className="flex items-center gap-2 text-[var(--color-icon-muted)]">
                    <ChevronRight size="14" />
                    <SavedViewsDropdown />
                  </div>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          )}
        </When>

        <When condition={isCustomerPortal}>
          {pathname === "/user-preferences"
            ? "Preferences"
            : pathname === "/members"
              ? "Users"
              : capitalize(pathname.split("/")[1])}
        </When>
      </div>
      <Separator />
    </>
  );
}

export default Header;
