import ThenaImg from "@/assets/logos/thena-black.svg";

const ThenaLoader = ({ className = "" }: { className?: string }) => {
  return (
    <div
      className={`h-full w-full flex items-center justify-center gap-4 ${className}`}
    >
      <div className="relative rounded-full">
        <div className="h-[4rem] w-[4rem] rounded-full bg-[var(--color-bg-loader)] shadow-[0px_0px_37px_-20px_#1E293B]  flex items-center justify-center animate-pulse" />
        <img
          src={ThenaImg}
          alt="loading"
          className="dark:invert dark:filter dark:brightness-0 h-[1.7rem] w-[1.7rem] absolute top-[20px]  right-[19px] bottom-[20px] m-auto"
        />
      </div>
    </div>
  );
};

export default ThenaLoader;
