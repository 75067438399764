import { ChevronDown, Reply, ReplyAll, X, icons } from "lucide-react";
import { useEffect, useState, useRef } from "react";
import { Thread } from "@/types/kanbanTypes";
import { Lane } from "./Lane";
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from "@/components/ui/menubar";
import { Button } from "@/components/ui/button";
import { compact, set, uniq } from "lodash";

const validateEmail = (inputText: string) => {
  const mailFormat = /\S+@\S+\.\S+/;
  if (inputText.match(mailFormat)) {
    return true;
  }
  return false;
};
const InputBox = ({
  emails,
  setEmails,
}: {
  emails: string[];
  setEmails: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const insertNewEmail = (email: string) => {
    if (!validateEmail(email)) {
      return;
    }
    if (ref.current) ref.current.value = "";
    setEmails((prev) => [...prev, email]);
  };
  return (
    <input
      ref={ref}
      className="w-1 grow text-sm border-none outline-none focus:outline-none text-[var(--color-text)] bg-transparent"
      onKeyDown={(e) => {
        if (e.key === " ") {
          const email = (e.target as any).value;
          insertNewEmail(email);
        }
      }}
      onBlur={(e) => {
        const email = (e.target as any).value;
        insertNewEmail(email);
      }}
    />
  );
};

const EmailToolbar = ({
  item,
  ccEmails,
  setCcEmails,
  bccEmails,
  setBccEmails,
  toEmails,
  setToEmails,
}: {
  item: any;
  ccEmails: string[];
  setCcEmails: React.Dispatch<React.SetStateAction<string[]>>;
  bccEmails: string[];
  setBccEmails: React.Dispatch<React.SetStateAction<string[]>>;
  toEmails: string[];
  setToEmails: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [ccLane, setCcLane] = useState(false);
  const [bccLane, setBccLane] = useState(false);
  const [replyState, setReplyState] = useState("");
  const replyAllRanWithEmailBlockPresent = useRef(false);

  const setReplyToFromLastMostCustomerEmail = () => {
    setReplyState("reply");
    let lastEmail;
    if (item?.childReplies?.length > 0) {
      lastEmail = item?.childReplies[item?.childReplies?.length - 1]?.email;
    } else {
      lastEmail = item?.email;
    }
    const lastFromEmail = lastEmail?.from?.email || "";
    const setupEmail = lastEmail?.setupEmail || "";
    const replyTo = lastEmail?.replyTo || [];
    const replyToExits = replyTo?.length > 0 && replyTo[0] !== null;
    const allToOfLastEmail = lastEmail?.to?.map((it) => it.email) || [];
    setCcEmails(() => compact(uniq([setupEmail])));
    if (lastFromEmail === setupEmail) {
      //last email from vendor themself
      setToEmails(() => compact(uniq([...allToOfLastEmail])));
    } else {
      if (replyToExits) {
        setToEmails(() => compact(uniq([...replyTo])));
      } else {
        setToEmails(() => compact(uniq([lastFromEmail])));
      }
    }
  };

  useEffect(() => {
    if (replyAllRanWithEmailBlockPresent.current) {
      return;
    }
    if (item.email) {
      replyAllRanWithEmailBlockPresent.current = true;
      handleReplyAllDropdown();
    }
  }, [item]);

  // useEffect(() => {
  //   if (ccEmails?.length > 0) {
  //     setCcLane(true);
  //   }
  //   if (ccEmails?.length === 0) {
  //     setCcLane(false);
  //   }
  // }, [ccEmails]);

  const clearEmailEditor = () => {
    setCcEmails([]);
    setCcLane(false);
    setBccEmails([]);
    setBccLane(false);
  };

  const handleReplyDropdown = () => {
    setReplyToFromLastMostCustomerEmail();
  };

  const handleReplyAllDropdown = () => {
    setReplyState("reply_all");
    let lastEmail;
    if (item?.childReplies?.length > 0) {
      lastEmail = item?.childReplies[item?.childReplies?.length - 1]?.email;
    } else {
      lastEmail = item?.email;
    }
    const lastFromEmail = lastEmail?.from?.email || "";
    const setupEmail = lastEmail?.setupEmail || "";
    const replyTo = lastEmail?.replyTo || [];
    const replyToExits = replyTo?.length > 0 && replyTo[0] !== null;
    const allToOfLastEmail = lastEmail?.to?.map((it) => it.email) || [];
    const allCcOfLastEmail = lastEmail?.cc || [];
    if (lastFromEmail === setupEmail) {
      //last email from vendor themself
      setToEmails(() => compact(uniq([...allToOfLastEmail])));
      setCcEmails(() => compact(uniq([...allCcOfLastEmail])));
    } else {
      setCcEmails(() =>
        compact(uniq([setupEmail, ...allCcOfLastEmail, ...allToOfLastEmail]))
      );
      if (replyToExits) {
        setToEmails(() => compact(uniq([...replyTo])));
      } else {
        setToEmails(() => compact(uniq([lastFromEmail])));
      }
    }
  };

  return (
    <div className="flex border-b p-0.5">
      <Menubar className="border-none">
        <MenubarMenu>
          <MenubarTrigger className="h-9 ">
            {replyState === "reply" ? (
              <Reply size={16} />
            ) : (
              <ReplyAll size={16} />
            )}
            <ChevronDown className="ml-2" size={16} />
          </MenubarTrigger>
          <MenubarContent className=" ">
            <MenubarItem
              onClick={() => {
                handleReplyDropdown();
              }}
              className=" "
            >
              <Reply size={16} className="mr-2" />
              Reply
            </MenubarItem>
            <MenubarItem
              onClick={() => {
                handleReplyAllDropdown();
              }}
              className=""
            >
              <ReplyAll size={16} className="mr-2" />
              Reply all
            </MenubarItem>
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
      <div className="flex flex-col grow">
        {/* To Emails starts */}
        <div className="h-[36px] flex items-center">
          <span className="text-[var(--color-icon-muted)] text-sm mr-2 w-6">
            To
          </span>
          <Lane emails={toEmails} setEmails={setToEmails} />
          <InputBox emails={toEmails} setEmails={setToEmails} />
          {ccEmails.length === 0 && (
            <Button
              size={"sm"}
              variant={"ghost"}
              onClick={() => setCcLane((prev) => !prev)}
              className="mt-1"
            >
              Cc
            </Button>
          )}
          {bccEmails.length === 0 && (
            <Button
              size={"sm"}
              variant={"ghost"}
              onClick={() => setBccLane((prev) => !prev)}
              className="mr-1 mt-1"
            >
              Bcc
            </Button>
          )}
        </div>
        {/* To Emails ends */}
        {/* {CC emails starts} */}
        {(ccLane || ccEmails.length > 0) && (
          <div className="h-[36px] flex items-center">
            <span className="text-[var(--color-icon-muted)] text-sm mr-2 w-6">
              Cc
            </span>
            <Lane emails={ccEmails} setEmails={setCcEmails} />
            <InputBox emails={ccEmails} setEmails={setCcEmails} />
          </div>
        )}
        {/* {CC emails ends} */}
        {/* {Bcc emails starts} */}
        {bccLane && (
          <div className="h-[36px] flex items-center">
            <span className="text-[var(--color-icon-muted)] text-sm mr-2 w-6">
              Bcc
            </span>
            <Lane emails={bccEmails} setEmails={setBccEmails} />
            <InputBox emails={bccEmails} setEmails={setBccEmails} />
          </div>
        )}
        {/* {Bcc emails ends} */}
      </div>
    </div>
  );
};

export { EmailToolbar };
