import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { fetchFreshdeskIntegration } from "../../../api/integrations";
import { helphubUtil } from "../../../utils/helphub";
import { useConnectedApp } from "../../ConnectedApps";
import { ExtendedConnectorConfiguration } from "../ConnectorConfiguration";
import { useFreshdesk } from "./FreshdeskProvider";
import {
  ChevronsUpDown,
  CircleCheck,
  Loader2,
  TriangleAlert,
} from "lucide-react";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";

type Props = {
  requestType: {
    label: string;
    value: string;
    disabled: boolean;
  }[];
};

export const FreshdeskSetUp = ({ requestType }: Props) => {
  const { isEdition } = useConnectedApp();
  const [isOpen, setIsOpen] = useState(false);
  const {
    isSetUpOpen,
    connectionPassed,
    connectionFailed,
    saveRequestTypesMutation,
    testFreshdeskConnectionMutation,
    setIsSetUpOpen,
  } = useFreshdesk();

  const { data: freshdeskConfiguration, isLoading } = useQuery({
    queryKey: ["fetch-freshdesk-integration"],
    queryFn: fetchFreshdeskIntegration,
    select: (data) => {
      return {
        apiKey: data?.apiKey,
        baseurl: data?.baseurl,
        requestTypes: data?.requestTypes,
        helpdesk_name: data?.helpdesk_name,
      };
    },
  });

  const onSubmit = (data: any) => {
    testFreshdeskConnectionMutation?.mutate({
      apiKey: data.apiKey,
      baseurl: data.baseurl,
      requestTypes: data.requestTypes,
      helpdesk_name: data.helpdesk_name,
    });
  };

  const onSave = (data: any) => {
    saveRequestTypesMutation?.mutate({
      apiKey: data.apiKey,
      baseurl: data.baseurl,
      requestTypes: data.requestTypes,
      helpdesk_name: data.helpdesk_name,
    });
  };

  const { handleSubmit, reset, control, formState } = useForm();

  useEffect(() => {
    reset(freshdeskConfiguration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freshdeskConfiguration]);

  const { errors, isDirty } = formState;
  const disableSave =
    (!isEdition && !connectionPassed) ||
    (isEdition && isDirty && !connectionPassed);

  // Loader
  if (isLoading)
    return (
      <div className="w-full h-96 flex justify-center items-center">
        <Loader2 size="24" className="animate-spin" />
      </div>
    );

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="border border-solid border-brand-border rounded-lg"
      onChange={() => {
        setIsSetUpOpen((p) => !p);
      }}
    >
      <CollapsibleTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="flex gap-3 w-full justify-start"
        >
          <ChevronsUpDown className="h-4 w-4" />
          <h4 className="text-sm 3xl:text-[length:var(--sm)]">
            Setup Integration
          </h4>
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent className="space-y-2" key="1">
        <div className="p-4">
          <div className="flex items-center justify-end">
            <p
              className="text-primary m-0 cursor-pointer"
              onClick={() => helphubUtil(133832)}
              role="button"
              data-intercom-target="Settings Integrations Salesforce Setup"
            >
              Where do I find this?
            </p>
          </div>
          <div className="flex flex-col gap-3">
            <div className="flex flex-col gap-2">
              <span className="font-medium">Helpdesk Name</span>
              <Controller
                name="helpdesk_name"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    className="mt-1"
                    placeholder="Helpdesk Name"
                    disabled={connectionPassed}
                    {...field}
                    required
                    // status={errors.helpdesk_name ? 'error' : undefined}
                  />
                )}
              />
              {Boolean(errors.helpdesk_name) && (
                <div className="font-small text-red-500">
                  {errors?.helpdesk_name?.type === "required" &&
                    "Helpdesk Name is required"}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-medium">API Key</span>
              <Controller
                name="apiKey"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    className="mt-1"
                    placeholder="API Key"
                    disabled={connectionPassed}
                    {...field}
                    required
                    // status={errors.apiKey ? 'error' : undefined}
                  />
                )}
              />
              {Boolean(errors.apiKey) && (
                <div className="font-small text-red-500">
                  {errors?.apiKey?.type === "required" && "API Key is required"}
                </div>
              )}
            </div>
            <div className="space-y-4 flex flex-col gap-2">
              <div>
                <span className="font-medium">Base URL</span>
                <div className="flex space-x-2 mt-1">
                  <div className="w-full">
                    <Controller
                      name="baseurl"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <Input
                          placeholder="Base URL"
                          className="mt-1"
                          {...field}
                          // status={
                          //   connectionFailed || Boolean(errors.baseurl)
                          //     ? 'error'
                          //     : undefined
                          // }
                          disabled={connectionPassed}
                          required
                        />
                      )}
                    />
                    {Boolean(errors.baseurl) && (
                      <div className="font-small text-red-500">
                        {errors?.baseurl?.type === "required" &&
                          "Base Url is required"}
                      </div>
                    )}
                  </div>
                  <Button
                    variant={"outline"}
                    className="text-primary border-primary/40 mt-1 flex items-center"
                    onClick={handleSubmit(onSubmit)}
                    disabled={connectionPassed}
                  >
                    {testFreshdeskConnectionMutation?.isPending && (
                      <Loader2 className="animate-spin" />
                    )}
                    Test connection
                  </Button>
                </div>
                <div className="flex space-x-2 mt-1">
                  {connectionPassed && (
                    <span className="flex items-center">
                      <CircleCheck className="mr-1" size={14} color="#2BBC58" />{" "}
                      <div className="text-xs text-success font-medium">
                        Connection Successful!
                      </div>
                    </span>
                  )}
                  {connectionFailed && !connectionPassed && (
                    <span className="flex items-center">
                      <TriangleAlert
                        className="mr-1"
                        size={14}
                        color="#D91029"
                      />{" "}
                      <div className="text-xs text-error font-medium">
                        Test connection failed!
                      </div>
                    </span>
                  )}
                  {!connectionFailed && !connectionPassed && (
                    <div className="text-xs text-color-icon-muted font-medium">
                      Testing connection required
                    </div>
                  )}
                </div>
              </div>
              <div className="col-span-2">
                <div className="font-medium">Request type(s) to send...</div>
                <div className="mt-3 w-full">
                  <Controller
                    name="requestTypes"
                    control={control}
                    render={({ field }) => (
                      <div {...field} className="flex gap-12">
                        {requestType.map((el) => (
                          <div className="flex items-center gap-2">
                            <Checkbox
                              key={el.value}
                              value={el.value}
                              id={el.value}
                              disabled={disableSave || el.disabled}
                            />

                            <label htmlFor={el.value} className="mr-2 text-sm font-normal">
                              {el.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="w-full flex justify-end ">
                <Button
                  variant={"outline"}
                  className="text-primary border-primary/40 mt-4 mb-1 font-bold px-8"
                  disabled={disableSave}
                  onClick={handleSubmit(onSave)}
                >
                  {saveRequestTypesMutation?.isPending && (
                    <Loader2 className="animate-spin" />
                  )}
                  Save
                </Button>
              </div>
              <ExtendedConnectorConfiguration
                isEdition={isEdition}
                className="mb-8"
              />
            </div>
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};
