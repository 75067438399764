
import React, { useContext, useState } from 'react'
import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query'
import { testFreshdeskConnection } from '../../../api/integrations'
import { useConnectedApp } from '../../ConnectedApps'
import { FreshdeskTestConnectionPayloadType } from '../definitions'
import { useToast } from '@/components/ui/use-toast'

interface FreshdeskContextType {
  isSetUpOpen: boolean
  connectionPassed: boolean
  connectionFailed: boolean
  setIsSetUpOpen: React.Dispatch<React.SetStateAction<boolean>>
  setConnectionPassed: React.Dispatch<React.SetStateAction<boolean>>
  setConnectionFailed: React.Dispatch<React.SetStateAction<boolean>>
  isTestingConnection: boolean
  testFreshdeskConnectionMutation?: UseMutationResult<
    any,
    unknown,
    FreshdeskTestConnectionPayloadType,
    unknown
  >
  saveRequestTypesMutation?: UseMutationResult<
    any,
    unknown,
    FreshdeskTestConnectionPayloadType,
    unknown
  >
}

const FreshdeskContext = React.createContext<FreshdeskContextType>({
  isSetUpOpen: false,
  connectionPassed: false,
  connectionFailed: false,
  isTestingConnection: false,
  setIsSetUpOpen: () => null,
  setConnectionPassed: () => null,
  setConnectionFailed: () => null,
})

export const FreshdeskProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const queryClient = useQueryClient()
  const { isEdition } = useConnectedApp()
  const [connectionPassed, setConnectionPassed] = useState<boolean>(false)
  const [connectionFailed, setConnectionFailed] = useState<boolean>(false)
  const [isSetUpOpen, setIsSetUpOpen] = useState<boolean>(true)
  const {toast} = useToast()
  // Test Freshdesk configuration
  const testFreshdeskConnectionMutation = useMutation( {
    mutationFn: testFreshdeskConnection,
    onSuccess: () => {
      setConnectionPassed(true)
      setConnectionFailed(false)
    },
    onError: () => {
      setConnectionPassed(false)
      setConnectionFailed(true)
    },
  })

  // Added same mutation as testFreshdeskConnectionMutation to avoid unnecessary checks and balances
  const saveRequestTypesMutation = useMutation( {
    mutationFn: testFreshdeskConnection,
    onSuccess: () => {
      setIsSetUpOpen(false)
      setConnectionPassed(true)
      setConnectionFailed(false)
      toast({
        title: 'Successfully saved Freshdesk configuration.',
      })
      queryClient.invalidateQueries({queryKey: ['fetch-freshdesk-integration']})
    },
    onError: () => {
      setConnectionPassed(false)
      setConnectionFailed(true)
    },
  })

  return (
    <FreshdeskContext.Provider
      value={{
        isSetUpOpen,
        connectionPassed,
        connectionFailed,
        isTestingConnection: testFreshdeskConnectionMutation?.isPending,
        setIsSetUpOpen,
        setConnectionPassed,
        setConnectionFailed,
        testFreshdeskConnectionMutation,
        saveRequestTypesMutation,
      }}
    >
      {children}
    </FreshdeskContext.Provider>
  )
}

export const useFreshdesk = () => {
  return useContext(FreshdeskContext)
}
