import { create } from "zustand";
import { devtools, redux } from "zustand/middleware";
import { AuthenticatedConnectUser } from "../../paragon";

interface ParagonState {
  paragonUser: AuthenticatedConnectUser | undefined;
  loading: boolean;
  paragon: any;
}

const paragonReducer = (
  state: ParagonState,
  action: { type: string; payload: any }
): ParagonState => {
  const { type, payload } = action;

  switch (type) {
    case "SET_PARAGON":
      return {
        ...state,
        paragonUser: payload?.paragonUser,
        loading: payload?.loading,
        paragon: payload?.paragon,
      };

    default:
      return state;
  }
};

const paragonInitialState: ParagonState = {
  paragonUser: undefined,
  loading: false,
  paragon: undefined,
};

const useParagonStore = create(
  devtools(redux(paragonReducer, paragonInitialState), {
    name: "useParagonStore",
  })
);

export { useParagonStore };
