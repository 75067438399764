import { create } from "zustand";

let isKanbanLoadTimeCalculated = false;

interface StateType {
  duckDBInitCount: number;
  analyticsInitLoading: boolean;
  isKanbanReady: boolean;
  broadcastTab: string;
  kanbanLoadTime: number;
  setIsKanbanReady: (data: boolean) => void;
  setBroadcastTab: (data: string) => void;
  isWindowVisible: boolean;
  setIsWindowVisible: (data: boolean) => void;
  enableSlackConversation: boolean;
  setEnableSlackConversation: (data: boolean) => void;
  enableInternalThreadConversation: boolean;
  setEnableInternalThreadConversation: (data: boolean) => void;
  customStatusesFetched: boolean;
  setCustomStatusesFetched: (data: boolean) => void;
  setAnalyticsInitLoading: (data: boolean) => void;
  setDuckDBInitCount: (data: number) => void;
  formDirtyMap: Record<string, boolean>;
  setFormDirtyMap: (data: Record<string, boolean>) => void;
}

export const useGlobalStore = create<StateType>((set) => ({
  formDirtyMap: {},
  duckDBInitCount: 0,
  analyticsInitLoading: true,
  isKanbanReady: false,
  broadcastTab: "scheduled",
  kanbanLoadTime: performance.now(),
  isWindowVisible: true,
  enableSlackConversation: true,
  enableInternalThreadConversation: true,
  setIsKanbanReady: function (data) {
    if (!isKanbanLoadTimeCalculated) {
      const diff = performance.now() - this.kanbanLoadTime;
      set({
        isKanbanReady: data,
        kanbanLoadTime: isKanbanLoadTimeCalculated ? this.kanbanLoadTime : diff,
      });
      console.log("Kanban Load Time: ", diff);
      isKanbanLoadTimeCalculated = true;
    }
  },
  setIsWindowVisible: (data) => set({ isWindowVisible: data }),
  setBroadcastTab: (data) => set({ broadcastTab: data }),
  setEnableSlackConversation: (data) => set({ enableSlackConversation: data }),
  setEnableInternalThreadConversation: (data) =>
    set({ enableInternalThreadConversation: data }),
  customStatusesFetched: false,
  setCustomStatusesFetched: (data) => set({ customStatusesFetched: data }),
  setAnalyticsInitLoading: (data) => set({ analyticsInitLoading: data }),
  setDuckDBInitCount: (data) => set({ duckDBInitCount: data }),
  setFormDirtyMap: (data) => set({ formDirtyMap: data }),
}));
