import React, { useContext, useState } from 'react'

interface ConnectedAppContextType {
  isEdition: boolean
  selectedIntegration: string | null
  setIsEdition: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedIntegration: React.Dispatch<React.SetStateAction<string | null>>
}

const ConnectedAppContext = React.createContext<ConnectedAppContextType>({
  isEdition: false,
  setIsEdition: () => null,
  selectedIntegration: null,
  setSelectedIntegration: () => null,
})

export const ConnectedAppProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [isEdition, setIsEdition] = useState<boolean>(false)
  const [selectedIntegration, setSelectedIntegration] = useState<string | null>(
    null
  )
  return (
    <ConnectedAppContext.Provider
      value={{
        isEdition,
        setIsEdition,
        selectedIntegration,
        setSelectedIntegration,
      }}
    >
      {children}
    </ConnectedAppContext.Provider>
  )
}

export const useConnectedApp = () => {
  return useContext(ConnectedAppContext)
}
