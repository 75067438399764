export const API_URL = process.env.REACT_APP_LOGIN_URL;
export const LOGIN_URL = `${API_URL}/login`;
export const LOGOUT_URL = `${API_URL}/logout`;
export const CUSTOMER_LOGIN_URL = `${API_URL}/c/login`;
export const CUSTOMER_LOGOUT_URL = `${API_URL}/c/logout`;

export type NameWithLink = {
  name: string;
  href: string;
};

export type Name = string;

export type BreadcrumbItemType = Name | NameWithLink;

type Breadcrumbs = {
  [route: string]: BreadcrumbItemType[];
};

export const breadcrumbs: Breadcrumbs = {
  "/requests": ["Customer support", "Requests"],
  "/snippets": ["Customer support", "Snippets"],
  "/tickets": ["Customer support", "Tickets"],
  "/internal-helpdesk/tickets": ["Internal helpdesk", "Tickets"],
  "/saved-views": ["Customer support", "Saved views"],
  "/internal-views": ["Internal helpdesk", "Saved views"],
  "/external-links": ["Customer support", "External links"],
  "/analytics": ["Customer support", "Analytics"],
  "/request": ["Internal helpdesk", "Requests"],
  "/configuration": ["Configuration"],
  "/configuration/settings": ["Configuration", "Settings"],
  "/configuration/slack": ["Configuration", "Slack"],
  "/configuration/slack/add-thena": ["Configuration", "Slack", "Add Thena"],
  "/configuration/slack/configure": ["Configuration", "Slack", "Settings"],
  "/configuration/integration": ["Configuration", "Integrations"],
  "/configuration/adminsettings": ["Configuration", "Admin settings"],
  "/configuration/apikey": ["Configuration", "Api key"],
  "/configuration/email": ["Configuration", "Email"],
  "/configuration/web-chat": ["Configuration", "Web chat"],
  "/configuration/crm-connect": ["Configuration", "CRM"],
  "/configuration/ms-teams": ["Configuration", "Microsoft Teams"],
  "/user-preferences": ["Preferences"],
  "/admin": ["Configuration", "Admin"],
  "/configuration/setup": ["Configuration", "Setup"],
  "/configuration/accounts": ["Configuration", "Accounts"],
  "/salesforce-cases-integration": [
    "Configuration",
    { name: "Integrations", href: "/configuration/integration" },
    "Salesforce Cases",
  ],
  "/configuration/accounts/configure": [
    "Configuration",
    { name: "Accounts", href: "/configuration/accounts" },
    "Settings",
  ],
  "/configuration/integration/zendesk": [
    "Configuration",
    { name: "Integrations", href: "/configuration/integration" },
    "Zendesk",
  ],
  "/slack-broadcasting": [
    "Engagement",
    { name: "Broadcasts", href: "/slack-broadcasting" },
  ],
  "/slack-broadcasting/audience": [
    "Engagement",
    { name: "Broadcasts", href: "/slack-broadcasting" },
    { name: "Manage audiences", href: "/slack-broadcasting/audience" },
  ],
  "/slack-broadcasting/audience/create": [
    "Engagement",
    { name: "Broadcasts", href: "/slack-broadcasting" },
    { name: "Manage audiences", href: "/slack-broadcasting/audience" },
    "New audience list",
  ],
  "/slack-broadcasting/audience/edit": [
    "Engagement",
    { name: "Broadcasts", href: "/slack-broadcasting" },
    { name: "Manage audiences", href: "/slack-broadcasting/audience" },
    "Edit",
  ],
  "/slack-broadcasting/template-list": [
    "Engagement",
    { name: "Broadcasts", href: "/slack-broadcasting" },
    { name: "Template list", href: "/slack-broadcasting/template-list" },
  ],
  "/slack-broadcasting/select-accounts": [
    "Engagement",
    { name: "Broadcasts", href: "/slack-broadcasting" },
    { name: "Editor", href: "/slack-broadcasting/editor" },
    "Select accounts",
  ],
  "/slack-broadcasting/editor": [
    "Engagement",
    { name: "Broadcasts", href: "/slack-broadcasting" },
    "Editor",
  ],
  "/slack-broadcasting/send": [
    "Engagement",
    { name: "Broadcasts", href: "/slack-broadcasting" },
    { name: "Editor", href: "/slack-broadcasting/editor" },
    { name: "Select accounts", href: "/slack-broadcasting/select-accounts" },
    "Send",
  ],
  "/customer-support/ai-assist": ["Customer support", "AI assist"],
  "/internal-helpdesk/ai-assist": ["Internal helpdesk", "AI assist"],
  "/slack-broadcasting/analytics": [
    "Engagement",
    { name: "Broadcasts", href: "/slack-broadcasting" },
    "Analytics",
  ],
};
