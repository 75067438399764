import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { ENABLED_ROUTES } from "./useHeartbeatInit";

const useIsEnabledRoute = () => {
  const location = useLocation();

  const isEnabledRoute = useMemo(() => {
    return ENABLED_ROUTES.some((path) => location.pathname.includes(path));
  }, [location.pathname]);

  return isEnabledRoute;
};

export default useIsEnabledRoute;
