import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { truncate } from "lodash";
import { X } from "lucide-react";

type Props = {
  emails: string[];
  setEmails: React.Dispatch<React.SetStateAction<string[]>>;
};

const TooltipMaker = ({ children, text }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className="cursor-pointer">{children}</div>
        </TooltipTrigger>
        <TooltipContent>{text}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const Email = ({
  email,
  setEmails,
}: {
  email: string;
  setEmails: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  return (
    <TooltipMaker text={email}>
      <div className="text-[var(--color-text)] text-xs border px-2 py-1 rounded-md flex items-center">
        <span>{truncate( email,{length:20})}</span>
        <X
          size={12}
          className="text-[var(--color-icon-muted)] font-bold ml-1 cursor-pointer"
          onClick={() => {
            setEmails((prev) => prev.filter((item) => item !== email));
          }}
        />
      </div>
    </TooltipMaker>
  );
};

const renderChips = ({
  emails,
  setEmails,
}: {
  emails: string[];
  setEmails: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  return (
    <div className="flex gap-1">
      {emails.slice(0, 2).map((item) => {
        return <Email email={item} key={item} setEmails={setEmails} />;
      })}
    </div>
  );
};

const renderSurplusChips = ({
  emails,
  setEmails,
}: {
  emails: string[];
  setEmails: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const count = emails.length - 2;
  if (count < 1) return null;

  const content = () => {
    return emails.slice(2).map((item) => {
      return <Email email={item} key={item} setEmails={setEmails} />;
    });
  };

  return (
    <div className="text-[var(--color-text)] text-xs border p-0.5 px-1 rounded-md flex items-center ml-1">
      <TooltipMaker text={content()}>{` +${count} `}</TooltipMaker>
    </div>
  );
};

const Lane = ({ emails, setEmails }: Props) => {
  return (
    <>
      {renderChips({ emails, setEmails })}
      {renderSurplusChips({ emails, setEmails })}
    </>
  );
};

export { Lane };
