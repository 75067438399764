import { STATUS_ORDER } from "@/constants/kanban";
import { generateJoinAndConditions } from "./duckDB";

const getColumnByIndexedKey = (indexedKey) => {
  switch (indexedKey) {
    case "assigned_to_user_id":
      return "assigned_to_user_id";

    case "status":
      return "status";

    case "ai_metadata.sentiment":
      return "sentiment";

    case "ai_metadata.urgency":
      return "urgency";

    default:
      return indexedKey;
  }
};

const handleCustomFieldFilter = (filter) => {
  const valuesStr = filter.values.map((v) => `'${v}'`).join(",");
  return `cf.field_hash = '${filter.indexed_key}' AND cf.field_values && ARRAY[${valuesStr}]`;
};

// only pass request filters
// tableAlias can be like `r.` or `req.`
const getRequestQuery = (filters: any[] = [], tableAlias = "") => {
  let resultQuery = "";
  let isFirstFilter = true;

  for (const filter of filters) {
    const column = getColumnByIndexedKey(filter.indexed_key);

    let query = "";

    switch (column) {
      case "status": {
        const statuses = filter.values.filter((v) => STATUS_ORDER.includes(v));
        if (statuses.length) {
          const statusesStr = statuses.map((v) => `'${v}'`).join(",");
          query = `${tableAlias}status IN (${statusesStr})`;
        }
        //
        const subStatuses = filter.values.filter(
          (v) => !STATUS_ORDER.includes(v)
        );
        if (subStatuses.length) {
          const subStatusesStr = subStatuses.map((v) => `'${v}'`).join(",");
          if (query.length) {
            query = `(${query} OR ${tableAlias}sub_status IN (${subStatusesStr}))`;
          } else {
            query = `${tableAlias}sub_status IN (${subStatusesStr})`;
          }
        }
        break;
      }

      case "ai_metadata.tags": {
        const valuesStr = filter.values.map((v) => `'${v}'`).join(",");
        query = `${tableAlias}aiTags && ARRAY[${valuesStr}]`;
        break;
      }

      default: {
        if (!filter.indexed_key.includes("custom_")) {
          const valuesStr = filter.values.map((v) => `'${v}'`).join(",");
          query += `${tableAlias}${column} IN (${valuesStr})`;
        }
        break;
      }
    }

    if (query.length) {
      if (!isFirstFilter) {
        resultQuery += " AND ";
      }
      resultQuery += query;
      isFirstFilter = false;
    }
  }

  return {
    resultQuery,
  };
};

const handleRelationshipIdFilter = (filter, tableAlias = "rel.") => {
  const valuesStr = filter.values.map((v) => `'${v}'`).join(",");

  switch (filter.name) {
    case "Account owners": {
      return `${tableAlias}csmUsers && ARRAY[${valuesStr}]`;
    }

    case "Solution Engineer": {
      return `${tableAlias}solutionEngineers && ARRAY[${valuesStr}]`;
    }

    case "Channel": {
      return `${tableAlias}channel_id IN (${valuesStr})`;
    }

    default: {
      return `${tableAlias}customer_name IN (${valuesStr})`;
    }
  }
};

const handleCRMFieldFilter = (filter) => {
  const valuesStr = filter.values.map((v) => `'${v}'`).join(",");
  return `crm.field_name = '${filter.indexed_key}' AND crm.field_values && ARRAY[${valuesStr}]`;
};

// only pass relationship filters
const getRelationshipQuery = (filters: any[] = [], tableAlias = "") => {
  let resultQuery = "";
  let isRelationshipFilter = false;

  for (const filter of filters) {
    switch (filter.indexed_key) {
      case "relationship_id": {
        resultQuery += handleRelationshipIdFilter(filter, tableAlias);
        isRelationshipFilter = true;
        break;
      }
      default: {
        break;
      }
    }
    if (resultQuery) {
      resultQuery += " AND ";
    }
  }

  return {
    resultQuery,
    isRelationshipFilter,
  };
};

export const getFinalQuery = (
  filters: any[] = [],
  requestTableAlias = "",
  relationshipTableAlias = ""
) => {
  const requestFilters = filters.filter(
    (f) =>
      f.indexed_key !== "relationship_id" && !f.indexed_key.includes("crm_")
  );
  const relationshipFilters = filters.filter(
    (f) => f.indexed_key === "relationship_id" || f.indexed_key.includes("crm_")
  );

  const {
    customInnerJoinString,
    customWhereClauseString,
    crmInnerJoinString,
    crmWhereClauseString,
  } = generateJoinAndConditions(filters);

  const { resultQuery: requestQueryRaw } = getRequestQuery(requestFilters);
  const { resultQuery: relationshipQueryRaw, isRelationshipFilter } =
    getRelationshipQuery(relationshipFilters);

  const requestQuery = requestQueryRaw.length ? `AND ${requestQueryRaw}` : "";

  let relationshipQuery = relationshipQueryRaw.replace(/ AND $/, "");
  relationshipQuery = relationshipQuery ? `AND ${relationshipQuery}` : "";

  const customAndCrmFiltersString = [
    customWhereClauseString && `AND ${customWhereClauseString}`,
    crmWhereClauseString && `AND ${crmWhereClauseString}`,
  ]
    .filter(Boolean)
    .join(" ");

  return {
    requestQuery,
    isCRMFieldFilter: crmInnerJoinString.length ? true : false,
    relationshipQuery,
    isCustomFieldFilter: customInnerJoinString.length ? true : false,
    isRelationshipFilter,
    conditionsString: customAndCrmFiltersString,
  };
};
