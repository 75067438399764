import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { useConnectedApp } from "../../../modules/ConnectedApps";
import { IntegrationType as CardProps } from "../../../modules/ConnectedApps/definitions";
import { useZendeskStore } from "../../../store/zendeskStore";
import { ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

type Props = CardProps & {
  hasConnected: boolean;
  configured: boolean;
  inConnectedApps?: boolean;
  isEnabled: boolean;
  showSwitch: boolean;
  showDelete: boolean;
  faq: number;
  setActiveIntegrationId: React.Dispatch<React.SetStateAction<string>>;
};

export const IntegrationCard = ({
  id,
  icon,
  title,
  description,
  comingSoon,
  className,
  hasConnected,
  showSwitch,
  showDelete,
  configured,
  inConnectedApps = false,
  setActiveIntegrationId,
}: Props) => {
  const navigate = useNavigate();
  const { setIsEdition, setSelectedIntegration } = useConnectedApp();

  const isNewZendeskFlow = id === "zendesk";
  const isSalesforceFlow = title === "Salesforce Cases";
  const dispatch = useZendeskStore((state) => state.dispatch);

  const showConnectForZendesk = isNewZendeskFlow && configured;

  const card = (
    <Card
      key={id}
      className="hover:border-hover-accent shadow-lg w-[260px] pl-0 pr-0 pb-2 pt-6"
    >
      <div className={`h-auto`}>
        <div className="flex items-center justify-between">
          <div className="pl-6">
            <img
              src={icon}
              className={className || ""}
              alt={title + " logo icon"}
            />
          </div>
          {((configured && !inConnectedApps) || showConnectForZendesk) && (
            <div className="bg-[var(--color-bg-success-muted)] text-[var(--color-text-success)] border-[var(--color-border-success)] px-2 rounded border  mx-3 flex items-center justify-center">
              <div className="text-xs">Connected</div>
            </div>
          )}
          <div
            style={{
              display:
                inConnectedApps && (showSwitch || showDelete)
                  ? "block"
                  : "none",
            }}
          ></div>
        </div>
        <div className="pl-6 pr-5 flex flex-col justify-left items-start gap-2  justify-left w-full mt-4">
          <p className="text-sm 3xl:text-[length:var(--sm)] font-bold">
            {title === "HubSpot" ? "HubSpot Tickets" : title}
          </p>
          <p
            style={{ minHeight: "40px" }}
            className="font-normal text-xs 3xl:text-[length:var(--xs)] text-gray-dark"
            title={description}
          >
            {description}
          </p>
        </div>
        <div className="flex justify-between items-center">
          {!hasConnected && !configured && (
            <Button
              key="add-integration"
              variant={"link"}
              className="pl-6 text-[var(--color-text-info)] hover:text-brand-accent mt-6"
              disabled={comingSoon}
              onClick={() => {
                if (isSalesforceFlow) {
                  navigate("/salesforce-cases-integration");
                }
                if (isNewZendeskFlow) {
                  navigate("/configuration/integration/zendesk", {});
                  return dispatch({
                    type: "SET_MODAL_STATE",
                    payload: {
                      modalState: {
                        isOpen: true,
                        type: "CREATE_INTEGRATION",
                        title: "New Zendesk workspace",
                      },
                    },
                  });
                }
                setSelectedIntegration(id);
                setActiveIntegrationId(id);
              }}
            >
              Set up <ArrowRight size={14} />
            </Button>
          )}
          {hasConnected && (
            <Button
              key="view-integration"
              variant={"link"}
              className="pl-6 flex items-center mt-2 text-[var(--color-text-info)] gap-2"
              disabled={comingSoon}
              onClick={() => {
                if (isSalesforceFlow) {
                  navigate("/salesforce-cases-integration");
                }
                if (isNewZendeskFlow) {
                  navigate("/configuration/integration/zendesk", {});
                  return dispatch({
                    type: "SET_MODAL_STATE",
                    payload: {
                      modalState: {
                        isOpen: true,
                        type: "ALL_INTEGRATIONS",
                        title: "Zendesk integration",
                      },
                    },
                  });
                }
                setIsEdition(true);
                setSelectedIntegration(id);
                setActiveIntegrationId(id);
              }}
            >
              Configure <ArrowRight size={14} />
            </Button>
          )}
          {!hasConnected && configured && !inConnectedApps && (
            <Button
              key="configure"
              variant={"link"}
              className="pl-6 text-primary mt-2 hover:text-brand-accent"
              disabled={comingSoon}
              onClick={() => {
                setSelectedIntegration(id);
              }}
            >
              Configure
            </Button>
          )}
        </div>
      </div>
    </Card>
  );

  return card;
};
