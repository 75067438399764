import { useState, useEffect, useImperativeHandle, forwardRef } from "react";
import { findParagraphs } from "../Macros/MacrosTable";
import { useLiveQuery } from "dexie-react-hooks";
import { getThenaDB } from "@/db";
import { Dialog } from "../ui/dialog";
import AddMacro from "../Macros/AddMacro";
import { fetchAllMacros } from "@/api/kanban";
import { useQuery } from "@tanstack/react-query";
import { set } from "lodash";
import { cn } from "@/utils";
import styled from "styled-components";
import { FileX } from "lucide-react";

const Wrapper = styled.div`
  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  scrollbar-width: thin; /* Make scrollbar thinner */
  scrollbar-color: transparent transparent;
`;

const MacroList = forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [commandVisibility, setCommandVisibility] = useState("");
  const [isMainMenuVisible, setIsMainMenuVisible] = useState(true);
  const [open, setOpen] = useState(false);
  const macros: any = useLiveQuery(() => {
    return getThenaDB().macros.toArray();
  });

  const { data, isFetched, refetch } = useQuery({
    queryKey: ["macros"],
    queryFn: fetchAllMacros,
  });

  const { items, command, editor } = props;

  useEffect(() => {
    if (items.query?.length > 0) {
      setCommandVisibility("SHOW_MACROS");
      setIsMainMenuVisible(false);
    }
  }, [items.query]);

  useEffect(() => {
    setSelectedIndex(0);
  }, [items, macros]);

  useEffect(() => {
    props.closePopover();
  }, [open]);

  useEffect(() => {
    if (isFetched && data) {
      try {
        getThenaDB().macros.clear();
        getThenaDB().macros.bulkPut(data);
      } catch (error) {
        console.log("Error adding Macros", error);
      }
    }
  }, [data, isFetched]);

  useEffect(() => {
    editor.commands.focus();
  }, [selectedIndex, editor]);

  const scrollToItem = (index) => {
    const list = document.querySelector(".command-list");
    const item = list?.children[index];
    if (item) {
      item.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const upHandler = () => {
    const newIndex =
      commandVisibility === "SHOW_MACROS"
        ? (selectedIndex + macros.length - 1) % macros.length
        : (selectedIndex + items.slashCommands.length - 1) %
          items.slashCommands.length;
    setSelectedIndex(newIndex);
    scrollToItem(newIndex);
  };

  const downHandler = () => {
    const newIndex =
      commandVisibility === "SHOW_MACROS"
        ? (selectedIndex + 1) % macros.length
        : (selectedIndex + 1) % items.slashCommands.length;
    setSelectedIndex(newIndex);
    scrollToItem(newIndex);
  };

  const enterHandler = () => {
    if (commandVisibility === "SHOW_MACROS") {
      selectItem(selectedIndex);
    } else {
      handelCommand(items.slashCommands[selectedIndex]);
    }
  };

  useEffect(() => setSelectedIndex(0), [props.items, macros]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === "ArrowUp") {
        upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        downHandler();
        return true;
      }

      if (event.key === "Enter") {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  const handelCommand = (item) => {
    if (item.macroCommand === "CREATE_MACRO") {
      setOpen(true);
      setCommandVisibility(item.macroCommand);
      return;
    }
    setCommandVisibility(item.macroCommand);
    setIsMainMenuVisible(false);
  };

  const filteredMacros = macros
    ?.filter((item) =>
      item.name.toLowerCase().includes(items.query.toLowerCase())
    )
    .sort(
      (a, b) =>
        new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
    );

  const selectItem = (index) => {
    const item = filteredMacros[index];
    if (item) {
      command(item);
    }
  };

  return (
    <Wrapper className="w-[250px] rounded border border-[var(--color-border)] bg-[var(--modal-bg)] shadow-[0px_1px_2px_-1px_rgba(0,0,0,0.10),_0px_1px_3px_0px_rgba(16,24,40,0.10)]">
      {isMainMenuVisible && (
        <div className="h-full overflow-auto px-2 py-2 command-list">
          {items?.slashCommands?.length &&
            items.slashCommands.map((item, index) => {
              return (
                <button
                  className={`w-full p-2 rounded flex items-center gap-2 justify-start  hover:bg-[var(--color-bg-subtle)]  ${index === selectedIndex ? "bg-[var(--color-bg-subtle)] w-full" : ""}`}
                  key={index}
                  onClick={() => handelCommand(item)}
                  onMouseEnter={() => setSelectedIndex(index)}
                >
                  {item.beforeIcon}
                  <div className="text-sm flex justify-between items-center w-full">
                    {item.name} {item.afterIcon}
                  </div>
                </button>
              );
            })}
        </div>
      )}
      <Dialog
        open={open}
        onOpenChange={(value) => {
          setOpen(value);
          editor.commands.focus();
        }}
      >
        <AddMacro
          refetch={refetch}
          setOpen={setOpen}
          convoEditor={editor}
          defaultContent={editor.getJSON()}
          isInsert
        />
      </Dialog>
      {commandVisibility === "SHOW_MACROS" && (
        <div className={cn("h-64 overflow-auto p-2 command-list")}>
          {filteredMacros?.length > 0 ? (
            filteredMacros.map((item, index) => {
              const paragraph = findParagraphs(JSON.parse(item.content));
              let paragraphContent = "";
              paragraph?.content.map((item) => (paragraphContent += item.text));
              return (
                <button
                  className={`w-full p-2 rounded flex flex-col justify-start hover:bg-[var(--color-bg-subtle)] ${index === selectedIndex ? "bg-[var(--color-bg-subtle)]" : ""}`}
                  key={index}
                  onClick={() => selectItem(index)}
                  onMouseEnter={() => setSelectedIndex(index)}
                >
                  <div className="text-sm text-start whitespace-nowrap truncate w-[200px]">
                    {item.name}
                  </div>
                  <div className="text-[var(--color-icon-muted)] w-[200px] text-left truncate">
                    {paragraphContent}
                  </div>
                </button>
              );
            })
          ) : (
            <div className="flex items-center flex-col gap-2 justify-center h-full w-full text-muted-text">
              <FileX size={16} /> No snippets found.
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
});

export default MacroList;
