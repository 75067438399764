import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { getThenaDB } from "@/db";
import { useKanbanStore } from "@/store/kanbanStore";
import { useLiveQuery } from "dexie-react-hooks";
import { Edit2, Ellipsis, FileUp, Trash } from "lucide-react";
import { useSearchParams } from "react-router-dom";
import EditView from "./EditView";
import DeleteView from "./DeleteView";
import { useState } from "react";
import UpdateView from "./UpdateView";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { truncate } from "lodash";

function SavedViewsDropdown() {
  const savedViews = useKanbanStore((state) => state.currentView);
  const [currentMode, setCurrentMode] = useState("");
  const searchParams = useSearchParams()[0];
  const currentView = searchParams.get("view");
  const allSavedViews = useLiveQuery(() => {
    return getThenaDB().views.toArray();
  }, []);
  const currentViewData = allSavedViews?.filter(
    (item) => item._id === currentView
  )[0];
  const dispatch = useKanbanStore((state) => state.dispatch);

  return (
    <>
      <h3 className="text-base">
        <Tooltip>
          <TooltipTrigger>
            {currentViewData?.name && currentViewData?.name?.length > 20
              ? truncate(currentViewData?.name, {
                  length: 20,
                })
              : currentViewData?.name}
          </TooltipTrigger>
          <TooltipContent>{currentViewData?.name}</TooltipContent>
        </Tooltip>
      </h3>
    </>
  );
}

export default SavedViewsDropdown;
