import { fetchSelectedChannels } from "@/api/channels";
import {
  fetchAllFormsList,
  fetchAllHelpdesks,
  fetchAllMappingFields,
  fetchConnectorSettings,
  fetchHelpdeskConfigs,
} from "@/api/zendesk";
import { useZendeskStore } from "@/store/zendeskStore";
import { useQuery } from "@tanstack/react-query";

export const usePrefetchZendeskApis = () => {
  useQuery({
    queryKey: ["fetchAllHelpdesks"],
    queryFn: () => fetchAllHelpdesks(),
  });

  useQuery({
    queryKey: ["fetchAllFormsList"],
    queryFn: fetchAllFormsList,
  });

  useQuery({
    queryKey: ["fetchConnectorSettings"],
    queryFn: fetchConnectorSettings,
  });

  useQuery({
    queryKey: ["fetchAllMappingFields"],
    queryFn: fetchAllMappingFields,
  });

  const allHelpdesks = useZendeskStore((state) => state.allHelpdesks);
  const idList = allHelpdesks.map((helpdesk) => helpdesk._id);

  useQuery({
    queryKey: ["fetchHelpdeskConfigs", idList],
    queryFn: () => fetchHelpdeskConfigs(idList),
    enabled: idList.length > 0,
  });

  useQuery({
    queryKey: ["fetchSelectedChannels"],
    queryFn: () => fetchSelectedChannels({ type: "SETTINGS" }),
  });
};
