import { API_ROOT } from "@/config";
import { sendErrorToLogRocket } from "@/utils/errors";
import { useEffect, useRef, useState } from "react";
import useFocusedLiveQuery from "./useFocusedLiveQuery";
import { getDBInfo, getThenaDB } from "@/db";

const useFetchBroadcastAnalytics = () => {
  const [workerInit, setWorkerInit] = useState(false);

  const workerRef = useRef<any>(null);

  useEffect(() => {
    if (!workerInit) {
      workerRef.current = new Worker(
        new URL("@/workers/low-priorirty-fetch-worker.js", import.meta.url),
        { type: "module" }
      );
      workerRef.current.onerror = function (event) {
        console.log("WORKER_ERROR", event);
        sendErrorToLogRocket(event);
      };
      setWorkerInit(true);
    }
  }, [workerInit]);

  const campaignIdList = useFocusedLiveQuery(
    () => {
      return getThenaDB()
        .campaigns.orderBy("updated_at_date")
        .and(
          (campaign) =>
            campaign.campaign_status === "SENT" && campaign.is_deleted === false
        )
        .limit(5)
        .toArray()
        .then((res) => res.map((campaign) => campaign._id));
    },
    [],
    []
  );

  // fetch Broadcast Analytics
  useEffect(() => {
    if (!workerRef.current) {
      return;
    }
    workerRef.current.postMessage({
      dbName: getDBInfo().dbName,
      type: "FETCH_BROADCAST_ANALYTICS",
      apiUrl: `${API_ROOT}/v1/marketing-automation/analytics`,
      method: "POST",
      list: campaignIdList.map((campaignId) => ({ campaignId })),
    });
  }, [campaignIdList]);
};

export default useFetchBroadcastAnalytics;
