import { SlackConversationPayloadType, ViewsType } from "@/types/requests";
import { api } from ".";
import { CLOUDINARY_CLOUDNAME } from "../config";
import { cloneDeep } from "lodash";
import { CancelToken } from "axios";
import { MaAdditionalPrefs } from "@/pages/SlackBroadCasting/types";

export const uploadFilesToCloudinary = (formdata: FormData) =>
  fetch(
    `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME}/image/upload`,
    {
      method: "POST",
      body: formdata,
    }
  );

export const fetchSlackConversation = (
  data: SlackConversationPayloadType,
  signal?: AbortSignal
) =>
  api
    .post(`/v2/kanban/slack-conversations`, data, {
      signal,
    })
    .then((data) => data.data);

export const fetchSlackConversationV2 = (
  data: any,
  signal?: AbortSignal,
  addEnforceHeader = false
) =>
  api
    .post(`/v2/kanban/conversations`, data, {
      signal,
      ...(addEnforceHeader && { headers: { "x-enforce-no-cache": "true" } }),
    })
    .then((data) => data.data);

export const fetchPatchKanban = (payload: {
  _id: string;
  payload: Record<string, any>;
}) => {
  return api
    .patch(`v2/kanban/mutate/${payload._id}`, payload.payload)
    .then(({ data }) => data.data);
};

export const addThenaDefaultCustomStatus = (
  defaultThenaCustomSubStatus: boolean
) =>
  api
    .post(`/v2/requests/config/bulk/status`, { defaultThenaCustomSubStatus })
    .then(({ data }) => data.data);

export type StatusPayload = {
  parent: string;
  name: string;
  description: string;
  isDefaultForParent: boolean;
  isAIManaged: boolean;
  isEnabled: boolean;
};

export const addCustomStatus = (payload: StatusPayload) =>
  api.post(`/v2/requests/config/status`, { ...payload }).then(({ data }) => {
    return {
      data: data.data,
      parentStatus: payload.parent,
      name: payload.name,
    };
  });

export const searchAllTickets = async (searchTerm: string, token?: any) =>
  await api
    .post(
      `/v2/external-links/search/linear`,
      {
        searchTerm,
      },
      {
        cancelToken: token,
      }
    )
    .then(({ data }) => data);
export const fetchExternalLinksForm = (): Promise<any> =>
  api.get("/v2/external-links/getforms").then((data) => data.data);

export const postExternalLinks = ({
  requestId,
  url,
}: {
  url: string;
  requestId: string;
  currentCardId?: string;
}) =>
  api
    .post(`/v2/external-links/add/${requestId}`, { url })
    .then(({ data }) => data);

export const postUnlinkExternalLink = (payload: any) =>
  api
    .post("/v2/external-links/unlink", {
      ...payload,
    })
    .then(({ data }) => data.data);
export const postCreateLinearTicket = (payload: any, sink: string) =>
  api
    .post(`/v2/external-links/create/${sink}`, {
      ...payload,
    })
    .then(({ data }) => data.data);

type UnlinkPayload = {
  requestId: string;
  externalLinkId: string;
};

export const postComments = ({
  message,
  channel_id,
  ts,
  emailData,
}: {
  message: string;
  channel_id: string;
  ts: string;
  emailData: any;
}) =>
  api
    .post("/v2/kanban/post-comment-to-thread", {
      message,
      channel_id,
      ts,
      emailData,
    })
    .then(({ data }) => data.data);

export const deleteCustomStatus = (
  subStatusId: string,
  moveToSubStatusId: string | null
) =>
  api
    .delete(`/v2/requests/config/status/${subStatusId}`, {
      data: { moveToSubStatusId },
    })
    .then(({ data }) => data.data);

export const updateCustomStatus = (
  subStatusId: string,
  payload: StatusPayload
) =>
  api
    .post(`/v2/requests/config/status/${subStatusId}`, {
      ...payload,
    })
    .then(({ data }) => data.data);

export const postInternalComments = ({
  message,
  channel_id,
  ts,
  emailData,
}: {
  message: string;
  channel_id: string;
  ts: string;
  emailData: any;
}) =>
  api
    .post("/v2/kanban/post-internal-comment-to-thread", {
      message,
      channel_id,
      ts,
      emailData,
    })
    .then(({ data }) => data.data);

export const fetchSavedView = () =>
  api.get(`/v2/view`).then((data) => data.data);

export const addSavedView = (view: SavedViewPayload) =>
  api.post(`/v2/view`, { view }).then((data) => data.data);

export const updatedSavedView = (
  viewId: string,
  view: SavedViewPayload,
  cancelToken?: CancelToken
) =>
  api
    .patch(
      `/v2/view/${viewId}`,
      {
        view,
      },
      { cancelToken }
    )
    .then((data) => data.data);

export const deleteSavedView = (viewId: string) =>
  api.delete(`/v2/view/${viewId}`).then((data) => data.data);

export type SavedViewPayload = {
  name: string;
  type: string;
  scope: string;
  filter: string;
  additionalPrefs:
    | {
        columnMetadata: ViewsType["additionalPrefs"]["columnMetadata"];
        ordered_columns: string[];
        filters: any;
        filtersOrder: string[];
        displayOptions?: any;
        defaultFilters?: any;
        sortby: { field: string; order: "ASC" | "DESC" };
        externalLinksTab?: {
          externalLinkStatus?:
            | { value: string; label: string; avatar: string }[]
            | null;
        };
      }
    | MaAdditionalPrefs;
};
export const kanbanNudgeUser = (id: any) =>
  api.post(`/v2/kanban/nudge-user`, { id: id }).then((data) => data.data);

export const fetchInternalSlackConversation = (
  data: any,
  signal?: AbortSignal
) =>
  api
    .get(`/v2/kanban/internal-threads?id=${data.id}`, { data, signal })
    .then((data) => data.data);

export const getActivities = (payload) =>
  api
    .post(`/v2/kanban/get-activity`, {
      ...payload,
    })
    .then(({ data }) => data.data);

export const updateKanban = (data) => {
  const payload = cloneDeep(data);
  delete payload._id;

  return api.patch(`/v2/kanban/${data._id}`, payload).then((data) => data);
};

export const newRequestCreation = ({
  channelId,
  text,
}: {
  channelId: string;
  text: string;
}) =>
  api
    .post("/v2/requests", {
      channelId,
      text,
    })
    .then(({ data }) => data.data);

export const fetchEnabledConnector = () =>
  api.get(`/v2/kanban/enabled-connector`).then((data) => data.data);

export const kanbanCreateTicket = (data: any) => {
  return api.post(`/v2/kanban/create-ticket`, data).then((data) => data.data);
};

export const fetchKanban = (filterData: any) => {
  return api.post(`/v2/kanban`, filterData).then(({ data }) => data.data);
};

export const postHTMLComments = ({
  message,
  channel_id,
  ts,
  emailData,
  request_mongo_id,
  json,
}: {
  message: string;
  channel_id: string;
  ts: string;
  request_mongo_id: string;
  emailData: any;
  json: any;
}) =>
  api
    .post("/v2/kanban/comment-on-thread", {
      message,
      channel_id,
      ts,
      emailData,
      request_mongo_id,
      json,
    })
    .then(({ data }) => data.data);

export const postHTMLInternalComments = ({
  message,
  channel_id,
  ts,
  emailData,
  request_mongo_id,
  json,
}: {
  message: string;
  channel_id: string;
  ts: string;
  request_mongo_id: string;
  emailData: any;
  json: any;
}) =>
  api
    .post("/v2/kanban/comment-on-triage-thread", {
      message,
      channel_id,
      ts,
      emailData,
      request_mongo_id,
      json,
    })
    .then(({ data }) => data.data);

export const fetchAllCRMSelectFields = () =>
  api.get(`/v2/crm/available-fields`).then((data) => data.data);

export const fetchCRMSelectedFields = async () => {
  return api.get(`/v2/crm/fields`).then((data) => data.data);
};

export const addCRMSelectFields = async (dataFields) => {
  return api
    .patch(`/v2/crm/fields`, { fields: dataFields })
    .then((data) => data.data);
};

export const deleteCRMSelectFields = async (deleteArrNames) => {
  return api
    .delete(`/v2/crm/fields`, { data: { fields: deleteArrNames } })
    .then((data) => data.data);
};

export const uploadFilesToS3 = (formdata: FormData) =>
  api
    .post("/v2/kanban/upload-files-to-S3", formdata, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "*/*",
      },
    })
    .then(({ data }) => data.data);

export const deleteFileFromS3 = (key: string) =>
  api
    .post("/v2/kanban/delete-file-from-S3", { data: { key } })
    .then(({ data }) => data.data);

export const fetchRequestsForMerging = (
  channelId: string,
  original_request_id: string | null,
  team_id?: string
) =>
  api
    .get(`/v2/kanban/requests-for-merging`, {
      params: { channelId, original_request_id, team_id },
    })
    .then(({ data }) => data.data);

export const postMergeRequests = ({
  parent_request,
  toBeMergedRequests,
}: {
  parent_request: string;
  toBeMergedRequests: string[];
}) =>
  api
    .post("/v2/kanban/merge-requests", {
      parent_request,
      toBeMergedRequests,
    })
    .then(({ data }) => data.data);

//fetch lack auth url
export const fetchSlackAuthUrl = (): Promise<any> =>
  api.get(`/v2/slack/user/authorize-url`).then((data) => data.data);

export const deleteSlackAuth = async () => {
  return api.delete(`/v2/slack/user/token`).then((data) => data.data);
};

export const changeReplyAs = async (value) => {
  return api
    .patch(`/v2/slack/user/token`, {
      settings: { behavior: value },
    })
    .then((data) => data.data);
};

//conversation profile active

export const getUserPresence = async (userIds) => {
  return api
    .post(`/v2/slack/users/presence`, {
      userIds,
    })
    .then((data) => data.data);
};

export const getSlackHuddleAndDmLink = (slackId) =>
  api
    .get(`v2/slack/conversation/links?userId=${slackId}`)
    .then((data) => data.data);

// macros

export const fetchAllMacros = () =>
  api.get(`/v2/macros`).then((data) => data.data);

export const addMacro = (payload) =>
  api.post(`/v2/macros`, payload).then((data) => data.data);

export const updateMacro = (id, payload) =>
  api.patch(`/v2/macros/${id}`, payload).then((data) => data.data);

export const deleteMacro = (id) =>
  api.delete(`/v2/macros/${id}`).then((data) => data.data);
