import { Button } from "@/components/ui/button";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Separator } from "@/components/ui/separator";
import { Clock4, Headphones, MessageCircle } from "lucide-react";
import { formatInTimeZone } from "date-fns-tz";
import { cn } from "@/utils";
import { useQuery } from "@tanstack/react-query";
import { getSlackHuddleAndDmLink } from "@/api/kanban";
import { useState } from "react";
import { useAuthStorePersist } from "@/store/authStorePersist";
import useAuth from "@/hooks/useAuth";
import { useLiveQuery } from "dexie-react-hooks";
import { getThenaDB } from "@/db";

const HoverContent = ({ slackId, presence, userInfo, open }) => {
  const isUserAuthorisedForReplyAs = useAuthStorePersist(
    (state) => state.isUserAuthorisedForReplyAs
  );
  const { data: slackData, refetch } = useQuery({
    queryKey: ["getSlackHuddleAndDmLink", slackId],
    queryFn: () => getSlackHuddleAndDmLink(slackId),
    enabled: open,
  });

  const customerContact = useLiveQuery(() => {
    return getThenaDB()
      .customerContacts.where("metadata.slack.id")
      .equals(slackId ?? "")
      .first();
  }, [slackId]);

  const timeZone = customerContact?.metadata?.slack?.tz;
  let formattedTime;
  if (timeZone) {
    formattedTime = formatInTimeZone(new Date(), timeZone, "HH:mm");
  }
  const user: any = useAuth();
  const handelProfileButtonClick = async (isHuddle) => {
    await refetch();
    if (slackData) {
      if (isHuddle) {
        window.open(slackData.huddleLink, "_blank");
        return;
      }
      window.open(slackData.dmLink, "_blank");
      return;
    }
  };

  const slackDmUrl = `slack://user?team=${user?.team_id}&id=${slackId}`;

  return (
    <HoverCardContent className="min-w-[302px]">
      <div className=" w-full flex flex-col gap-3">
        <div className="flex items-center gap-2">
          <div className="relative">
            <img
              src={userInfo?.image}
              className="h-12 w-12 rounded-full"
              alt="profile"
              style={{
                objectFit: "contain",
              }}
            />
          </div>
          <div className="flex flex-col ">
            <span>{userInfo?.display_name}</span>
            <span className="text-[var(--color-icon-muted)] text-sm">
              {userInfo?.title}
            </span>
          </div>
        </div>
        <Separator />
        {formattedTime && (
          <div className="flex items-center gap-2 text-sm text-[var(--color-icon-muted)]">
            <Clock4 size={16} />
            Local time: {formattedTime} {timeZone?.replace("_", " ")}
          </div>
        )}
        {!userInfo?.is_bot && (
          <div className="flex gap-3 justify-end">
            {slackDmUrl && (
              <a
                href={slackDmUrl || slackData?.dmLink}
                target="_blank"
                className="!no-underline"
              >
                <Button
                  variant={"outline"}
                  className="flex gap-2 items-center no-underline text-[var(--color-text)]"
                >
                  <MessageCircle size="16" /> Slack DM
                </Button>
              </a>
            )}
            {isUserAuthorisedForReplyAs && slackData?.huddleLink ? (
              <a
                href={slackData?.huddleLink}
                target="_blank"
                className="!no-underline"
              >
                <Button
                  variant={"outline"}
                  className="flex gap-2 items-center text-[var(--color-text)]"
                >
                  <Headphones size="16" /> Slack huddle
                </Button>
              </a>
            ) : (
              <Button
                variant={"outline"}
                className="flex gap-2 items-center text-[var(--color-text)]"
                onClick={() => handelProfileButtonClick(true)}
              >
                <Headphones size="16" /> Slack huddle
              </Button>
            )}
          </div>
        )}
      </div>
    </HoverCardContent>
  );
};

const ConversationProfileCard = ({ children, slackId, presence, userInfo }) => {
  const [open, setOpen] = useState(false);
  return (
    <HoverCard open={open} onOpenChange={setOpen}>
      <HoverCardTrigger className="relative !group-[a]:no-underline !no-underline">
        {children}
      </HoverCardTrigger>
      <HoverContent
        slackId={slackId}
        presence={presence}
        userInfo={userInfo}
        open={open}
      />
    </HoverCard>
  );
};

export default ConversationProfileCard;
