import { useTheme } from "@/context/theme-provider";

const useThemeToggle = <TItem, PItem = TItem>(
  ...args: { dark: TItem; light: PItem }[]
) => {
  const theme = useTheme().theme;

  const isDarkTheme =
    theme === "dark" ||
    (theme === "system" &&
      window.matchMedia("(prefers-color-scheme: dark)").matches);

  return args.map((item) => (isDarkTheme ? item.dark : item.light));
};

export default useThemeToggle;
