import { OrgIdResponse, UserType } from "@/types/auth";
import { api } from ".";
import { getIsCustomerPortal } from "@/utils/domUtils";

export const fetchOrgIdByName = (name: string) =>
  api
    .get(`/v2/public/subdomain/${name}`)
    .then(({ data }) => data as OrgIdResponse);

export const fetchUser = (): Promise<UserType> =>
  api.get("/v1/user-info").then(({ data }) => data.data);

export const getUserOrganisations = (): Promise<{
  data: {
    id: string;
    name: string;
    type: "customer" | "vendor";
    image: string;
  }[];
}> => {
  if (getIsCustomerPortal()) {
    return api.get("v2/customer-auth/user/organizations");
  }
  return api.get("/v2/auth/user/organizations");
};
