import { getThenaDB } from "@/db";

export const smartUpdateDBWithTx = async (
  newData: any[],
  table = "requests"
) => {
  try {
    const db = getThenaDB();
    await db.transaction("rw", db.table(table), async () => {
      const ids = newData.map((item) => item._id);

      const existingItemsArray = await db.table(table).bulkGet(ids);
      const existingItemsMap = existingItemsArray.reduce((acc, item) => {
        if (item) acc[item._id] = item;
        return acc;
      }, {});

      const itemsToUpdateOrAdd = newData.filter((item) => {
        const existing = existingItemsMap[item._id];
        // Add to the list if there's no existing item or if the item is newer
        return !existing || item.updated_at_date > existing.updated_at_date;
      });

      if (itemsToUpdateOrAdd.length > 0) {
        await db.table(table).bulkPut(itemsToUpdateOrAdd);
      }
    });
  } catch (error) {
    console.error("Error in updateRecordsWithTransaction", error);
  }
};
