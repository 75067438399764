import { Dialog, DialogContent, DialogHeader } from "@/components/ui/dialog";
import { IntegrationModalHeader } from "./IntegrationModalHeader";
import { Separator } from "@/components/ui/separator";
import { SalesforceIntegration } from "../../integrations/salesforce/SalesforceIntegration";
import { SalesforceProvider } from "../../integrations/salesforce/SalesforceProvider";
import {
  FreshdeskIntegration,
  FreshdeskProvider,
} from "@/modules/integrations/freshdesk";
import { ShortcutProvider } from "@/modules/integrations/shortcut/ShortcutProvider";
import { ShortcutIntegration } from "@/modules/integrations/shortcut/ShortcutIntegration";

const IntegrationModal = ({
  integrations,
  activeIntegrationId,
  setActiveIntegrationId,
}: {
  integrations: any;
  activeIntegrationId: string;
  setActiveIntegrationId: (id: string) => void;
}) => {
  const activeIntegration = integrations.find(
    (integration: any) => integration.id === activeIntegrationId
  );
  const salesforceRequestType = integrations
    ?.filter(
      (e) =>
        e.id !== "salesforce" &&
        e.id !== "slack" &&
        e.id !== "intercom" &&
        e.isEnabled
    )
    .flatMap((e) => e.requestTypes);

  const freshdeskRequestType = integrations
    ?.filter(
      (e) =>
        e.id !== "freshdesk" &&
        e.id !== "slack" &&
        e.id !== "intercom" &&
        e.isEnabled
    )
    .flatMap((e) => e.requestTypes);

  const availableFreshdeskRequestTypes = [
    { label: "Product", value: "Product", disabled: false },
    { label: "Billing", value: "Billing", disabled: false },
    {
      label: "Account",
      value: "Account Manager",
      disabled: false,
    },
    {
      label: "Tech Support",
      value: "Tech Support",
      disabled: false,
    },
  ].map((item) => {
    const item2 = freshdeskRequestType?.find((e) => e === item.label);
    if (item2) {
      item.disabled = true;
    }
    return item;
  });

  const availableSalesforceRequestTypes = [
    { label: "Product", value: "Product", disabled: false },
    { label: "Billing", value: "Billing", disabled: false },
    {
      label: "Account",
      value: "Account Manager",
      disabled: false,
    },
    {
      label: "Tech Support",
      value: "Tech Support",
      disabled: false,
    },
  ].map((item) => {
    const item2 = salesforceRequestType?.find((e) => e === item.label);
    if (item2) {
      item.disabled = true;
    }
    return item;
  });
  return (
    <Dialog
      open={true}
      onOpenChange={(e) => {
        setActiveIntegrationId("");
      }}
    >
      <DialogContent className="max-h-[700px] overflow-y-scroll min-w-[700px]">
        <DialogHeader className="items-left">
          <IntegrationModalHeader
            imgSrc={activeIntegration.icon}
            title={activeIntegration.title}
            description={activeIntegration.description}
            imageClassName={activeIntegration.classname}
          />
        </DialogHeader>
        <Separator className="my-0" />
        {activeIntegrationId === "salesforce" && (
          <SalesforceProvider>
            <SalesforceIntegration
              requestType={availableSalesforceRequestTypes}
            />
          </SalesforceProvider>
        )}
        {activeIntegrationId === "freshdesk" && (
          <FreshdeskProvider>
            <FreshdeskIntegration
              requestType={availableFreshdeskRequestTypes}
            />
          </FreshdeskProvider>
        )}
        {activeIntegrationId === "shortcut" && (
          <ShortcutProvider>
            <ShortcutIntegration />
          </ShortcutProvider>
        )}
      </DialogContent>
    </Dialog>
  );
};

export { IntegrationModal };
