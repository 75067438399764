import { getUserOrganisations } from "@/api/auth";
import { ThemePicker } from "@/components/ThemePicker";
import { getImageFallback } from "@/components/kanban/Drawer/Conversation";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { API_URL } from "@/constants";
import useAuth from "@/hooks/useAuth";
import ThenaLogo from "@/images/logos/thena.svg";
import { customerPortalMenuItems, menuItems } from "@/layout/menuItems";
import { useLayoutStorePersist } from "@/store/layoutStorePersist";
import { cn } from "@/utils";
import { getIsCustomerPortal } from "@/utils/domUtils";
import { getNameFromEmail } from "@/utils/user";
import { useQuery } from "@tanstack/react-query";
import {
  ArrowLeftFromLine,
  ArrowUpRight,
  Check,
  EllipsisVertical,
  Search,
} from "lucide-react";
import { useRef } from "react";
import { Nav } from "./Nav";
import TooltipWrapper from "@/components/common/TooltipWrapper";

type Props = {
  collapseSidebar: () => void;
};

const Sidebar = ({ collapseSidebar }: Props) => {
  const isCollapsed = useLayoutStorePersist((state) => state.isCollapsed);
  const user = useAuth();
  const userName = user.name || getNameFromEmail(user.email);
  const userAvatar = user.user_image;
  const teamName = user.primaryInstallation?.team?.name;
  const teamIcon = user.team_image;

  const isCustomerPortal = getIsCustomerPortal();

  const { data } = useQuery({
    queryKey: ["user-organisations"],
    queryFn: getUserOrganisations,
  });

  const organisations = data?.data || [];

  const inputRef = useRef<HTMLInputElement>(null);

  const onSearchClick = () => {
    window?.CommandBar?.open();
    inputRef.current?.blur();
  };

  const redirectToOldApp = () => {
    window.open("https://v1.thena.ai", "_blank");
  };

  const changeWorkspace = (id: string) => {
    const path = isCustomerPortal ? "customer-auth" : "auth";
    window.open(
      `${API_URL}/v2/${path}/user/organizations/${id}/switch`,
      "_self"
    );
  };

  const customerPortalOrgs = organisations?.filter(
    (org) => org.type === "customer"
  );

  const vendorPortalOrgs = organisations?.filter(
    (org) => org.type !== "customer"
  );

  return (
    <div className="h-full flex flex-col">
      <div>
        <div
          className={cn(
            "flex h-[52px] items-center justify-between p-4",
            isCollapsed ? "h-[52px]" : ""
          )}
        >
          <div className="flex items-center gap-2">
            <img
              width={28}
              height={28}
              src={teamIcon}
              className="object-contain rounded-full"
            />
            <span className="text-nowrap w-[88px] overflow-hidden text-ellipsis">
              {teamName}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <ArrowLeftFromLine
              size={16}
              className="cursor-pointer text-color-icon-muted"
              onClick={collapseSidebar}
            />
            {organisations?.length > 1 && (
              <Popover>
                <PopoverTrigger>
                  <EllipsisVertical
                    size={16}
                    className="cursor-pointer text-color-icon-muted"
                  />
                </PopoverTrigger>
                <PopoverContent
                  className="w-[223px] p-3"
                  onOpenAutoFocus={(event) => {
                    event.preventDefault();
                  }}
                >
                  <div className="flex gap-3 flex-col">
                    {vendorPortalOrgs?.map(({ id, name, image }) => {
                      return (
                        <div
                          key={id}
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => changeWorkspace(id)}
                        >
                          {image ? (
                            <img
                              width={16}
                              height={16}
                              src={image}
                              className="object-contain rounded-full shrink-0"
                            />
                          ) : (
                            getImageFallback(name)
                          )}
                          <TooltipWrapper
                            tooltipContent={name}
                            delayDuration={600}
                            triggerClassname="items-center inline-flex"
                          >
                            <span className="text-nowrap w-[180px] text-left inline-flex justify-between items-center overflow-hidden text-ellipsis text-sm">
                              {name}
                              {id === user.organization_id && (
                                <Check size={16} />
                              )}
                            </span>
                          </TooltipWrapper>
                        </div>
                      );
                    })}
                  </div>

                  {customerPortalOrgs.length > 0 && (
                    <>
                      {vendorPortalOrgs.length > 0 && (
                        <div className="border-b my-3" />
                      )}
                      <span className="text-color-text-placeholder text-xs font-medium inline-block mb-3">
                        Customer portal
                      </span>
                    </>
                  )}
                  <div className="flex gap-3 flex-col">
                    {customerPortalOrgs?.map(({ id, name, image }) => {
                      return (
                        <div
                          key={id}
                          className="flex gap-2 items-center cursor-pointer"
                          onClick={() => changeWorkspace(id)}
                        >
                          {image ? (
                            <img
                              width={16}
                              height={16}
                              src={image}
                              className="object-contain rounded-full shrink-0"
                            />
                          ) : (
                            getImageFallback(name)
                          )}
                          <TooltipWrapper
                            tooltipContent={name}
                            delayDuration={600}
                            triggerClassname="items-center inline-flex"
                          >
                            <span className="text-nowrap w-[180px] text-left inline-flex justify-between items-center overflow-hidden text-ellipsis text-sm">
                              {name}
                              {id === user.organization_id && (
                                <Check size={16} />
                              )}
                            </span>
                          </TooltipWrapper>
                        </div>
                      );
                    })}
                  </div>
                </PopoverContent>
              </Popover>
            )}
          </div>
        </div>
        {!isCustomerPortal && (
          <div className="p-4">
            <div
              onClick={onSearchClick}
              className="relative ml-auto flex-1 md:grow-0"
            >
              <Search className="absolute left-2.5 top-[5px] h-4 w-4 text-[var(--color-text)]" />

              <Input
                ref={inputRef}
                type="search"
                placeholder="Search in Thena"
                className="w-full rounded-md h-7 bg-background pl-8 text-[var(--color-icon-muted)]"
                onFocus={() => inputRef.current?.blur()}
              />
            </div>
          </div>
        )}
      </div>
      <div className="grow overflow-auto px-3 min-w-fit flex flex-col">
        <Nav
          isCollapsed={isCollapsed}
          routes={isCustomerPortal ? customerPortalMenuItems : menuItems}
        />

        {!isCustomerPortal && (
          <Button
            variant="outline"
            className="p-2 w-max mb-2 gap-2 self-center"
            onClick={redirectToOldApp}
          >
            <img src={ThenaLogo} alt="" width={16} height={16} />
            Access old version
            <ArrowUpRight size={16} />
          </Button>
        )}
      </div>
      <div className="p-4 flex items-center justify-between">
        <div className="flex items-center gap-4 shrink-0">
          {userAvatar ? (
            <img
              src={userAvatar}
              alt={userName}
              className="w-[24px] h-[24px] rounded-full"
            />
          ) : (
            <div className="w-[24px] h-[24px] rounded-full flex items-center justify-center">
              {getImageFallback(userName)}
            </div>
          )}
          <p className="text-sm">{userName}</p>
        </div>
        {!isCollapsed && <ThemePicker />}
      </div>
    </div>
  );
};

export default Sidebar;
