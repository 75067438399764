/* eslint-disable max-lines */
import { STATUS_ORDER } from "@/constants/kanban";
import { useDuckDBStore } from "@/store/duckDBStore";
import { RelationshipType } from "@/types/relationships";
import { RequestType, SubStatusType } from "@/types/requests";
import { UserType } from "@/types/users";
import {
  addDays,
  addMonths,
  addWeeks,
  differenceInDays,
  format,
  parseISO,
  startOfMonth,
  startOfWeek,
  subDays,
} from "date-fns";
import { map, omit } from "lodash";
import { getFeedbackNumber } from "./requests";
import { getFinalQuery } from "./duckDBQuery";
import { consoleInDev } from "./errors";
import { useAnalyticsStore } from "@/store/analyticsStore";

const getStringValue = (value: any) => {
  if (typeof value === "string") {
    return value;
  }
  if (typeof value === "number") {
    return value.toString();
  }
  return "";
};

export function escapeString(input) {
  // Replace single quotes with two single quotes to escape them for SQL
  return input && input.replace(/'/g, "''");
}

export const TABLE_TRUNCATION_QUERIES = {
  requests: () => {
    const str = `TRUNCATE TABLE requests`;
    return str;
  },
  customFields: () => {
    const str = `TRUNCATE TABLE customFields`;
    return str;
  },
  relationships: () => {
    const str = `TRUNCATE TABLE relationships`;
    return str;
  },
  crmFields: () => {
    const str = `TRUNCATE TABLE crmFields`;
    return str;
  },
  users: () => {
    const str = `TRUNCATE TABLE users`;
    return str;
  },
  subStatuses: () => {
    const str = `TRUNCATE TABLE subStatuses`;
    return str;
  },
};

export const TABLE_CREATION_QUERIES = {
  requests: `CREATE TABLE requests 
               (_id VARCHAR, 
                  request_id INTEGER, 
                  installation_id VARCHAR, 
                  installation_team_id VARCHAR,
                  message_details_ts VARCHAR,
                  relationship_id VARCHAR,
                  status VARCHAR,
                  original_message_user_id VARCHAR,
                  original_message_source VARCHAR,
                  original_message_ts VARCHAR,
                  assigned_to_user_id VARCHAR,
                  assigned_by_user_id VARCHAR,
                  created_at VARCHAR,
                  updated_at VARCHAR,
                  sub_status VARCHAR,
                  finalResolutionSlaOrder VARCHAR,
                  firstResponseSlaOrder VARCHAR,
                  first_response_time VARCHAR,
                  internalHelpDeskIndex VARCHAR,
                  lastCustomerReply VARCHAR,
                  lastCustomerReplyOrder VARCHAR,
                  lastReplyOrder VARCHAR,
                  slaBreached VARCHAR,
                  closed_on VARCHAR,
                  aiTags VARCHAR[],
                  sentiment VARCHAR,
                  urgency VARCHAR,
                  feedback INTEGER[],
                  last_reply_by_vendor_ts VARCHAR,
                  last_reply_by_customer_ts VARCHAR,
                  sla_metrics VARCHAR
                )`,

  customFields: `CREATE TABLE customFields
                  (_id VARCHAR, 
                    field_hash VARCHAR, 
                    field_values VARCHAR[])`,

  relationships: `CREATE TABLE relationships
                  (_id VARCHAR,
                    channel_id VARCHAR,
                    channel_is_ext_shared BOOLEAN,
                    channel_is_private BOOLEAN,
                    channel_name VARCHAR,
                    created_at VARCHAR,
                    updated_at VARCHAR,
                    csmUsers VARCHAR[],
                    customer_name VARCHAR,
                    initial_installation_id VARCHAR,
                    solutionEngineers VARCHAR[],
                    is_internal_helpdesk BOOLEAN,
                    vendor_name VARCHAR
                  )`,

  crmFields: `CREATE TABLE crmFields 
              (_id VARCHAR, 
                field_name VARCHAR, 
                field_values VARCHAR[]
              )`,

  users: `CREATE TABLE users 
          (_id VARCHAR,
            name VARCHAR,
            created_at VARCHAR,
            updated_at VARCHAR,
            slack_team_id VARCHAR
          )`,

  subStatuses: `CREATE TABLE subStatuses 
                (_id VARCHAR,
                 description VARCHAR,
                 installation VARCHAR,
                 isAIManaged BOOLEAN,
                 isEnabled BOOLEAN,
                 name VARCHAR,
                 parent VARCHAR,
                )`,
};

export const TABLE_INSERT_QUERIES = {
  requests: (data: RequestType) => {
    const str = `INSERT INTO requests VALUES (
        '${data._id}', 
        '${data.request_id || 0}', 
        '${data.installation_id}', 
        '${data.installation_team_id}', 
        '${data.message_details_ts}', 
        '${data.relationship_id}', 
        '${data.status}', 
        '${data.original_message_user_id}', 
        '${data.original_message_source || ""}', 
        '${data.original_message_ts}', 
        '${data.assigned_to_user_id || ""}', 
        '${data.assigned_by_user_id || ""}', 
        '${data.created_at}', 
        '${data.updated_at}', 
        '${escapeString(data.sub_status) || ""}',
        '${getStringValue(data.finalResolutionSlaOrder)}',
        '${getStringValue(data.firstResponseSlaOrder)}',
        '${getStringValue(data.first_response_time?.ts)}',
        '${getStringValue(data.internalHelpDeskIndex)}',
        '${getStringValue(data.lastCustomerReply)}',
        '${getStringValue(data.lastCustomerReplyOrder)}',
        '${getStringValue(data.lastReplyOrder)}',
        '${getStringValue(data.slaBreached)}',
        '${getStringValue(data.closed_on)}',
        ARRAY[${data?.ai_metadata?.tags?.map((t) => `'${t}'`) || []}],
        '${getStringValue(data.ai_metadata?.sentiment)}',
        '${getStringValue(data.ai_metadata?.urgency)}',
        ARRAY[${data.feedback?.map((o) => getFeedbackNumber(o.feedback)) || []}],
        '${getStringValue(data.last_reply_by_vendor_ts)}',
        '${getStringValue(data.last_reply_by_customer_ts)}',
        '${JSON.stringify(data.sla_metrics || "")}'
    )`;
    return str;
  },

  customFields: (data: any) => {
    const str = `INSERT INTO customFields VALUES (
          '${data._id}', 
          '${data.field_hash}', 
          ARRAY[${data.field_values.map((v) => `'${escapeString(v)}'`)}]
        )`;
    return str;
  },

  relationships: (data: RelationshipType) => {
    const str = `INSERT INTO relationships VALUES (
          '${data._id}', 
          '${data.channel_id}', 
          '${data.channel_is_ext_shared}', 
          '${data.channel_is_private}', 
          '${data.channel_name}', 
          '${data.created_at}', 
          '${data.updated_at}', 
          ARRAY[${data.csmUsers?.map((u) => `'${u}'`)}], 
          '${escapeString(data.customer_name)}', 
          '${data.initial_installation_id}', 
          ARRAY[${data.solutionEngineers?.map((u) => `'${u}'`)}], 
          '${data.is_internal_helpdesk ?? false}',
          '${escapeString(data.vendor_name)}'
        )`;
    return str;
  },

  crmFields: (data: any) => {
    const str = `INSERT INTO crmFields VALUES (
          '${data._id}', 
          '${escapeString(data.field_name)}', 
          ARRAY[${data.field_values.map((v) => `'${escapeString(v)}'`)}]
        )`;
    return str;
  },

  users: (data: UserType) => {
    const str = `INSERT INTO users VALUES (
          '${getStringValue(data._id)}',
          '${escapeString(getStringValue(data.name))}',
          '${getStringValue(data.created_at)}',
          '${getStringValue(data.updated_at)}',
          '${getStringValue(data.slack_team_id)}'
        )`;
    return str;
  },

  subStatuses: (data: SubStatusType) => {
    const str = `INSERT INTO subStatuses VALUES (
          '${getStringValue(data._id)}',
          '${escapeString(getStringValue(data.description))}',
          '${getStringValue(data.installation)}',
          '${Boolean(data.isAIManaged)}',
          '${Boolean(data.isEnabled)}',
          '${escapeString(getStringValue(data.name))}',
          '${getStringValue(data.parent)}'
        )`;
    return str;
  },
};

export const TABLE_UPDATE_QUERIES = {
  requests: (data: RequestType) => {
    const str = `UPDATE requests SET
          request_id = ${data.request_id || 0}, 
          installation_id = '${data.installation_id}', 
          installation_team_id = '${data.installation_team_id}', 
          message_details_ts = '${data.message_details_ts}', 
          relationship_id = '${data.relationship_id}', 
          status = '${data.status}', 
          original_message_user_id = '${data.original_message_user_id}', 
          original_message_source = '${data.original_message_source}', 
          original_message_ts = '${data.original_message_ts}', 
          assigned_to_user_id = '${data.assigned_to_user_id || ""}', 
          assigned_by_user_id = '${data.assigned_by_user_id || ""}', 
          created_at = '${data.created_at}', 
          updated_at = '${data.updated_at}', 
          sub_status = '${escapeString(data.sub_status) || ""}',
          finalResolutionSlaOrder = '${getStringValue(data.finalResolutionSlaOrder)}',
          firstResponseSlaOrder = '${getStringValue(data.firstResponseSlaOrder)}',
          first_response_time = '${getStringValue(data.first_response_time?.ts)}',
          internalHelpDeskIndex = '${getStringValue(data.internalHelpDeskIndex)}',
          lastCustomerReply = '${getStringValue(data.lastCustomerReply)}',
          lastCustomerReplyOrder = '${getStringValue(data.lastCustomerReplyOrder)}',
          lastReplyOrder = '${getStringValue(data.lastReplyOrder)}',
          slaBreached = '${getStringValue(data.slaBreached)}',
          closed_on = '${getStringValue(data.closed_on)}',
          aiTags = ARRAY[${data?.ai_metadata.tags?.map((t) => `'${escapeString(t)}'`)}],
          sentiment = '${getStringValue(data.ai_metadata.sentiment)}',
          urgency = '${getStringValue(data.ai_metadata.urgency)}',
          feedback = ARRAY[${data.feedback?.map((o) => getFeedbackNumber(o.feedback)) || []}],
          last_reply_by_vendor_ts = '${getStringValue(data.last_reply_by_vendor_ts)}',
          last_reply_by_customer_ts = '${getStringValue(data.last_reply_by_customer_ts)}',
          sla_metrics = '${JSON.stringify(data.sla_metrics || "")}'
        WHERE _id = '${data._id}'`;
    return str;
  },
  relationships: (data: RelationshipType) => {
    console.log("UPDATE relationships SET", data);
    const str = `UPDATE relationships SET
          channel_id = '${data.channel_id}', 
          channel_is_ext_shared = '${data.channel_is_ext_shared}', 
          channel_is_private = '${data.channel_is_private}', 
          channel_name = '${data.channel_name}', 
          created_at = '${data.created_at}', 
          updated_at = '${data.updated_at}', 
          csmUsers = ARRAY[${data.csmUsers?.map((u) => `'${u}'`)}], 
          customer_name = '${escapeString(data.customer_name)}', 
          initial_installation_id = '${data.initial_installation_id}', 
          solutionEngineers = ARRAY[${data.solutionEngineers?.map((u) => `'${u}'`)}], 
          is_internal_helpdesk = '${data.is_internal_helpdesk ?? false}',
          vendor_name = '${escapeString(data.vendor_name)}'
        WHERE _id = '${data._id}'`;
    return str;
  },
};

const SUPPORTED_TABLES = ["requests", "relationships"] as const;

type Props = {
  dataList: any[];
  table: (typeof SUPPORTED_TABLES)[number];
};

export const insertOrUpdateDataToDuckDB = async (props: Props) => {
  try {
    const conn = useDuckDBStore.getState().dbConnection;
    if (conn === null) {
      consoleInDev("DB connection is null");
      return;
    }
    const { dataList, table } = props;
    if (!dataList.length) {
      console.log("No data to insert");
      return;
    }
    if (!SUPPORTED_TABLES.includes(table)) {
      console.log("Invalid table name");
      return;
    }

    const dataIdListString = dataList.map((data) => data._id).join("','");

    const checkExistingDataQuery = await conn.query(
      `SELECT * FROM ${table} WHERE _id IN ('${dataIdListString}')`
    );

    const result: any[] = [];
    for (const row of checkExistingDataQuery) {
      const rowData = {};
      for (const [key, value] of Object.entries(row)) {
        // @ts-expect-error TS does not know about this type
        if (value.constructor.name === "_Vector") {
          // @ts-expect-error TS does not know about this type
          rowData[key] = value.toArray();
        } else {
          rowData[key] = value;
        }
      }
      result.push(rowData);
    }

    const existingDataIds = result.map((d) => d._id);

    const listOfIdsToInsert = dataList
      .filter((d) => !existingDataIds.includes(d._id))
      .map((d) => d._id);

    const listOfIdsToUpdate = dataList
      .filter((d) => existingDataIds.includes(d._id))
      .map((d) => d._id);

    const insertDataList = listOfIdsToInsert.map((_id) =>
      dataList.find((d) => d._id === _id)
    );

    const updateDataList = listOfIdsToUpdate.map((_id) =>
      dataList.find((d) => d._id === _id)
    );

    if (insertDataList.length) {
      const insertPromises = insertDataList.map((data) =>
        conn.query(TABLE_INSERT_QUERIES[table](data))
      );
      await Promise.all(insertPromises);
    }

    if (updateDataList.length) {
      const updatePromises = updateDataList.map((data) =>
        conn.query(TABLE_UPDATE_QUERIES[table](data))
      );
      await Promise.all(updatePromises);
    }
  } catch (error) {
    console.log("Error in insertOrUpdateDataToDuckDB", error);
  }
};

const getColumnByIndexedKey = (indexedKey) => {
  switch (indexedKey) {
    case "assigned_to_user_id":
      return "assigned_to_user_id";

    case "status":
      return "status";

    case "ai_metadata.sentiment":
      return "sentiment";

    case "ai_metadata.urgency":
      return "urgency";

    default:
      return indexedKey;
  }
};

const handleRelationshipIdFilter = (filter) => {
  const valuesStr = filter.values.map((v) => `'${v}'`).join(",");

  switch (filter.name) {
    case "Account owners": {
      return `rel.csmUsers && ARRAY[${valuesStr}]`;
    }

    case "Solution Engineer": {
      return `rel.solutionEngineers && ARRAY[${valuesStr}]`;
    }

    case "Channel": {
      return `rel.channel_id IN (${valuesStr})`;
    }

    default: {
      return `rel.customer_name IN (${valuesStr})`;
    }
  }
};

const handleCustomFieldFilter = (filter) => {
  const valuesStr = filter.values.map((v) => `'${v}'`).join(",");
  return `cf.field_hash = '${filter.indexed_key}' AND cf.field_values && ARRAY[${valuesStr}]`;
};

const handleCRMFieldFilter = (filter) => {
  const valuesStr = filter.values.map((v) => `'${v}'`).join(",");
  return `crm.field_name = '${filter.indexed_key}' AND crm.field_values && ARRAY[${valuesStr}]`;
};

const CUSTOM_FIELD_JOIN = (requestTableAliasId: TemplateStringsArray) => `
    INNER JOIN
      customFields cf
    ON
      ${requestTableAliasId} = cf._id`;

const CRM_FIELD_JOIN = (relationTableAliasId: TemplateStringsArray) => `
    INNER JOIN
      crmFields crm
    ON
      ${relationTableAliasId} = crm._id`;

const RELATIONSHIP_JOIN = (newTableAlias, prevTableAlias) => `
    INNER JOIN
      relationships ${newTableAlias}
    ON
      ${prevTableAlias}relationship_id = ${newTableAlias}._id`;

const CUSTOMFIELD_JOIN = (data, requestTableAliasId) => {
  const innerJoinString = data
    .map((item, index) => {
      if (item.indexed_key.startsWith("custom_")) {
        const alias = `d_cf${index}`;
        return `INNER JOIN customFields ${alias} ON ${requestTableAliasId} = ${alias}._id`;
      }
      return null;
    })
    .filter(Boolean)
    .join(" ");
  return innerJoinString;
};

const CRMFIELD_JOIN = (data, requestTableAliasId) => {
  const innerJoinString = data
    .map((item, index) => {
      if (item.indexed_key.startsWith("crm_")) {
        const alias = `d_crm${index}`;
        return `INNER JOIN crmFields ${alias} ON ${requestTableAliasId} = ${alias}._id`;
      }
      return null;
    })
    .filter(Boolean)
    .join(" ");
  return innerJoinString;
};

export const generateJoinAndConditions = (data) => {
  const customInnerJoinString = data
    .map((item, index) => {
      if (item.indexed_key.startsWith("custom_")) {
        const alias = `d_cf${index}`;
        return `INNER JOIN customFields ${alias} ON requests._id = ${alias}._id`;
      }
      return null;
    })
    .filter(Boolean)
    .join(" ");

  const crmInnerJoinString = data
    .map((item, index) => {
      if (item.indexed_key.startsWith("crm_")) {
        const alias = `d_crm${index}`;
        return `INNER JOIN crmFields ${alias} ON requests.relationship_id = ${alias}._id`;
      }
      return null;
    })
    .filter(Boolean)
    .join(" ");

  const conditionsArray = data
    .map((item, index) => {
      if (item.indexed_key.startsWith("custom_")) {
        const alias = `d_cf${index}`;
        const fieldHashCondition = `${alias}.field_hash = '${item.indexed_key}'`;
        const fieldValuesCondition = `${alias}.field_values && ARRAY[${item.values.map((value) => `'${value}'`).join(", ")}]`;
        return `${fieldHashCondition} AND ${fieldValuesCondition}`;
      }
      return null;
    })
    .filter(Boolean);

  const crmWhereClauseString = data
    .map((item, index) => {
      if (item.indexed_key.startsWith("crm_")) {
        const alias = `d_crm${index}`;
        const fieldValues = item.values.map((value) => `'${value}'`).join(", ");
        return `${alias}.field_name = '${item.indexed_key}' AND ${alias}.field_values && ARRAY[${fieldValues}]`;
      }
      return null;
    })
    .filter(Boolean)
    .join(" AND ");

  const customWhereClauseString =
    conditionsArray.length > 0 ? conditionsArray.join(" AND ") : "";

  return {
    customInnerJoinString,
    customWhereClauseString,
    crmInnerJoinString,
    crmWhereClauseString,
  };
};

export const DUCK_DB_CHART_QUERIES = {
  relationshipsByRequestCount: ({
    fromDate,
    toDate,
    filters,
    dateType,
    fromDateStr,
    toDateStr,
  }) => {
    const queryInfo = getFinalQuery(filters, "", "rel.");
    let query;

    switch (dateType) {
      case "day":
        query = `--sql 
            WITH RECURSIVE date_range AS (
              SELECT
                  CAST('${fromDateStr}' AS DATE) AS start_date,
                  CAST('${toDateStr}' AS DATE) AS end_date
            ),
            date_series AS (
                SELECT start_date AS day_date
                FROM date_range
                UNION ALL
                SELECT day_date + INTERVAL '1 day'
                FROM date_series
                WHERE day_date + INTERVAL '1 day' <= (SELECT end_date FROM date_range)
            ),
            created_requests AS (
                SELECT
                    ds.day_date,
                    COUNT(r._id) AS created_request_count
                FROM
                    date_series ds
                LEFT JOIN
                    requests r ON CAST(r.created_at AS DATE) = ds.day_date
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                WHERE
                    r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                AND
                    r.internalHelpDeskIndex = 'no'
                AND
                    r.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    ds.day_date
            ),
            closed_requests AS (
                SELECT
                    ds.day_date,
                    COUNT(r._id) AS closed_request_count
                FROM
                    date_series ds
                LEFT JOIN
                    requests r ON CAST(r.closed_on AS DATE) = ds.day_date
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                WHERE
                    r.status = 'CLOSED'
                AND
                    r.internalHelpDeskIndex = 'no'
                AND
                    r.closed_on BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    ds.day_date
            )
            SELECT
                ds.day_date,
                COALESCE(cr.created_request_count, 0) AS total_created_requests,
                COALESCE(clr.closed_request_count, 0) AS total_closed_requests
            FROM
                date_series ds
            LEFT JOIN created_requests cr
                ON ds.day_date = cr.day_date
            LEFT JOIN closed_requests clr
                ON ds.day_date = clr.day_date
            ORDER BY
                ds.day_date`;
        break;
      case "week":
        query = `--sql 
            WITH RECURSIVE date_range AS (
              SELECT 
                  CAST('${fromDateStr}' AS DATE) AS start_date,
                  CAST('${toDateStr}' AS DATE) AS end_date
          ),
          date_series AS (
            SELECT
                DATE_TRUNC('week', start_date) AS week_start,
                DATE_TRUNC('week', start_date) + INTERVAL '6 days' AS week_end
            FROM date_range
            UNION ALL
            SELECT
                DATE_TRUNC('week', week_start + INTERVAL '1 week'),
                DATE_TRUNC('week', week_start + INTERVAL '1 week') + INTERVAL '6 days'
            FROM date_series
            WHERE DATE_TRUNC('week', week_start + INTERVAL '1 week') <= (SELECT end_date FROM date_range)
        ),
          created_requests AS (
              SELECT
                  ds.week_start,
                  ds.week_end,
                  COUNT(r._id) AS created_request_count
              FROM
                  date_series ds
              LEFT JOIN
                  requests r ON CAST(r.created_at AS DATE) BETWEEN ds.week_start AND ds.week_end
                  ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                  ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                  ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
              WHERE
                  r.status  in ('OPEN','INPROGRESS','ONHOLD','CLOSED')
              AND
                  r.internalHelpDeskIndex = 'no'
              AND
                  r.created_at BETWEEN '${fromDate}' AND '${toDate}'
                  ${queryInfo.conditionsString}
                  ${queryInfo.requestQuery}
                  ${queryInfo.relationshipQuery}
              GROUP BY
                  ds.week_start, ds.week_end
          ),
          closed_requests AS (
              SELECT
                  ds.week_start,
                  ds.week_end,
                  COUNT(r._id) AS closed_request_count
              FROM
                  date_series ds
              LEFT JOIN
                  requests r ON CAST(r.closed_on AS DATE) BETWEEN ds.week_start AND ds.week_end
                  ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                  ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                  ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
              WHERE
                  r.status = 'CLOSED'
              AND
                  r.internalHelpDeskIndex = 'no'
              AND
                  closed_on BETWEEN '${fromDate}' AND '${toDate}'
                  ${queryInfo.conditionsString}
                  ${queryInfo.requestQuery}
                  ${queryInfo.relationshipQuery}
              GROUP BY
                  ds.week_start, ds.week_end
          )
          SELECT
              ds.week_start,
              ds.week_end,
              COALESCE(cr.created_request_count, 0) AS total_created_requests,
              COALESCE(clr.closed_request_count, 0) AS total_closed_requests
          FROM
              date_series ds
          LEFT JOIN created_requests cr
              ON ds.week_start = cr.week_start AND ds.week_end = cr.week_end
          LEFT JOIN closed_requests clr
              ON ds.week_start = clr.week_start AND ds.week_end = clr.week_end
          ORDER BY
              ds.week_start`;
        break;

      case "month":
        query = `--sql 
              WITH RECURSIVE date_range AS (
                SELECT
                    DATE_TRUNC('month', CAST('${fromDate}' AS DATE)) AS start_date,
                    CAST('${toDate}' AS DATE) AS end_date
            ),
            date_series AS (
                SELECT
                    DATE_TRUNC('month', start_date) AS month_start,
                    DATE_TRUNC('month', start_date) + INTERVAL '1 month' - INTERVAL '1 day' AS month_end
                FROM date_range
                UNION ALL
                SELECT
                    month_start + INTERVAL '1 month',
                    DATE_TRUNC('month', month_start + INTERVAL '1 month') + INTERVAL '1 month' - INTERVAL '1 day'
                FROM date_series
                WHERE month_start + INTERVAL '1 month' <= (SELECT end_date FROM date_range)
            ),
            created_requests AS (
                SELECT
                    ds.month_start,
                    ds.month_end,
                    COUNT(r._id) AS created_request_count
                FROM 
                    date_series ds
                LEFT JOIN 
                    requests r
                    ON CAST(r.created_at AS DATE) BETWEEN ds.month_start AND ds.month_end
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                WHERE 
                    r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                AND 
                    r.internalHelpDeskIndex = 'no'
                AND 
                    r.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY ds.month_start, ds.month_end
            ),
            closed_requests AS (
                SELECT
                    ds.month_start,
                    ds.month_end,
                    COUNT(r._id) AS closed_request_count
                FROM date_series ds
                LEFT JOIN requests r
                    ON CAST(r.closed_on AS DATE) BETWEEN ds.month_start AND ds.month_end
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                WHERE 
                    r.status = 'CLOSED'
                AND 
                    r.internalHelpDeskIndex = 'no'
                AND 
                    r.closed_on BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY 
                    ds.month_start, ds.month_end
            )
            SELECT
                ds.month_start || ' - ' || ds.month_end AS period,
                COALESCE(cr.created_request_count, 0) AS total_created_requests,
                COALESCE(clr.closed_request_count, 0) AS total_closed_requests
            FROM date_series ds
            LEFT JOIN created_requests cr
                ON ds.month_start = cr.month_start AND ds.month_end = cr.month_end
            LEFT JOIN closed_requests clr
                ON ds.month_start = clr.month_start AND ds.month_end = clr.month_end
            ORDER BY ds.month_start`;
        break;
      default:
    }

    console.log({ filtersQuery: query });

    return query.split("--sql")[1];
  },

  breakdownTable: ({ fromDate, toDate, filters }) => {
    const queryInfo = getFinalQuery(filters, "", "rel.");

    const query = `--sql
    WITH request_closure_p90 AS (
      SELECT
        distinct r.relationship_id,
        quantile_cont(
          EXTRACT(
            EPOCH
            FROM
              (
                CAST(r.closed_on AS TIMESTAMP) - CAST(r.created_at AS TIMESTAMP)
              )
          ),
          0.90
        ) AS request_closure_p90
      FROM
        requests r
          ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
          ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
          ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
      WHERE
      r.status = 'CLOSED'
        AND r.internalHelpDeskIndex = 'no'
        AND r.closed_on IS NOT NULL
        AND r.closed_on != ''
        AND r.created_at BETWEEN '${fromDate}' AND '${toDate}'
        ${queryInfo.conditionsString}
        ${queryInfo.requestQuery}
        ${queryInfo.relationshipQuery}
      GROUP BY
      r.relationship_id
      
    ),
    request_closure_p70 AS(
      SELECT
      distinct 
      r.relationship_id,
        quantile_cont(
          EXTRACT(
            EPOCH
            FROM
              (
                CAST(r.closed_on AS TIMESTAMP) - CAST(r.created_at AS TIMESTAMP)
              )
          ),
          0.70
        ) AS request_closure_p70
      FROM
        requests r
        ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
        ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
        ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
      WHERE
      r.status = 'CLOSED'
        AND r.internalHelpDeskIndex = 'no'
        AND r.closed_on IS NOT NULL
        AND r.closed_on != ''
        AND r.created_at BETWEEN '${fromDate}' AND '${toDate}'
        ${queryInfo.conditionsString}
        ${queryInfo.requestQuery}
        ${queryInfo.relationshipQuery}
      GROUP BY
      r.relationship_id
      
    ),
    request_closure_p50 AS(
        SELECT
        distinct 
        r.relationship_id,
          quantile_cont(
            EXTRACT(
              EPOCH
              FROM
                (
                  CAST(r.closed_on AS TIMESTAMP) - CAST(r.created_at AS TIMESTAMP)
                )
            ),
            0.50
          ) AS request_closure_p50
        FROM
          requests r
          ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
          ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
          ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
        WHERE
        r.status = 'CLOSED'
          AND r.internalHelpDeskIndex = 'no'
          AND r.closed_on IS NOT NULL
          AND r.closed_on != ''
          AND r.created_at BETWEEN '${fromDate}' AND '${toDate}'
          ${queryInfo.conditionsString}
          ${queryInfo.requestQuery}
          ${queryInfo.relationshipQuery}
        GROUP BY
        r.relationship_id
        
      ),
    first_response_p90 AS (
      SELECT
      distinct 
      r.relationship_id,
        quantile_cont(
          CAST(r.first_response_time AS DOUBLE) - CAST(r.original_message_ts AS DOUBLE),
          0.90
        ) AS first_response_p90
      FROM
        requests r
        ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
        ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
        ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
      WHERE
      r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
        AND r.internalHelpDeskIndex = 'no'
        AND r.first_response_time IS NOT NULL
        AND r.first_response_time != ''
        AND r.first_response_time != '[]'
        AND r.created_at BETWEEN '${fromDate}' AND '${toDate}'
        ${queryInfo.conditionsString}
        ${queryInfo.requestQuery}
        ${queryInfo.relationshipQuery}
      GROUP BY
      r.relationship_id
    ),
    first_response_p70 AS (
      SELECT
      distinct 
      r.relationship_id,
        quantile_cont(
          CAST(r.first_response_time AS DOUBLE) - CAST(r.original_message_ts AS DOUBLE),
          0.70
        ) AS first_response_p70
      FROM
        requests r
        ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
        ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
        ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
      WHERE
      r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
        AND r.internalHelpDeskIndex = 'no'
        AND r.first_response_time IS NOT NULL
        AND r.first_response_time != ''
        AND r.first_response_time != '[]'
        AND r.created_at BETWEEN '${fromDate}' AND '${toDate}'
        ${queryInfo.conditionsString}
        ${queryInfo.requestQuery}
        ${queryInfo.relationshipQuery}
      GROUP BY
      r.relationship_id
    ),
    first_response_p50 AS (
        SELECT
        distinct 
        r.relationship_id,
          quantile_cont(
            CAST(r.first_response_time AS DOUBLE) - CAST(r.original_message_ts AS DOUBLE),
            0.50
          ) AS first_response_p50
        FROM
          requests r
          ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
          ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
          ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
        WHERE
        r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
          AND r.internalHelpDeskIndex = 'no'
          AND r.first_response_time IS NOT NULL
          AND r.first_response_time != ''
          AND r.first_response_time != '[]'
          AND r.created_at BETWEEN '${fromDate}' AND '${toDate}'
          ${queryInfo.conditionsString}
          ${queryInfo.requestQuery}
          ${queryInfo.relationshipQuery}
        GROUP BY
        r.relationship_id
      ),
    combined_metrics AS (
      SELECT
      distinct 
        r.relationship_id,
        COUNT(distinct r._id) AS request_count,
        MAX(r.created_at) AS latest_created_at,
        COUNT(distinct r._id) FILTER (
            WHERE
            cast(r.sla_metrics->>'first_response_sla'->>'is_breached' as bool) =true
          ) AS first_response_sla_count,
          COUNT(distinct r._id) FILTER (
            WHERE
            cast(r.sla_metrics->>'resolution_sla'->>'is_breached' as bool) = true
          ) AS final_resolution_sla_count
      FROM
          requests r
          ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
          ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
          ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
      WHERE
      r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
        AND r.internalHelpDeskIndex = 'no'
        AND r.created_at BETWEEN '${fromDate}' AND '${toDate}'
        ${queryInfo.conditionsString}
        ${queryInfo.requestQuery}
        ${queryInfo.relationshipQuery}
      GROUP BY
      r.relationship_id
    )
    SELECT
    distinct 
      rel.customer_name,
      rel.channel_name,
      rel.csmUsers,
      rel.solutionEngineers,
      rel._id AS relationship_id,
      cm.request_count,
      cm.latest_created_at,
      rcp5.request_closure_p50,
      rcp.request_closure_p70,
      frp5.first_response_p50,
      frp.first_response_p70,
      rcp9.request_closure_p90,
      frp9.first_response_p90,
      cm.first_response_sla_count,
      cm.final_resolution_sla_count
    FROM
      combined_metrics cm
      INNER JOIN relationships rel ON rel._id = cm.relationship_id
      LEFT JOIN request_closure_p50 rcp5 ON rel._id = rcp5.relationship_id
      LEFT JOIN request_closure_p70 rcp ON rel._id = rcp.relationship_id
      LEFT JOIN request_closure_p90 rcp9 ON rel._id = rcp9.relationship_id
      LEFT JOIN first_response_p50 frp5 ON rel._id = frp5.relationship_id
      LEFT JOIN first_response_p90 frp9 ON rel._id = frp9.relationship_id
      LEFT JOIN first_response_p70 frp ON rel._id = frp.relationship_id
    ORDER BY 
      cm.request_count desc`;

    console.log({ breakdownTableQuery: query });

    return query.split("--sql")[1];
  },

  totalRequests: ({ fromDate, toDate, filters }) => {
    const diffDate = differenceInDays(new Date(toDate), new Date(fromDate));

    const pastToDate = subDays(fromDate, 1);
    const pastFromDate = subDays(pastToDate, diffDate);

    const pastToDateStr = `${format(pastToDate, "yyyy-MM-dd")}T23:59:59.999Z`;
    const pastFromDateStr = `${format(pastFromDate, "yyyy-MM-dd")}T00:00:00.000Z`;

    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });

    const query = `--sql 
      WITH current_period AS (
        SELECT COUNT(distinct requests._id) AS count
        FROM requests
        ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
        ${
          queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter
            ? RELATIONSHIP_JOIN("rel", "requests.")
            : ""
        }
        ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
        WHERE status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
        AND requests.internalHelpDeskIndex = 'no'
        AND requests.created_at BETWEEN '${fromDate}' AND '${toDate}'
        ${queryInfo.conditionsString}
        ${queryInfo.requestQuery}
        ${queryInfo.relationshipQuery}
      ),
      previous_period AS (
          SELECT COUNT(distinct requests._id) AS count
          FROM requests
          ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
          ${
            queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter
              ? RELATIONSHIP_JOIN("rel", "requests.")
              : ""
          }
          ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
          WHERE status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
          AND requests.internalHelpDeskIndex = 'no'
          AND requests.created_at BETWEEN '${pastFromDateStr}' AND '${pastToDateStr}'
          ${queryInfo.requestQuery}
          ${queryInfo.relationshipQuery}
      )
      SELECT 
          (SELECT count FROM current_period) AS current_count,
          (SELECT count FROM previous_period) AS previous_count`;

    console.log({ totalRequests: query });

    return query.split("--sql")[1];
  },

  openRequests: ({ fromDate, toDate, filters }) => {
    const diffDate = differenceInDays(new Date(toDate), new Date(fromDate));

    const pastToDate = subDays(fromDate, 1);
    const pastFromDate = subDays(pastToDate, diffDate);

    const pastToDateStr = `${format(pastToDate, "yyyy-MM-dd")}T23:59:59.999Z`;
    const pastFromDateStr = `${format(pastFromDate, "yyyy-MM-dd")}T00:00:00.000Z`;

    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });

    const query = `--sql 
      WITH current_period AS (
        SELECT COUNT(distinct requests._id) AS count
        FROM requests
        ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
        ${
          queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter
            ? RELATIONSHIP_JOIN("rel", "requests.")
            : ""
        }
        ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
        WHERE status IN ('OPEN', 'INPROGRESS', 'ONHOLD')
        AND requests.internalHelpDeskIndex = 'no'
        AND requests.created_at BETWEEN '${fromDate}' AND '${toDate}'
        ${queryInfo.conditionsString}
        ${queryInfo.requestQuery}
        ${queryInfo.relationshipQuery}
      ),
      previous_period AS (
          SELECT COUNT(distinct requests._id) AS count
          FROM requests
          ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
          ${
            queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter
              ? RELATIONSHIP_JOIN("rel", "requests.")
              : ""
          }
          ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
          WHERE status IN ('OPEN', 'INPROGRESS', 'ONHOLD')
          AND requests.internalHelpDeskIndex = 'no'
          AND requests.created_at BETWEEN '${pastFromDateStr}' AND '${pastToDateStr}'
          ${queryInfo.conditionsString}
          ${queryInfo.requestQuery}
          ${queryInfo.relationshipQuery}
      )
      SELECT 
          (SELECT count FROM current_period) AS current_count,
          (SELECT count FROM previous_period) AS previous_count`;

    console.log({ openRequests: query });

    return query.split("--sql")[1];
  },

  firstResponse: ({ fromDate, toDate, filters }) => {
    const diffDate = differenceInDays(new Date(toDate), new Date(fromDate));

    const pastToDate = subDays(fromDate, 1);
    const pastFromDate = subDays(pastToDate, diffDate);

    const pastToDateStr = `${format(pastToDate, "yyyy-MM-dd")}T23:59:59.999Z`;
    const pastFromDateStr = `${format(pastFromDate, "yyyy-MM-dd")}T00:00:00.000Z`;

    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });

    const query = `--sql 
        WITH current_period AS (
          SELECT 
          distinct 
              quantile_cont(CAST(first_response_time AS BIGINT) - CAST(original_message_ts AS BIGINT), 0.90) AS p90_first_response
          FROM 
              requests
              ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
              ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "requests.") : ""}
              ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
          WHERE
              status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
              AND first_response_time IS NOT NULL 
              AND first_response_time != '' 
              AND first_response_time != '{}'
              AND internalHelpDeskIndex = 'no'
              AND requests.created_at BETWEEN '${fromDate}' AND '${toDate}'
              ${queryInfo.conditionsString}
              ${queryInfo.requestQuery}
              ${queryInfo.relationshipQuery}
          ),
          previous_period AS (
              SELECT 
              distinct 
              quantile_cont(CAST(first_response_time AS BIGINT) - CAST(original_message_ts AS BIGINT), 0.90) AS p90_first_response
              FROM 
                  requests
                  ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
                  ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "requests.") : ""}
                  ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
              WHERE 
                  status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                  AND first_response_time IS NOT NULL 
                  AND first_response_time != '' 
                  AND first_response_time != '{}'
                  AND internalHelpDeskIndex = 'no'
                  AND requests.created_at BETWEEN '${pastFromDateStr}' AND '${pastToDateStr}'
                  ${queryInfo.conditionsString}
                  ${queryInfo.requestQuery}
                  ${queryInfo.relationshipQuery}
          )
          SELECT 
              (SELECT p90_first_response FROM current_period) AS current_p70,
              (SELECT p90_first_response FROM previous_period) AS previous_p70`;

    console.log({ firstResponse: query });

    return query.split("--sql")[1];
  },

  firstResponseP50: ({ fromDate, toDate, filters }) => {
    const diffDate = differenceInDays(new Date(toDate), new Date(fromDate));

    const pastToDate = subDays(fromDate, 1);
    const pastFromDate = subDays(pastToDate, diffDate);

    const pastToDateStr = `${format(pastToDate, "yyyy-MM-dd")}T23:59:59.999Z`;
    const pastFromDateStr = `${format(pastFromDate, "yyyy-MM-dd")}T00:00:00.000Z`;

    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });

    const query = `--sql 
        WITH current_period AS (
          SELECT 
          distinct
              quantile_cont(CAST(first_response_time AS BIGINT) - CAST(original_message_ts AS BIGINT), 0.50) AS p50_first_response
          FROM 
              requests
              ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
              ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "requests.") : ""}
              ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
          WHERE
              status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
              AND first_response_time IS NOT NULL 
              AND first_response_time != '' 
              AND first_response_time != '{}'
              AND internalHelpDeskIndex = 'no'
              AND requests.created_at BETWEEN '${fromDate}' AND '${toDate}'
              ${queryInfo.conditionsString}
              ${queryInfo.requestQuery}
              ${queryInfo.relationshipQuery}
          ),
          previous_period AS (
              SELECT 
              distinct
              quantile_cont(CAST(first_response_time AS BIGINT) - CAST(original_message_ts AS BIGINT), 0.50) AS p50_first_response
              FROM 
                  requests
                  ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
                  ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "requests.") : ""}
                  ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
              WHERE 
                  status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                  AND first_response_time IS NOT NULL 
                  AND first_response_time != '' 
                  AND first_response_time != '{}'
                  AND internalHelpDeskIndex = 'no'
                  AND requests.created_at BETWEEN '${pastFromDateStr}' AND '${pastToDateStr}'
                  ${queryInfo.conditionsString}
                  ${queryInfo.requestQuery}
                  ${queryInfo.relationshipQuery}
          )
          SELECT 
              (SELECT p50_first_response FROM current_period) AS current_p70,
              (SELECT p50_first_response FROM previous_period) AS previous_p70`;

    console.log({ firstResponseP50: query });

    return query.split("--sql")[1];
  },

  requestClouserTime: ({ fromDate, toDate, filters }) => {
    const diffDate = differenceInDays(new Date(toDate), new Date(fromDate));

    const pastToDate = subDays(fromDate, 1);
    const pastFromDate = subDays(pastToDate, diffDate);

    const pastToDateStr = `${format(pastToDate, "yyyy-MM-dd")}T23:59:59.999Z`;
    const pastFromDateStr = `${format(pastFromDate, "yyyy-MM-dd")}T00:00:00.000Z`;

    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });

    const query = `--sql 
        WITH current_period AS (
          SELECT 
          distinct 
          quantile_cont(EXTRACT(EPOCH FROM (CAST(closed_on AS TIMESTAMP) - CAST(requests.created_at AS TIMESTAMP))), 0.90) AS p90_request_closure_time_seconds
          FROM
              requests
              ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
              ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "requests.") : ""}
              ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
          WHERE 
              status = 'CLOSED' 
              AND closed_on IS NOT NULL 
              AND closed_on != '' 
              AND requests.created_at BETWEEN '${fromDate}' AND '${toDate}'
              AND requests.internalHelpDeskIndex = 'no'
              ${queryInfo.conditionsString}
              ${queryInfo.requestQuery}
              ${queryInfo.relationshipQuery}
            ),
          previous_period AS (
              SELECT 
              distinct 
              quantile_cont(EXTRACT(EPOCH FROM (CAST(closed_on AS TIMESTAMP) - CAST(requests.created_at AS TIMESTAMP))), 0.90) AS p90_request_closure_time_seconds
              FROM 
                  requests
                  ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
                  ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "requests.") : ""}
                  ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
              WHERE 
                  status = 'CLOSED' 
                  AND closed_on IS NOT NULL 
                  AND closed_on != '' 
                  AND requests.created_at BETWEEN '${pastFromDateStr}' AND '${pastToDateStr}'
                  AND requests.internalHelpDeskIndex = 'no'
                  ${queryInfo.conditionsString}
                  ${queryInfo.requestQuery}
                  ${queryInfo.relationshipQuery}
                )
          SELECT 
              (SELECT p90_request_closure_time_seconds FROM current_period) AS current_p70,
              (SELECT p90_request_closure_time_seconds FROM previous_period) AS previous_p70`;

    console.log({ requestClouserTime: query });

    return query.split("--sql")[1];
  },

  requestClouserTimeP50: ({ fromDate, toDate, filters }) => {
    const diffDate = differenceInDays(new Date(toDate), new Date(fromDate));

    const pastToDate = subDays(fromDate, 1);
    const pastFromDate = subDays(pastToDate, diffDate);

    const pastToDateStr = `${format(pastToDate, "yyyy-MM-dd")}T23:59:59.999Z`;
    const pastFromDateStr = `${format(pastFromDate, "yyyy-MM-dd")}T00:00:00.000Z`;

    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });

    const query = `--sql 
        WITH current_period AS (
          SELECT 
          distinct 
          quantile_cont(EXTRACT(EPOCH FROM (CAST(closed_on AS TIMESTAMP) - CAST(requests.created_at AS TIMESTAMP))), 0.50) AS p50_request_closure_time_seconds
          FROM
              requests
              ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
              ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "requests.") : ""}
              ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
          WHERE 
              status = 'CLOSED' 
              AND closed_on IS NOT NULL 
              AND closed_on != '' 
              AND requests.created_at BETWEEN '${fromDate}' AND '${toDate}'
              AND requests.internalHelpDeskIndex = 'no'
              ${queryInfo.conditionsString}
              ${queryInfo.requestQuery}
              ${queryInfo.relationshipQuery}
            ),
          previous_period AS (
              SELECT 
              distinct 
              quantile_cont(EXTRACT(EPOCH FROM (CAST(closed_on AS TIMESTAMP) - CAST(requests.created_at AS TIMESTAMP))), 0.50) AS p50_request_closure_time_seconds
              FROM 
                  requests
                  ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
                  ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "requests.") : ""}
                  ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
              WHERE 
                  status = 'CLOSED' 
                  AND closed_on IS NOT NULL 
                  AND closed_on != '' 
                  AND requests.created_at BETWEEN '${pastFromDateStr}' AND '${pastToDateStr}'
                  AND requests.internalHelpDeskIndex = 'no'
                  ${queryInfo.conditionsString}
                  ${queryInfo.requestQuery}
                  ${queryInfo.relationshipQuery}
                )
          SELECT 
              (SELECT p50_request_closure_time_seconds FROM current_period) AS current_p70,
              (SELECT p50_request_closure_time_seconds FROM previous_period) AS previous_p70`;

    console.log({ requestClouserTimeP50: query });

    return query.split("--sql")[1];
  },

  firstResponseAlertCount: ({ fromDate, toDate, filters }) => {
    const diffDate = differenceInDays(new Date(toDate), new Date(fromDate));

    const pastToDate = subDays(fromDate, 1);
    const pastFromDate = subDays(pastToDate, diffDate);

    const pastToDateStr = `${format(pastToDate, "yyyy-MM-dd")}T23:59:59.999Z`;
    const pastFromDateStr = `${format(pastFromDate, "yyyy-MM-dd")}T00:00:00.000Z`;

    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });

    const query = `--sql 
    WITH current_period AS (
        SELECT COUNT(distinct requests._id) AS count
        FROM
            requests
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "requests.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
        WHERE
            status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
            and cast( sla_metrics ->> 'first_response_sla' ->> 'is_breached' as bool ) = true
            AND requests.internalHelpDeskIndex = 'no'
            AND requests.created_at BETWEEN '${fromDate}' AND '${toDate}'
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
    ),
    previous_period AS (
        SELECT COUNT(distinct requests._id) AS count
        FROM
            requests
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "requests.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
        WHERE
            status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
            and cast( sla_metrics ->> 'first_response_sla' ->> 'is_breached' as bool ) = true
            AND requests.internalHelpDeskIndex = 'no'
            AND requests.created_at BETWEEN '${pastFromDateStr}' AND '${pastToDateStr}'
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        )
    SELECT 
        ( SELECT count FROM current_period ) AS current_count, 
        ( SELECT count FROM previous_period ) AS previous_count`;

    console.log({ firstResponseAlertCount: query });

    return query.split("--sql")[1];
  },

  requestClosureAlertCount: ({ fromDate, toDate, filters }) => {
    const diffDate = differenceInDays(new Date(toDate), new Date(fromDate));

    const pastToDate = subDays(fromDate, 1);
    const pastFromDate = subDays(pastToDate, diffDate);

    const pastToDateStr = `${format(pastToDate, "yyyy-MM-dd")}T23:59:59.999Z`;
    const pastFromDateStr = `${format(pastFromDate, "yyyy-MM-dd")}T00:00:00.000Z`;

    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });

    const query = `--sql 
    WITH current_period AS (
        SELECT COUNT(distinct requests._id) AS count
        FROM
            requests
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "requests.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
        WHERE
            status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
            and cast( sla_metrics ->> 'resolution_sla' ->> 'is_breached' as bool ) = true
            AND requests.internalHelpDeskIndex = 'no'
            AND requests.created_at BETWEEN '${fromDate}' AND '${toDate}'
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
    ),
    previous_period AS (
        SELECT COUNT(distinct requests._id) AS count
        FROM
            requests
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `requests._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "requests.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "requests.relationship_id") : ""}
        WHERE
            status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
            and cast( sla_metrics ->> 'resolution_sla' ->> 'is_breached' as bool ) = true
            AND requests.internalHelpDeskIndex = 'no'
            AND requests.created_at BETWEEN '${pastFromDateStr}' AND '${pastToDateStr}'
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
    )
    SELECT
        ( SELECT count FROM current_period ) AS current_count,
        ( SELECT count FROM previous_period ) AS previous_count`;

    console.log({ requestClosureAlertCount: query });

    return query.split("--sql")[1];
  },

  accountWithRequests: ({ fromDate, toDate, filters }) => {
    const queryInfo = getFinalQuery(filters, "", "rel.");

    const diffDate = differenceInDays(new Date(toDate), new Date(fromDate));

    const pastToDate = subDays(fromDate, 1);
    const pastFromDate = subDays(pastToDate, diffDate);

    const pastToDateStr = `${format(pastToDate, "yyyy-MM-dd")}T23:59:59.999Z`;
    const pastFromDateStr = `${format(pastFromDate, "yyyy-MM-dd")}T00:00:00.000Z`;

    console.log({ queryInfo });

    const query = `--sql
    WITH current_period AS (
        SELECT
            COUNT(DISTINCT r.relationship_id) AS count
        FROM
        requests r
            INNER JOIN relationships rel ON r.relationship_id = rel._id
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
        WHERE
            r.created_at BETWEEN '${fromDate}' AND '${toDate}'
            AND r.status NOT IN (
                'NOT_A_REQUEST',
                'MERGED',
                'CONVERTED_TO_TICKET',
                'ESCALATED'
            )
            AND r.internalHelpDeskIndex = 'no'
            AND rel._id IS NOT NULL
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
    ),
    previous_period AS (
        SELECT
            COUNT(DISTINCT r.relationship_id) AS count
        FROM
            requests r
            INNER JOIN relationships rel ON r.relationship_id = rel._id
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
        WHERE
            r.created_at BETWEEN '${pastFromDateStr}' AND '${pastToDateStr}'
            AND r.status NOT IN (
                'NOT_A_REQUEST',
                'MERGED',
                'CONVERTED_TO_TICKET',
                'ESCALATED'
            )
            AND r.internalHelpDeskIndex = 'no'
            AND rel._id IS NOT NULL
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
    )
    SELECT
        (
            SELECT
                count
            FROM
                current_period
        ) AS current_count,
        (
            SELECT
                count
            FROM
                previous_period
        ) AS previous_count`;

    console.log({ accountWithRequests: query });

    return query.split("--sql")[1];
  },

  customerRequestsHourly: ({ fromDate, toDate, filters }) => {
    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });

    const query = `--sql 
    WITH hours AS (
      SELECT UNNEST([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]) AS hour
    ),
    requests_per_hour AS (
        SELECT
            distinct 
            EXTRACT(hour FROM cast(r.created_at as timestamp)) AS hour,
            COUNT(distinct r._id) AS request_count
        FROM 
        requests r
        ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
        ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
        ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
        WHERE
                r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
        AND r.internalHelpDeskIndex = 'no'
            AND
        r.created_at BETWEEN '${fromDate}' AND '${toDate}'
        ${queryInfo.conditionsString}
        ${queryInfo.requestQuery}
        ${queryInfo.relationshipQuery}
        GROUP BY hour
    )
    SELECT
        distinct 
        h.hour,
        COALESCE(rph.request_count, 0) AS request_count
    FROM
        hours h
    LEFT JOIN
        requests_per_hour rph
    ON
        h.hour = rph.hour
    ORDER BY
        h.hour`;

    console.log({ customerRequestsHourly: query });

    return query.split("--sql")[1];
  },
  customerRequestsDayWise: ({ fromDate, toDate, filters }) => {
    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });

    const query = `--sql
        WITH days AS (
          SELECT UNNEST(['0', '1', '2', '3', '4', '5', '6']) AS day_of_week
      ),
      requests_per_day AS (
          SELECT
              distinct 
              EXTRACT(DOW FROM CAST(r.created_at AS TIMESTAMP)) AS day_of_week,
              COUNT(DISTINCT r._id) AS request_count,
              ARRAY_AGG(CAST(r._id AS VARCHAR)) AS req_id_list
          FROM
              requests r
              ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
              ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
              ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
          WHERE
              r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
          AND r.internalHelpDeskIndex = 'no'
          AND
              r.created_at BETWEEN '${fromDate}' AND '${toDate}'
              ${queryInfo.conditionsString}
              ${queryInfo.requestQuery}
              ${queryInfo.relationshipQuery}
          GROUP BY day_of_week
      )
      SELECT
          d.day_of_week,
          COALESCE(rpd.request_count, 0) AS request_count,
          COALESCE(rpd.req_id_list, ARRAY[]::VARCHAR[]) AS req_id_list
      FROM
          days d
      LEFT JOIN
          requests_per_day rpd
      ON
          d.day_of_week::INT = rpd.day_of_week
      ORDER BY
          d.day_of_week::INT`;

    console.log({ customerRequestsDayWise: query });

    return query.split("--sql")[1];
  },

  requestOfCustomFieldsOverTime: ({
    fromDate,
    toDate,
    filters,
    dateType,
    customField,
    type,
  }) => {
    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });

    const query = `--sql 
    SELECT
        distinct 
        DATE_TRUNC('${dateType}', CAST(r.created_at AS DATE)) AS created_date,
        ARRAY_AGG(distinct cf._id) AS request_id_list,
        cf.field_values
    FROM
        requests r
        inner join customFields cf
        on r._id = cf._id
        ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
        ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
        ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
    WHERE
        cf.field_hash = 'custom_${customField}' 
        AND r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED') 
        AND r.created_at BETWEEN '${fromDate}' AND '${toDate}' 
        AND r.internalHelpDeskIndex = 'no'
        ${type === "select" ? "AND array_length(cf.field_values, 1) = 1" : ""}

        ${queryInfo.conditionsString}
        ${queryInfo.requestQuery}
        ${queryInfo.relationshipQuery}
    GROUP BY 
        DATE_TRUNC('${dateType}', CAST(r.created_at AS DATE)), cf.field_values
    ORDER BY
        created_date, count(cf._id) DESC
    `;

    console.log({ requestOfCustomFieldsOverTime: query });

    return query.split("--sql")[1];
  },

  topValues: ({ fromDate, toDate, filters, customField, type }) => {
    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });

    const query = `--sql 
    SELECT
        distinct 
        count(distinct cf._id) AS custom_field_count,
        cf.field_values,
        ARRAY_AGG(distinct cf._id) AS custom_field_ids
    FROM
        requests r
        inner join customFields cf
        on r._id = cf._id
        ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
        ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
        ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
    WHERE
        cf.field_hash = 'custom_${customField}' 
        AND r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
        AND r.created_at BETWEEN '${fromDate}' AND '${toDate}'
        AND r.internalHelpDeskIndex = 'no'
        ${type === "select" ? "AND array_length(cf.field_values, 1) = 1" : ""}
        ${queryInfo.conditionsString}
        ${queryInfo.requestQuery}
        ${queryInfo.relationshipQuery}
    GROUP BY
        cf.field_values
    ORDER BY
        count(cf._id) DESC,
        cf.field_values
    `;

    console.log({ sentimentDefault: query });

    return query.split("--sql")[1];
  },

  getHashWithHighestCount: ({ hashes }) => {
    const formattedHashes = hashes.map((hash) => `'custom_${hash}'`).join(", ");
    const query = `--sql
      with 
        count_query as (
          select 
            count(*), field_hash 
          from 
            customFields 
          where 
            field_hash in (${formattedHashes}) 
          group by field_hash 
          order by count(*) desc 
          limit 1
        ) 
      select 
        replace(field_hash,'custom_','') as field_hash 
      from 
        count_query`;

    // console.log({ getHashWithHighestCount: query });
    return query.split("--sql")[1];
  },

  getStartDate: () => {
    const query = `--sql
      SELECT
          date_trunc('day',cast(min(created_at) as timestamp)) as fromDate
      FROM requests`;

    console.log({ getStartDate: query });
    return query.split("--sql")[1];
  },

  requestPerAccountOverTime: ({ fromDate, toDate, filters, dateType }) => {
    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });
    let query;

    switch (dateType) {
      case "day":
        query = `--sql 
            WITH RECURSIVE date_range AS (
              SELECT
                  CAST('${fromDate}' AS DATE) AS start_date,
                  CAST('${toDate}' AS DATE) AS end_date
            ),
            date_series AS (
                SELECT
                    start_date AS day
                FROM
                    date_range
                UNION
                ALL
                SELECT
                    day + INTERVAL '1 day'
                FROM
                    date_series
                WHERE
                    day + INTERVAL '1 day' <= (
                        SELECT
                            end_date
                        FROM
                            date_range
                    )
            ),
            daily_requests AS (
                SELECT
                    DATE_TRUNC('day', r.created_at :: timestamp) AS request_day,
                    r.relationship_id AS customer_id,
                    COUNT(distinct r._id) AS daily_request_count
                FROM
                    requests r
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                WHERE
                    r.created_at :: timestamp BETWEEN '${fromDate}' AND '${toDate}'
                    AND r.status NOT IN ('MERGED', 'NOT_A_REQUEST', 'CONVERTED_TO_TICKET','ESCALATED')
                    AND r.internalHelpDeskIndex = 'no'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    DATE_TRUNC('day', r.created_at :: timestamp),
                    r.relationship_id
            ),
            requests_with_dates AS (
                SELECT
                    ds.day,
                    wr.customer_id,
                    COALESCE(wr.daily_request_count, 0) AS daily_request_count
                FROM
                    date_series ds
                    LEFT JOIN daily_requests wr ON wr.request_day = ds.day
            )
            SELECT
                day,
                ROUND(AVG(daily_request_count), 2) AS avg_requests_per_customer_per_day
            FROM
                requests_with_dates
            GROUP BY
                day
            ORDER BY day`;
        break;

      case "week":
        query = `--sql
              WITH RECURSIVE date_range AS (
                SELECT
                    CAST('${fromDate}' AS DATE) AS start_date,
                    CAST('${toDate}' AS DATE) AS end_date
              ),
              date_series AS (
                  SELECT
                      DATE_TRUNC('week', start_date) AS week_start,
                      (DATE_TRUNC('week', start_date) + INTERVAL '6 days') AS week_end
                  FROM date_range
                  UNION ALL
                  SELECT
                      DATE_TRUNC('week', week_start + INTERVAL '1 week'),
                      (DATE_TRUNC('week', week_start + INTERVAL '1 week') + INTERVAL '6 days')
                  FROM date_series
                  WHERE DATE_TRUNC('week', week_start + INTERVAL '1 week') <= (SELECT end_date FROM date_range)
              ),
              weekly_requests AS (
                  SELECT
                      DATE_TRUNC('week', r.created_at::timestamp) AS request_week,
                      r.relationship_id AS customer_id,
                      COUNT(distinct r._id) AS weekly_request_count
                  FROM
                      requests r
                      ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                      ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                      ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                  WHERE
                      r.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
                      AND r.status NOT IN ('MERGED', 'NOT_A_REQUEST', 'CONVERTED_TO_TICKET','ESCALATED')
                      AND r.internalHelpDeskIndex = 'no'
                      ${queryInfo.conditionsString}
                      ${queryInfo.requestQuery}
                      ${queryInfo.relationshipQuery}
                  GROUP BY
                      DATE_TRUNC('week', r.created_at::timestamp),
                      r.relationship_id
              ),
              requests_with_dates AS (
                  SELECT
                      ds.week_start,
                      ds.week_end,
                      wr.customer_id,
                      COALESCE(wr.weekly_request_count, 0) AS weekly_request_count
                  FROM
                      date_series ds
                  LEFT JOIN
                      weekly_requests wr
                  ON
                      wr.request_week = ds.week_start
              )
              SELECT
                  week_start,
                  week_end,
                  ROUND(AVG(weekly_request_count), 2) AS avg_requests_per_customer_per_week
              FROM
                  requests_with_dates
              GROUP BY
                  week_start, week_end
              ORDER BY
                  week_start`;
        break;

      case "month":
        query = `--sql 
              WITH RECURSIVE date_range AS (
                SELECT 
                    CAST('${fromDate}' AS DATE) AS start_date, 
                    CAST('${toDate}' AS DATE) AS end_date
              ),
              date_series AS (
                  SELECT DATE_TRUNC('month', start_date) AS month_start,
                        (DATE_TRUNC('month', start_date) + INTERVAL '1 month' - INTERVAL '1 day') AS month_end
                  FROM date_range
                  UNION ALL
                  SELECT DATE_TRUNC('month', month_start + INTERVAL '1 month'),
                        (DATE_TRUNC('month', month_start + INTERVAL '1 month') + INTERVAL '1 month' - INTERVAL '1 day')
                  FROM date_series
                  WHERE DATE_TRUNC('month', month_start + INTERVAL '1 month') <= (SELECT end_date FROM date_range)
              ),
              monthly_requests AS (
                  SELECT
                      DATE_TRUNC('month', r.created_at::timestamp) AS request_month,
                      r.relationship_id AS customer_id,
                      COUNT(distinct r._id) AS monthly_request_count
                  FROM
                      requests r
                      ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                      ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                      ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                  WHERE
                      r.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
                      AND r.internalHelpDeskIndex = 'no'
                      ${queryInfo.conditionsString}
                      ${queryInfo.requestQuery}
                      ${queryInfo.relationshipQuery}
                  AND r.status not in ('MERGED', 'NOT_A_REQUEST','CONVERTED_TO_TICKET','ESCALATED')
                      GROUP BY
                          DATE_TRUNC('month', r.created_at::timestamp),
                          r.relationship_id
              ),
              requests_with_dates AS (
                  SELECT
                      ds.month_start AS request_month,
                      ds.month_end AS request_month_end,
                      mr.customer_id,
                      COALESCE(mr.monthly_request_count, 0) AS monthly_request_count
                  FROM
                      date_series ds
                  LEFT JOIN
                      monthly_requests mr
                  ON
                      DATE_TRUNC('month', mr.request_month) = ds.month_start
              )
              SELECT
                  request_month,
                  request_month_end,
                  ROUND(AVG(monthly_request_count), 2) AS avg_requests_per_customer_per_month
              FROM
                  requests_with_dates
              GROUP BY
                  request_month, request_month_end
              ORDER BY
                  request_month`;
        break;
      default:
    }

    console.log({ requestPerAccountOverTime: query });

    return query.split("--sql")[1];
  },

  customeFieldMatrix: ({ fromDate, toDate, filters, customField, type }) => {
    const queryInfo = getFinalQuery(filters, "", "rel.");

    const query = `--sql
    WITH request_closure_p90 AS (
        SELECT
            cf.field_values,
            quantile_cont(
                EXTRACT(
                    EPOCH
                    FROM
                        (
                            CAST(req.closed_on AS TIMESTAMP) - CAST(req.created_at AS TIMESTAMP)
                        )
                ),
                0.90
            ) AS request_closure_p90
        FROM
            requests req
            INNER JOIN customFields cf ON req._id = cf._id
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
        WHERE
            req.status = 'CLOSED'
            AND req.internalHelpDeskIndex = 'no'
            AND req.closed_on IS NOT NULL
            AND req.closed_on != ''
            AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
            AND cf.field_hash = 'custom_${customField}'
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        GROUP BY
            cf.field_values
    ),
    request_closure_p70 AS (
        SELECT
            cf.field_values,
            quantile_cont(
                EXTRACT(
                    EPOCH
                    FROM
                        (
                            CAST(req.closed_on AS TIMESTAMP) - CAST(req.created_at AS TIMESTAMP)
                        )
                ),
                0.70
            ) AS request_closure_p70
        FROM
            requests req
            INNER JOIN customFields cf ON req._id = cf._id
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
        WHERE
            req.status = 'CLOSED'
            AND req.internalHelpDeskIndex = 'no'
            AND req.closed_on IS NOT NULL
            AND req.closed_on != ''
            AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
            AND cf.field_hash = 'custom_${customField}'
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        GROUP BY
            cf.field_values
    ),
    request_closure_p50 AS (
        SELECT
            cf.field_values,
            quantile_cont(
                EXTRACT(
                    EPOCH
                    FROM
                        (
                            CAST(req.closed_on AS TIMESTAMP) - CAST(req.created_at AS TIMESTAMP)
                        )
                ),
                0.50
            ) AS request_closure_p50
        FROM
            requests req
            INNER JOIN customFields cf ON req._id = cf._id
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
        WHERE
            req.status = 'CLOSED'
            AND req.internalHelpDeskIndex = 'no'
            AND req.closed_on IS NOT NULL
            AND req.closed_on != ''
            AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
            AND cf.field_hash = 'custom_${customField}'
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        GROUP BY
            cf.field_values
    ),
    first_response_p90 AS (
        SELECT
            cf.field_values,
            quantile_cont(
                CAST(req.first_response_time AS DOUBLE) - CAST(req.original_message_ts AS DOUBLE),
                0.90
            ) AS first_response_p90
        FROM
            requests req
            INNER JOIN customFields cf ON req._id = cf._id
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
        WHERE
            req.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
            AND req.internalHelpDeskIndex = 'no'
            AND req.first_response_time IS NOT NULL
            AND req.first_response_time != ''
            AND req.first_response_time != '[]'
            AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
            AND cf.field_hash = 'custom_${customField}'
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        GROUP BY
            cf.field_values
    ),
    first_response_p70 AS (
        SELECT
            cf.field_values,
            quantile_cont(
                CAST(req.first_response_time AS DOUBLE) - CAST(req.original_message_ts AS DOUBLE),
                0.70
            ) AS first_response_p70
        FROM
            requests req
            INNER JOIN customFields cf ON req._id = cf._id
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
        WHERE
            req.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
            AND req.internalHelpDeskIndex = 'no'
            AND req.first_response_time IS NOT NULL
            AND req.first_response_time != ''
            AND req.first_response_time != '[]'
            AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
            AND cf.field_hash = 'custom_${customField}'
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        GROUP BY
            cf.field_values
    ),
    first_response_p50 AS (
        SELECT
            cf.field_values,
            quantile_cont(
                CAST(req.first_response_time AS DOUBLE) - CAST(req.original_message_ts AS DOUBLE),
                0.50
            ) AS first_response_p50
        FROM
            requests req
            INNER JOIN customFields cf ON req._id = cf._id
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
        WHERE
            req.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
            AND req.internalHelpDeskIndex = 'no'
            AND req.first_response_time IS NOT NULL
            AND req.first_response_time != ''
            AND req.first_response_time != '[]'
            AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
            AND cf.field_hash = 'custom_${customField}'
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        GROUP BY
            cf.field_values
    ),
    customer_requests AS (
        SELECT
            cf.field_values,
            COALESCE(rel.customer_name, rel.channel_name) AS customer_name,
            COUNT(distinct req._id) AS request_count,
            ROW_NUMBER() OVER (
                PARTITION BY cf.field_values
                ORDER BY
                    COUNT(distinct req._id) DESC
            ) AS rn
        FROM
            requests req
            INNER JOIN customFields cf ON req._id = cf._id
            INNER JOIN relationships rel ON req.relationship_id = rel._id
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
        WHERE
            req.created_at BETWEEN '${fromDate}' AND '${toDate}'
            AND cf.field_hash = 'custom_${customField}'
            ${type === "select" ? "AND array_length(cf.field_values, 1) = 1" : ""}
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        GROUP BY
            cf.field_values,
            COALESCE(rel.customer_name, rel.channel_name)
    ),
    final_data AS (
        SELECT
            DISTINCT COUNT(DISTINCT r._id) AS request_count,
            MAX(r.created_at) as last_created_request,
            COUNT(distinct r._id) FILTER (
                WHERE cast(r.sla_metrics->>'first_response_sla'->>'is_breached' AS bool) = true
            ) AS first_response_sla_count,
            COUNT(distinct r._id) FILTER (
                    WHERE cast(r.sla_metrics->>'resolution_sla'->>'is_breached' AS bool) = true
            ) AS final_resolution_sla_count,
            cf.field_values,
            fr_p50.first_response_p50,
            fr_p70.first_response_p70,
            fr_p90.first_response_p90,
            rc_p50.request_closure_p50,
            rc_p70.request_closure_p70,
            rc_p90.request_closure_p90,
        FROM
            requests r
            INNER JOIN customFields cf ON r._id = cf._id
            LEFT JOIN first_response_p50 fr_p50 ON cf.field_values = fr_p50.field_values
            LEFT JOIN first_response_p70 fr_p70 ON cf.field_values = fr_p70.field_values
            LEFT JOIN first_response_p90 fr_p90 ON cf.field_values = fr_p90.field_values
            LEFT JOIN request_closure_p50 rc_p50 ON cf.field_values = rc_p50.field_values
            LEFT JOIN request_closure_p70 rc_p70 ON cf.field_values = rc_p70.field_values
            LEFT JOIN request_closure_p90 rc_p90 ON cf.field_values = rc_p90.field_values
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
        WHERE
            cf.field_hash = 'custom_${customField}'
            AND r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
            AND r.created_at BETWEEN '${fromDate}' AND '${toDate}'
            AND r.internalHelpDeskIndex = 'no'
            ${type === "select" ? "AND array_length(cf.field_values, 1) = 1" : ""}
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        GROUP BY
            cf.field_values,
            fr_p50.first_response_p50,
            fr_p70.first_response_p70,
            fr_p90.first_response_p90,
            rc_p50.request_closure_p50,
            rc_p70.request_closure_p70,
            rc_p90.request_closure_p90
    )
    SELECT
        *
    FROM
        final_data
    ORDER BY
        request_count DESC`;

    console.log({ customeFieldMatrix: query });

    return query.split("--sql")[1];
  },
};

export const DUCK_DB_HOVER_ACCOUNT_TABLE_CHART_QUERIES = {
  requestCreatedOnHover: ({ relationshipId }) => {
    const query = `--sql
    WITH RECURSIVE date_range AS (
        SELECT
            DATE_TRUNC('week', CURRENT_DATE - INTERVAL '1 month') AS start_date,
            CURRENT_DATE AS end_date
    ),
    date_series AS (
        SELECT
            start_date AS week_start,
            start_date + INTERVAL '6 days' AS week_end
        FROM
            date_range
        UNION
        ALL
        SELECT
            week_start + INTERVAL '1 week',
            week_start + INTERVAL '1 week' + INTERVAL '6 days'
        FROM
            date_series
        WHERE
            week_start + INTERVAL '1 week' <= (
                SELECT
                    end_date
                FROM
                    date_range
            )
    ),
    request_count AS (
        SELECT
            ds.week_start,
            ds.week_end,
            count(distinct r._id) as created_requests
        FROM
            date_series ds
            LEFT JOIN requests r ON CAST(r.created_at AS DATE) BETWEEN ds.week_start AND ds.week_end
        WHERE
            r.status NOT IN ('NOT_A_REQUEST', 'MERGED', 'CONVERTED_TO_TICKET')
            AND r.internalHelpDeskIndex = 'no'
            AND r.relationship_id = '${relationshipId}'
        GROUP BY
            ds.week_start,
            ds.week_end
    )
    SELECT
        ds.week_start || ' - ' || ds.week_end AS period,
        COALESCE(rc.created_requests, 0) AS created_requests
    FROM
        date_series ds
        LEFT JOIN request_count rc ON ds.week_start = rc.week_start
        AND ds.week_end = rc.week_end
    ORDER BY
        ds.week_start
    `;

    console.log("THis Request ", { requestCreatedOnHover: query });

    return query.split("--sql")[1];
  },
  firstResponseP90OnHover: ({ relationshipId }) => {
    const query = `--sql 
    WITH RECURSIVE date_range AS (
        SELECT
            DATE_TRUNC('week', CURRENT_DATE - INTERVAL '1 month') AS start_date,
            CURRENT_DATE AS end_date
    ),
    date_series AS (
        SELECT
            start_date AS week_start,
            start_date + INTERVAL '6 days' AS week_end
        FROM
            date_range
        UNION
        ALL
        SELECT
            week_start + INTERVAL '1 week',
            week_start + INTERVAL '1 week' + INTERVAL '6 days'
        FROM
            date_series
        WHERE
            week_start + INTERVAL '1 week' <= (
                SELECT
                    end_date
                FROM
                    date_range
            )
    ),
    frt_requests AS (
        SELECT
            ds.week_start,
            ds.week_end,
            quantile_cont(CAST(r.first_response_time AS DOUBLE) - CAST(r.original_message_ts AS DOUBLE),0.90) 
                 
                AS first_response_p90
        FROM
            date_series ds
            LEFT JOIN requests r ON CAST(r.created_at AS DATE) BETWEEN ds.week_start AND ds.week_end
        WHERE
            r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
            AND r.internalHelpDeskIndex = 'no'
            AND r.created_at BETWEEN CURRENT_DATE - INTERVAL '1 month' AND CURRENT_DATE
            AND r.relationship_id = '${relationshipId}'
            AND r.first_response_time IS NOT NULL
            AND r.first_response_time != ''
        GROUP BY
            ds.week_start,
            ds.week_end
    )
    SELECT
        ds.week_start || ' - ' || ds.week_end AS period,
        COALESCE(frt.first_response_p90, 0) AS first_response_p90
    FROM
        date_series ds
        LEFT JOIN frt_requests frt ON ds.week_start = frt.week_start AND ds.week_end = frt.week_end
    ORDER BY
        ds.week_start`;

    console.log({ firstResponseP90OnHover: query });

    return query.split("--sql")[1];
  },
  resolutionP90OnHover: ({ relationshipId }) => {
    const query = `--sql 
    WITH RECURSIVE date_range AS (
        SELECT
            DATE_TRUNC('week', CURRENT_DATE - INTERVAL '1 month') AS start_date,
            CURRENT_DATE AS end_date
    ),
    date_series AS (
        SELECT
            start_date AS week_start,
            start_date + INTERVAL '6 days' AS week_end
        FROM
            date_range
        UNION
        ALL
        SELECT
            week_start + INTERVAL '1 week',
            week_start + INTERVAL '1 week' + INTERVAL '6 days'
        FROM
            date_series
        WHERE
            week_start + INTERVAL '1 week' <= (
                SELECT
                    end_date
                FROM
                    date_range
            )
    ),
    closure_requests AS (
        SELECT
            ds.week_start,
            ds.week_end,
            quantile_cont( EXTRACT(EPOCH FROM (CAST(r.closed_on AS TIMESTAMP) - CAST(r.created_at AS TIMESTAMP))),0.90) 
                
                AS request_closure_p90
        FROM
            date_series ds
            LEFT JOIN requests r ON CAST(r.created_at AS DATE) BETWEEN ds.week_start AND ds.week_end
        WHERE
            r.status IN ('CLOSED')
            AND r.internalHelpDeskIndex = 'no'
            AND r.created_at BETWEEN CURRENT_DATE - INTERVAL '1 month' AND CURRENT_DATE
            AND r.relationship_id = '${relationshipId}'
            AND r.closed_on IS NOT NULL
            AND r.closed_on != ''
        GROUP BY
            ds.week_start,
            ds.week_end
    )
    SELECT
        ds.week_start || ' - ' || ds.week_end AS period,
        COALESCE(cr.request_closure_p90, 0) AS request_closure_p90
    FROM
        date_series ds
        LEFT JOIN closure_requests cr ON ds.week_start = cr.week_start AND ds.week_end = cr.week_end
    ORDER BY
        ds.week_start    
    `;

    console.log({
      firstResolutionP90OnHover: query,
    });

    return query.split("--sql")[1];
  },
};

export const DUCK_DB_RESOURCE_CHART_QUERIES = {
  requestCount: ({ fromDate, toDate, filters, dateType, sliceBy }) => {
    const pastFromDateStr = `${format(subDays(fromDate, 1), "yyyy-MM-dd")}T00:00:00.000Z`;
    const pastToDateStr = `${format(toDate, "yyyy-MM-dd")}T23:59:59.999Z`;
    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });
    let query;

    if (sliceBy === "assignee") {
      switch (dateType) {
        case "day":
          query = `--sql 
            WITH RECURSIVE date_range AS (
              SELECT
                  CAST('${fromDate}' AS DATE) AS start_date,
                  CAST('${toDate}' AS DATE) AS end_date
            ),
            date_series AS (
                SELECT
                    start_date AS day
                FROM
                    date_range
                UNION
                ALL
                SELECT
                    day + INTERVAL '1 day'
                FROM
                    date_series
                WHERE
                    day + INTERVAL '1 day' <= (
                        SELECT
                            end_date
                        FROM
                            date_range
                    )
            ),
            daily_requests AS (
                SELECT
                    DATE_TRUNC('day', r.created_at :: timestamp) AS request_day,
                    u.name,
                    COUNT(distinct r._id) AS request_count
                FROM
                    requests r
                    LEFT JOIN users u ON r.assigned_to_user_id = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                WHERE
                    r.created_at :: timestamp BETWEEN '${fromDate}' AND '${toDate}'
                    AND r.status NOT IN ('MERGED', 'NOT_A_REQUEST', 'CONVERTED_TO_TICKET')
                    AND r.internalHelpDeskIndex = 'no'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    DATE_TRUNC('day', r.created_at :: timestamp),
                    u.name
            ),
            requests_with_dates AS (
                SELECT
                    ds.day AS request_day,
                    wr.name,
                    COALESCE(wr.request_count, 0) AS daily_request_count
                FROM
                    date_series ds
                    LEFT JOIN daily_requests wr ON DATE_TRUNC('day', wr.request_day) = ds.day
                    AND wr.name IS NOT NULL
            )
            SELECT
                request_day,
                name,
                daily_request_count
            FROM
                requests_with_dates
            ORDER BY
                request_day,
                name`;
          break;

        case "week":
          query = `--sql 
            WITH RECURSIVE date_range AS (
              SELECT 
              CAST('${pastFromDateStr}' AS DATE) AS start_date,
              CAST('${pastToDateStr}' AS DATE) AS end_date
            ),
            date_series AS (
                SELECT 
                    DATE_TRUNC('week', start_date) AS week_start,
                    DATE_TRUNC('week', start_date) + INTERVAL '6 days' AS week_end
                FROM date_range
                UNION ALL
                SELECT 
                    DATE_TRUNC('week', week_start + INTERVAL '1 week'),
                    DATE_TRUNC('week', week_start + INTERVAL '1 week') + INTERVAL '6 days'
                FROM date_series
                WHERE DATE_TRUNC('week', week_start + INTERVAL '1 week') <= (SELECT end_date FROM date_range)
            ),
            weekly_requests AS (
                SELECT
                    DATE_TRUNC('week', r.created_at::timestamp) AS request_week,
                    u.name,
                    COUNT(distinct r._id) AS request_count
                FROM 
                    requests r
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                LEFT JOIN 
                    users u
                ON 
                    r.assigned_to_user_id = u._id
                WHERE 
                    r.created_at::timestamp BETWEEN '${pastFromDateStr}' AND '${pastToDateStr}'
                    AND r.status NOT IN ('MERGED', 'NOT_A_REQUEST', 'CONVERTED_TO_TICKET')
                    AND r.internalHelpDeskIndex = 'no'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY 
                    DATE_TRUNC('week', r.created_at::timestamp), u.name
            ),
            requests_with_dates AS (
                SELECT
                    ds.week_start AS request_week,
                    ds.week_end AS request_week_end,
                    wr.name,
                    COALESCE(wr.request_count, 0) AS weekly_request_count
                FROM
                    date_series ds
                LEFT JOIN
                    weekly_requests wr
                ON
                    DATE_TRUNC('week', wr.request_week) = ds.week_start AND wr.name IS NOT NULL
            )
            
            SELECT
                request_week,
                request_week_end,
                name,
                weekly_request_count
            FROM
                requests_with_dates
            ORDER BY
                request_week, name`;
          break;

        case "month":
          query = `--sql 
            WITH RECURSIVE date_range AS (
              SELECT 
                  CAST('${fromDate}' AS DATE) AS start_date,
                  CAST('${toDate}' AS DATE) AS end_date
            ),
            date_series AS (
                SELECT 
                    DATE_TRUNC('month', start_date) AS month_start,
                    DATE_TRUNC('month', start_date) + INTERVAL '1 month' - INTERVAL '1 day' AS month_end
                FROM date_range
                UNION ALL
                SELECT 
                    DATE_TRUNC('month', month_start + INTERVAL '1 month'),
                    DATE_TRUNC('month', month_start + INTERVAL '1 month') + INTERVAL '1 month' - INTERVAL '1 day'
                FROM date_series
                WHERE DATE_TRUNC('month', month_start + INTERVAL '1 month') <= (SELECT end_date FROM date_range)
            ),
            monthly_requests AS (
                SELECT
                    DATE_TRUNC('month', r.created_at::timestamp) AS request_month,
                    u.name,
                    COUNT(distinct r._id) AS request_count
                FROM 
                    requests r
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                LEFT JOIN 
                    users u
                ON 
                    r.assigned_to_user_id = u._id
                WHERE 
                    r.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
                    AND r.status NOT IN ('MERGED', 'NOT_A_REQUEST', 'CONVERTED_TO_TICKET')
                    AND r.internalHelpDeskIndex = 'no'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY 
                    DATE_TRUNC('month', r.created_at::timestamp), u.name
            ),
            requests_with_dates AS (
                SELECT
                    ds.month_start AS request_month,
                    ds.month_end AS request_month_end,
                    wr.name,
                    COALESCE(wr.request_count, 0) AS monthly_request_count
                FROM
                    date_series ds
                LEFT JOIN
                    monthly_requests wr
                ON
                    DATE_TRUNC('month', wr.request_month) = ds.month_start AND wr.name IS NOT NULL
            )
            
            SELECT
                request_month,
                request_month_end,
                name,
                monthly_request_count
            FROM
                requests_with_dates
            ORDER BY
                request_month, name`;
          break;
        default:
      }
    } else {
      switch (dateType) {
        case "day":
          query = `--sql 
          WITH RECURSIVE date_range AS (
            SELECT 
                CAST('${fromDate}' AS DATE) AS start_date, 
                CAST('${toDate}' AS DATE) AS end_date
        ),
        date_series AS (
            SELECT 
                start_date AS day
            FROM date_range
            UNION ALL
            SELECT 
                day + INTERVAL '1 day'
            FROM date_series
            WHERE day + INTERVAL '1 day' <= (SELECT end_date FROM date_range)
        ),
        unnested_relationships AS (
            SELECT
                _id,
                UNNEST(csmUsers) AS account_owner
            FROM
                relationships
        ),
        daily_requests AS (
            SELECT
                DATE_TRUNC('day', req.created_at::timestamp) AS request_day,
        u.name as account_owner,
                COUNT(distinct req._id) AS request_count
            FROM 
                requests req
                ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
            LEFT JOIN 
                unnested_relationships unrel
            ON 
                req.relationship_id = unrel._id
        INNER JOIN 
                users u
            ON unrel.account_owner = u._id
            WHERE 
                req.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
                AND req.status NOT IN ('MERGED', 'NOT_A_REQUEST', 'CONVERTED_TO_TICKET')
                AND req.internalHelpDeskIndex = 'no'
                ${queryInfo.conditionsString}
                ${queryInfo.requestQuery}
                ${queryInfo.relationshipQuery}
            GROUP BY 
                DATE_TRUNC('day', req.created_at::timestamp), u.name
        ),
        requests_with_dates AS (
            SELECT
                ds.day AS request_day,
                wr.account_owner,
                COALESCE(wr.request_count, 0) AS daily_request_count
            FROM
                date_series ds
            LEFT JOIN
                daily_requests wr
            ON
                DATE_TRUNC('day', wr.request_day) = ds.day AND wr.account_owner IS NOT NULL
        )
        
        SELECT
            request_day,
            account_owner as name,
            daily_request_count
        FROM
            requests_with_dates
        ORDER BY
            request_day, account_owner`;
          break;

        case "week":
          query = `--sql 
          WITH RECURSIVE date_range AS (
            SELECT 
                CAST('${fromDate}' AS DATE) AS start_date, 
                CAST('${toDate}' AS DATE) AS end_date
        ),
        date_series AS (
            SELECT 
                DATE_TRUNC('week', start_date) AS week_start,
                DATE_TRUNC('week', start_date) + INTERVAL '6 days' AS week_end
            FROM date_range
            UNION ALL
            SELECT 
                DATE_TRUNC('week', week_start + INTERVAL '1 week'),
                DATE_TRUNC('week', week_start + INTERVAL '1 week') + INTERVAL '6 days'
            FROM date_series
            WHERE DATE_TRUNC('week', week_start + INTERVAL '1 week') <= (SELECT end_date FROM date_range)
        ),
        unnested_relationships AS (
            SELECT
                _id,
                UNNEST(csmUsers) AS account_owner
            FROM
                relationships
        ),
        weekly_requests AS (
            SELECT
                DATE_TRUNC('week', req.created_at::timestamp) AS request_week,
              u.name as account_owner,
                COUNT(distinct req._id) AS request_count
            FROM 
                requests req
                ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
            INNER JOIN 
                unnested_relationships unrel
            ON 
                req.relationship_id = unrel._id
        INNER JOIN 
                users u
            ON unrel.account_owner = u._id
        
            WHERE 
                req.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
                AND req.status NOT IN ('MERGED', 'NOT_A_REQUEST', 'CONVERTED_TO_TICKET')
                AND req.internalHelpDeskIndex = 'no'
                ${queryInfo.conditionsString}
                ${queryInfo.requestQuery}
                ${queryInfo.relationshipQuery}
            GROUP BY 
                DATE_TRUNC('week', req.created_at::timestamp), u.name
        ),
        requests_with_dates AS (
            SELECT
                ds.week_start AS request_week,
                ds.week_end AS request_week_end,
                wr.account_owner,
                COALESCE(wr.request_count, 0) AS weekly_request_count
            FROM
                date_series ds
            LEFT JOIN
                weekly_requests wr
            ON
                DATE_TRUNC('week', wr.request_week) = ds.week_start AND wr.account_owner IS NOT NULL
        )
        
        SELECT
            request_week,
            request_week_end,
            account_owner as name,
            weekly_request_count
        FROM
            requests_with_dates
        ORDER BY
            request_week, account_owner`;
          break;

        case "month":
          query = `--sql 
          WITH RECURSIVE date_range AS (
            SELECT 
                CAST('${fromDate}' AS DATE) AS start_date, 
                CAST('${toDate}' AS DATE) AS end_date
        ),
        date_series AS (
            SELECT 
                DATE_TRUNC('month', start_date) AS month_start,
                DATE_TRUNC('month', start_date) + INTERVAL '1 month' - INTERVAL '1 day' AS month_end
            FROM date_range
            UNION ALL
            SELECT 
                DATE_TRUNC('month', month_start + INTERVAL '1 month'),
                DATE_TRUNC('month', month_start + INTERVAL '1 month') + INTERVAL '1 month' - INTERVAL '1 day'
            FROM date_series
            WHERE DATE_TRUNC('month', month_start + INTERVAL '1 month') <= (SELECT end_date FROM date_range)
        ),
        unnested_relationships AS (
            SELECT
                _id,
                UNNEST(csmUsers) AS account_owner
            FROM
                relationships
        ),
        monthly_requests AS (
            SELECT
                DATE_TRUNC('month', req.created_at::timestamp) AS request_month,
                u.name as account_owner,
                COUNT(distinct req._id) AS request_count
            FROM 
                requests req
                ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
            LEFT JOIN 
                unnested_relationships unrel
            ON 
                req.relationship_id = unrel._id
        INNER JOIN 
                users u
            ON unrel.account_owner = u._id
        
            WHERE 
                req.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
                AND req.status NOT IN ('MERGED', 'NOT_A_REQUEST', 'CONVERTED_TO_TICKET')
                AND req.internalHelpDeskIndex = 'no'
                ${queryInfo.conditionsString}
                ${queryInfo.requestQuery}
                ${queryInfo.relationshipQuery}
            GROUP BY 
                DATE_TRUNC('month', req.created_at::timestamp), u.name
        ),
        requests_with_dates AS (
            SELECT
                ds.month_start AS request_month,
                ds.month_end AS request_month_end,
                wr.account_owner,
                COALESCE(wr.request_count, 0) AS monthly_request_count
            FROM
                date_series ds
            LEFT JOIN
                monthly_requests wr
            ON
                DATE_TRUNC('month', wr.request_month) = ds.month_start AND wr.account_owner IS NOT NULL
        )
        
        SELECT
            request_month,
            request_month_end,
            account_owner as name,
            monthly_request_count
        FROM
            requests_with_dates
        ORDER BY
            request_month, account_owner`;
          break;
        default:
      }
    }

    console.log({ requestCount: query });

    return query.split("--sql")[1];
  },
  firstReponsep90: ({ fromDate, toDate, filters, dateType, sliceBy }) => {
    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });
    let query;

    if (sliceBy === "assignee") {
      switch (dateType) {
        case "day":
          query = `--sql 
            WITH RECURSIVE date_range AS (
              SELECT
                  CAST('${fromDate}' AS DATE) AS start_date,
                  CAST('${toDate}' AS DATE) AS end_date
            ),
            date_series AS (
                SELECT
                    start_date AS day
                FROM
                    date_range
                UNION
                ALL
                SELECT
                    day + INTERVAL '1 day'
                FROM
                    date_series
                WHERE
                    day + INTERVAL '1 day' <= (
                        SELECT
                            end_date
                        FROM
                            date_range
                    )
            ),
            daily_frt AS (
                SELECT
                    distinct 
                    DATE_TRUNC('day', r.created_at :: timestamp) AS request_day,
                    u.name,
                    quantile_cont(CAST(r.first_response_time AS DOUBLE) - CAST(r.original_message_ts AS DOUBLE),0.9) AS first_response_p90
                FROM
                    requests r
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                    LEFT JOIN users u ON r.assigned_to_user_id = u._id
                WHERE
                    r.created_at :: timestamp BETWEEN '${fromDate}' AND '${toDate}'
                    AND r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                    AND r.internalHelpDeskIndex = 'no'
                    AND r.first_response_time IS NOT NULL
                    AND r.first_response_time != ''
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    DATE_TRUNC('day', r.created_at :: timestamp),
                    u.name
            ),
            requests_with_dates AS (
                SELECT
                    ds.day AS request_day,
                    wr.name,
                    COALESCE(wr.first_response_p90, 0) AS daily_first_response_p90
                FROM
                    date_series ds
                    LEFT JOIN daily_frt wr ON DATE_TRUNC('day', wr.request_day) = ds.day
                    AND wr.name IS NOT NULL
            )
            SELECT
                request_day,
                name,
                daily_first_response_p90
            FROM
                requests_with_dates
            ORDER BY
                request_day,
                name`;
          break;

        case "week":
          query = `--sql 
            WITH RECURSIVE date_range AS (
              SELECT 
                  CAST('${fromDate}' AS DATE) AS start_date,
                  CAST('${toDate}' AS DATE) AS end_date
            ),
            date_series AS (
                SELECT 
                    DATE_TRUNC('week', start_date) AS week_start,
                    DATE_TRUNC('week', start_date) + INTERVAL '6 days' AS week_end
                FROM date_range
                UNION ALL
                SELECT 
                    DATE_TRUNC('week', week_start + INTERVAL '1 week'),
                    DATE_TRUNC('week', week_start + INTERVAL '1 week') + INTERVAL '6 days'
                FROM date_series
                WHERE DATE_TRUNC('week', week_start + INTERVAL '1 week') <= (SELECT end_date FROM date_range)
            ),
            weekly_frt AS (
                SELECT
                    distinct 
                    DATE_TRUNC('week', r.created_at::timestamp) AS request_week,
                    u.name,
                    quantile_cont(CAST(r.first_response_time AS DOUBLE) - CAST(r.original_message_ts AS DOUBLE),0.9) AS first_response_p90
                FROM 
                    requests r
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                LEFT JOIN 
                    users u
                ON 
                    r.assigned_to_user_id = u._id
                WHERE 
                    r.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
                    AND r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                    AND r.internalHelpDeskIndex = 'no'
                    AND r.first_response_time IS NOT NULL
                    AND r.first_response_time != ''
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY 
                    DATE_TRUNC('week', r.created_at::timestamp), u.name
            ),
            requests_with_dates AS (
                SELECT
                    ds.week_start AS request_week,
                    ds.week_end AS request_week_end,
                    wr.name,
                    COALESCE(wr.first_response_p90, 0) AS weekly_first_response_p90
                FROM
                    date_series ds
                LEFT JOIN
                    weekly_frt wr
                ON
                    DATE_TRUNC('week', wr.request_week) = ds.week_start AND wr.name IS NOT NULL
            )
            
            SELECT
                request_week,
                request_week_end,
                name,
                weekly_first_response_p90
            FROM
                requests_with_dates
            ORDER BY
                request_week, name`;
          break;

        case "month":
          query = `--sql 
            WITH RECURSIVE date_range AS (
              SELECT 
                  CAST('${fromDate}' AS DATE) AS start_date,
                  CAST('${toDate}' AS DATE) AS end_date
            ),
            date_series AS (
                SELECT 
                    DATE_TRUNC('month', start_date) AS month_start,
                    DATE_TRUNC('month', start_date) + INTERVAL '1 month' - INTERVAL '1 day' AS month_end
                FROM date_range
                UNION ALL
                SELECT 
                    DATE_TRUNC('month', month_start + INTERVAL '1 month'),
                    DATE_TRUNC('month', month_start + INTERVAL '1 month') + INTERVAL '1 month' - INTERVAL '1 day'
                FROM date_series
                WHERE DATE_TRUNC('month', month_start + INTERVAL '1 month') <= (SELECT end_date FROM date_range)
            ),
            monthly_frt AS (
                SELECT
                    distinct 
                    DATE_TRUNC('month', r.created_at::timestamp) AS request_month,
                    u.name,
                    quantile_cont(CAST(r.first_response_time AS DOUBLE) - CAST(r.original_message_ts AS DOUBLE),0.9) AS first_response_p90
                FROM 
                    requests r
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                LEFT JOIN 
                    users u
                ON 
                    r.assigned_to_user_id = u._id
                WHERE 
                    r.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
                    AND r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                    AND r.internalHelpDeskIndex = 'no'
                    AND r.first_response_time IS NOT NULL
                    AND r.first_response_time != ''
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY 
                    DATE_TRUNC('month', r.created_at::timestamp), u.name
            ),
            requests_with_dates AS (
                SELECT
                    ds.month_start AS request_month,
                    ds.month_end AS request_month_end,
                    wr.name,
                    COALESCE(wr.first_response_p90, 0) AS monthly_first_response_p90
                FROM
                    date_series ds
                LEFT JOIN
                    monthly_frt wr
                ON
                    DATE_TRUNC('month', wr.request_month) = ds.month_start AND wr.name IS NOT NULL
            )
            
            SELECT
                request_month,
                request_month_end,
                name,
                monthly_first_response_p90
            FROM
                requests_with_dates
            ORDER BY
                request_month, name`;
          break;
        default:
      }
    } else {
      switch (dateType) {
        case "day":
          query = `--sql 
          WITH RECURSIVE date_range AS (
            SELECT 
                CAST('${fromDate}' AS DATE) AS start_date, 
                CAST('${toDate}' AS DATE) AS end_date
        ),
        date_series AS (
            SELECT 
                start_date AS day
            FROM date_range
            UNION ALL
            SELECT 
                day + INTERVAL '1 day'
            FROM date_series
            WHERE day + INTERVAL '1 day' <= (SELECT end_date FROM date_range)
        ),
        unnested_relationships AS (
            SELECT
                _id,
                UNNEST(csmUsers) AS account_owner
            FROM
                relationships
        ),
        daily_frt AS (
            SELECT
                distinct 
                DATE_TRUNC('day', req.created_at::timestamp) AS request_day,
        u.name as account_owner,
                quantile_cont(CAST(req.first_response_time AS DOUBLE) - CAST(req.original_message_ts AS DOUBLE),0.9) AS first_response_p90
            FROM 
                requests req
                ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
            LEFT JOIN 
                unnested_relationships unrel
            ON 
                req.relationship_id = unrel._id
        INNER JOIN 
                users u
            ON unrel.account_owner = u._id
        
            WHERE 
                req.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
                AND req.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                AND req.internalHelpDeskIndex = 'no'
                AND req.first_response_time IS NOT NULL
                AND req.first_response_time != ''
                ${queryInfo.conditionsString}
                ${queryInfo.requestQuery}
                ${queryInfo.relationshipQuery}
            GROUP BY 
                DATE_TRUNC('day', req.created_at::timestamp),u.name
        ),
        requests_with_dates AS (
            SELECT
                ds.day AS request_day,
                wr.account_owner,
                COALESCE(wr.first_response_p90, 0) AS daily_first_response_p90
            FROM
                date_series ds
            LEFT JOIN
                daily_frt wr
            ON
                DATE_TRUNC('day', wr.request_day) = ds.day AND wr.account_owner IS NOT NULL
        )
        
        SELECT
            request_day,
            account_owner as name,
            daily_first_response_p90
        FROM
            requests_with_dates
        ORDER BY
            request_day, account_owner`;
          break;

        case "week":
          query = `--sql 
          WITH RECURSIVE date_range AS (
            SELECT 
            CAST('${fromDate}' AS DATE) AS start_date, 
            CAST('${toDate}' AS DATE) AS end_date
        ),
        date_series AS (
            SELECT 
                DATE_TRUNC('week', start_date) AS week_start,
                (DATE_TRUNC('week', start_date) + INTERVAL '6 days') AS week_end
            FROM date_range
            UNION ALL
            SELECT 
                DATE_TRUNC('week', week_start + INTERVAL '1 week'),
                (DATE_TRUNC('week', week_start + INTERVAL '1 week') + INTERVAL '6 days')
            FROM date_series
            WHERE DATE_TRUNC('week', week_start + INTERVAL '1 week') <= (SELECT end_date FROM date_range)
        ),
        unnested_relationships AS (
            SELECT
                _id,
                UNNEST(csmUsers) AS account_owner
            FROM
                relationships 
        ),
        weekly_frt AS (
            SELECT
            distinct 
            DATE_TRUNC('week', req.created_at::timestamp) AS request_week,
        u.name as account_owner,
            quantile_cont(CAST(req.first_response_time AS DOUBLE) - CAST(req.original_message_ts AS DOUBLE), 0.9) AS first_response_p90
        FROM 
            requests req
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
        LEFT JOIN 
            unnested_relationships unrel
        ON 
            req.relationship_id = unrel._id
        
        INNER JOIN 
                users u
            ON unrel.account_owner = u._id
        
        WHERE 
            req.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
            AND req.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
            AND req.internalHelpDeskIndex = 'no'
            AND req.first_response_time IS NOT NULL
            AND req.first_response_time != ''
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        GROUP BY 
            request_week, u.name
        ORDER BY
            request_week, u.name
        
        ),
        requests_with_dates AS (
            SELECT
                ds.week_start,
                ds.week_end,
                wr.account_owner,
                COALESCE(wr.first_response_p90, 0) AS weekly_first_response_p90
            FROM
                date_series ds
            LEFT JOIN
                weekly_frt wr
            ON
                DATE_TRUNC('week', wr.request_week) = ds.week_start AND wr.account_owner IS NOT NULL
        )
        
        SELECT
            week_start as request_week,
            week_end as request_week_end,
            account_owner as name,
            weekly_first_response_p90
        FROM
            requests_with_dates
        ORDER BY
            week_start, account_owner`;
          break;

        case "month":
          query = `--sql 
          WITH RECURSIVE date_range AS (
            SELECT 
            CAST('${fromDate}' AS DATE) AS start_date, 
            CAST('${toDate}' AS DATE) AS end_date
        ),
        date_series AS (
            SELECT 
                DATE_TRUNC('month', start_date) AS month_start,
                (DATE_TRUNC('month', start_date) + INTERVAL '1 month' - INTERVAL '1 day') AS month_end
            FROM date_range
            UNION ALL
            SELECT 
                DATE_TRUNC('month', month_start + INTERVAL '1 month'),
                (DATE_TRUNC('month', month_start + INTERVAL '1 month') + INTERVAL '1 month' - INTERVAL '1 day')
            FROM date_series
            WHERE DATE_TRUNC('month', month_start + INTERVAL '1 month') <= (SELECT end_date FROM date_range)
        ),
        unnested_relationships AS (
            SELECT
                _id,
                UNNEST(csmUsers) AS account_owner
            FROM
                relationships 
        ),
        monthly_frt AS (
            SELECT
            distinct 
            DATE_TRUNC('month', req.created_at::timestamp) AS request_month,
            u.name as account_owner,
            quantile_cont(CAST(req.first_response_time AS DOUBLE) - CAST(req.original_message_ts AS DOUBLE), 0.9) AS first_response_p90
        FROM 
            requests req
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
        LEFT JOIN 
            unnested_relationships unrel
        ON 
            req.relationship_id = unrel._id
        INNER JOIN 
                users u
            ON unrel.account_owner = u._id
        WHERE 
            req.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
            AND req.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
            AND req.internalHelpDeskIndex = 'no'
            AND req.first_response_time IS NOT NULL
            AND req.first_response_time != ''
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        GROUP BY 
            request_month, u.name
        ORDER BY
            request_month, u.name
        
        ),
        requests_with_dates AS (
            SELECT
                ds.month_start,
                ds.month_end,
                mr.account_owner,
                COALESCE(mr.first_response_p90, 0) AS monthly_first_response_p90
            FROM
                date_series ds
            LEFT JOIN
                monthly_frt mr
            ON
                DATE_TRUNC('month', mr.request_month) = ds.month_start AND mr.account_owner IS NOT NULL
        )
        
        SELECT
            month_start as request_month,
            month_end as request_month_end,
            account_owner as name,
            monthly_first_response_p90
        FROM
            requests_with_dates
        ORDER BY
            month_start, account_owner`;
          break;
        default:
      }
    }

    console.log({ firstReponsep90: query });

    return query.split("--sql")[1];
  },
  resolution90: ({ fromDate, toDate, filters, dateType, sliceBy }) => {
    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });
    let query;

    if (sliceBy === "assignee") {
      switch (dateType) {
        case "day":
          query = `--sql 
            WITH RECURSIVE date_range AS (
              SELECT
                  CAST('${fromDate}' AS DATE) AS start_date,
                  CAST('${toDate}' AS DATE) AS end_date
            ),
            date_series AS (
                SELECT
                    start_date AS day
                FROM
                    date_range
                UNION
                ALL
                SELECT
                    day + INTERVAL '1 day'
                FROM
                    date_series
                WHERE
                    day + INTERVAL '1 day' <= (
                        SELECT
                            end_date
                        FROM
                            date_range
                    )
            ),
            daily_resolution AS (
                SELECT
                    distinct 
                    DATE_TRUNC('day', r.created_at :: timestamp) AS request_day,
                    u.name,
                    quantile_cont(EXTRACT(EPOCH FROM (CAST(r.closed_on AS TIMESTAMP) - CAST(r.created_at AS TIMESTAMP))), 0.90)
                     AS request_closure_p90
                FROM
                    requests r
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                    LEFT JOIN users u ON r.assigned_to_user_id = u._id
                WHERE
                    r.created_at :: timestamp BETWEEN '${fromDate}' AND '${toDate}'
                    AND r.status = 'CLOSED'
                    AND r.internalHelpDeskIndex = 'no'
                    AND r.closed_on IS NOT NULL
                    AND r.closed_on != ''
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    DATE_TRUNC('day', r.created_at :: timestamp),
                    u.name
            ),
            requests_with_dates AS (
                SELECT
                    ds.day AS request_day,
                    wr.name,
                    COALESCE(wr.request_closure_p90, 0) AS daily_request_closure_p90
                FROM
                    date_series ds
                    LEFT JOIN daily_resolution wr ON DATE_TRUNC('day', wr.request_day) = ds.day
                    AND wr.name IS NOT NULL
            )
            SELECT
                request_day,
                name,
                daily_request_closure_p90
            FROM
                requests_with_dates
            ORDER BY
                request_day, name`;
          break;

        case "week":
          query = `--sql 
            WITH RECURSIVE date_range AS (
              SELECT 
                  CAST('${fromDate}' AS DATE) AS start_date,
                  CAST('${toDate}' AS DATE) AS end_date
            ),
            date_series AS (
                SELECT 
                    DATE_TRUNC('week', start_date) AS week_start,
                    DATE_TRUNC('week', start_date) + INTERVAL '6 days' AS week_end
                FROM date_range
                UNION ALL
                SELECT 
                    DATE_TRUNC('week', week_start + INTERVAL '1 week'),
                    DATE_TRUNC('week', week_start + INTERVAL '1 week') + INTERVAL '6 days'
                FROM date_series
                WHERE DATE_TRUNC('week', week_start + INTERVAL '1 week') <= (SELECT end_date FROM date_range)
            ),
            weekly_resolution AS (
                SELECT
                    distinct
                    DATE_TRUNC('week', r.created_at::timestamp) AS request_week,
                    u.name,
                    quantile_cont(EXTRACT(EPOCH FROM (CAST(r.closed_on AS TIMESTAMP) - CAST(r.created_at AS TIMESTAMP))), 0.90)
                     AS request_closure_p90
                FROM 
                    requests r
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                LEFT JOIN 
                    users u
                ON 
                    r.assigned_to_user_id = u._id
                WHERE 
                    r.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
                    AND r.status = 'CLOSED'
                    AND r.internalHelpDeskIndex = 'no'
                    AND r.closed_on IS NOT NULL
                    AND r.closed_on != ''
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY 
                    DATE_TRUNC('week', r.created_at::timestamp), u.name
            ),
            requests_with_dates AS (
                SELECT
                    ds.week_start AS request_week,
                    ds.week_end AS request_week_end,
                    wr.name,
                    COALESCE(wr.request_closure_p90, 0) AS weekly_request_closure_p90
                FROM
                    date_series ds
                LEFT JOIN
                    weekly_resolution wr
                ON
                    DATE_TRUNC('week', wr.request_week) = ds.week_start AND wr.name IS NOT NULL
            )
            
            SELECT
                request_week,
                request_week_end,
                name,
                weekly_request_closure_p90
            FROM
                requests_with_dates
            ORDER BY
                request_week, name`;
          break;

        case "month":
          query = `--sql 
            WITH RECURSIVE date_range AS (
              SELECT 
                  CAST('${fromDate}' AS DATE) AS start_date,
                  CAST('${toDate}' AS DATE) AS end_date
            ),
            date_series AS (
                SELECT 
                    DATE_TRUNC('month', start_date) AS month_start,
                    DATE_TRUNC('month', start_date) + INTERVAL '1 month' - INTERVAL '1 day' AS month_end
                FROM date_range
                UNION ALL
                SELECT 
                    DATE_TRUNC('month', month_start + INTERVAL '1 month'),
                    DATE_TRUNC('month', month_start + INTERVAL '1 month') + INTERVAL '1 month' - INTERVAL '1 day'
                FROM date_series
                WHERE DATE_TRUNC('month', month_start + INTERVAL '1 month') <= (SELECT end_date FROM date_range)
            ),
            monthly_resolution AS (
                SELECT
                distinct 
                    DATE_TRUNC('month', r.created_at::timestamp) AS request_month,
                    u.name,
                    quantile_cont(EXTRACT(EPOCH FROM (CAST(r.closed_on AS TIMESTAMP) - CAST(r.created_at AS TIMESTAMP))), 0.90)
                     AS request_closure_p90
                FROM 
                    requests r
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
                LEFT JOIN 
                    users u
                ON 
                    r.assigned_to_user_id = u._id
                WHERE 
                    r.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
                    AND r.status = 'CLOSED'
                    AND r.internalHelpDeskIndex = 'no'
                    AND r.closed_on IS NOT NULL
                    AND r.closed_on != ''
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY 
                    DATE_TRUNC('month', r.created_at::timestamp), u.name
            ),
            requests_with_dates AS (
                SELECT
                    ds.month_start AS request_month,
                    ds.month_end AS request_month_end,
                    wr.name,
                    COALESCE(wr.request_closure_p90, 0) AS monthly_request_closure_p90
                FROM
                    date_series ds
                LEFT JOIN
                    monthly_resolution wr
                ON
                    DATE_TRUNC('month', wr.request_month) = ds.month_start AND wr.name IS NOT NULL
            )
            
            SELECT
                request_month,
                request_month_end,
                name,
                monthly_request_closure_p90
            FROM
                requests_with_dates
            ORDER BY
                request_month, name`;
          break;
        default:
      }
    } else {
      switch (dateType) {
        case "day":
          query = `--sql 
          WITH RECURSIVE date_range AS (
            SELECT
            CAST('${fromDate}' AS DATE) AS start_date, 
            CAST('${toDate}' AS DATE) AS end_date
        ),
        date_series AS (
            SELECT
                start_date AS day
            FROM
                date_range
            UNION
            ALL
            SELECT
                day + INTERVAL '1 day'
            FROM
                date_series
            WHERE
                day + INTERVAL '1 day' <= (
                    SELECT
                        end_date
                    FROM
                        date_range
                )
        ),
        unnested_relationships AS (
            SELECT
                _id,
                UNNEST(csmUsers) AS account_owner
            FROM
                relationships
        ),
        daily_resolution AS (
            SELECT
                DATE_TRUNC('day', req.created_at :: timestamp) AS request_day,
                u.name as account_owner,
                quantile_cont(
                    EXTRACT(
                        EPOCH
                        FROM
                            (
                                CAST(req.closed_on AS TIMESTAMP) - CAST(req.created_at AS TIMESTAMP)
                            )
                    ),
                    0.90
                ) AS resolution_p90
            FROM
                requests req
                ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                LEFT JOIN unnested_relationships unrel ON req.relationship_id = unrel._id
                INNER JOIN users u ON unrel.account_owner = u._id
            WHERE
                req.created_at :: timestamp BETWEEN '${fromDate}' AND '${toDate}'
                AND req.status = 'CLOSED'
                AND req.internalHelpDeskIndex = 'no'
                AND req.closed_on IS NOT NULL
                AND req.closed_on != ''
                ${queryInfo.conditionsString}
                ${queryInfo.requestQuery}
                ${queryInfo.relationshipQuery}
            GROUP BY
                request_day,
                u.name
            ORDER BY
                request_day,
                u.name
        ),
        requests_with_dates AS (
            SELECT
                ds.day AS request_day,
                wr.account_owner,
                COALESCE(wr.resolution_p90, 0) AS daily_resolution_p90
            FROM
                date_series ds
                LEFT JOIN daily_resolution wr ON DATE_TRUNC('day', wr.request_day) = ds.day
                AND wr.account_owner IS NOT NULL
        )
        SELECT
            request_day,
            account_owner as name,
            daily_resolution_p90 as daily_request_closure_p90
        FROM
            requests_with_dates
        ORDER BY
            request_day,
            account_owner`;
          break;

        case "week":
          query = `--sql
          
          WITH RECURSIVE date_range AS (
            SELECT 
                CAST('${fromDate}' AS DATE) AS start_date,
                CAST('${toDate}' AS DATE) AS end_date
          ),
          date_series AS (
              SELECT 
                  DATE_TRUNC('week', start_date) AS week_start,
                  DATE_TRUNC('week', start_date) + INTERVAL '6 days' AS week_end
              FROM date_range
              UNION ALL
              SELECT 
                  DATE_TRUNC('week', week_start + INTERVAL '1 week'),
                  DATE_TRUNC('week', week_start + INTERVAL '1 week') + INTERVAL '6 days'
              FROM date_series
              WHERE DATE_TRUNC('week', week_start + INTERVAL '1 week') <= (SELECT end_date FROM date_range)
          ),
        unnested_relationships AS (
            SELECT
                _id,
                UNNEST(csmUsers) AS account_owner
            FROM
                relationships 
        ),
        weekly_resolution AS (
            SELECT
            DATE_TRUNC('week', req.created_at::timestamp) AS request_week,
            u.name as account_owner,
            quantile_cont(EXTRACT(EPOCH FROM (CAST(req.closed_on AS TIMESTAMP) - CAST(req.created_at AS TIMESTAMP))), 0.90) AS resolution_p90
        FROM 
            requests req
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
        LEFT JOIN 
            unnested_relationships unrel
        ON 
            req.relationship_id = unrel._id
        INNER JOIN 
                users u
            ON unrel.account_owner = u._id
        WHERE 
            req.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
            AND req.status = 'CLOSED'
            AND req.internalHelpDeskIndex = 'no'
            AND req.closed_on IS NOT NULL
            AND req.closed_on != ''
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        GROUP BY 
            request_week, u.name
        ORDER BY
            request_week, u.name
        
        ),
        requests_with_dates AS (
            SELECT
                ds.week_start,
                ds.week_end,
                wr.account_owner,
                COALESCE(wr.resolution_p90, 0) AS weekly_resolution_p90
            FROM
                date_series ds
            LEFT JOIN
                weekly_resolution wr
            ON
                DATE_TRUNC('week', wr.request_week) = ds.week_start AND wr.account_owner IS NOT NULL
        )
        
        SELECT
            week_start as request_week,
            week_end as request_week_end,
            account_owner as name,
            weekly_resolution_p90 as weekly_request_closure_p90
        FROM
            requests_with_dates
        ORDER BY
            week_start, name
        
          `;
          break;

        case "month":
          query = `--sql 
          WITH RECURSIVE date_range AS (
            SELECT 
                CAST('${fromDate}' AS DATE) AS start_date, 
                CAST('${toDate}' AS DATE) AS end_date
        ),
        date_series AS (
            SELECT 
                DATE_TRUNC('month', start_date) AS month_start,
                (DATE_TRUNC('month', start_date) + INTERVAL '1 month' - INTERVAL '1 day') AS month_end
            FROM date_range
            UNION ALL
            SELECT 
                DATE_TRUNC('month', month_start + INTERVAL '1 month'),
                (DATE_TRUNC('month', month_start + INTERVAL '1 month') + INTERVAL '1 month' - INTERVAL '1 day')
            FROM date_series
            WHERE DATE_TRUNC('month', month_start + INTERVAL '1 month') <= (SELECT end_date FROM date_range)
        ),
        unnested_relationships AS (
            SELECT
                _id,
                UNNEST(csmUsers) AS account_owner
            FROM
                relationships 
        ),
        monthly_resolution AS (
            SELECT
            DATE_TRUNC('month', req.created_at::timestamp) AS request_month,
        u.name as account_owner,
            quantile_cont(EXTRACT(EPOCH FROM (CAST(req.closed_on AS TIMESTAMP) - CAST(req.created_at AS TIMESTAMP))), 0.90) AS resolution_p90
        FROM 
            requests req
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
        LEFT JOIN 
            unnested_relationships unrel
        ON 
            req.relationship_id = unrel._id
        INNER JOIN 
                users u
            ON unrel.account_owner = u._id
        WHERE 
            req.created_at::timestamp BETWEEN '${fromDate}' AND '${toDate}'
            AND req.status = 'CLOSED'
            AND req.internalHelpDeskIndex = 'no'
            AND req.closed_on IS NOT NULL
            AND req.closed_on != ''
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        GROUP BY 
            request_month, u.name
        ORDER BY
            request_month, u.name
        
        ),
        requests_with_dates AS (
                        SELECT
                            ds.month_start AS request_month,
                            ds.month_end AS request_month_end,
                            wr.account_owner,
                            COALESCE(wr.resolution_p90, 0) AS monthly_request_closure_p90
                        FROM
                            date_series ds
                        LEFT JOIN
                            monthly_resolution wr
                        ON
                            DATE_TRUNC('month', wr.request_month) = ds.month_start AND wr.account_owner IS NOT NULL
                          
                    )
                    
                    SELECT
                        request_month,
                        request_month_end,
                        account_owner as name,
                        monthly_request_closure_p90
                    FROM
                        requests_with_dates
                    ORDER BY
                        request_month, account_owner        
          `;
          break;
        default:
      }
    }

    console.log({ resolution90: query });

    return query.split("--sql")[1];
  },
  assigneeMatrixTable: ({ fromDate, toDate, filters, sliceBy }) => {
    const queryInfo = getFinalQuery(filters, "", "rel.");

    console.log({ queryInfo });
    let query;
    if (sliceBy === "assignee") {
      query = `--sql
            WITH request_closure_p90 AS (
                SELECT
                    distinct 
                    u.name AS assigned_user,
                    quantile_cont(EXTRACT(EPOCH FROM (CAST(req.closed_on AS TIMESTAMP) - CAST(req.created_at AS TIMESTAMP))), 0.90)AS request_closure_p90
                FROM
                    requests req
                    LEFT JOIN users u ON req.assigned_to_user_id = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                WHERE
                    req.status = 'CLOSED'
                    AND req.internalHelpDeskIndex = 'no'
                    AND req.closed_on IS NOT NULL
                    AND req.closed_on != ''
                    AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    u.name
            ),
            request_closure_p70 AS (
                SELECT
                    distinct 
                    u.name AS assigned_user,
                    quantile_cont(EXTRACT(EPOCH FROM (CAST(req.closed_on AS TIMESTAMP) - CAST(req.created_at AS TIMESTAMP))), 0.70) AS request_closure_p70
                FROM
                    requests req
                    LEFT JOIN users u ON req.assigned_to_user_id = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                WHERE
                    req.status = 'CLOSED'
                    AND req.internalHelpDeskIndex = 'no'
                    AND req.closed_on IS NOT NULL
                    AND req.closed_on != ''
                    AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    u.name
            ),
        request_closure_p50 AS (
                SELECT
                    distinct 
                    u.name AS assigned_user,
                    quantile_cont(EXTRACT(EPOCH FROM (CAST(req.closed_on AS TIMESTAMP) - CAST(req.created_at AS TIMESTAMP))), 0.50) AS request_closure_p50
                FROM
                    requests req
                    LEFT JOIN users u ON req.assigned_to_user_id = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                WHERE
                    req.status = 'CLOSED'
                    AND req.internalHelpDeskIndex = 'no'
                    AND req.closed_on IS NOT NULL
                    AND req.closed_on != ''
                    AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    u.name
            ),
            first_response_p90 AS (
                SELECT
                    distinct 
                    u.name AS assigned_user,
                    quantile_cont(
                        CAST(req.first_response_time AS DOUBLE) - CAST(req.original_message_ts AS DOUBLE),
                        0.90
                    ) AS first_response_p90
                FROM
                    requests req
                    LEFT JOIN users u ON req.assigned_to_user_id = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                WHERE
                    req.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                    AND req.internalHelpDeskIndex = 'no'
                    AND req.first_response_time IS NOT NULL
                    AND req.first_response_time != ''
                    AND req.first_response_time != '[]'
                    AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    u.name
            ),
            first_response_p70 AS (
                SELECT
                    distinct 
                    u.name AS assigned_user,
                    quantile_cont(
                        CAST(req.first_response_time AS DOUBLE) - CAST(req.original_message_ts AS DOUBLE),
                        0.70
                    ) AS first_response_p70
                FROM
                    requests req
                    LEFT JOIN users u ON req.assigned_to_user_id = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                WHERE
                    req.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                    AND req.internalHelpDeskIndex = 'no'
                    AND req.first_response_time IS NOT NULL
                    AND req.first_response_time != ''
                    AND req.first_response_time != '[]'
                    AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    u.name
            ),
        first_response_p50 AS (
                SELECT
                    distinct 
                    u.name AS assigned_user,
                    quantile_cont(
                        CAST(req.first_response_time AS DOUBLE) - CAST(req.original_message_ts AS DOUBLE),
                        0.50
                    ) AS first_response_p50
                FROM
                    requests req
                    LEFT JOIN users u ON req.assigned_to_user_id = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                WHERE
                    req.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                    AND req.internalHelpDeskIndex = 'no'
                    AND req.first_response_time IS NOT NULL
                    AND req.first_response_time != ''
                    AND req.first_response_time != '[]'
                    AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    u.name
            ),

            combined_metrics AS (
                SELECT
                    distinct
                    u.name AS assigned_user,
                    req.assigned_to_user_id,
                    COUNT(distinct req._id) AS request_count,
                    MAX(req.created_at) AS latest_created_at,
                    COUNT(distinct req._id) FILTER (
                        WHERE
                        cast(req.sla_metrics->>'first_response_sla'->>'is_breached' as bool) =true
                      ) AS first_response_sla_count,
                      COUNT(distinct req._id) FILTER (
                        WHERE
                        cast(req.sla_metrics->>'resolution_sla'->>'is_breached' as bool) = true
                      ) AS final_resolution_sla_count
                FROM
                    requests req
                    INNER JOIN users u ON req.assigned_to_user_id = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                WHERE
                    req.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                    AND req.internalHelpDeskIndex = 'no'
                    AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    u.name,
                    req.assigned_to_user_id
            )
            SELECT
                cm.assigned_user,
                cm.assigned_to_user_id,
                cm.request_count,
                cm.latest_created_at,
                rcp5.request_closure_p50,
                rcp.request_closure_p70,
                frp5.first_response_p50,
                frp.first_response_p70,
                rcp9.request_closure_p90,
                frp9.first_response_p90,
                cm.first_response_sla_count,
                cm.final_resolution_sla_count
            FROM
                combined_metrics cm
                LEFT JOIN request_closure_p50 rcp5 ON cm.assigned_user = rcp5.assigned_user
                LEFT JOIN request_closure_p70 rcp ON cm.assigned_user = rcp.assigned_user
                LEFT JOIN request_closure_p90 rcp9 ON cm.assigned_user = rcp9.assigned_user
                LEFT JOIN first_response_p90 frp9 ON cm.assigned_user = frp9.assigned_user
                LEFT JOIN first_response_p70 frp ON cm.assigned_user = frp.assigned_user
                LEFT JOIN first_response_p50 frp5 ON cm.assigned_user = frp5.assigned_user
            ORDER BY 
                cm.request_count desc`;
    } else {
      query = `--sql 
            WITH unnested_relationships AS (
                SELECT
                    _id AS relationship_id,
                    UNNEST(csmUsers) AS account_owner
                FROM
                    relationships
            ),
            request_closure_p90 AS (
                SELECT
                    distinct
                    u.name as account_owner,
                    quantile_cont(
                        EXTRACT(
                            EPOCH
                            FROM
                                (
                                    CAST(req.closed_on AS TIMESTAMP) - CAST(req.created_at AS TIMESTAMP)
                                )
                        ),
                        0.90
                    ) AS request_closure_p90
                FROM
                    requests req
                    LEFT JOIN unnested_relationships unrel ON req.relationship_id = unrel.relationship_id
                    INNER JOIN users u ON unrel.account_owner = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                WHERE
                    req.status = 'CLOSED'
                    AND req.internalHelpDeskIndex = 'no'
                    AND req.closed_on IS NOT NULL
                    AND req.closed_on != ''
                    AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    u.name
            ),
            request_closure_p70 AS (
                SELECT
                    distinct 
                    u.name as account_owner,
                    quantile_cont(
                        EXTRACT(
                            EPOCH
                            FROM
                                (
                                    CAST(req.closed_on AS TIMESTAMP) - CAST(req.created_at AS TIMESTAMP)
                                )
                        ),
                        0.70
                    ) as request_closure_p70
                FROM
                    requests req
                    LEFT JOIN unnested_relationships unrel ON req.relationship_id = unrel.relationship_id
                    INNER JOIN users u ON unrel.account_owner = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                WHERE
                    req.status = 'CLOSED'
                    AND req.internalHelpDeskIndex = 'no'
                    AND req.closed_on IS NOT NULL
                    AND req.closed_on != ''
                    AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    u.name
            ),
            request_closure_p50 AS (
                SELECT
                    distinct
                    u.name as account_owner,
                    quantile_cont(
                        EXTRACT(
                            EPOCH
                            FROM
                                (
                                    CAST(req.closed_on AS TIMESTAMP) - CAST(req.created_at AS TIMESTAMP)
                                )
                        ),
                        0.50
                    ) as request_closure_p50
                FROM
                    requests req
                    LEFT JOIN unnested_relationships unrel ON req.relationship_id = unrel.relationship_id
                    INNER JOIN users u ON unrel.account_owner = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                WHERE
                    req.status = 'CLOSED'
                    AND req.internalHelpDeskIndex = 'no'
                    AND req.closed_on IS NOT NULL
                    AND req.closed_on != ''
                    AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    u.name
            ),
            first_response_p90 AS (
                SELECT
                    distinct 
                    u.name as account_owner,
                    quantile_cont(
                        CAST(req.first_response_time AS DOUBLE) - CAST(req.original_message_ts AS DOUBLE),
                        0.9
                    ) AS first_response_p90
                FROM
                    requests req
                    LEFT JOIN unnested_relationships unrel ON req.relationship_id = unrel.relationship_id
                    INNER JOIN users u ON unrel.account_owner = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                WHERE
                    req.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                    AND req.internalHelpDeskIndex = 'no'
                    AND req.first_response_time IS NOT NULL
                    AND req.first_response_time != ''
                    AND req.first_response_time != '[]'
                    AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    u.name
            ),
            first_response_p70 AS (
                SELECT
                    distinct 
                    u.name as account_owner,
                    quantile_cont(
                        CAST(req.first_response_time AS DOUBLE) - CAST(req.original_message_ts AS DOUBLE),
                        0.7
                    ) AS first_response_p70
                FROM
                    requests req
                    LEFT JOIN unnested_relationships unrel ON req.relationship_id = unrel.relationship_id
                    INNER JOIN users u ON unrel.account_owner = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                WHERE
                    req.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                    AND req.internalHelpDeskIndex = 'no'
                    AND req.first_response_time IS NOT NULL
                    AND req.first_response_time != ''
                    AND req.first_response_time != '[]'
                    AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    u.name
            ),
            first_response_p50 AS (
                SELECT
                    distinct 
                    u.name as account_owner,
                    quantile_cont(
                        CAST(req.first_response_time AS DOUBLE) - CAST(req.original_message_ts AS DOUBLE),
                        0.5
                    ) AS first_response_p50
                FROM
                    requests req
                    LEFT JOIN unnested_relationships unrel ON req.relationship_id = unrel.relationship_id
                    INNER JOIN users u ON unrel.account_owner = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                WHERE
                    req.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                    AND req.internalHelpDeskIndex = 'no'
                    AND req.first_response_time IS NOT NULL
                    AND req.first_response_time != ''
                    AND req.first_response_time != '[]'
                    AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    u.name
            ),
            combined_metrics AS (
                SELECT
                    distinct 
                    u.name as account_owner,
                    u._id as assigned_to_user_id,
                    COUNT(distinct req._id) AS request_count,
                    MAX(req.created_at) AS latest_created_at,
                    COUNT(distinct req._id) FILTER (
                        WHERE
                        cast(req.sla_metrics->>'first_response_sla'->>'is_breached' as bool) =true
                      ) AS first_response_sla_count,
                      COUNT(distinct req._id) FILTER (
                        WHERE
                        cast(req.sla_metrics->>'resolution_sla'->>'is_breached' as bool) = true
                      ) AS final_resolution_sla_count
                FROM
                    requests req
                    LEFT JOIN unnested_relationships unrel ON req.relationship_id = unrel.relationship_id
                    INNER JOIN users u ON unrel.account_owner = u._id
                    ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `req._id`) : ""}
                    ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "req.") : ""}
                    ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "req.relationship_id") : ""}
                WHERE
                    req.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
                    AND req.internalHelpDeskIndex = 'no'
                    AND req.created_at BETWEEN '${fromDate}' AND '${toDate}'
                    ${queryInfo.conditionsString}
                    ${queryInfo.requestQuery}
                    ${queryInfo.relationshipQuery}
                GROUP BY
                    u.name,
                    u._id
            )
            SELECT
                distinct 
                cm.account_owner as assigned_user,
                cm.assigned_to_user_id as assigned_to_user_id,
                cm.request_count,
                cm.latest_created_at,
                rcp5.request_closure_p50,
                rcp.request_closure_p70,
                rcp9.request_closure_p90,
                frp5.first_response_p50,
                frp.first_response_p70,
                frp9.first_response_p90,
                cm.first_response_sla_count,
                cm.final_resolution_sla_count
            FROM
                combined_metrics cm
                LEFT JOIN request_closure_p50 rcp5 ON cm.account_owner = rcp5.account_owner
                LEFT JOIN request_closure_p70 rcp ON cm.account_owner = rcp.account_owner
                LEFT JOIN request_closure_p90 rcp9 ON cm.account_owner = rcp9.account_owner
                LEFT JOIN first_response_p90 frp9 ON cm.account_owner = frp9.account_owner
                LEFT JOIN first_response_p50 frp5 ON cm.account_owner = frp5.account_owner
                LEFT JOIN first_response_p70 frp ON cm.account_owner = frp.account_owner
            ORDER BY
                request_count desc`;
    }

    console.log({ assigneeMatrixTable: query });

    return query.split("--sql")[1];
  },
};

export const getDateRange = (from, to) => {
  const startDate = parseISO(from);
  const endDate = parseISO(to);
  const dateArray: any[] = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    dateArray.push(format(currentDate, "yyyy-MM-dd"));
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
};

export const getDateRangeByType = (from, to, increment = "day") => {
  const startDate = parseISO(from);
  const endDate = parseISO(to);
  const dateArray: any = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    if (increment === "day") {
      dateArray.push(format(currentDate, "yyyy-MM-dd"));
      currentDate = addDays(currentDate, 1);
    } else if (increment === "week") {
      const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 }); // Setting week start to Monday
      if (startOfCurrentWeek >= startDate && startOfCurrentWeek <= endDate) {
        dateArray.push(format(startOfCurrentWeek, "yyyy-MM-dd"));
      }
      currentDate = addWeeks(currentDate, 1);
    } else if (increment === "month") {
      const startOfCurrentMonth = startOfMonth(currentDate);
      if (startOfCurrentMonth >= startDate && startOfCurrentMonth <= endDate) {
        dateArray.push(format(startOfCurrentMonth, "yyyy-MM-dd"));
      }
      currentDate = addMonths(currentDate, 1);
    } else {
      throw new Error("Invalid increment type");
    }
  }

  return dateArray;
};

export const DUCKDB_ARRAY_TYPES = [
  "_Vector",
  "Vt",
  "Yt",
  "Xt",
  "Ca",
  "Mi",
  "Ui",
  "Bi",
  "Js",
  "Xs",
  "is",
];

export const transformObjectToList = (input) => {
  const _id = input._id;

  return map(omit(input, "_id"), (value, key) => ({
    _id: _id,
    field_name: key,
    field_values: value,
  }));
};

export const getIsDateType = (dateList: any[] = []) => {
  const isWeeklyOrMonthly = dateList.length > 28;
  const isMonthly = isWeeklyOrMonthly && dateList.length > 168;
  const isWeekly = isWeeklyOrMonthly && !isMonthly;
  const selectedFilter = useAnalyticsStore.getState().selectedFilter;

  if (selectedFilter) {
    return selectedFilter === "D"
      ? "day"
      : selectedFilter === "W"
        ? "week"
        : "month";
  }
  if (isWeekly) {
    return "week";
  }
  if (isMonthly) {
    return "month";
  }
  return "day";
};
export const DUCK_DB_CARD_HOVER_CHART_QUERIES = {
  firstResponseOnCardHover: ({ value, label, filters }) => {
    const queryInfo = getFinalQuery(filters, "", "rel.");
    const query = `--sql 
    WITH RECURSIVE date_range AS (
        SELECT
            DATE_TRUNC('week', CURRENT_DATE - INTERVAL '1 month') AS start_date,
            CURRENT_DATE AS end_date
    ),
    date_series AS (
        SELECT
            start_date AS week_start,
            start_date + INTERVAL '6 days' AS week_end
        FROM
            date_range
        UNION
        ALL
        SELECT
            week_start + INTERVAL '1 week',
            week_start + INTERVAL '1 week' + INTERVAL '6 days'
        FROM
            date_series
        WHERE
            week_start + INTERVAL '1 week' <= (
                SELECT
                    end_date
                FROM
                    date_range
            )
    ),
    frt_requests AS (
        SELECT
            ds.week_start,
            ds.week_end,
            quantile_cont(CAST(r.first_response_time AS DOUBLE) - CAST(r.original_message_ts AS DOUBLE),0.${value}) 
                 
                AS ${label}
        FROM
            date_series ds
            LEFT JOIN requests r ON CAST(r.created_at AS DATE) BETWEEN ds.week_start AND ds.week_end
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
        WHERE
            r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
            AND r.internalHelpDeskIndex = 'no'
            AND r.created_at BETWEEN CURRENT_DATE - INTERVAL '1 month' AND CURRENT_DATE
            AND r.first_response_time IS NOT NULL
            AND r.first_response_time != ''
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        GROUP BY
            ds.week_start,
            ds.week_end
    )
    SELECT
        ds.week_start || ' - ' || ds.week_end AS period,
        COALESCE(frt.${label}, 0) AS ${label}
    FROM
        date_series ds
        LEFT JOIN frt_requests frt ON ds.week_start = frt.week_start AND ds.week_end = frt.week_end
    ORDER BY
        ds.week_start    
    `;

    console.log({ firstResponseOnHoverHover: query });

    return query.split("--sql")[1];
  },
  resolutionOnCardHover: ({ value, label, filters }) => {
    const queryInfo = getFinalQuery(filters, "", "rel.");
    const query = `--sql 
    WITH RECURSIVE date_range AS (
        SELECT
            DATE_TRUNC('week', CURRENT_DATE - INTERVAL '1 month') AS start_date,
            CURRENT_DATE AS end_date
    ),
    date_series AS (
        SELECT
            start_date AS week_start,
            start_date + INTERVAL '6 days' AS week_end
        FROM
            date_range
        UNION
        ALL
        SELECT
            week_start + INTERVAL '1 week',
            week_start + INTERVAL '1 week' + INTERVAL '6 days'
        FROM
            date_series
        WHERE
            week_start + INTERVAL '1 week' <= (
                SELECT
                    end_date
                FROM
                    date_range
            )
    ),
    closure_requests AS (
        SELECT
            ds.week_start,
            ds.week_end,
            quantile_cont( EXTRACT(EPOCH FROM (CAST(r.closed_on AS TIMESTAMP) - CAST(r.created_at AS TIMESTAMP))),0.${value}) 
                
                AS ${label}
        FROM
            date_series ds
            LEFT JOIN requests r ON CAST(r.created_at AS DATE) BETWEEN ds.week_start AND ds.week_end
            ${queryInfo.isCustomFieldFilter ? CUSTOMFIELD_JOIN(filters, `r._id`) : ""}
            ${queryInfo.isRelationshipFilter || queryInfo.isCRMFieldFilter ? RELATIONSHIP_JOIN("rel", "r.") : ""}
            ${queryInfo.isCRMFieldFilter ? CRMFIELD_JOIN(filters, "r.relationship_id") : ""}
        WHERE
            r.status IN ('CLOSED')
            AND r.internalHelpDeskIndex = 'no'
            AND r.created_at BETWEEN CURRENT_DATE - INTERVAL '1 month' AND CURRENT_DATE
            AND r.closed_on IS NOT NULL
            AND r.closed_on != ''
            ${queryInfo.conditionsString}
            ${queryInfo.requestQuery}
            ${queryInfo.relationshipQuery}
        GROUP BY
            ds.week_start,
            ds.week_end
    )
    SELECT
        ds.week_start || ' - ' || ds.week_end AS period,
        COALESCE(cr.${label}, 0) AS ${label}
    FROM
        date_series ds
        LEFT JOIN closure_requests cr ON ds.week_start = cr.week_start AND ds.week_end = cr.week_end
    ORDER BY
        ds.week_start
    `;

    console.log({ firstResolutionONHoverHover: query });

    return query.split("--sql")[1];
  },
};

export const DUCK_DB_CUSTOM_FIELD_TABLE_HOVER_CHART_QUERIES = {
  requestCreatedOnHover: ({ customFieldHash, fieldValue }) => {
    const query = `--sql
    WITH RECURSIVE date_range AS (
        SELECT
            DATE_TRUNC('week', CURRENT_DATE - INTERVAL '1 month') AS start_date,
            CURRENT_DATE AS end_date
    ),
    date_series AS (
        SELECT
            start_date AS week_start,
            start_date + INTERVAL '6 days' AS week_end
        FROM
            date_range
        UNION
        ALL
        SELECT
            week_start + INTERVAL '1 week',
            week_start + INTERVAL '1 week' + INTERVAL '6 days'
        FROM
            date_series
        WHERE
            week_start + INTERVAL '1 week' <= (
                SELECT
                    end_date
                FROM
                    date_range
            )
    ),
    frt_requests AS (
        SELECT
            ds.week_start,
            ds.week_end,
            count(distinct r._id) as request_count
        FROM
            date_series ds
            LEFT JOIN requests r ON CAST(r.created_at AS DATE) BETWEEN ds.week_start AND ds.week_end
            LEFT JOIN customFields cf ON cf._id = r._id
            
            
            
        WHERE
            r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
            AND r.internalHelpDeskIndex = 'no'
            AND r.created_at BETWEEN CURRENT_DATE - INTERVAL '1 month' AND CURRENT_DATE
        and    cf.field_hash = 'custom_${customFieldHash}'
        and cf.field_values ilike '%${fieldValue}%'
            
            
        GROUP BY
            ds.week_start,
            ds.week_end
    )
    SELECT
        ds.week_start || ' - ' || ds.week_end AS period,
        COALESCE(frt.request_count, 0) AS request_count
    FROM
        date_series ds
        LEFT JOIN frt_requests frt ON ds.week_start = frt.week_start AND ds.week_end = frt.week_end
    ORDER BY
        ds.week_start `;

    console.log({ requestCreatedOnHover: query });

    return query.split("--sql")[1];
  },
  firstResponseP90OnHover: ({ customFieldHash, fieldValue }) => {
    const query = `--sql 
    WITH RECURSIVE date_range AS (
        SELECT
            DATE_TRUNC('week', CURRENT_DATE - INTERVAL '1 month') AS start_date,
            CURRENT_DATE AS end_date
    ),
    date_series AS (
        SELECT
            start_date AS week_start,
            start_date + INTERVAL '6 days' AS week_end
        FROM
            date_range
        UNION
        ALL
        SELECT
            week_start + INTERVAL '1 week',
            week_start + INTERVAL '1 week' + INTERVAL '6 days'
        FROM
            date_series
        WHERE
            week_start + INTERVAL '1 week' <= (
                SELECT
                    end_date
                FROM
                    date_range
            )
    ),
    frt_requests AS (
        SELECT
            ds.week_start,
            ds.week_end,
            quantile_cont(CAST(r.first_response_time AS DOUBLE) - CAST(r.original_message_ts AS DOUBLE),0.90) 
                 
                AS first_response_p90
        FROM
            date_series ds
            LEFT JOIN requests r ON CAST(r.created_at AS DATE) BETWEEN ds.week_start AND ds.week_end
            LEFT JOIN customFields cf ON r._id = cf._id
        WHERE
            r.status IN ('OPEN', 'INPROGRESS', 'ONHOLD', 'CLOSED')
            AND r.internalHelpDeskIndex = 'no'
            AND r.created_at BETWEEN CURRENT_DATE - INTERVAL '1 month' AND CURRENT_DATE
 and    cf.field_hash = 'custom_${customFieldHash}'
        and cf.field_values ilike '%${fieldValue}%'
        AND first_response_time is not null 
        AND first_response_time != ''

        GROUP BY
            ds.week_start,
            ds.week_end
    )
    SELECT
        ds.week_start || ' - ' || ds.week_end AS period,
        COALESCE(frt.first_response_p90, 0) AS first_response_p90
    FROM
        date_series ds
        LEFT JOIN frt_requests frt ON ds.week_start = frt.week_start AND ds.week_end = frt.week_end
    ORDER BY
        ds.week_start
`;

    console.log({ firstResponseP90OnHover: query });

    return query.split("--sql")[1];
  },
  resolutionP90OnHover: ({ customFieldHash, fieldValue }) => {
    const query = `--sql 
    WITH RECURSIVE date_range AS (
        SELECT
            DATE_TRUNC('week', CURRENT_DATE - INTERVAL '1 month') AS start_date,
            CURRENT_DATE AS end_date
    ),
    date_series AS (
        SELECT
            start_date AS week_start,
            start_date + INTERVAL '6 days' AS week_end
        FROM
            date_range
        UNION
        ALL
        SELECT
            week_start + INTERVAL '1 week',
            week_start + INTERVAL '1 week' + INTERVAL '6 days'
        FROM
            date_series
        WHERE
            week_start + INTERVAL '1 week' <= (
                SELECT
                    end_date
                FROM
                    date_range
            )
    ),
    closure_requests AS (
        SELECT
            ds.week_start,
            ds.week_end,
            quantile_cont( EXTRACT(EPOCH FROM (CAST(r.closed_on AS TIMESTAMP) - CAST(r.created_at AS TIMESTAMP))),0.90) 
                
                AS request_closure_p90
        FROM
            date_series ds
            LEFT JOIN requests r ON CAST(r.created_at AS DATE) BETWEEN ds.week_start AND ds.week_end
            LEFT JOIN customFields cf on r._id = cf._id
        WHERE
            r.status IN ('CLOSED')
            AND r.internalHelpDeskIndex = 'no'
            AND r.created_at BETWEEN CURRENT_DATE - INTERVAL '1 month' AND CURRENT_DATE
            AND r.closed_on IS NOT NULL
            AND r.closed_on != ''
            and    cf.field_hash = 'custom_${customFieldHash}'
        and cf.field_values ilike '%${fieldValue}%'

        GROUP BY
            ds.week_start,
            ds.week_end
    )
    SELECT
        ds.week_start || ' - ' || ds.week_end AS period,
        COALESCE(cr.request_closure_p90, 0) AS request_closure_p90
    FROM
        date_series ds
        LEFT JOIN closure_requests cr ON ds.week_start = cr.week_start AND ds.week_end = cr.week_end
    ORDER BY
        ds.week_start   
`;

    console.log({
      firstResolutionP90OnCustomFieldHover: query,
    });

    return query.split("--sql")[1];
  },
};
