import React, { useState } from "react";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { EditorContent, useEditor } from "@tiptap/react";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { Button } from "../ui/button";
import StarterKit from "@tiptap/starter-kit";
import { updateMacro } from "@/api/kanban";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";
import { EditorToolbar } from "./EditorToolBar";
import Link from "@tiptap/extension-link";

const MacroEdit = ({ macro, refetch }) => {
  const [macroForm, setMacroForm] = useState({
    name: macro?.name,
    content: macro?.content,
    scope: macro?.scope,
  });

  const onStateChange = (val, name) => {
    setMacroForm((prev) => ({
      ...prev,
      [name]: val,
    }));
  };

  const { mutate, isPending } = useMutation({
    mutationFn: (id) => {
      return updateMacro(id, macroForm);
    },
    onSuccess: () => {
      refetch();
      toast.success("Successfully updated");
    },
  });

  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        openOnClick: false,
      }),
    ],
    content: JSON.parse(macro.content),
    onUpdate: ({ editor }) => {
      return onStateChange(JSON.stringify(editor.getJSON()), "content");
    },
  });
  const handelUpdateMacro = () => {
    mutate(macro._id);
  };
  return (
    <DialogContent className="sm:max-w-[640px]">
      <DialogHeader>
        <DialogTitle>Edit snippet</DialogTitle>
      </DialogHeader>
      <div className="grid gap-6 py-4">
        <div className="flex flex-col gap-2 items-start">
          <Label htmlFor="name" className="text-right">
            Snippet name <sup className="text-[var(--color-text-error)]">*</sup>
          </Label>
          <Input
            id="name"
            value={macroForm.name}
            placeholder="Give a name"
            onChange={(e) => onStateChange(e.target.value, "name")}
            className="col-span-3 bg-transparent"
          />
        </div>
        <div className="flex flex-col gap-3 items-start">
          <Label htmlFor="username" className="text-right">
            Content <sup className="text-[var(--color-text-error)]">*</sup>
          </Label>
          <div className="border w-full rounded">
            <EditorToolbar editor={editor} />
            <div className="h-40 overflow-scroll">
              <EditorContent
                editor={editor}
                className="h-40 p-4 outline-none *:outline-none"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-2 items-start">
          <Label htmlFor="name" className="text-right">
            Access <sup className="text-[var(--color-text-error)]">*</sup>
          </Label>
          <RadioGroup
            defaultValue={macroForm.scope}
            className="flex gap-3"
            onValueChange={(val) => onStateChange(val, "scope")}
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="USER" id="r1" />
              <Label htmlFor="r1">Only me </Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="GLOBAL" id="r2" />
              <Label htmlFor="r2">Everyone</Label>
            </div>
          </RadioGroup>
        </div>
      </div>
      <DialogFooter>
        <Button
          type="submit"
          disabled={isPending}
          loading={isPending}
          onClick={handelUpdateMacro}
        >
          Save
        </Button>
      </DialogFooter>
    </DialogContent>
  );
};

export default MacroEdit;
