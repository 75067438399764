import { fetchRequests } from "@/api/customerPortal";
import { fetchSlackAuthUrl } from "@/api/kanban";
import ThenaLoader from "@/components/common/ThenaLoader";
import { ThemeProvider } from "@/context/theme-provider";
import Layout from "@/layout/Layout";
import CustomerPortalRoutes from "@/routes/CustomerPortalRoutes";
import { useAuthStorePersist } from "@/store/authStorePersist";
import { useCustomerStore } from "@/store/customerStore";
import { processRequests } from "@/utils/customerPortal";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

const processRequestData = (data) => {
  if (Array.isArray(data)) {
    const processedData = data.map(processRequests);
    useCustomerStore.getState().setRequests(processedData);
  }
  return data;
};

const App = () => {
  const { isLoading } = useQuery({
    queryKey: ["customerPortalRequests"],
    queryFn: fetchRequests,
    select: processRequestData,
  });

  const authStoreDispatch = useAuthStorePersist((state) => state.dispatch);

  const { data: userAuthData } = useQuery({
    queryKey: ["fetchSlackAuthUrl"],
    queryFn: fetchSlackAuthUrl,
  });

  useEffect(() => {
    if (userAuthData) {
      authStoreDispatch({
        type: "SET_USER_REPLY_AS_AUTH",
        payload: { isUserAuthorisedForReplyAs: userAuthData.enabled },
      });
    }
  }, [authStoreDispatch, userAuthData]);

  const renderContent = () => {
    if (isLoading) {
      return <ThenaLoader />;
    }

    return (
      <Layout>
        <CustomerPortalRoutes />
      </Layout>
    );
  };

  return (
    <ThemeProvider storageKey="vite-ui-theme">{renderContent()}</ThemeProvider>
  );
};

export default App;
