import {
  Bold,
  CodeXml,
  Italic,
  List,
  ListOrdered,
  SquareDashedBottomCode,
  Strikethrough,
  TextQuote,
} from "lucide-react";
import { Button } from "../ui/button";
import { LinkComponent } from "./LinksComponent";

function EditorToolbar({ editor }) {
  return (
    <div className="border-b p-1" tabIndex={-1}>
      <Button
        variant="ghost"
        size="icon"
        onClick={() => editor.chain().focus().toggleBold().run()}
        disabled={!editor.can().chain().focus().toggleBold().run()}
        className={editor.isActive("bold") ? "is-active w-9 h-9" : "w-9 h-9"}
        tabIndex={-1}
      >
        <Bold size={16} />
      </Button>
      <Button
        tabIndex={-1}
        variant="ghost"
        size="icon"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        disabled={!editor.can().chain().focus().toggleItalic().run()}
        className={editor.isActive("italic") ? "is-active w-9 h-9" : "w-9 h-9"}
      >
        <Italic size={16} />
      </Button>
      <Button
        variant="ghost"
        size="icon"
        onClick={() => editor.chain().focus().toggleStrike().run()}
        disabled={!editor.can().chain().focus().toggleStrike().run()}
        className={editor.isActive("strike") ? "is-active w-9 h-9" : "w-9 h-9"}
        tabIndex={-1}
      >
        <Strikethrough size={16} />
      </Button>
      <Button
        variant="ghost"
        size="icon"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={
          editor.isActive("bulletList") ? "is-active w-9 h-9" : "w-9 h-9"
        }
        tabIndex={-1}
      >
        <List size={16} />
      </Button>
      <Button
        variant="ghost"
        size="icon"
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={
          editor.isActive("orderedList") ? "is-active w-9 h-9" : "w-9 h-9"
        }
        tabIndex={-1}
      >
        <ListOrdered size={16} />
      </Button>
      <Button
        size="icon"
        variant="ghost"
        onClick={() => editor.chain().focus().toggleCode().run()}
        disabled={!editor.can().chain().focus().toggleCode().run()}
        className={editor.isActive("code") ? "is-active w-9 h-9" : "w-9 h-9"}
        tabIndex={-1}
      >
        <CodeXml size={16} />
      </Button>
      <LinkComponent editor={editor} />
      <Button
        variant="ghost"
        size="icon"
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={
          editor.isActive("blockquote") ? "is-active w-9 h-9" : "w-9 h-9"
        }
        tabIndex={-1}
      >
        <TextQuote size={16} />
      </Button>
      <Button
        variant="ghost"
        size="icon"
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={
          editor.isActive("codeBlock") ? "is-active w-9 h-9" : "w-9 h-9"
        }
        tabIndex={-1}
      >
        {" "}
        <SquareDashedBottomCode size={16} />
      </Button>
    </div>
  );
}

export { EditorToolbar };
