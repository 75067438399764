import { api } from ".";

export const fetchRecommendedChannels = () =>
  api.get("/v1/recommended").then(({ data }) => data.data);

export const fetchOnboardingChannels = () =>
  api.get("/v1/onboarding/channels").then(({ data }) => data.data);

export const fetchAllPublicSharedChannels = () =>
  api.get("/v1/channels/all-shared").then(({ data }) => data.data);

export const fetchAllChannels = (filters: any) =>
  api
    .get("/v1/channels", {
      params: filters,
    })
    .then(({ data }) => data.data);

export const fetchBothInternalAndExternalChannels = () =>
  api.get("/v1/all-channels").then(({ data }) => data.data);

export const fetchChannel = (id: string) =>
  api.get(`/v1/channel/${id}`).then(({ data }) => data.data);

export const fetchPublicChannels = ({ page = 0, search = "" }) =>
  api
    .get("/v1/channels/internal", { params: { page, search } })
    .then(({ data: { data } }) => data);

export const fetchHiddenChannels = () =>
  api.get("/v1/channels/hidden").then(({ data }) => data.data);

export const fetchSelectedChannels = ({ type }: { type?: string } = {}) =>
  api
    .get("/v1/channels/selected", { params: { type } })
    .then(({ data }) => data.data);

export const hideChannel = (channelId: string) =>
  api
    .post("/v1/channel/hide", { channel_id: channelId })
    .then(({ data }) => data.data);

export const unhideChannel = (channelIds: string[], unhideAll = false) =>
  api
    .post("/v1/channels/unhide", { channel_ids: channelIds, unhideAll })
    .then(({ data }) => data.data);

export const selectChannel = (channelId: string) =>
  api
    .post("/v1/channel/select", { channel_id: channelId })
    .then(({ data }) => data.data);

export const selectChannelV2 = ({
  channelId,
  teamId,
}: {
  channelId: string;
  teamId: string;
}) =>
  api
    .post("/v1/channel/select", { channel_id: channelId, team_id: teamId })
    .then(({ data }) => data.data);

export const unselectChannel = (channelId: string) =>
  api
    .delete("/v1/channel/select", {
      data: {
        channel_id: channelId,
      },
    })
    .then(({ data }) => data.data);

export const unselectAllChannel = (channelId: string) =>
  api.delete("/v1/channel/select-all").then(({ data }) => data.data);

export const selectAllChannels = () =>
  api.post("/v1/channels/select-all").then(({ data }) => data.data);

export const selectManyChannels = (channelIds: string[] = []) =>
  api
    .post("/v1/channels/select-many", {
      channel_ids: channelIds,
    })
    .then(({ data }) => data.data);

export const fetchThenaAddedChannels = () =>
  api.get(`/v2/settings/thena-added-channels`).then(({ data }) => data.data);
