import AuthRedirectPage from "@/components/AuthRedirectPage";
import { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

const MembersPromise = import("@/pages/CustomerPortal/Members/Members");
const Members = lazy(() => MembersPromise);

const UserPreferencePromise = import("@/pages/UserPreference/UserPreference");
const UserPreference = lazy(() => UserPreferencePromise);

const RequestPromise = import("@/pages/CustomerPortal/Requests");
const Requests = lazy(() => RequestPromise);

const CustomerPortalRoutes = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/requests" element={<Requests />} />
        <Route path="/members" element={<Members />} />
        <Route path="/user-preferences" element={<UserPreference />} />
        <Route path="/slack/user/authorize" element={<AuthRedirectPage />} />
      </Routes>
    </Suspense>
  );
};

export default CustomerPortalRoutes;
