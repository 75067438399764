import { getThenaDB } from "@/db";
import { FilterType } from "@/types/filters";
import DefaultProfileIcon from "@/images/icons/ic_default_slack_profile.png";
import { getLaneTitle } from "./Kanban/utils";
import { views } from "@/constants/views";

const FILTER_INDEX_IGNORE_LIST = [
  "updated_at",
  "sub_status",
  "created_at",
  "request_id",
  "installation_id",
  "created_at_date",
  "message_details_ts",
  "assigned_by_user_id",
  "installation_team_id",
  "original_message_user_id",
  "urgencyOrder",
  "finalResolutionSlaOrder",
  "firstResponseSlaOrder",
  "internalHelpDeskIndex",
  "lastCustomerReplyOrder",
  "lastReplyOrder",
  "slaBreached",
  "lastCustomerReply",
  "updated_at_date",
  "escalation_type",
];

const TICKETS_FILTER_INDEX_IGNORE_LIST: string[] = [
  "_id",
  "created_at",
  "created_at_date",
  "updated_at",
  "updated_at_date",
  "sink",
  "ai_metadata",
  "installation_id",
  "installation_team_id",
  "relationship_channel_id",
  "relationship_channel_name",
  "ts",
  "type",
  "tags",
  "user",
  "title",
  "requestor",
  "permalink",
  "description",
  "context_element",
  "original_message_ts",
  "original_message_channel_id",
  "escalation_details",
  "feedback",
  "first_action_message_ts",
];

export const buildFilters = async (keys: string[]) => {
  const keysForFilter = keys.filter(
    (key) => !FILTER_INDEX_IGNORE_LIST.includes(key)
  );

  const { customKeys, defaultKeys } = keysForFilter.reduce(
    (acc, key) => {
      if (key.includes("custom_")) {
        acc.customKeys.push(key);
      } else {
        acc.defaultKeys.push(key);
      }
      return acc;
    },
    {
      defaultKeys: [] as string[],
      customKeys: [] as string[],
    }
  );

  const customFieldFilters = await buildCustomFilters(customKeys);

  const filterList: any[] = [];

  for (const index in defaultKeys) {
    const key = defaultKeys[index];

    if (key === "relationship_id") {
      filterList.push({
        name: "Customers",
        values: await getCustomersValues(),
        indexed_key: "relationship_id",
      });
      filterList.push({
        name: "Account owners",
        values: await getFilterValues("all_users"),
        indexed_key: "relationship_id",
      });
      filterList.push({
        name: "Solution Engineer",
        values: await getFilterValues("all_users"),
        indexed_key: "relationship_id",
      });
      filterList.push({
        name: "Channel",
        values: await getFilterValues("channel"),
        indexed_key: "relationship_id",
      });
    } else if (key === "status") {
      filterList.push({
        name: "Status",
        values: await getStatusValues(),
        indexed_key: key,
      });
    } else {
      const name = getFilterName(key);
      const values = await getFilterValues(key);
      filterList.push({
        name,
        values,
        indexed_key: key,
      });
    }
  }
  return filterList.concat(customFieldFilters);
};

export const buildCustomFilters = async (keys: string[]) => {
  const list: FilterType[] = [];

  for (const index in keys) {
    const key = keys[index];
    const customFieldHash = key.split("custom_")[1];
    const customFieldData = await getThenaDB()
      .customFields.where("hash")
      .equals(customFieldHash)
      .first();

    if (customFieldData) {
      list.push({
        name: customFieldData.category_name,
        values: customFieldData?.available_values?.map((item) => {
          return {
            label: item.name,
            value: item.value,
          };
        }),
        indexed_key: key,
      });
    }
  }
  return list;
};

export const buildFiltersForTickets = async (keys: string[]) => {
  const keysForFilter = keys.filter(
    (key) => !TICKETS_FILTER_INDEX_IGNORE_LIST.includes(key)
  );
  const filterList: any[] = [];

  for (const index in keysForFilter) {
    const key = keysForFilter[index];
    if (key === "relationship_id") {
      filterList.push({
        name: "Customers",
        values: await getCustomersValues(),
        indexed_key: "relationship_id",
      });
      filterList.push({
        name: "Account owners",
        values: await getFilterValues("all_users"),
        indexed_key: "relationship_id",
      });
      filterList.push({
        name: "Solution Engineer",
        values: await getFilterValues("all_users"),
        indexed_key: "relationship_id",
      });
      filterList.push({
        name: "Channel",
        values: await getFilterValues("channel"),
        indexed_key: "relationship_id",
      });
    } else {
      const name = getFilterName(key);
      const values = await getFilterValues(key);
      filterList.push({
        name,
        values,
        indexed_key: key,
      });
    }
  }
  return filterList;
};

const getCustomersValues = () => {
  return getThenaDB()
    .relationships.where("customer_name")
    .notEqual("")
    .distinct()
    .uniqueKeys()
    .then((customers) => {
      return customers.map((customer) => {
        return {
          value: customer,
          label: customer,
        };
      });
    });
};

const getFilterName = (key: string) => {
  if (key === "ai_metadata.sentiment") return "Sentiment";
  if (key === "ai_metadata.urgency") return "Urgency";
  if (key === "ai_metadata.tags") return "AI tags";
  if (key === "original_message_source") return "Source";
  if (key === "assigned_to_user_id") return "Assignee";
  if (key === "assignee_id") return "Assignee";
  return key;
};

const getFilterValues = async (key: string) => {
  switch (key) {
    case "ai_metadata.sentiment": {
      return [
        {
          value: "Positive",
          label: "Positive",
        },
        {
          value: "Negative",
          label: "Negative",
        },
        {
          value: "Neutral",
          label: "Neutral",
        },
      ];
    }

    case "ai_metadata.urgency": {
      return [
        {
          value: "Low",
          label: "Low",
        },
        {
          value: "Medium",
          label: "Medium",
        },
        {
          value: "High",
          label: "High",
        },
      ];
    }

    case "ai_metadata.tags": {
      const tags = await getThenaDB()
        .requests.where("ai_metadata.tags")
        .noneOf([])
        .distinct()
        .uniqueKeys();

      return (tags || []).map((tag) => {
        return {
          value: tag,
          label: tag,
        };
      });
    }

    case "original_message_source": {
      return [
        {
          value: "EMAIL",
          label: "Email",
        },
        {
          value: "WEB",
          label: "Web",
        },
        {
          value: "SLACK",
          label: "Slack",
        },
        {
          value: "MS_TEAMS",
          label: "MS Teams",
        },
      ];
    }

    case "all_users": {
      const users = await getThenaDB().users.toArray();
      return (users || []).map((user) => {
        return {
          value: user._id,
          label: user.name,
          avatar: user.slack_profile_image_24,
        };
      });
    }

    case "assigned_to_user_id":
    case "assignee_id": {
      const users = await getThenaDB().users.toArray();

      const list = [
        {
          value: "Unassigned",
          label: "Unassigned",
          avatar: DefaultProfileIcon,
        },
      ];

      return list.concat(
        (users || []).map((user) => {
          return {
            value: user._id,
            label: user.name,
            avatar: user.slack_profile_image_24,
          };
        })
      );
    }

    case "channel": {
      const relationships = await getThenaDB().relationships.toArray();
      return (relationships || []).map((relation) => {
        return {
          value: relation.channel_id,
          label: relation.channel_name,
        };
      });
    }

    default: {
      const customField = await getThenaDB().customFields.get({
        category_name: key,
      });
      if (customField) {
        return customField.available_values.map((item) => {
          return {
            label: item.name,
            value: item.value,
          };
        });
      }
      return [];
    }
  }
};

const getStatusValues = async () => {
  const [view, subStatusesFromDB] = await Promise.all([
    getThenaDB().views.where("name").equals(views.KANBAN_COLUMN_ORDER).first(),
    getThenaDB().subStatuses.toArray(),
  ]);

  const resultList: any = [];

  const columnOrder = view?.additionalPrefs?.ordered_columns;

  if (columnOrder) {
    Object.keys(columnOrder).forEach((status) => {
      const subStatuses = columnOrder[status] || [];
      if (subStatuses.length) {
        subStatuses.forEach((subStatusId: string) => {
          resultList.push({
            value: subStatusId,
            label:
              subStatusesFromDB.find((sub) => sub._id === subStatusId)?.name ||
              subStatusId,
          });
        });
      } else {
        resultList.push({
          value: status,
          label: getLaneTitle(status),
        });
      }
    });
  }
  return resultList;
};
