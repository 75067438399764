import { ASSISTANT_TYPE } from "@/constants/aiAssistant";
import { useAuthStorePersist } from "@/store/authStorePersist";
import { useGlobalStorePersist } from "@/store/globalStorePersist";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import { Editor } from "@tiptap/react";
import axios from "axios";

const getThenaApiKey = () =>
  useAuthStorePersist?.getState()?.user?.installation?.thena_api_key;
export const getAiAssistantId = (type) => {
  if (type === ASSISTANT_TYPE.CUSTOMER_SUPPORT) {
    return useGlobalStorePersist.getState().aiAssistants?.cs;
  }
  if (type === ASSISTANT_TYPE.INTERNAL_HELPDESK) {
    return useGlobalStorePersist.getState().aiAssistants?.ih;
  }
  return null;
};
export const getAiAssistantHasSources = (type) => {
  if (type === ASSISTANT_TYPE.CUSTOMER_SUPPORT) {
    return useGlobalStorePersist.getState().aiAssistantSources?.cs;
  }
  if (type === ASSISTANT_TYPE.INTERNAL_HELPDESK) {
    return useGlobalStorePersist.getState().aiAssistantSources?.ih;
  }
  return false;
};

useGlobalStorePersist.getState().aiAssistants.cs;
const api = axios.create({
  baseURL: process.env.REACT_APP_ASSISTANT_ROOT,
  headers: {
    "Content-type": "application/json",
    Accept: "text/event-stream",
    //   "Authorization" : "Bearer thena_K2a0Q5i24j3kaHIQKEO3JjTA8VmUi74z1HVSa39C",
  },
  withCredentials: false,
});

export const fetchAssistantAIStatus = async () => {
  try {
    return api
      .get(`/assistant`, {
        headers: {
          Authorization: `Bearer ${getThenaApiKey()}`,
        },
      })
      .then(({ data }) => {
        return data.data;
      })
      .catch((err) => {
        throw new Error(err);
      });
  } catch (err) {
    console.log("ERROR", err);
  }
};
export const createAssistant = async (data) => {
  return api.post("/assistant", data, {
    headers: {
      Authorization: `Bearer ${getThenaApiKey()}`,
    },
  });
};
export const updateAssistant = async (aissistantId, status: boolean) => {
  return api
    .put(
      "/assistant/" + aissistantId,
      { active: status },
      {
        headers: {
          Authorization: `Bearer ${getThenaApiKey()}`,
        },
      }
    )
    .then(({ data }) => data.data);
};
export const fetchAssistantSources = async (type: string) => {
  try {
    if (getAiAssistantId(type)) {
      return api
        .get(`/assistant/${getAiAssistantId(type)}/list_sources`, {
          headers: {
            Authorization: `Bearer ${getThenaApiKey()}`,
          },
        })
        .then(({ data }) => data.data);
    } else {
      throw new Error("No active assitant found for fetching sources");
    }
  } catch (err) {
    console.log("Error -> ", err);
    return;
  }
};
export const addAssistantSource = async (data, type) => {
  try {
    if (data.type === "url" && data.url) {
      const body = {
        weblink: data.url,
        source_name: data.name,
      };
      return api
        .post(`/assistant/${getAiAssistantId(type)}/weblink_upload`, body, {
          headers: {
            Authorization: `Bearer ${getThenaApiKey()}`,
          },
        })
        .then(({ data }) => data.data)
        .catch((err) => {
          throw new Error(err.response.data.message);
        });
    } else if (data.type === "file" && data?.file) {
      return api
        .post(`/assistant/${getAiAssistantId(type)}/file_upload`, data.file, {
          headers: {
            "Content-type": "multipart/form-data",
            Authorization: `Bearer ${getThenaApiKey()}`,
          },
        })
        .then(({ data }) => data?.data)
        .catch((err) => {
          throw new Error(err);
        });
    } else if (data.type === "thena_requests") {
      return api
        .post(
          `/assistant/${getAiAssistantId(type)}/thena_request`,
          { enable: true },
          {
            headers: {
              Authorization: `Bearer ${getThenaApiKey()}`,
            },
          }
        )
        .then(({ data }) => data?.data)
        .catch((err) => {
          throw new Error(err);
        });
    } else {
      throw new Error("Invalid Data");
    }
  } catch (err) {
    return err;
  }
};
export const syncAssistantSource = async (
  data: { source_id: string },
  type
) => {
  try {
    return api
      .post(`/assistant/${getAiAssistantId(type)}/resync_source`, data, {
        headers: {
          Authorization: `Bearer ${getThenaApiKey()}`,
        },
      })
      .then((data) => data?.data?.data);
  } catch (err) {
    console.log(err);
  }
};

export const deleteAssistantSource = async (
  data: { source_id: string },
  type
) => {
  try {
    return api
      .delete(`/assistant/${getAiAssistantId(type)}`, {
        data: data,
        headers: {
          Authorization: `Bearer ${getThenaApiKey()}`,
        },
      })
      .then((data) => data?.data?.data);
  } catch (err) {
    console.log(err);
  }
};
export const streamData = async (
  editor: Editor | null,
  aiAssistantId: string,
  body,
  onMessageHandler,
  existingContent,
  controller,
  onStreamInit,
  onStreamSuccess,
  onStreamFailure,
  onStreamingError
) => {
  let fetchedText = "";
  try {
    if (!aiAssistantId) {
      throw new Error("No Assistant Id present");
    }
    if (!editor) {
      throw new Error("No Editor Found");
    }
    onStreamInit(editor);
    await fetchEventSource(
      `${process.env.REACT_APP_ASSISTANT_ROOT}/assistant/${aiAssistantId}/ask`,
      {
        method: "POST",
        headers: {
          Accept: "text/event-stream",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getThenaApiKey()}`,
        },
        body: JSON.stringify(body),
        signal: controller?.signal,
        onopen(response) {
          return Promise.resolve().then(() => {
            () => {
              if (response.ok && response.status === 200) {
                console.log("Connection made ");
              } else if (
                response.status >= 400 &&
                response.status < 500 &&
                response.status !== 429
              ) {
                controller.abort();
              }
            };
          });
        },
        onmessage(event) {
          fetchedText = onMessageHandler(
            event,
            fetchedText,
            controller,
            editor
          );
        },
        onclose() {
          console.log("Connection closed by the server");
          onStreamingError(editor);
        },
        onerror(err) {
          console.log("There was an error from server", err);
          throw new Error(err);
        },
      }
    );
  } catch (err) {
    console.log("ERROR IN CONNECTION WITH AI", err);
    onStreamFailure(editor);
  }
};
