import React, { useContext, useState } from "react";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { testSalesforceConnection } from "../../../api/integrations";
import { useConnectedApp } from "../../ConnectedApps";
import { SalesforceTestConnectionPayloadType } from "../definitions";

interface SalesforceContextType {
  isSetUpOpen: boolean;
  isConfigureOpen: boolean;
  connectionPassed: boolean;
  connectionFailed: boolean;
  setIsSetUpOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsConfigureOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setConnectionPassed: React.Dispatch<React.SetStateAction<boolean>>;
  setConnectionFailed: React.Dispatch<React.SetStateAction<boolean>>;
  isTestingConnection: boolean;
  testSalesforceConnectionMutation?: UseMutationResult<
    any,
    unknown,
    SalesforceTestConnectionPayloadType,
    unknown
  >;
  saveRequestTypesMutation?: UseMutationResult<
    any,
    unknown,
    SalesforceTestConnectionPayloadType,
    unknown
  >;
}

const SalesforceContext = React.createContext<SalesforceContextType>({
  isSetUpOpen: false,
  isConfigureOpen: false,
  connectionPassed: false,
  connectionFailed: false,
  isTestingConnection: false,
  setIsSetUpOpen: () => null,
  setIsConfigureOpen: () => null,
  setConnectionPassed: () => null,
  setConnectionFailed: () => null,
});

export const SalesforceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const queryClient = useQueryClient();
  const { isEdition } = useConnectedApp();
  const [connectionPassed, setConnectionPassed] = useState<boolean>(false);
  const [connectionFailed, setConnectionFailed] = useState<boolean>(false);
  const [isSetUpOpen, setIsSetUpOpen] = useState<boolean>(!isEdition);
  const [isConfigureOpen, setIsConfigureOpen] = useState<boolean>(isEdition);

  // Test Salesforce configuration
  const testSalesforceConnectionMutation = useMutation({
    mutationFn: testSalesforceConnection,
    onSuccess: () => {
      setConnectionPassed(true);
      setConnectionFailed(false);
    },
    onError: () => {
      setConnectionPassed(false);
      setConnectionFailed(true);
    },
  });

  // Added same mutation as testSalesforceConnectionMutation to avoid unnecessary checks and balances
  const saveRequestTypesMutation = useMutation({
    mutationFn: testSalesforceConnection,
    onSuccess: () => {
      setIsSetUpOpen(false);
      setIsConfigureOpen(true);
      setConnectionPassed(true);
      setConnectionFailed(false);
      //   message.success("Salesforce configuration saved");
      queryClient.invalidateQueries({
        queryKey: ["fetch-salesforce-integration"],
      });
    },
    onError: () => {
      setConnectionPassed(false);
      setConnectionFailed(true);
    },
  });

  return (
    <SalesforceContext.Provider
      value={{
        isSetUpOpen,
        isConfigureOpen,
        connectionPassed,
        connectionFailed,
        isTestingConnection: testSalesforceConnectionMutation?.isPending,
        setIsSetUpOpen,
        setIsConfigureOpen,
        setConnectionPassed,
        setConnectionFailed,
        testSalesforceConnectionMutation,
        saveRequestTypesMutation,
      }}
    >
      {children}
    </SalesforceContext.Provider>
  );
};

export const useSalesforce = () => {
  return useContext(SalesforceContext);
};
