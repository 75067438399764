import { useCurrentEditor } from "@tiptap/react";
import styled from "styled-components";
import { Button } from "../ui/button";
import { EmailToolbar } from "./emailToolbar/EmailToolbar";
import {
  Bold,
  CodeXml,
  Italic,
  List,
  ListOrdered,
  Sparkles,
  SquareDashedBottomCode,
  Strikethrough,
  TextQuote,
} from "lucide-react";
import { LinkComponent } from "./Link";
import { useEffect } from "react";
import { Thread } from "@/types/kanbanTypes";
import { useFlags } from "launchdarkly-react-client-sdk";
import TooltipWrapper from "../common/TooltipWrapper";
import { AI_ASSIST_TOOLTIP_TEXT } from "@/constants/aiAssistant";

const MenuBarWrapper = styled.div`
  width: 580px;
  border-width: 0px 0px 1px 0px;
`;
const MainMenuBar = styled.div`
  width: 580px;
  display: flex;
  justify-content: space-between;
`;
export const MenuBarItems = styled.div`
  display: flex;
  padding: 4px;
`;

export const BottomMenuBarWrapper = styled(MenuBarWrapper)<{
  $isInternal?: boolean;
}>`
  border: 0px;
  padding: 0px;
  padding-right: 8px;
  padding-left: 2px;
  /* height: 40px; */
  ${({ $isInternal }) => $isInternal && `width: 350px;`}
`;

export const MenuBar = ({
  autoFocus,
  item,
  isEmail,
  ccEmails,
  setCCEmails,
  bccEmails,
  setBCCEmails,
  toEmails,
  setToEmails,
  isRequestMerged,
  fetchStreamData,
  isStreaming,
  isInternalHelpdesk,
}: {
  autoFocus: boolean;
  item?: Thread;
  isEmail: boolean;
  ccEmails: string[];
  setCCEmails: React.Dispatch<React.SetStateAction<string[]>>;
  bccEmails: string[];
  setBCCEmails: React.Dispatch<React.SetStateAction<string[]>>;
  toEmails: string[];
  setToEmails: React.Dispatch<React.SetStateAction<string[]>>;
  isRequestMerged?: boolean;
  fetchStreamData?: any;
  isStreaming?: boolean;
  isInternalHelpdesk?: boolean;
}) => {
  const { editor } = useCurrentEditor();
  const flags = useFlags();

  useEffect(() => {
    if (autoFocus) {
      editor?.commands.focus("start");
    }
  }, [autoFocus]);

  if (!editor) {
    return null;
  }

  return (
    <MenuBarWrapper>
      {isEmail && item && (
        <EmailToolbar
          item={item}
          ccEmails={ccEmails}
          setCcEmails={setCCEmails}
          bccEmails={bccEmails}
          setBccEmails={setBCCEmails}
          toEmails={toEmails}
          setToEmails={setToEmails}
        />
      )}
      <MainMenuBar>
        <MenuBarItems>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={
              !editor.can().chain().focus().toggleBold().run() ||
              isRequestMerged
            }
            className={
              editor.isActive("bold") ? "is-active w-9 h-9" : "w-9 h-9"
            }
          >
            <Bold size={16} />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={
              !editor.can().chain().focus().toggleItalic().run() ||
              isRequestMerged
            }
            className={
              editor.isActive("italic") ? "is-active w-9 h-9" : "w-9 h-9"
            }
          >
            <Italic size={16} />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => editor.chain().focus().toggleStrike().run()}
            disabled={
              !editor.can().chain().focus().toggleStrike().run() ||
              isRequestMerged
            }
            className={
              editor.isActive("strike") ? "is-active w-9 h-9" : "w-9 h-9"
            }
          >
            <Strikethrough size={16} />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            disabled={isRequestMerged}
            className={
              editor.isActive("bulletList") ? "is-active w-9 h-9" : "w-9 h-9"
            }
          >
            <List size={16} />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            disabled={isRequestMerged}
            className={
              editor.isActive("orderedList") ? "is-active w-9 h-9" : "w-9 h-9"
            }
          >
            <ListOrdered size={16} />
          </Button>
          <Button
            size="icon"
            variant="ghost"
            onClick={() => editor.chain().focus().toggleCode().run()}
            disabled={
              !editor.can().chain().focus().toggleCode().run() ||
              isRequestMerged
            }
            className={
              editor.isActive("code") ? "is-active w-9 h-9" : "w-9 h-9"
            }
          >
            <CodeXml size={16} />
          </Button>
          <LinkComponent isRequestMerged={isRequestMerged} />
          <Button
            variant="ghost"
            size="icon"
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            disabled={isRequestMerged}
            className={
              editor.isActive("blockquote") ? "is-active w-9 h-9" : "w-9 h-9"
            }
          >
            <TextQuote size={16} />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => editor.chain().focus().toggleCodeBlock().run()}
            disabled={isRequestMerged}
            className={
              editor.isActive("codeBlock") ? "is-active w-9 h-9" : "w-9 h-9"
            }
          >
            <SquareDashedBottomCode size={16} />
          </Button>
        </MenuBarItems>
        {flags.aiAssistant && !isInternalHelpdesk && (
          <MenuBarItems className="mr-2">
            <TooltipWrapper tooltipContent={AI_ASSIST_TOOLTIP_TEXT}>
              <Button
                variant="outline"
                size="navItem"
                onClick={() => fetchStreamData(editor)}
                disabled={isStreaming}
                className={`w-full px-2 text-color-icon-magic hover:text-color-icon-magic border-color-icon-magic ${editor.isActive("codeBlock") ? "is-active" : ""}`}
              >
                <Sparkles className=" w-4 h-4 mr-2 text-color-icon-magic" />
                Generate
              </Button>
            </TooltipWrapper>
          </MenuBarItems>
        )}
      </MainMenuBar>
    </MenuBarWrapper>
  );
};
