import { useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import Select from "react-select";
import { Button } from "@/components/ui/button";
import { debounce } from "lodash";
import {
  fetchIntegrationChannels,
  fetchSalesforceAccounts,
  saveSalesforceAccounts,
} from "../../../api/integrations";
import AsyncSelect from "react-select/async";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { useConnectedApp } from "../../ConnectedApps";
import {
  IntegrationEligibleChannelsType,
  SalesforceAccountMappingType,
} from "../definitions";
import { useSalesforce } from "./SalesforceProvider";
import { Input } from "@/components/ui/input";

export const AccountConfiguration = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { isEdition, setSelectedIntegration } = useConnectedApp();
  const { connectionPassed, isConfigureOpen, setIsConfigureOpen } =
    useSalesforce();

  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<string>("unmapped");
  const [searchChannels, setSearchChannels] = useState<string>("");

  // Fetching Salesforce Accounts
  const {
    data: accounts,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["fetchSalesforceAccounts", search],
    queryFn: () => fetchSalesforceAccounts(search, ""),
    enabled: isConfigureOpen,
  });

  // Fetching Integration channels
  const { data: integrationChannels } = useQuery({
    queryKey: ["integration-channels", { integration: "salesforce" }],
    queryFn: () =>
      fetchIntegrationChannels({
        page: 0,
        search: "",
        integration: "salesforce",
      }),
  });

  // Filtering channels function
  const memorizeFilteredChannels = (
    channels: IntegrationEligibleChannelsType[]
  ) => {
    let filteredChannels = channels.filter(
      (ch: IntegrationEligibleChannelsType) => {
        if (filter === "mapped") return ch.isMapped;
        return !ch.isMapped;
      }
    );

    if (searchChannels) {
      filteredChannels = filteredChannels.filter((ch) =>
        ch.name?.includes(searchChannels)
      );
    }

    return filteredChannels;
  };

  // Memorizing Filtering channels
  const memorizedFilteredChannels = useMemo(
    () => memorizeFilteredChannels(integrationChannels?.channels || []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [integrationChannels, filter, searchChannels]
  );

  // Creating Salesforce Options
  const getAccounts = (accounts: SalesforceAccountMappingType[]) =>
    accounts.map((account) => ({
      label: account.Name,
      value: account.Id,
    }));

  // Memorizing Salesforce Options
  const salesforceAccounts = useMemo(
    () => getAccounts(accounts || []),
    [accounts]
  );

  // Save Salesforce account mapping
  const mapSalesforceAccountMutation = useMutation({
    mutationFn: saveSalesforceAccounts,
    onSuccess: () => {
      //   message.success("Salesforce Account configured");
      queryClient.invalidateQueries({
        queryKey: ["integration-channels", { integration: "salesforce" }],
      });
    },
    onError: (error) => {
      console.error(error);
      //   message.error("Something went wrong");
    },
  });

  const selectOptions = [
    { label: "Unmapped Channels", value: "unmapped", key: "1" },
    { label: "Mapped Channels", value: "mapped", key: "2" },
  ];

  const activeOption = selectOptions.find((opt) => opt.value === filter);

  return (
    <Accordion
      type="single"
      collapsible
      // disabled={!isEdition && !connectionPassed ? true : false}
    >
      <AccordionItem value="item-1">
        <AccordionTrigger>Account Configuration</AccordionTrigger>
        <AccordionContent>
          <div className="border boder-solid p-4 rounded-sm">
            {isConfigureOpen && (
              <p className="text-grey font-medium mb-0 ml-3">
                Match each channel to its relevant salesforce account
              </p>
            )}
            <div className="flex items-center justify-between mb-6">
              <Input
                name="search"
                id="search"
                className="w-fit"
                placeholder="Search channel..."
                onChange={({ target: { value } }) => {
                  setSearchChannels(value);
                }}
              />
              <Select
                value={activeOption}
                options={selectOptions}
                onChange={(val: any) => {
                  const value = val.value;
                  setFilter(value);
                }}
              />
            </div>
            {integrationChannels?.channels.length === 0 && (
              <div className="flex flex-col justify-center items-center h-64 scroll-mr-4">
                <p className="text-brand-border font-medium mb-0">
                  No channels added to Thena
                </p>
                <Button
                  variant={"link"}
                  onClick={() => {
                    setSelectedIntegration(null);
                    navigate("/settings?tab=channels");
                  }}
                >
                  Add here
                </Button>
              </div>
            )}
            {memorizedFilteredChannels.length === 0 &&
              integrationChannels?.channels.length !== 0 && (
                <div className="flex justify-center items-center h-64 scroll-mr-4">
                  {Boolean(search) && (
                    <p className="text-brand-border font-medium mb-0">
                      No channels found
                    </p>
                  )}
                  {filter === "mapped" && (
                    <p className="text-brand-border font-medium mb-0">
                      No channels are mapped yet
                    </p>
                  )}
                  {filter === "unmapped" && (
                    <p className="text-brand-border font-medium mb-0">
                      All channels are mapped
                    </p>
                  )}
                </div>
              )}
            {memorizedFilteredChannels.length > 0 && (
              <div className="overflow-y-auto space-y-2 h-64 scroll-mr-4">
                {memorizedFilteredChannels.map(
                  (channel: IntegrationEligibleChannelsType) => (
                    <div
                      className="grid grid-cols-6 items-center space-y-2"
                      key={channel._id}
                    >
                      <p className="col-span-2 text-sm font-medium mb-0">
                        # {channel.name_normalized}
                      </p>
                      <div className="col-span-2" />

                      <div className="col-span-2 mr-4">
                        <AsyncSelect
                          isClearable
                          isSearchable
                          //   showSearch
                          className="w-full"
                          //   dropdownMatchSelectWidth
                          placeholder="Account Name"
                          onBlur={() => {
                            setSearch("");
                          }}
                          value={
                            channel.salesforce || channel.accountName
                              ? {
                                  value: channel.salesforce,
                                  label: channel.accountName,
                                }
                              : null
                          }
                          isLoading={isLoading || isFetching}
                          onChange={(value) => {
                            setSearch(value?.label || "");
                            const valueObjWithType = {
                              value: value?.value,
                              label: value?.label,
                            };
                            mapSalesforceAccountMutation.mutate({
                              channelIds: [channel.id],
                              accountId: valueObjWithType?.value || null,
                              accountName: valueObjWithType?.label || null,
                            });
                          }}
                          loadOptions={(inputValue, callback) => {
                            debounce(() => setSearch(inputValue), 1000);
                            fetchSalesforceAccounts(inputValue, "").then(
                              (res) => {
                                callback(
                                  res.map((account) => ({
                                    value: account.Id,
                                    label: account.Name,
                                  }))
                                );
                              }
                            );
                          }}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
            <div className="relative bottom-0 border-0 border-t border-brand-border rounded-b-lg border-solid p-3 -mx-4 -mb-3 z-10">
              <div className="flex flex-row-reverse">
                <Button
                  //   type="default"
                  onClick={() => {
                    setIsConfigureOpen(false);
                  }}
                >
                  Done
                </Button>
              </div>
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
