export const MODAL_TYPES = {
  KANBAN_DRAWER: "KANBAN_DRAWER",
};

export const STATUS_ORDER = ["OPEN", "INPROGRESS", "ONHOLD", "CLOSED"];

export const DEFAULT_COLUMN_ORDER = {
  OPEN: [],
  INPROGRESS: [],
  ONHOLD: [],
  CLOSED: [],
};

export const CLOSED_REQUESTS_SHOW_PERIOD_OPTIONS = [
  {
    label: "Past week",
    value: "past-week",
  },
  {
    label: "Past month",
    value: "past-month",
  },
  {
    label: "All",
    value: "all",
  },
];
