import { useState, useEffect } from "react";

const useBodyWidth = () => {
  const [bodyWidth, setBodyWidth] = useState(document.body.clientWidth);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        setBodyWidth(entry.contentRect.width);
      }
    });
    resizeObserver.observe(document.body);
    return () => resizeObserver.unobserve(document.body);
  }, []);

  return bodyWidth;
};

export default useBodyWidth;
