export const processRelationships = (item) => {
  return {
    ...item,
    csmUsers: item.users
      ?.filter((user) => user.role === "csm")
      ?.map((user) => user.user),
    solutionEngineers: item.users
      ?.filter((user) => user.role === "solutionEngineer")
      ?.map((user) => user.user),
    updated_at_date: new Date(item.updated_at),
  };
};

export const getRelationshipFilterKey = (key: string) => {
  if (key === "Account owners") {
    return "csmUsers";
  }
  if (key === "Solution Engineer") {
    return "solutionEngineers";
  }
  // default for Customers
  return "customer_name";
};
