import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";

import { SavedViewPayload, addSavedView, fetchSavedView } from "@/api/kanban";
import { getThenaDB } from "@/db";
import { useKanbanStorePersist } from "@/store/kanbanStorePersist";
import { views } from "@/constants/views";
import { RequestStatusType } from "@/types/requests";
import { useGlobalStore } from "@/store/globalStore";

const defaultColumnOrderView = async (mutate) => {
  const subStatusList = await getThenaDB().subStatuses.toArray();

  const columnOrder = await getThenaDB()
    .views.where("name")
    .equals(views.KANBAN_COLUMN_ORDER)
    .first();

  if (columnOrder) {
    return;
  }

  const subStatusOrder: Record<RequestStatusType, string[]> =
    subStatusList.reduce(
      (acc, curr) => {
        return {
          ...acc,
          [curr.parent]: [...acc[curr.parent], curr._id],
        };
      },
      { OPEN: [], INPROGRESS: [], ONHOLD: [], CLOSED: [] }
    );

  const list = {
    name: "column_order" as const,
    value: subStatusOrder,
  };

  const externalLinksDefault: SavedViewPayload = {
    name: views.KANBAN_COLUMN_ORDER,
    type: views.KANBAN_COLUMN_ORDER,
    scope: "GLOBAL",
    filter: "",
    additionalPrefs: {
      columnMetadata: {},
      ordered_columns: list.value as any,
      filters: "",
      filtersOrder: [],
      displayOptions: {},
      sortby: {
        field: "created_at_date",
        order: "DESC",
      },
    },
  };
  mutate(externalLinksDefault);
};

const useViewsInit = () => {
  const { data } = useQuery({
    queryKey: ["bootstrap-saved-views"],
    queryFn: fetchSavedView,
  });

  const customStatusesFetched = useGlobalStore(
    (state) => state.customStatusesFetched
  );

  const createDisplayOptionsMutation = useMutation({
    mutationFn: addSavedView,
    onSuccess: (res) => getThenaDB().views.put(res),
  });

  useEffect(() => {
    /**
     * This useEffect checks if KANBAN_DISPLAY_OPTIONS, HELPDESK_DISPLAY_OPTIONS, EXTERNAL_LINKS_DEFAULT
     * views are present in the GET views API. If not, it will do a post call to add
     *  it in the backend and the ThenaDB
     *
     * TODO: Fallback to be added from BE for everything below
     */
    if (!Array.isArray(data)) {
      return;
    }
    const displayOptions = useKanbanStorePersist.getState().displayOptions;
    const storedDisplayOptions = data?.find((item) => {
      return item.type === views.REQUEST_PAGE_DEFAULT;
    });

    const helpdeskDisplayOptions = data?.find((item) => {
      return item.type === views.INTERNAL_HELPDESK_DEFAULT;
    });

    const externalLinksDefault = data?.find((item) => {
      return item.type === views.EXTERNAL_LINKS_DEFAULT;
    });

    const kanbanColumnOrder = data?.find((item) => {
      return item.type === views.KANBAN_COLUMN_ORDER;
    });

    if (!storedDisplayOptions) {
      const savedDisplayOptions: SavedViewPayload = {
        name: views.REQUEST_PAGE_DEFAULT,
        type: views.REQUEST_PAGE_DEFAULT,
        scope: "USER",
        filter: "",
        additionalPrefs: {
          columnMetadata: {},
          ordered_columns: [],
          filters: "",
          filtersOrder: [],
          displayOptions: displayOptions,
          sortby: {
            field: "created_at_date",
            order: "DESC",
          },
        },
      };
      createDisplayOptionsMutation.mutate(savedDisplayOptions);
    }
    if (!helpdeskDisplayOptions) {
      // If helpdesk display options are not present, create them
      const savedDisplayOptions: SavedViewPayload = {
        name: views.INTERNAL_HELPDESK_DEFAULT,
        type: views.INTERNAL_HELPDESK_DEFAULT,
        scope: "USER",
        filter: "",
        additionalPrefs: {
          columnMetadata: {},
          ordered_columns: [],
          filters: "",
          filtersOrder: [],
          displayOptions: displayOptions,
          sortby: {
            field: "created_at_date",
            order: "DESC",
          },
        },
      };
      createDisplayOptionsMutation.mutate(savedDisplayOptions);
    }
    if (!externalLinksDefault) {
      const externalLinksDefault: SavedViewPayload = {
        name: views.EXTERNAL_LINKS_DEFAULT,
        type: views.EXTERNAL_LINKS_DEFAULT,
        scope: "USER",
        filter: "",
        additionalPrefs: {
          columnMetadata: {},
          ordered_columns: [],
          filters: "",
          filtersOrder: [],
          displayOptions: {}, // no display options in external links tab
          sortby: {
            field: "created_at_date",
            order: "DESC",
          },
        },
      };
      createDisplayOptionsMutation.mutate(externalLinksDefault);
    }

    if (!kanbanColumnOrder && customStatusesFetched) {
      defaultColumnOrderView(createDisplayOptionsMutation.mutate);
    }
  }, [data, customStatusesFetched]);

  useEffect(() => {
    if (Array.isArray(data) && data?.length > 0) {
      try {
        getThenaDB().views.bulkPut(data);
        console.log("All Saved Views added/updated to DB");
      } catch (error) {
        console.log("Error adding Saved Views to DB", error);
      }
    }
  }, [data]);
};

export default useViewsInit;
