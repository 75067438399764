import * as Sentry from "@sentry/browser";
import { LogOut } from "lucide-react";
import { Component, ErrorInfo, ReactNode } from "react";
import styled from "styled-components";
import { Button } from "./components/ui/button";
import { logoutHelper } from "./utils/auth";
import { sendErrorToLogRocket } from "./utils/errors";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  loading: boolean;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const ErrorText = styled.h2`
  margin-top: 32px;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, loading: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });
    console.error("Caught in ErrorBoundary", {
      error,
      errorInfo,
    });
    sendErrorToLogRocket({
      error: "Caught in ErrorBoundary",
      extra: {
        error,
        errorInfo,
      },
    });
    Sentry.withScope((scope) => {
      scope.setTag("error-boundary", "true");
      scope.setLevel("error");
      scope.setExtra("errorInfo", errorInfo);
      Sentry.captureException(error);
    });
  }

  handleRefresh = async () => {
    this.setState({ loading: true });
    await logoutHelper(false);
    location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div className="flex justify-end p-4 w-full">
            <Button
              className="flex items-center gap-2"
              onClick={() => logoutHelper(true)}
            >
              <LogOut size={16} />
              Logout
            </Button>
          </div>
          <Wrapper className="mt-8">
            <ErrorText>
              Something went wrong. Please refresh the page to try again.
            </ErrorText>
            <StyledButton
              loading={this.state.loading}
              onClick={() => this.handleRefresh()}
            >
              Refresh
            </StyledButton>
          </Wrapper>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
