import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";

import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Link } from "lucide-react";
import { useCallback, useEffect, useState } from "react";

export const LinkComponent = ({ editor }) => {
  const [open, setOpen] = useState(false);
  const [currentLink, setCurrentLink] = useState(
    editor?.getAttributes("link").href ?? ""
  );
  const [selectedText, setSelectedText] = useState("");

  useEffect(() => {
    if (editor) {
      const selection = editor.state.selection;
      if (!selection.empty) {
        const { from, to } = selection;
        const text = editor.state.doc.textBetween(from, to, " ");
        setSelectedText(text);
      }
    }
  }, [editor?.state.selection]);

  const setLink = useCallback(() => {
    if (editor) {
      // cancelled
      if (currentLink === null) {
        return;
      }
      // empty
      if (currentLink === "") {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }
      // update link
      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: currentLink })
        .run();
    }
  }, [editor, currentLink]);

  if (editor) {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild tabIndex={-20}>
          <Button
            variant="ghost"
            size="icon"
            className={`${
              editor.isActive("link") ? "is-active w-9 h-9" : "w-9 h-9"
            }`}
          >
            <Link size={15} />
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px] bg-modal-bg z-[100000]">
          <DialogHeader>
            <DialogTitle>Add link</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="flex flex-col items-start justify-center gap-4">
              <Label htmlFor="text" className="text-right">
                Text
              </Label>
              <Input
                id="text"
                value={selectedText}
                className="col-span-3"
                readOnly
              />
            </div>
            <div className="flex flex-col items-start justify-center gap-4">
              <Label htmlFor="link" className="text-right">
                Link
              </Label>
              <Input
                id="link"
                defaultValue={editor.getAttributes("link").href}
                className="col-span-3"
                onChange={(e) => setCurrentLink(e.target.value)}
              />
            </div>
          </div>
          <DialogFooter>
            <DialogClose asChild>
              <Button
                variant={"outline"}
                className="bg-[var(--modal-bg)] hover:bg-[var(--color-label-bg)]"
              >
                Cancel
              </Button>
            </DialogClose>
            <Button
              onClick={() => {
                setLink();
                setOpen(false);
              }}
            >
              Save changes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    );
  }
  return null;
};
