import { Button } from "@/components/ui/button";
import { ADD_TO_SLACK_URL } from "@/config/constants";
import AddThenaLight from "@/images/icons/add-thena-to-slack-light.svg";
import AddThenaDark from "@/images/icons/add-thena-to-slack-dark.svg";
import { useTheme } from "@/context/theme-provider";
import { LogOut } from "lucide-react";
import { logoutHelper } from "@/utils/auth";
import useAuth from "@/hooks/useAuth";

const onClick = () => {
  window.open(ADD_TO_SLACK_URL, "_self");
};

const AddToSlack = () => {
  const user = useAuth();
  const { theme } = useTheme();
  const imgSrc = theme === "dark" ? AddThenaDark : AddThenaLight;

  return (
    <div className="bg-[var(--color-bg-subtle)] w-full h-full flex flex-col items-center justify-center">
      <div className="flex justify-end p-4 w-full">
        <Button
          className="flex items-center gap-2"
          onClick={() => logoutHelper(true)}
        >
          <LogOut size={16} />
          Logout
        </Button>
      </div>
      <div className="bg-[var(--color-bg-subtle)] w-full h-full flex items-center justify-center">
        <div
          className="w-[480px] h-[440px] shrink-0 rounded-[6px]  border-[var(--modal-border)] border"
          style={{
            boxShadow:
              "0px 4px 6px -4px rgba(16, 24, 40, 0.10), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)",
          }}
        >
          <img src={imgSrc} alt="" className="w-full h-[254px]" />
          <div className="px-8 pt-6 pb-4">
            <p className="text-[var(--color-text)] text-center text-2xl font-semibold">
              Add Thena to {user.team_name}
            </p>
            <p className="text-[var(--color-text-body)] text-center text-base mt-[6px]">
              Authorize Slack permissions for Thena setup.
            </p>
            <div className="flex flex-col items-center">
              <Button onClick={onClick} size="sm" className="mt-8">
                Install
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddToSlack;
