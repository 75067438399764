import { Button } from "@/components/ui/button";
import { ClipboardList, MinusCircle } from "lucide-react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

export const getXaxisPlotBands = (data, theme) => {
  const primary = theme === "dark" ? "rgba(0,0,0,0)" : "rgba(255,255,255,0)";
  const bandColor =
    theme === "dark"
      ? {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "#020617"],
            [1, "#1E293B"],
          ],
        }
      : {
          linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          stops: [
            [0, "#ffffff"],
            [1, "#F4F4F5"],
          ],
        };
  return data.map((point, index) => ({
    from: index,
    to: index + 1,
    color: index % 2 !== 0 ? primary : bandColor,
    zIndex: 0,
  }));
};

export const EmptyComponent = ({ onClickHandler }) => {
  return (
    <div className="flex items-center justify-center flex-col w-full gap-4 border border-[var(--modal-border)] py-[60px] px-[250px] rounded-lg text-[var(--color-icon-muted)]">
      <div
        className="flex items-center justify-center flex-col"
        style={{ maxWidth: "432px" }}
      >
        <ClipboardList size={40} className="mb-4" />
        <p className="text-base text-[var(--color-icon-muted)] font-regular mb-6 text-center">
          You have no added sources.
        </p>
        <Button
          onClick={onClickHandler}
          size={"sm"}
          className="h-8 gap-1 flex items-center"
        >
          Add source
        </Button>
      </div>
    </div>
  );
};
export const ActiveAssistantComponent = ({ onClickHandler }) => {
  return (
    <div className="flex items-center justify-center flex-col w-full gap-4 border border-[var(--modal-border)] py-[60px] px-[250px] rounded-lg text-[var(--color-icon-muted)]">
      <div
        className="flex items-center justify-center flex-col"
        style={{ maxWidth: "432px" }}
      >
        <MinusCircle size={40} className="mb-4" />
        <p className="text-base text-[var(--color-icon-muted)] font-regular mb-6 text-center">
          Your assistant is currently deactivated. Activate to start seeing the
          sources
        </p>
        <Button onClick={onClickHandler}>Activate assistant</Button>
      </div>
    </div>
  );
};

export const SourceDeleteDialog = ({
  open,
  onOpenChange,
  title,
  subtitle,
  onPrimaryClick,
  isPending,
}) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="w-full bg-[var(--modal-bg)]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{subtitle}</DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex mt-4">
          <DialogClose asChild>
            <Button
              className="h-[32px] w-fit bg-var(--color-kanban-bg) dark:border-color-border"
              variant={"outline"}
            >
              Cancel
            </Button>
          </DialogClose>
          <Button
            className="h-[32px] w-fit"
            variant={"default"}
            loading={isPending}
            disabled={isPending}
            onClick={onPrimaryClick}
          >
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const AIAssistSettingDialog = ({
  open,
  onOpenChange,
  title,
  subtitle,
  onPrimaryClick,
  primaryCTAText,
}) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-[var(--modal-bg)]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{subtitle}</DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex mt-4">
          <DialogClose asChild>
            <Button
              className="h-[32px] w-fit bg-var(--color-kanban-bg) dark:border-color-border"
              variant={"outline"}
            >
              Cancel
            </Button>
          </DialogClose>
          <DialogClose asChild>
            <Button
              className="h-[32px] w-fit"
              variant={"default"}
              onClick={onPrimaryClick}
            >
              {primaryCTAText}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
