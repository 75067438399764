import LogRocket from "logrocket";

/**
 * @param error string
 * @param extra Record<string, any>
 */

export const sendErrorToLogRocket = ({
  error,
  extra,
}: {
  error: string;
  extra?: Record<string, any>;
}) => {
  LogRocket.captureException(new Error(error), {
    extra,
  });
};

/**
 * sendErrorToLogRocket({
      error: new Error("Clear filters"),
      extra: {
        from: "ClearFilter",
      },
    });
 */

export const consoleInDev = (message: string, ...args: any[]) => {
  if (process.env.NODE_ENV === "development") {
    console.log(message, ...args);
  }
};
