/* eslint-disable max-lines */
import { getEpochByDifference, getTodayLastEpoch } from "@/utils/dateUtils";
import useInfiniteFetcher from "./useInfiniteFetcher";
import { CustomerContactType, UserType } from "@/types/users";
import { RequestType } from "@/types/requests";
import { RelationshipType } from "@/types/relationships";
import { useEffect } from "react";
import { ChannelType } from "@/types/channel";
import {
  GlobalStoreType,
  ModelState,
  useGlobalStorePersist,
} from "@/store/globalStorePersist";
import { useQuery } from "@tanstack/react-query";
import { fetchSubStatuses } from "@/api/bootstrap";
import { processRequestData } from "@/utils/requests";
import { getThenaDB } from "@/db";
import { processRelationships } from "@/utils/relationships";
import { CampaignType } from "@/types/campaigns";
import { WebUserType } from "@/types/webUsers";
import { processUserData } from "@/utils/user";
import { processChannelData } from "@/utils/channel";
import { processExternalLinkData } from "@/utils/externalLink";
import { processCustomerContactData } from "@/utils/customerContact";
import { TicketType } from "@/types/tickets";
import { processTicketsDataBeforeAddingInDb } from "@/components/Tickets/utils";
import { processCampaignData } from "@/utils/broadcasts";
import { fetchSlackAuthUrl } from "@/api/kanban";
import { useAuthStorePersist } from "@/store/authStorePersist";
import { useGlobalStore } from "@/store/globalStore";
import { get } from "lodash";
import { processSubstatusData } from "@/utils/subStatus";

const startDate = getEpochByDifference("LAST_30_DAYS");
const endDate = getTodayLastEpoch();

const useBootstrap = () => {
  const boot = useGlobalStorePersist((state) => state.boot);
  const dispatch = useGlobalStorePersist((state) => state.dispatch);

  const updateModelStatus = (
    model: keyof GlobalStoreType["boot"]["model"],
    status: keyof ModelState,
    statusValue: boolean
  ) => {
    dispatch({
      type: "SET_BOOT_MODEL_STATUS",
      payload: {
        model,
        status,
        statusValue,
      },
    });
  };

  useInfiniteFetcher({
    query: {
      limit: 2000,
      type: "partial",
      model: "requests",
      end_date: endDate.toString(),
      start_date: startDate.toString(),
    },
    onFetchingDone: (data) => {
      if (data) {
        const list = data.pages
          .map((page) => page.results)
          .flat() as RequestType[];
        const listWithDate = list.map(processRequestData);
        getThenaDB()
          .requests.bulkPut(listWithDate)
          .then(() => {
            console.log("30 Days Requests added to DB");
            updateModelStatus("requests", "completed", true);
          })
          .catch((error) => {
            console.log("Error adding 30 Days Requests to DB", error);
          });
      }
    },
    queryKey: "bootstrap-requests",
    enabled: !boot.model.requests.completed,
    onFetch: () => {
      updateModelStatus("requests", "started", true);
    },
    onError: () => {
      updateModelStatus("requests", "isErrored", true);
    },
  });

  useInfiniteFetcher({
    query: {
      limit: 2000,
      type: "partial",
      model: "external_tickets",
      end_date: "",
      start_date: "",
    },
    onFetchingDone: (data) => {
      if (data) {
        const list = data.pages.map((page) => page.results).flat();
        const updatedList = list.map(processTicketsDataBeforeAddingInDb);
        getThenaDB()
          .tickets.bulkPut(updatedList as unknown as TicketType[])
          .then(() => {
            console.log("All Tickets added to DB");
            updateModelStatus("tickets", "completed", true);
          })
          .catch((error) => {
            console.log("Error adding tickets to db", error);
          });
      }
    },
    queryKey: "bootstrap-external_tickets",
    enabled: !boot.model.tickets.completed,
    onFetch: () => {
      updateModelStatus("tickets", "started", true);
    },
    onError: () => {
      updateModelStatus("tickets", "isErrored", true);
    },
  });

  useInfiniteFetcher({
    query: {
      limit: 1000,
      type: "partial",
      model: "users",
    },
    onFetchingDone: (data) => {
      if (data) {
        const list = data.pages.map((page) => page.results);
        const updatedList = list.flat().map(processUserData);
        getThenaDB()
          .users.bulkPut(updatedList as UserType[])
          .then(() => {
            console.log("All Users added to DB");
            updateModelStatus("users", "completed", true);
          })
          .catch((error) => {
            console.log("Error adding Users to DB", error);
          });
      }
    },
    queryKey: "bootstrap-users",
    enabled: !boot.model.users.completed,
    onFetch: () => {
      updateModelStatus("users", "started", true);
    },
    onError: () => {
      updateModelStatus("users", "isErrored", true);
    },
  });

  useInfiniteFetcher({
    query: {
      limit: 1000,
      type: "partial",
      model: "customer_contacts",
    },
    onFetchingDone: (data) => {
      if (data) {
        const list = data.pages.map((page) => page.results);
        const updatedList = list.flat().map(processCustomerContactData);
        getThenaDB()
          .customerContacts.bulkPut(updatedList as CustomerContactType[])
          .then(() => {
            console.log("All customer contacts added to DB");
            updateModelStatus("customer_contacts", "completed", true);
          })
          .catch((error) => {
            console.log("Error adding customer contacts to DB", error);
          });
      }
    },
    queryKey: "bootstrap-customer-contacts",
    enabled: !boot.model.customer_contacts.completed,
    onFetch: () => {
      updateModelStatus("customer_contacts", "started", true);
    },
    onError: () => {
      updateModelStatus("customer_contacts", "isErrored", true);
    },
  });

  useInfiniteFetcher({
    query: {
      limit: 1000,
      type: "partial",
      model: "relationships",
    },
    onFetchingDone: (data) => {
      if (data) {
        const list = data.pages
          .map((page) => page.results)
          .flat() as RelationshipType[];
        const processedList = list.map(processRelationships);
        getThenaDB()
          .relationships.bulkPut(processedList)
          .then(() => {
            console.log("All Relations added to DB");
            updateModelStatus("relationships", "completed", true);
          })
          .catch((error) => {
            console.log("Error adding Relations to DB", error);
          });
      }
    },
    queryKey: "bootstrap-relations",
    enabled: !boot.model.relationships.completed,
    onFetch: () => {
      updateModelStatus("relationships", "started", true);
    },
    onError: () => {
      updateModelStatus("relationships", "isErrored", true);
    },
  });

  useInfiniteFetcher({
    query: {
      limit: 1000,
      type: "partial",
      model: "external_links",
    },
    onFetchingDone: (data) => {
      if (data) {
        const list = data.pages.map((page) => page.results).flat() as any[];
        const updatedList = list.map(processExternalLinkData);
        getThenaDB()
          .externalLinks.bulkPut(updatedList)
          .then(() => {
            console.log("All external_links added to DB");
            updateModelStatus("external_links", "completed", true);
          })
          .catch((error) => {
            console.log("Error adding external_links to DB", error);
          });
      }
    },
    queryKey: "bootstrap-external_links",
    enabled: !boot.model.external_links.completed,
    onFetch: () => {
      updateModelStatus("external_links", "started", true);
    },
    onError: () => {
      updateModelStatus("external_links", "isErrored", true);
    },
  });

  useInfiniteFetcher({
    query: {
      limit: 1000,
      type: "partial",
      model: "channels",
    },
    onFetchingDone: (data) => {
      if (data) {
        const list = data.pages.map((page) => page.results);
        const updatedList = list.flat().map(processChannelData);
        getThenaDB()
          .channels.bulkPut(updatedList as ChannelType[])
          .then(() => {
            console.log("All Channels added to DB");
            updateModelStatus("channels", "completed", true);
          })
          .catch((error) => {
            console.log("Error adding Channels to DB", error);
          });
      }
    },
    queryKey: "bootstrap-channels",
    enabled: !boot.model.channels.completed,
    onFetch: () => {
      updateModelStatus("channels", "started", true);
    },
    onError: () => {
      updateModelStatus("channels", "isErrored", true);
    },
  });

  useInfiniteFetcher({
    query: {
      limit: 1000,
      type: "partial",
      model: "campaigns",
    },
    onFetchingDone: (data) => {
      if (data) {
        let list = data.pages.map((page) => page.results).flat();
        list = list.map(processCampaignData);
        getThenaDB()
          .campaigns.bulkPut(list as CampaignType[])
          .then(() => {
            console.log("All Campaigns added to DB");
            updateModelStatus("campaigns", "completed", true);
          })
          .catch((error) => {
            console.log("Error adding Campaigns to DB", error);
          });
      }
    },
    queryKey: "bootstrap-campaigns",
    enabled: !boot.model.campaigns?.completed,
    onFetch: () => {
      updateModelStatus("campaigns", "started", true);
    },
    onError: () => {
      updateModelStatus("campaigns", "isErrored", true);
    },
  });

  useInfiniteFetcher({
    query: {
      limit: 1000,
      type: "partial",
      model: "web_users",
    },
    onFetchingDone: (data) => {
      if (data) {
        const list = data.pages.map((page) => page.results);
        getThenaDB()
          .webUsers.bulkPut(list.flat() as WebUserType[])
          .then(() => {
            console.log("All Web Users added to DB");
            updateModelStatus("web_users", "completed", true);
          })
          .catch((error) => {
            console.log("Error adding Web Users to DB", error);
          });
      }
    },
    queryKey: "bootstrap-web-users",
    enabled: !boot.model.web_users.completed,
    onFetch: () => {
      updateModelStatus("web_users", "started", true);
    },
    onError: () => {
      updateModelStatus("web_users", "isErrored", true);
    },
  });

  useInfiniteFetcher({
    query: {
      type: "partial",
      model: "setup",
    },
    onFetchingDone: (data) => {
      if (data) {
        const customFields = get(
          data,
          "pages[0].results[0].setup.features.requests.categories",
          []
        );

        if (Array.isArray(customFields)) {
          getThenaDB()
            .customFields.bulkPut(customFields)
            .then(() => {
              console.log("All Custom Fields added to DB");
              updateModelStatus("setup", "completed", true);
            })
            .catch((error) => {
              console.log("Error adding Custom Fields to DB", error);
            });
        }
      }
    },
    queryKey: "bootstrap-setup",
    enabled: !boot.model.setup.completed,
    onFetch: () => {
      updateModelStatus("setup", "started", true);
    },
    onError: () => {
      updateModelStatus("setup", "isErrored", true);
    },
  });

  const { data, isFetched, isError } = useQuery({
    queryKey: ["bootstrap-sub-statuses"],
    queryFn: fetchSubStatuses,
  });

  const authStoreDispatch = useAuthStorePersist((state) => state.dispatch);

  const { data: userAuthData } = useQuery({
    queryKey: ["fetchSlackAuthUrl"],
    queryFn: fetchSlackAuthUrl,
  });

  useEffect(() => {
    if (userAuthData) {
      authStoreDispatch({
        type: "SET_USER_REPLY_AS_AUTH",
        payload: { isUserAuthorisedForReplyAs: userAuthData.enabled },
      });
    }
  }, [authStoreDispatch, userAuthData]);

  useEffect(() => {
    if (isError) {
      updateModelStatus("subStatuses", "isErrored", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  useEffect(() => {
    if (isFetched && Array.isArray(data) && data.length > 0) {
      const subStatusList = data
        .map((item) => item.sub_status)
        .flat()
        .map(processSubstatusData);

      try {
        getThenaDB()
          .subStatuses.bulkPut(subStatusList)
          .then(() => {
            useGlobalStore.getState().setCustomStatusesFetched(true);
          });
        console.log("All Sub Statuses added to DB");
        updateModelStatus("subStatuses", "completed", true);
      } catch (error) {
        console.log("Error adding Sub Statuses to DB", error);
        updateModelStatus("subStatuses", "isErrored", true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isFetched]);
};

export default useBootstrap;
