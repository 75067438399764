/* eslint-disable max-lines */
import { fetchAssistantAIStatus } from "@/api/editorAi";
import { fetchIntegrations } from "@/api/integrations";
import {
  fetchAllMacros,
  fetchCRMSelectedFields,
  fetchEnabledConnector,
  fetchExternalLinksForm,
} from "@/api/kanban";
import { Card } from "@/components/ui/card";
import { Progress } from "@/components/ui/progress";
import {
  API_ROOT,
  PUSHER_APP_CLUSTER,
  PUSHER_APP_KEY,
} from "@/config/constants";
import {
  AI_ASSISTANT_QUERY_KEY,
  ASSISTANT_TYPE,
} from "@/constants/aiAssistant";
import { addCustomIndexes, getThenaDB } from "@/db";
import useBootstrap from "@/hooks/useBootstrap";
import { initDuckDB, truncateTables } from "@/hooks/useDuckDBInit";
import useSkipFirstRender from "@/hooks/useFirstRender";
import useIsEnabledRoute from "@/hooks/useIsEnabledRoute";
import useViewsInit from "@/hooks/useViewsInit";
import { useAnalyticsStore } from "@/store/analyticsStore";
import { useAnalyticsPersist } from "@/store/analyticsStorePersist";
import { useAIAssistStore } from "@/store/assistantStore";
import { useAuthStorePersist } from "@/store/authStorePersist";
import { useDuckDBStore } from "@/store/duckDBStore";
import { useGlobalStore } from "@/store/globalStore";
import { useGlobalStorePersist } from "@/store/globalStorePersist";
import { useIntegrationStorePersist } from "@/store/integrationStorePersist";
import { useKanbanStorePersist } from "@/store/kanbanStorePersist";
import { smartUpdateDBWithTx } from "@/utils/dbUtils";
import { emitInfoToSW } from "@/utils/domUtils";
import { DUCKDB_ARRAY_TYPES, DUCK_DB_CHART_QUERIES } from "@/utils/duckDB";
import { sendErrorToLogRocket } from "@/utils/errors";
import { buildFilters, buildFiltersForTickets } from "@/utils/filters";
import {
  getCustomFieldHashes,
  handleCustomFieldsTableUpdate,
} from "@/utils/requests";
import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { useLiveQuery } from "dexie-react-hooks";
import { useFlags } from "launchdarkly-react-client-sdk";
import { debounce } from "lodash";
import { ReactNode, useEffect, useRef } from "react";
import { IntegrationsList } from "../ConnectedApps/definitions";

const Init = () => {
  useBootstrap();
  return null;
};

const ViewsInit = () => {
  useViewsInit();
  return null;
};

const buildFiltersFromDB = async () => {
  try {
    const keys = getThenaDB().requests.schema.indexes.map((item) => item.name);
    const filtersList = await buildFilters(keys);
    getThenaDB().filters.bulkPut(filtersList);
    console.log("All Filters added to DB");
  } catch (error) {
    console.log("Error adding Filters to DB", error);
  }
};

const buildFiltersFromDBDebounced = debounce(buildFiltersFromDB, 500, {
  leading: true,
});

const buildFiltersForTicketsFromDB = async () => {
  try {
    const keys = getThenaDB().tickets.schema.indexes.map((item) => item.name);
    const filtersList = await buildFiltersForTickets(keys);
    getThenaDB()
      .ticketfilters.bulkPut(filtersList)
      .then(() => {
        console.log("All tickets Filters added to DB");
      })
      .catch((error) => {
        console.log("Error tickets adding Filters to DB", error);
      });
  } catch (error) {
    console.log("Error adding Ticket Filters to DB", error);
  }
};

const Bootstrap = ({ children }: { children: ReactNode }) => {
  const boot = useGlobalStorePersist((state) => state.boot);
  const progress = useGlobalStorePersist((state) => state.progress);
  const dispatch = useGlobalStorePersist((state) => state.dispatch);
  const flags = useFlags();

  useEffect(() => {
    getThenaDB().on("blocked", function () {
      console.log("[Thena] DB is blocked, upgrade on hold");
      // sendErrorToLogRocket({
      //   error: "[Thena] DB is blocked, upgrade on hold",
      // });
    });
  }, []);

  useEffect(() => {
    const numberOfModelsCompleted = Object.values(boot.model).filter(
      (model) => model?.completed || model?.isErrored
    ).length;
    dispatch({
      type: "SET_PROGRESS",
      payload: { progress: (numberOfModelsCompleted / 4) * 100 || 5 },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boot]);

  const isBootCompleted =
    boot.model.users.completed &&
    boot.model.channels.completed &&
    boot.model.requests.completed &&
    boot.model.relationships.completed &&
    boot.model.subStatuses.completed &&
    boot.model.setup.completed &&
    boot.model.customer_contacts.completed &&
    boot.model.external_links.completed &&
    boot.model.tickets.completed;

  const { data, isFetched } = useQuery({
    queryKey: ["fetchExternalLinksForm"],
    queryFn: fetchExternalLinksForm,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnMount: true,
  });

  useEffect(() => {
    if (isFetched && data) {
      useKanbanStorePersist.dispatch({
        type: "SET_EXTERNAL_LINKS_FORM",
        payload: { externalLinksForm: data },
      });
    }
  }, [data, isFetched]);

  const isKanbanReady = useGlobalStore((state) => state.isKanbanReady);
  const isDBIndexed = useGlobalStorePersist((state) => state.isDBIndexed);

  const shouldStartBuildingFilters = isDBIndexed && isKanbanReady;

  const isFilterDirtyRef = useRef(false);

  useLiveQuery(() => {
    if (shouldStartBuildingFilters) {
      const usersQuery = getThenaDB().users.limit(1).primaryKeys();
      const requestsQuery = getThenaDB().requests.limit(1).primaryKeys();
      const relationshipsQuery = getThenaDB()
        .relationships.limit(1)
        .primaryKeys();

      Promise.all([usersQuery, requestsQuery, relationshipsQuery]).then(() => {
        isFilterDirtyRef.current = true;
      });
    }
  }, [shouldStartBuildingFilters]);

  useEffect(() => {
    const init = () => {
      if (isFilterDirtyRef.current) {
        isFilterDirtyRef.current = false;
        buildFiltersFromDBDebounced();
        buildFiltersForTicketsFromDB();
      }
    };
    window.addEventListener("FILTERS_LANE_CLICKED", init);
    return () => {
      window.removeEventListener("FILTERS_LANE_CLICKED", init);
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      if (isBootCompleted && !isDBIndexed) {
        try {
          const customFields = await getThenaDB().customFields.toArray();
          const validCustomFields = getCustomFieldHashes(customFields);
          if (!validCustomFields.length) {
            await buildFiltersFromDBDebounced();
            await buildFiltersForTicketsFromDB();
            useGlobalStorePersist.dispatch({
              type: "SET_DB_INDEXED_STATUS",
              payload: { isDBIndexed: true },
            });
            return;
          }
          addCustomIndexes(
            "requests",
            validCustomFields.map((item) => `*custom_${item}`),
            () => {}
          ).then(async () => {
            await buildFiltersFromDBDebounced();
            await buildFiltersForTicketsFromDB();
            useGlobalStorePersist.dispatch({
              type: "SET_DB_INDEXED_STATUS",
              payload: { isDBIndexed: true },
            });
          });
        } catch (error) {
          console.log("Error adding custom indexes", error);
          useGlobalStorePersist.dispatch({
            type: "SET_DB_INDEXED_STATUS",
            payload: { isDBIndexed: true },
          });
        }
      }
    };
    init();
  }, [isBootCompleted, isDBIndexed]);

  const isWorkerDone =
    useGlobalStorePersist((state) => state.worker.status) === "DONE";

  useEffect(() => {
    if (!isDBIndexed || isWorkerDone) {
      return;
    }
    const workerState = useGlobalStorePersist.getState().worker.status;
    if (workerState !== "DONE") {
      const worker = new Worker(
        new URL("@/workers/bootstrap-worker.js", import.meta.url),
        { type: "module" }
      );

      worker.onerror = function (event) {
        console.log("WORKER_ERROR", event);
        useGlobalStorePersist.dispatch({
          type: "SET_WORKER_STATUS",
          payload: { status: "ERROR" },
        });
      };

      useGlobalStorePersist.dispatch({
        type: "SET_WORKER_STATUS",
        payload: { status: "INITIALIZED" },
      });

      const query = new URLSearchParams();
      query.set("type", "partial");
      query.set("model", "requests");
      query.set("limit", "1000");

      worker.postMessage({
        query: query.toString(),
        API_ROOT: API_ROOT,
      });

      worker.onmessage = async function (event) {
        if (event.data.type === "WORK_DONE") {
          if (Array.isArray(event.data.data) && event.data.data.length > 0) {
            try {
              smartUpdateDBWithTx(event.data.data, "requests");
              console.log("All Requests added to DB from Worker");
            } catch (error) {
              console.log("Error adding all Requests to DB from Worker", error);
            }
          }
          worker.terminate();
          useGlobalStorePersist.dispatch({
            type: "SET_WORKER_STATUS",
            payload: { status: "DONE" },
          });
        } else {
          console.log("WORKER_ERROR", event.data);
          sendErrorToLogRocket({
            error: "WORKER_ERROR",
            extra: {
              reason: event.data,
            },
          });
          useGlobalStorePersist.dispatch({
            type: "SET_WORKER_STATUS",
            payload: { status: "ERROR" },
          });
        }
      };
    }
  }, [isDBIndexed, isWorkerDone]);

  const isSWActivated = useGlobalStorePersist((state) => state.isSWActivated);

  useEffect(() => {
    if ((isDBIndexed && isBootCompleted) || isSWActivated) {
      const { id, team_id, displayName } = useAuthStorePersist.getState().user;
      emitInfoToSW({
        type: "INIT",
        user: {
          id,
          team_id,
          displayName,
        },
        pusher: {
          PUSHER_APP_KEY,
          PUSHER_APP_CLUSTER,
        },
      });
    }

    return () => {
      //
    };
  }, [isDBIndexed, isSWActivated, isBootCompleted]);

  const isEnabledRoute = useIsEnabledRoute();

  const { data: crmData, isFetched: crmFetched } = useQuery({
    queryKey: ["fetchCRMSelectedFields"],
    queryFn: fetchCRMSelectedFields,
    enabled: isKanbanReady || isEnabledRoute,
  });

  useEffect(() => {
    if (crmFetched && crmData?.crm && Array.isArray(crmData?.fields)) {
      try {
        getThenaDB().crmFields.put({
          crm: crmData.crm,
          fields: crmData.fields,
        });
        console.log("All CRM Fields added to DB");
      } catch (error) {
        console.log("Error adding CRM Fields to DB", error);
      }
    }
  }, [crmData, crmFetched]);

  const { data: allConnectors, isFetched: connectorFetched } = useQuery({
    queryKey: ["connectors"],
    queryFn: fetchEnabledConnector,
    enabled: isKanbanReady || isEnabledRoute,
  });

  useEffect(() => {
    if (connectorFetched && allConnectors && allConnectors.data) {
      try {
        getThenaDB().connectors.put({
          name: allConnectors.data?.name || "",
          requestType: allConnectors.data?.requestType || "",
          additionalFields: allConnectors.data?.additionalFields || [],
          icon: allConnectors.data?.icon || "",
        });
        console.log("Connectors added to DB");
      } catch (error) {
        console.log("Error adding Connectors to DB", error);
      }
    }
  }, [allConnectors, connectorFetched]);

  //add macros here

  const { data: macros, isFetched: macrosFetched } = useQuery({
    queryKey: ["macros"],
    queryFn: fetchAllMacros,
  });

  useEffect(() => {
    if (macrosFetched && macros) {
      try {
        getThenaDB().macros.bulkPut(macros);
        console.log("All Macros added to DB");
      } catch (error) {
        console.log("Error adding MAcros to DB", error);
      }
    }
  }, [macros, macrosFetched]);

  const isBroadcastWorkerDone =
    useGlobalStorePersist((state) => state.broadcastWorker.status) === "DONE";

  useEffect(() => {
    if (isBroadcastWorkerDone) {
      return;
    }
    const workerState = useGlobalStorePersist.getState().broadcastWorker.status;
    if (workerState !== "DONE") {
      const worker = new Worker(
        new URL("@/workers/broadcast-worker.js", import.meta.url),
        { type: "module" }
      );

      worker.onerror = function (event) {
        console.log("WORKER_ERROR", event);
        useGlobalStorePersist.dispatch({
          type: "SET_BROADCAST_WORKER_STATUS",
          payload: { status: "ERROR" },
        });
      };

      useGlobalStorePersist.dispatch({
        type: "SET_BROADCAST_WORKER_STATUS",
        payload: { status: "INITIALIZED" },
      });
      worker.postMessage(undefined);
      worker.onmessage = async function (event) {
        if (event.data.type === "WORK_DONE") {
          if (Array.isArray(event.data.data) && event.data.data.length > 0) {
            try {
              getThenaDB().campaignTemplates.bulkPut(event.data.data);
              console.log("All Templates added to DB from Worker");
            } catch (error) {
              console.log(
                "Error adding all Templates to DB from Worker",
                error
              );
            }
          }
          worker.terminate();
          useGlobalStorePersist.dispatch({
            type: "SET_BROADCAST_WORKER_STATUS",
            payload: { status: "DONE" },
          });
        } else {
          console.log("BROADCAST_WORKER_ERROR", event.data);
          sendErrorToLogRocket({
            error: "WORKER_ERROR",
            extra: {
              reason: event.data,
            },
          });
          useGlobalStorePersist.dispatch({
            type: "SET_BROADCAST_WORKER_STATUS",
            payload: { status: "ERROR" },
          });
        }
      };
    }
  }, [isBroadcastWorkerDone]);

  const statusQuery: UseQueryResult<any> = useQuery({
    queryFn: fetchAssistantAIStatus,
    queryKey: [AI_ASSISTANT_QUERY_KEY],
    enabled: flags.aiAssistant === true,
  });
  const {
    data: assistantData,
    isFetched: isAssistantFetched,
    refetch: reFetchAssistants,
  } = statusQuery;

  const { assistants, setAssistants } = useAIAssistStore();

  useEffect(() => {
    if (flags.aiAssistant === true) {
      const handleVisibilityChange = () => {
        reFetchAssistants();
      };
      window.addEventListener("visibilitychange", handleVisibilityChange);
      return () => {
        window.removeEventListener("visibilitychange", handleVisibilityChange);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flags.aiAssistant]);

  useEffect(() => {
    if (isAssistantFetched) {
      setAssistants(assistantData);
    }
  }, [assistantData, setAssistants, isAssistantFetched]);
  useEffect(() => {
    if (assistants && assistants.length) {
      let activeCSAssistant: any = null;
      let activeIHAssistant: any = null;
      let hasSourcesCS: boolean = false;
      let hasSourcesIH: boolean = false;

      if (flags.aiAssistant) {
        const activeCSAssistants = assistants.filter(
          (a) => a.type === ASSISTANT_TYPE.CUSTOMER_SUPPORT && a.active
        );
        activeCSAssistant =
          activeCSAssistants.length > 0 ? activeCSAssistants[0] : null;
        hasSourcesCS =
          activeCSAssistants.length > 0
            ? activeCSAssistants[0].num_sources > 0
            : false;

        const activeIHAssistants = assistants.filter(
          (a) => a.type === ASSISTANT_TYPE.INTERNAL_HELPDESK && a.active
        );
        activeIHAssistant =
          activeIHAssistants.length > 0 ? activeIHAssistants[0] : null;
        hasSourcesIH =
          activeIHAssistants.length > 0
            ? activeIHAssistants[0].num_sources > 0
            : false;
      }
      useGlobalStorePersist.dispatch({
        type: "SET_AI_ASSISTANT_DATA",
        payload: {
          cs: activeCSAssistant ? activeCSAssistant?._id : null,
          ih: activeIHAssistant ? activeIHAssistant?._id : null,
        },
      });
      useGlobalStorePersist.dispatch({
        type: "SET_AI_ASSISTANT_DATA_HAS_SOURCES",
        payload: { cs: hasSourcesCS, ih: hasSourcesIH },
      });
    }
  }, [assistants, flags.aiAssistant]);

  const shouldRenderMainContent =
    isBootCompleted && progress >= 100 && isDBIndexed;

  useEffect(() => {
    if (
      shouldRenderMainContent &&
      useGlobalStore.getState().duckDBInitCount === 0
    ) {
      initDuckDB();
    }
  }, [shouldRenderMainContent]);

  const shouldUpdateCustomField = isBootCompleted && isDBIndexed;

  useEffect(() => {
    if (shouldUpdateCustomField) {
      handleCustomFieldsTableUpdate();
    }
  }, [shouldUpdateCustomField]);

  const skipFirstRender = useSkipFirstRender();
  const isAllDataLoadedFromWorker =
    useGlobalStorePersist((state) => state.worker.status) === "DONE";

  useEffect(() => {
    const runThenaDBQuery = async (query = "") => {
      try {
        const connection = useDuckDBStore.getState().dbConnection;
        console.time("Query Execution Time");
        const result = await connection.query(query);
        console.timeEnd("Query Execution Time");
        // console.log({ queryResult: result });

        const data: any[] = [];
        for (const row of result) {
          const rowData = {};
          for (const rowItem of Object.entries(row)) {
            const [key, value]: any[] = rowItem;
            if (DUCKDB_ARRAY_TYPES.includes(value?.constructor?.name)) {
              rowData[key] = value.toArray();
            } else if (
              Array.isArray(value) ||
              typeof value?.toArray === "function"
            ) {
              rowData[key] = value.toArray();
            } else {
              rowData[key] = value;
            }
          }
          data.push(rowData);
        }
        // console.log({ processedData: data });
        return data;
      } catch (error) {
        console.log("Error running query", error);
      }
    };

    window.runThenaDBQuery = runThenaDBQuery;

    return () => {
      // @ts-expect-error TS does not know about this global function
      window.runThenaDBQuery = undefined;
    };
  }, []);

  const duckDBInitCount = useGlobalStore((state) => state.duckDBInitCount);

  useEffect(() => {
    if (skipFirstRender) return;

    const init = async () => {
      if (isAllDataLoadedFromWorker && duckDBInitCount === 1) {
        const dbConn = useDuckDBStore.getState().dbConnection;
        await truncateTables(dbConn);
        await initDuckDB();

        useAnalyticsStore
          .getState()
          .setTriggerRender(useAnalyticsStore.getState().triggerRender + 1);

        const getOldDate = await window.runThenaDBQuery(
          DUCK_DB_CHART_QUERIES.getStartDate()
        );

        useAnalyticsPersist.dispatch({
          type: "SET_OLD_DATE",
          payload: { oldestDate: getOldDate && getOldDate[0] },
        });
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllDataLoadedFromWorker, duckDBInitCount]);
  const integrationDispatcher = useIntegrationStorePersist(
    (state) => state.dispatch
  );

  const { data: integrationData, isLoading } = useQuery({
    queryKey: ["integrations"],
    queryFn: fetchIntegrations,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });
  useEffect(() => {
    if (integrationData) {
      const integrationsObjectById = (integrationData || []).reduce(
        (acc: any, intg: any) => ({ ...acc, [intg.id]: intg }),
        {}
      );
      const showConnectedApps =
        useIntegrationStorePersist.getState().showConnectedApps;
      const temp = IntegrationsList.map((i) => {
        if (integrationsObjectById[i.id]) {
          if (!showConnectedApps && integrationsObjectById[i.id]?.hasConnected)
            integrationDispatcher({
              type: "SET_CONNECTED_APPS",
              payload: true,
            });
          return { ...i, ...integrationsObjectById[i.id] };
        }
        return {
          ...i,
          configured: false,
          hasConnected: false,
          showSwitch: false,
          showDelete: false,
          requestTypes: [],
        };
      });
      integrationDispatcher({
        type: "INIT",
        payload: temp,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationData]);

  useEffect(() => {
    integrationDispatcher({
      type: "SET_LOADER",
      payload: isLoading,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (shouldRenderMainContent) {
    return (
      <>
        {isKanbanReady && <ViewsInit />}
        {children}
      </>
    );
  }

  return (
    <>
      <Init />
      <ViewsInit />
      <div className="flex items-center justify-center h-screen w-screen">
        <Card className="w-[500px]">
          <div className="h-[200px] flex flex-col justify-center">
            <h1 className="flex items-center justify-center">
              Setting up your workspace
            </h1>
            <div className="h-[20px] flex items-center justify-center mt-2">
              <Progress
                value={isBootCompleted ? 100 : progress}
                className="w-[300px] h-[6px]"
              />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
export default Bootstrap;
