import { useClockStore } from "@/store/clockStore";
import { useEffect } from "react";

function Clock() {
  const clockStore = useClockStore();

  useEffect(() => {
    const tick = () => {
      clockStore.dispatch({
        type: "UPDATE_TIME_EVERY_MIN",
        payload: new Date().getTime().toString(),
      });
    };
    const timerID = setInterval(tick, 60000);
    return () => {
      clearInterval(timerID);
    };
  }, [clockStore]);

  return null;
}

export default Clock;
