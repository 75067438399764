import { create } from "zustand";
import { devtools, persist, redux } from "zustand/middleware";

export type StoreType = {
  isCollapsed: boolean;
};

type ActionType = {
  type: "SET_COLLAPSED";
  payload?: Record<string, unknown>;
};

const reducer = (state: StoreType, action: ActionType): StoreType => {
  const { type, payload } = action;

  switch (type) {
    case "SET_COLLAPSED": {
      const isCollapsed = payload?.isCollapsed as StoreType["isCollapsed"];
      return {
        ...state,
        isCollapsed,
      };
    }

    default:
      return state;
  }
};

const initalState: StoreType = {
  isCollapsed: false,
};

const useLayoutStorePersist = create(
  persist(
    devtools(redux(reducer, initalState), {
      name: "useLayoutStorePersist",
    }),
    {
      name: "useLayoutStorePersist",
      version: 0.1,
    }
  )
);

export { useLayoutStorePersist };
